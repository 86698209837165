import React, { useEffect, useState } from 'react'
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import { useDispatch, useSelector } from 'react-redux';
import SortingTable from '../../../../components/table/SortingTable/SortingTable';
// import MPAG_Modal from '../../../../components/DATAPLUS_Components/DATAPLUS_Modal';
import GCForm from './GCForm';
import Filtering from '../../../../components/DATAPLUS_Components/Filtering';
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Modal,
  ProgressBar,
  Button,
} from "react-bootstrap";
import CommonActions from '../../../../../store/actions/common-actions';
import { Urls } from '../../../../../utils/url';
import { ALERTS } from '../../../../../store/reducers/component-reducer';
import EditModal from '../../../../components/EditModal';
// import Filtering from '../../../../components/DATAPLUS_Components/Filtering';
import FilterModal from '../../../../components/DATAPLUS_Components/FilterModal';
import ActionsModal from '../../../../components/DATAPLUS_Components/ActionsModal';
import FormModal from '../../../../components/DATAPLUS_Components/FormModal';
import { Link, useNavigate } from 'react-router-dom';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';

const GC = () => {

  const [form, setForm] = useState(false)
  const [actions, setActions] = useState(false)
  const [callDelete, setCallDelete] = useState(false)
  const [itmValue, setItmValue] = useState({})
  const [alerts, setAlerts] = useState(false)

  const navigate = useNavigate

  // MPAG modal ,edit modal and filtering table
  // const tableHeading = "GC"
  const modalButton = "Edit GC";
  let FormComponent = (GCForm)
  // const modalHeading  =  "Edit GC"
  let showFilter = false
  const AddFormButtonName= '+ GC'
  const AddFormNavigate ="/DATAPLUS/Add/Gc"
 

  // const AddFormButton = (<Button style={{ margin: "10px" }}
  //   variant="primary"
  //   className="close"
  //   onClick={ navigate("/DATAPLUS/AddGc")}
  // >
  //   + Add GC
  // </Button>)

  
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminDashboardActions.getGCList())
  }, [])

  const Data = useSelector((state) => state?.AdminDashboard_reducer?.getGCList)
  console.log(Data, "khabasdasdsdjsd")

  let itemUniqueId = ""
  let tableData = Data?.map((itm, index) => {
    itemUniqueId = itm.uniqueid
    return {
      ...itm,
      "sNo": index + 1,
      // "accountName": itm.accountName,
      // "marketName":itm.marketName,
      // "mobilenumber":itm.mobilenumber,
      // "name":itm.name,
      "crewCompanyName": itm.crewresult.map((bar) => { return bar.crewCompanyName }),
      // "email":itm.email,
      // "mobileAppAccess":itm.mobileAppAccess,
      // "password":itm.password,
      "userrole": itm.roleresult.poc === true ? "Yes" : "No",
      // "crewType":itm.crewType,
      "actions": ((<Dropdown>
        <Dropdown.Toggle
          variant="danger"
          className="light sharp i-false"
        >
          {svg1}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => {

            // dispatch(adminDashboardActions.getManageAccountList());
            // console.log("onclick","onclick");
            // setForm(true)
            // setItmValue(itm)
            // console.log("ajgvsdk", "hdxgcbj")

            // console.log("ahshshhs", itm);
            // setmodalOpen(false )
          }}><Link to={`/DATAPLUS/AddGc/${itm.uniqueid}`}>Edit</Link></Dropdown.Item>
          <Dropdown.Item onClick={() => {
            console.log(itm.uniqueid, "dxgchj")
            setActions(true)
           
            // setItmValue(itm)
            //   dispatch(
            //   CommonActions.deleteApiCaller(
            //     `${Urls.Delete_GC_Item}/${itm.uniqueid}`,
            //     () => {

            //       console.log(itm.uniqueId, "dgcfk")
            //       dispatch(
            //         adminDashboardActions.getGCList()
            //       );
            //       dispatch(ALERTS({ show: false }));
            //     }
            //   )
            // );
          }}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>))

    }

  })

  console.log(itemUniqueId, "itemUniqueId")
  const tableColumns = [
    {
      Header: 'S No.',
      // Footer: 'S No',
      accessor: 'sNo',
      Filter: ColumnFilter,
    },
    {
      Header: 'ACCOUNT',
      // Footer: 'account',
      accessor: "accountName",
      Filter: ColumnFilter,
    },
    {
      Header: 'MARKET',
      // Footer: 'S No',
      accessor: 'marketName',
      Filter: ColumnFilter,
    },
    {
      Header: 'MOBILE NUMBER',
      // Footer: 'account',
      accessor: "mobilenumber",
      Filter: ColumnFilter,
    },
    {
      Header: 'NAME',
      // Footer: 'S No',
      accessor: 'name',
      Filter: ColumnFilter,
    },
    {
      Header: 'CREW LEAD COMPANY',
      // Footer: 'account',
      accessor: "crewCompanyName",
      Filter: ColumnFilter,
    },
    {
      Header: 'EMAIL',
      // Footer: 'S No',
      accessor: 'email',
      Filter: ColumnFilter,
    },
    {
      Header: 'CREW LEAD COMPANY MOBIILE-APP ACCESS',
      // Footer: 'account',
      accessor: "mobileAppAccess",
      Filter: ColumnFilter,
    },
    {
      Header: 'PASSWORD',
      // Footer: 'S No',
      accessor: 'password',
      Filter: ColumnFilter,
    },
    {
      Header: 'POC',
      // Footer: 'account',
      accessor: "userrole",
      Filter: ColumnFilter,
    },
    {
      Header: 'CREW TYPE',
      // Footer: 'S No',
      accessor: 'crewType',
      Filter: ColumnFilter,
    },
    {
      Header: 'ACTION',
      // Footer: 'account',
      accessor: "actions",
      Filter: "",
    },


  ]



  const GROUPED_COLUMNS = [
    {
      Header: 'Id',
      Footer: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Name',
      Footer: 'Name',
      columns: [
        {
          Header: 'First Name',
          Footer: 'First Name',
          accessor: 'first_name'
        },
        {
          Header: 'Last Name',
          Footer: 'Last Name',
          accessor: 'last_name'
        },
      ]
    },
    {
      Header: 'Info',
      Footer: 'Info',
      columns: [
        {
          Header: 'Date of  Birth',
          Footer: 'Date of  Birth',
          accessor: 'date_of_birth'
        },
        {
          Header: 'Country',
          Footer: 'Country',
          accessor: 'country',
        },
        {
          Header: 'Phone',
          Footer: 'Phone',
          accessor: 'phone'
        },
      ]
    },
  ]

  if (callDelete) {
    dispatch(
      CommonActions.deleteApiCaller(
        `${Urls.Delete_GC_Item}/${itemUniqueId}`,
        () => {

          console.log(itemUniqueId, "dgcfk")
          dispatch(
            adminDashboardActions.getGCList()
          );
          dispatch(ALERTS({ show: false }));
        }
      )
    );
    setCallDelete(false)

  }

  return (
    <>
    {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} />}
      {/* {alerts && <AlertsModal/>} */}
      
      {actions && <ActionsModal setAlerts={setAlerts} actions={actions} setCallDelete={setCallDelete} setActions={setActions} />}

      {/* {modal && <EditModal modalHeading={modalHeading} setModal={setModal} isOpen={modal} FormComponent={FormComponent} itmValue={itmValue} />} */}


      {/* {form && <FormModal modalHeading={modalButton} isOpen={form} setModal={setForm} FormComponent={FormComponent} itmValue={itmValue}/>} */}

      {/* <MPAG_Modal modalButton={modalButton} FormComponent={FormComponent} /> */}
      {/* <MPAG_Modal tableColumns={tableColumns} tableData={tableData}  /> */}
      {/* <FilterModal FormComponent={Filter} tableColumns={tableColumns} tableData={tableData} modalButton={filterButton}/> */}
      {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
      <div>
        <FilteringTable 
        AddFormNavigate={AddFormNavigate}
         AddFormButtonName={AddFormButtonName} 
         showFilter={showFilter} 
         tableColumns={tableColumns} 
         tableData={tableData}  
        //  item={itemUniqueId}
         />
      </div>
    </>
  )
}

export default GC