import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
//import "swiper/css";
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from './layouts/ScrollToTop';
/// Dashboard
import Home from './components/Dashboard/Home';
import EventList from './components/Dashboard/EventList';
import EventPage from './components/Dashboard/EventPage';
import Analytics from './components/Dashboard/Analytics';
import Reviews from './components/Dashboard/Reviews';
import DashboardCustomers from './components/Dashboard/Customers';
import Task from './components/Dashboard/Task';

//Demo
import DashboardLight from './components/Dashboard/demo/DashboardLight';
import Theme1 from './components/Dashboard/demo/Theme1';
import Theme2 from './components/Dashboard/demo/Theme2';
//import Theme3 from './components/Dashboard/demo/Theme3';
import Theme4 from './components/Dashboard/demo/Theme4';
import Theme5 from './components/Dashboard/demo/Theme5';
import Theme6 from './components/Dashboard/demo/Theme6';


//Content
import Content from './components/Cms/Content';
import Menu from './components/Cms/Menu';
import EmailTemplate from './components/Cms/EmailTemplate';
import Blog from './components/Cms/Blog';


//Ticket
import CreateTicket from './components/Ticket/CreateTicket';
import AllTicket from './components/Ticket/AllTicket';

//Customers
import Chat from './components/Customers/Chat';
import CustomersList from './components/Customers/CustomersList';

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
//import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
//import Nestable from "./components/PluginsMenu/Nestable/Nestable";
//import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";
//import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
//import WizardForm from "./components/Forms/ReduxWizard/Index";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import Reports from "./pages/Reports";


// DATAPLUS
import CustomerDashboard from "./pages/DATAPLUS/MPULSE/Customer Dashboard/CustomerDashboard";
import VendorDashboard from "./pages/DATAPLUS/MPULSE/Vendor Dashboard/VendorDashboard";
import AWSDashboard from "./pages/DATAPLUS/MPULSE/AWS Dashboard/AWSDashboard"
import RANDashboard from "./pages/DATAPLUS/RAN SUPPORT/RAN Dashboard/RANDashboard";
import RANTracker from "./pages/DATAPLUS/RAN SUPPORT/RAN Tracker/RANTracker";
import DailyActivityreport from "./pages/DATAPLUS/RAN SUPPORT/Daily Activity Report/DailyActivityReport"
import SLAReport from "./pages/DATAPLUS/RAN SUPPORT/SLA Report/SLAReport"
import KPIDashboard from "./pages/DATAPLUS/KPI Module/KPI Dashboard/KPIDashboard"
import KPITracker from "./pages/DATAPLUS/KPI Module/KPI Tracker/KPITracker"
import KPITroubleTicket from "./pages/DATAPLUS/KPI Module/KPI Trouble Ticket/KPITroubleTicket"
import CIQScriptingDashboard from "./pages/DATAPLUS/CIQ & Scripting/CIQ & Scripting Dashboard/CIQScriptingDashboard"
import TTDashboard from "./pages/DATAPLUS/Trouble Tickets/TT Dashboard/TTDashboard";
import E911Dashboard from "./pages/DATAPLUS/E911 Call test/E91 Dashboard/E911Dashboard"
import PASPDashboard from "./pages/DATAPLUS/E911 Call test/PSAP Dashboard/PSAPDashboard"

import ManageMarkets from "./pages/DATAPLUS/Admin Dashboard/Manage Markets/ManageMarkets"
import ManageCrew from "./pages/DATAPLUS/Admin Dashboard/Manage Crew/ManageCrew"
import ManageCrewsCompany from "./pages/DATAPLUS/Admin Dashboard/Manage Crews Company/ManageCrewsCompany"
import ManageEngineers from "./pages/DATAPLUS/Admin Dashboard/Manage Engineers/ManageEngineers"
import ManageSites from "./pages/DATAPLUS/Admin Dashboard/Manage Sites/ManageSites"
import ManageOSS from "./pages/DATAPLUS/Admin Dashboard/Manage OSS/ManageOSS"
// import ManageUtilization from "./pages/DATAPLUS/Admin Dashboard/Manage Account/ManageAccount"
import UploadMaster from "./pages/DATAPLUS/Admin Dashboard/Upload Master/UploadMaster"
import ManageDropdownList from "./pages/DATAPLUS/Admin Dashboard/Manage Dropdown List/ManageDropdownList"
import GC from "./pages/DATAPLUS/Admin Dashboard/GC/GC";
import UTRANEngineers from "./pages/DATAPLUS/Admin Dashboard/UTRAN Engineers/UTRANEngineers";
import OSS from "./pages/DATAPLUS/Admin Dashboard/OSS/OSS";
import AddCustomer from "./pages/DATAPLUS/Admin Dashboard/Add Customer/AddCustomer"
import IXScheduler from "./pages/DATAPLUS/IX Support/IX Scheduler/IXScheduler";
import IXCalender from "./pages/DATAPLUS/IX Support/IX Calender/IXCalender"
import IXDashboard from "./pages/DATAPLUS/IX Support/IX Dashboard/IXDashboard"
import FELoginForm from "./pages/DATAPLUS/IX Support/IX Dashboard/FELoginForm";
import IntegrationLoginForm from "./pages/DATAPLUS/IX Support/IX Dashboard/IntegrationLoginForm";
import MyProjects from "./pages/DATAPLUS/RAN SUPPORT/My Projects/MyProjects"
import CheckIn from "./components/DATAPLUS_Components/CheckIn";
import Tickets from "./pages/DATAPLUS/Tickets/Tickets";
// import TicketsForm from "./pages/DATAPLUS/Tickets/TicketsForm";
import GONOGOSupport from "./pages/DATAPLUS/GO NOGO Support/GONOGOSupport";
import GONOGOSupportForm from './pages/DATAPLUS/GO NOGO Support/GONOGOSupportForm'
import RunPreCheckForm from "./pages/DATAPLUS/RAN SUPPORT/RAN Dashboard/RunPreCheckForm";
import CheckInRequest from "./pages/DATAPLUS/Check In Request/CheckInRequest";
import GCForm from "./pages/DATAPLUS/Admin Dashboard/GC/GCForm";
import AddCustomerForm from "./pages/DATAPLUS/Admin Dashboard/Add Customer/AddCustomerForm";
import UTRANEngineersForm from "./pages/DATAPLUS/Admin Dashboard/UTRAN Engineers/UTRANEngineersForm";
import OSSForm from "./pages/DATAPLUS/Admin Dashboard/OSS/OSSForm";
import TTDashboardForm from "./pages/DATAPLUS/Trouble Tickets/TT Dashboard/TTDashboardForm";
import KPITroubleTicketForm from "./pages/DATAPLUS/KPI Module/KPI Trouble Ticket/KPITroubleTicketForm";
import ProjectDatabse from "./pages/DATAPLUS/Admin Dashboard/Project Database/ProjectDatabse";
import AddProjectDatabseForm from "./pages/DATAPLUS/Admin Dashboard/Project Database/AddProjectDatabseForm";
import SiteID from "./pages/DATAPLUS/Admin Dashboard/SIte ID/SiteID";
import SiteIdForm from "./pages/DATAPLUS/Admin Dashboard/SIte ID/SiteIdForm";
import Table from "./pages/DATAPLUS/CommonTable";
import Table2 from "./pages/DATAPLUS/Admin Dashboard/Table2";
import Accounts from "./pages/DATAPLUS/Admin Dashboard/Accounts/Accounts";
import AccountsForm from "./pages/DATAPLUS/Admin Dashboard/Accounts/AccountsForm";
// import ManageAccount from "./pages/DATAPLUS/Admin Dashboard/Add Customer/AddCustomer";

// import ExamplePage from "./pages/DATAPLUS/ExamplePage";

const Markup = () => {
  //const { menuToggle } = useContext(ThemeContext);
  const allroutes = [

    // DATAPLUS ROUTES Starts HERE

    // 1 mpulse

    { url: "/DATAPLUScheckIn/dashboard", component: <CheckInRequest/> },
    { url: "/DATAPLUS/customer/dashboard", component: <CustomerDashboard /> },
    { url: "/DATAPLUS/vendor/dashboard", component: <VendorDashboard /> },
    { url: "/DATAPLUS/aws/dashboard", component: <AWSDashboard /> },
    { url: "/DATAPLUS/tickets", component: <Tickets /> },
    // { url: "/DATAPLUS/addTicket", component: <TicketsForm/> },
    { url: '/DATAPLUS/GO-NOGO/dashboard', component: <GONOGOSupport /> },
    { url: '/DATAPLUS/add/GO-NOGO', component: <GONOGOSupportForm /> },

    // 2 RAN SUPPORT
    { url: "/DATAPLUS/projects", component: <MyProjects /> },
    { url: "/DATAPLUS/cx/support/dashboard", component: <RANDashboard /> },
    { url: "/DATAPLUS/add/cx/support", component: <RunPreCheckForm /> },
    // inside randashboard
    { url: "/DATAPLUS/cx/Pre-Check/dashboard/:id", component: <RunPreCheckForm /> },

    // 
    { url: "/DATAPLUS/cx/support/tracker", component: <RANTracker /> },
    { url: "/DATAPLUS/daily/activity/Report", component: <DailyActivityreport /> },
    { url: "/DATAPLUS/sla/report", component: <SLAReport /> },


    // IX Support
    // 3 KPI Module
    { url: "/DATAPLUS/ix/scheduler", component: <IXScheduler /> },
    { url: "/DATAPLUS/ix/calender", component: <IXCalender /> },
    { url: "/DATAPLUS/ix/dashboard", component: <IXDashboard /> },
    { url: "/DATAPLUS/ix/module/FLogin/:id", component: <FELoginForm /> },
    { url: "/DATAPLUS/ix/module/ILogin/:id", component: <IntegrationLoginForm /> },

    // 3 KPI Module
    { url: "/DATAPLUS/kpi/dashboard", component: <KPIDashboard /> },
    { url: "/DATAPLUS/kpi/tracker", component: <KPITracker /> },
    { url: '/DATAPLUS/kpi/trouble/Ticket/dashboard', component: <KPITroubleTicket /> },
    { url: '/DATAPLUS/Add/kpi/trouble/Ticket/dashboard', component: <KPITroubleTicketForm /> },
    { url: '/DATAPLUS/edit/kpi/trouble/Ticket/dashboard/:id', component: <KPITroubleTicketForm /> },

    // 4 Trouble Tickets
    { url: "/DATAPLUS/trouble/Ticket/dashboard", component: <TTDashboard /> },
    { url: "/DATAPLUS/Add/trouble/Ticket/dashboard/", component: <TTDashboardForm /> },
    { url: "/DATAPLUS/Add/trouble/Ticket/dashboard/:id", component: <TTDashboardForm /> },

    // 5 CIQ & Scripting 
    { url: "/DATAPLUS/CIQ/&/Scripting/dashboard", component: <CIQScriptingDashboard /> },

    // 6 E911 Call test
    { url: "/DATAPLUS/e911/dashboard", component: <E911Dashboard /> },
    { url: "/DATAPLUS/psap/dashboard", component: <PASPDashboard /> },

    // 7 Admin Dashboard
    // { url: "/DATAPLUS/manage/account", component: <ManageAccount/> },
    { url: "/DATAPLUS/accounts", component: <Accounts /> },
    { url: "/DATAPLUS/Add/account", component: <AccountsForm /> },
    { url: "/DATAPLUS/Edit/account/:id", component: <AccountsForm /> },
    { url: "/DATAPLUS/markets", component: <AddCustomer /> },
    { url: "/DATAPLUS/Add/markets", component: <AddCustomerForm /> },
    { url: "/DATAPLUS/Edit/markets/:id", component: <AddCustomerForm /> },
    // { url: "/DATAPLUS/market", component: <ManageMarkets /> },
    { url: "/DATAPLUS/gc", component: <GC /> },
    { url: "/DATAPLUS/Add/Gc", component: <GCForm /> },
    { url: "/DATAPLUS/AddGc/:id", component: <GCForm /> },
    { url: "/DATAPLUS/Engineers", component: <UTRANEngineers /> },
    { url: "/DATAPLUS/Add/Engineers", component: <UTRANEngineersForm /> },
    { url: "/DATAPLUS/Edit/Engineers/:id", component: <UTRANEngineersForm /> },
    { url: "/DATAPLUS/enm", component: <OSS /> },
    { url: "/DATAPLUS/Add/enm", component: <OSSForm /> },
    { url: "/DATAPLUS/Edit/enm/:id", component: <OSSForm /> },
    { url: "/DATAPLUS/Project/Progress", component: <ProjectDatabse /> },
    { url: "/DATAPLUS/Add/Project/Progress", component: <AddProjectDatabseForm /> },
    { url: "/DATAPLUS/Edit/Project/Progress/:id", component: <AddProjectDatabseForm /> },
    { url: "/DATAPLUS/site/config", component: <SiteID /> },
    { url: "/DATAPLUS/Add/site/config", component: <SiteIdForm /> },
    { url: "/DATAPLUS/Edit/site/config/:id", component: <SiteIdForm /> },


    /// 8 RAN Tools
    { url: "/DATAPLUS/checkin", component: <CheckIn /> },





    // { url: "/DATAPLUS/userCrew", component: <ManageCrew /> },
    // { url: "//DATAPLUS/manage/crewcompany", component: <ManageCrewsCompany /> },
    // { url: "/DATAPLUS/manageEngineers", component: <ManageEngineers /> },
    // { url: "/DATAPLUS/manageSites", component: <ManageSites /> },
    // { url: "/DATAPLUS/manage/oss", component: <ManageOSS /> },
    // { url: "/DATAPLUS/manage/utilization", component: <ManageUtilization /> },
    // { url: "/DATAPLUS/form-redux-wizard", component: <UploadMaster /> },
    // { url: "/DATAPLUS/manage/dropDown", component: <ManageDropdownList /> },





    // DATAPLUS ROUTES ends HERE


    /// Dashboard
    { url: "", component: <Home /> },
    { url: 'dashboard', component: <Home /> },
    { url: 'dashboard-light', component: <DashboardLight /> },
    { url: 'event-list', component: <EventList /> },
    { url: 'event', component: <EventPage /> },
    { url: 'analytics', component: <Analytics /> },
    { url: 'reviews', component: <Reviews /> },
    { url: 'customers', component: <DashboardCustomers /> },
    { url: 'task', component: <Task /> },

    //Demo
    { url: 'dark-sidebar', component: <Theme1 /> },
    { url: 'header-secondary', component: <Theme2 /> },
    { url: 'horizontal-sidebar', component: <Theme4 /> },
    { url: 'header-style', component: <Theme5 /> },
    { url: 'mini-sidebar', component: <Theme6 /> },

    //Content
    { url: 'content', component: <Content /> },
    { url: 'menu-1', component: <Menu /> },
    { url: 'email-template', component: <EmailTemplate /> },
    { url: 'blog', component: <Blog /> },

    //Ticket
    { url: 'create-ticket', component: <CreateTicket /> },
    { url: 'all-ticket', component: <AllTicket /> },

    //Customers
    { url: 'chat', component: <Chat /> },
    { url: 'customers-list', component: <CustomersList /> },

    //Reports
    { url: 'reports', component: <Reports /> },


    /// Apps
    { url: "app-profile", component: <AppProfile /> },
    { url: "post-details", component: <PostDetails /> },
    { url: "edit-profile", component: <EditProfile /> },
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox /> },
    { url: "email-read", component: <Read /> },
    { url: "app-calender", component: <Calendar /> },

    /// Chart
    { url: "chart-sparkline", component: <SparklineChart /> },
    { url: "chart-chartjs", component: <ChartJs /> },
    //{ url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: <ApexChart /> },
    { url: "chart-rechart", component: <RechartJs /> },

    /// Bootstrap
    { url: "ui-alert", component: <UiAlert /> },
    { url: "ui-badge", component: <UiBadge /> },
    { url: "ui-button", component: <UiButton /> },
    { url: "ui-modal", component: <UiModal /> },
    { url: "ui-button-group", component: <UiButtonGroup /> },
    { url: "ui-accordion", component: <UiAccordion /> },
    { url: "ui-list-group", component: <UiListGroup /> },
    //{ url: "ui-media-object", component: UiMediaObject },
    { url: "ui-card", component: <UiCards /> },
    { url: "ui-carousel", component: <UiCarousel /> },
    { url: "ui-dropdown", component: <UiDropDown /> },
    { url: "ui-popover", component: <UiPopOver /> },
    { url: "ui-progressbar", component: <UiProgressBar /> },
    { url: "ui-tab", component: <UiTab /> },
    { url: "ui-pagination", component: <UiPagination /> },
    { url: "ui-typography", component: <UiTypography /> },
    { url: "ui-grid", component: <UiGrid /> },

    /// Plugin
    { url: "uc-select2", component: <Select2 /> },
    //{ url: "uc-nestable", component: Nestable },
    //{ url: "uc-noui-slider", component: <MainNouiSlider/> },
    { url: "uc-sweetalert", component: <MainSweetAlert /> },
    { url: "uc-toastr", component: <Toastr /> },
    { url: "map-jqvmap", component: <JqvMap /> },
    { url: "uc-lightgallery", component: <Lightgallery /> },

    ///Redux
    { url: "todo", component: <Todo /> },
    //{ url: "redux-form", component: ReduxForm },
    //{ url: "redux-wizard", component: WizardForm },

    /// Widget
    { url: "widget-basic", component: <Widget /> },

    /// Shop
    { url: "ecom-product-grid", component: <ProductGrid /> },
    { url: "ecom-product-list", component: <ProductList /> },
    { url: "ecom-product-detail", component: <ProductDetail /> },
    { url: "ecom-product-order", component: <ProductOrder /> },
    { url: "ecom-checkout", component: <Checkout /> },
    { url: "ecom-invoice", component: <Invoice /> },
    { url: "ecom-product-detail", component: <ProductDetail /> },
    { url: "ecom-customers", component: <Customers /> },

    /// Form
    { url: "form-element", component: <Element /> },
    { url: "form-wizard", component: <Wizard /> },
    { url: "form-ckeditor", component: <CkEditor /> },
    { url: "form-pickers", component: <Pickers /> },
    { url: "form-validation", component: <FormValidation /> },

    /// table
    { url: 'table-filtering', component: <FilteringTable /> },
    { url: 'table-sorting', component: <SortingTable /> },
    { url: "table-datatable-basic", component: <DataTable /> },
    { url: "table-bootstrap-basic", component: <BootstrapTable /> },

    /// pages
    { url: "page-register", component: <Registration /> },
    { url: "page-lock-screen", component: <LockScreen /> },
    { url: "page-login", component: <Login /> },
    { url: "page-forgot-password", component: <ForgotPassword /> },
    { url: "page-error-400", component: <Error400 /> },
    { url: "page-error-403", component: <Error403 /> },
    { url: "page-error-404", component: <Error404 /> },
    { url: "page-error-500", component: <Error500 /> },
    { url: "page-error-503", component: <Error503 /> },

  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      {/* <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div> */}
      <Routes>
        <Route path='page-lock-screen' element={<LockScreen />} />
        <Route path='page-error-400' element={<Error400 />} />
        <Route path='page-error-403' element={<Error403 />} />
        <Route path='page-error-404' element={<Error404 />} />
        <Route path='page-error-500' element={<Error500 />} />
        <Route path='page-error-503' element={<Error503 />} />
        <Route path='/' element={<MainLayout />} >
          {allroutes.map((data, i) => 
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
        )}
        </Route>
      </Routes>
      <Setting />
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${menuToggle ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )

};

export default Markup;