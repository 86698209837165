import Api from "../../utils/api";
import { Urls } from "../../utils/url";
import {SET_CIQ_SITES_KPI, SET_KPI_DASHBOARD,SET_KPI_TROUBLE_TICKET,SET_KPI_CIQ_SITES,SET_KPI_ENGINEER,SET_KPI_ID} from "../reducers/KPIModule-reducer"
import { ALERTS } from "../reducers/component-reducer";

const KPIModuleActions = {
  //  manager account starts here
  // KPI DASHBOARD STARTS HERE

        getKPIDashboardList:
        (reset = true, args = "") =>
        async (dispatch, _) => {
          try {
            const res = await Api.get({
              url: `${Urls.KPI_Dashboard_Data}${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 200) return;
            let dataAll = res?.data?.data;
            console.log(dataAll, "tbvjn");
            dispatch(SET_KPI_DASHBOARD({ dataAll, reset }));
          } catch (error) {}
        },

        postKPIDashboardList: (uniqueId=null, cb) => async (dispatch, _) => {
          try {
            const res = await Api.post({
              // data: data,
              url:
                uniqueId === null
                  ?
                  Urls.Manage_CIQ_Sites_KPI_ID_Data
                  : Urls.Manage_CIQ_Sites_KPI_ID_Data + "/" + uniqueId,
            });
            if (res?.status !== 201 && res?.status !== 200) {
              let msgdata = {
                show: false,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"1111")
              dispatch(ALERTS(msgdata));
            } else {
              console.log(res,"resdata")
              let msgdata = {
                show: true,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"22222")
              dispatch(ALERTS(msgdata));
              cb();
             
            }
            
          } catch (error) {
            
            return;
          }
        },

       

// CIQ SITES KPI starts here

          getManageCIQSitesKPIlist:
          (reset = true, args = "") =>
          async (dispatch, _) => {
            try {
              const res = await Api.get({
                url: `${Urls.Manage_CIQ_Sites_KPI_Data}${args != "" ? "?" + args : ""}`,
              });
              if (res?.status !== 200) return;
              let dataAll = res?.data?.data;
              console.log(dataAll, "sjdbk");
              dispatch(SET_CIQ_SITES_KPI({ dataAll, reset }));
            } catch (error) {}
          },

      // ciq id data
      getManageCIQSitesKPIIdlist:(uniqueId=null,reset = true,cb, args = "") =>
      async (dispatch, _) => {
        try {
          const res = await Api.get({
            url: `${uniqueId === null
              ?
              Urls.Manage_CIQ_Data
              : Urls.Manage_CIQ_Data + "/" + uniqueId}${args != "" ? "?" + args : ""}`,
          });
          if (res?.status !== 201 && res?.status !== 200) {
            let msgdata = {
              show: false,
              icon: "",
              buttons: [],
              type: 1,
              text: res?.data?.msg,
            };
            console.log(msgdata,"1111")
            dispatch(ALERTS(msgdata));
          } else {
            console.log(res,"resdata")
            let msgdata = {
              show: true,
              icon: "",
              buttons: [],
              type: 1,
              text: res?.data?.msg,
            };
            console.log(msgdata,"22222")
            dispatch(ALERTS(msgdata));
            cb();
           
          }
          
        } catch (error) {
          
          return;
        }
      },

        // KPI Trouble ticket data
        getKPITroubleTicketList:
          (reset = true, args = "") =>
          async (dispatch, _) => {
            try {
              const res = await Api.get({
                url: `${Urls.KPI_Trouble_Ticket_Data}${args != "" ? "?" + args : ""}`,
              });
              if (res?.status !== 200) return;
              let dataAll = res?.data?.data;
              console.log(dataAll, "sjdbk");
              dispatch(SET_KPI_TROUBLE_TICKET({ dataAll, reset }));
            } catch (error) {}
          },


           // KPI ciq sites data
           getKPICIQSitesList:
        (reset = true, args = "") =>
        async (dispatch, _) => {
          try {
            const res = await Api.get({
              url: `${Urls.Manage_CIQ_Sites_KPI_Data}${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 200) return;
            let dataAll = res?.data?.data;
            console.log(dataAll, "sjdbk");
            dispatch(SET_KPI_CIQ_SITES({ dataAll, reset }));
          } catch (error) {}
        },


         // post kpi dashboard list
         postKPITroubleTicket: ( data, cb, uniqueId=null) => async (dispatch, _) => {
          try {
            const res = await Api.post({
              data: data,
              url:
                uniqueId === null
                  ?
                  Urls.Post_KPI_Trouble_Ticket_Data
                  : Urls.Post_KPI_Trouble_Ticket_Data + "/" + uniqueId,
            });
            if (res?.status !== 201 && res?.status !== 200) {
              let msgdata = {
                show: false,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"1111")
              dispatch(ALERTS(msgdata));
            } else {
              console.log(res,"resdata")
              let msgdata = {
                show: true,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"22222")
              dispatch(ALERTS(msgdata));
              cb();
             
            }
            
          } catch (error) {
            
            return;
          }
        },

         // KPI engineer data
         getKPIEngineerList:
         (reset = true, args = "") =>
         async (dispatch, _) => {
           try {
             const res = await Api.get({
               url: `${Urls.Manage_Engineer_Data}${args != "" ? "?" + args : ""}`,
             });
             if (res?.status !== 200) return;
             let dataAll = res?.data?.data;
             console.log(dataAll, "sjdbk");
             dispatch(SET_KPI_ENGINEER({ dataAll, reset }));
           } catch (error) {}
         },
         getKPIDashboardIDList:
         (uniqueId=null,reset = true, ) =>
         async (dispatch, _) => {
           try {
             const res = await Api.get({
               url: `${Urls.KPI_Dashboard_Data}/${uniqueId}`,
             });
             if (res?.status !== 200) return;
             let dataAll = res?.data?.data;
             console.log(dataAll, "sjdbk");
             dispatch(SET_KPI_ENGINEER({ dataAll, reset }));
           } catch (error) {}
         },

         // post kpi dashboard list
         postKPIEngineer: ( data, cb, uniqueId=null,args="") => async (dispatch, _) => {
          try {
            const res = await Api.post({
              data: data,
              url:
                uniqueId === null
                  ?
                  Urls.Post_KPI_Engineer_Data
                  : `${Urls.Post_KPI_Engineer_Data + "/" + uniqueId }${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 201 && res?.status !== 200) {
              let msgdata = {
                show: false,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"1111")
              dispatch(ALERTS(msgdata));
            } else {
              console.log(res,"resdata")
              let msgdata = {
                show: true,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"22222")
              dispatch(ALERTS(msgdata));
              cb();
             
            }
            
          } catch (error) {
            
            return;
          }
        },

        // post kpi dashboard list
        postKPIReport: ( data, cb, uniqueId=null,args="") => async (dispatch, _) => {
          try {
            const res = await Api.post({
              data: data,
              url:
                uniqueId === null
                  ?
                  Urls.KPI_Dashboard_Data
                  : `${Urls.KPI_Dashboard_Data + "/" + uniqueId }${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 201 && res?.status !== 200) {
              let msgdata = {
                show: false,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"1111")
              dispatch(ALERTS(msgdata));
            } else {
              console.log(res,"resdata")
              let msgdata = {
                show: true,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"22222")
              dispatch(ALERTS(msgdata));
              cb();
             
            }
            
          } catch (error) {
            
            return;
          }
        },


};
export default KPIModuleActions;
