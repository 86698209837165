import React, { useEffect, useState } from 'react'
import MPAG_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import troubleTicketActions from '../../../../../store/actions/TroubleTicket-actions';
import KPIModuleActions from '../../../../../store/actions/KPIModule-actions';
import moment from 'moment';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';

const KPIAgeForm = ({item,Heading={},setModal}) => {
  
const dispatch = useDispatch()
  const { register, handleSubmit ,setValue} = useForm();
  const[alerts,setAlerts] = useState(false)
  // const navigateLink  =("/DATAPLUS/trouble/Ticket/dashboard")
 
  const formStatus  = `${Heading.key}formStatus`


  useEffect(()=>{
    dispatch(KPIModuleActions.getKPIDashboardIDList(item.ranSupportId,true))
},[])

    

  useEffect(()=>{
    dispatch(troubleTicketActions.getManageEngineerList())
},[])

const engineerData  = useSelector((state)=>state?.TroubleTicketReducer?.getManageEngineerList)
console.log(engineerData,"jhabsdu")

const engineerOption = engineerData.map((itm)=>{return {label:itm.name, value:itm.name}})




    

    const Form =[
        {
          label:"KPI Engineer Assigned",
          value:"",
          name:`${Heading.key}KPIEngineer`,
          type:"select",
          option:engineerOption,
          placeholder:"Please Select SiteID"
        },
        {
          label:`${Heading.heading} VoLTE Accessibility`,
          value:"",
          name:`${Heading.key}VoLTEAccessibility`,
          type:"select",
          option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
          placeholder:"Please Select SiteID"
        },
        {
          label:`${Heading.heading} VoLTE Retainability`,
          value:"",
          name:`${Heading.key}VoLTERetainability`,
          type:"select",
          option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
          placeholder:"Please Select SiteID"
        },
        {
          label:`${Heading.heading} LTE Accessibility`,
          value:"",
          name:`${Heading.key}LTEAccessibility`,
          type:"select",
          option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
          placeholder:"Please Select SiteID"
        },
        {
          label:`${Heading.heading} LTE Retainability`,
          value:"",
          name:`${Heading.key}LTERetainability`,
          type:"select",
          option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
          placeholder:"Please Select SiteID"
        },
        {
          label:`${Heading.heading} Traffic/Throughput`,
          value:"",
          name:`${Heading.key}TrafficThroughput`,
          type:"select",
          option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
          placeholder:"Please Select SiteID"
        },
        {
          label:`${Heading.heading} Mobility`,
          value:"",
          name:`${Heading.key}Mobility`,
          type:"select",
          option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
          placeholder:"Please Select SiteID"
        },
        // {
        //   label:`${Heading.heading} Active Issue(Notes)`,
        //   value:"",
        //   name:`${Heading.key}ActiveIssueNotes`,
        //   type:"text",
          
        //   placeholder:"Please Select SiteID"
        // },
        
        
          
      ]


      const onSubmit = (data) => {
        // Handle form submission here, you can access form data in the 'data' object
        data={...data,[formStatus]:1}
        console.log(data,formStatus,item.ranSupportId,"UAYNVSD");
  
        // // const uniqueId = data.siteid
        // //     console.log(uniqueId,"kjjasbdyn")
      
          dispatch(KPIModuleActions.postKPIReport(data,()=>{
              dispatch(KPIModuleActions.getKPIDashboardList())
          },item.ranSupportId,""))
        // setModal(false)
        setAlerts(true)
  
  
        
  
      };

      useEffect(()=>{
        Form.forEach((key) => {
          console.log(`sdxghbj`,key.name, item[key.name])
          if (["endAt", "startAt"].indexOf(key.name) != -1) {
            console.log("date formValuekey", key.name, item[key.name])
            const momentObj = moment(item[key.name]);
            setValue(key.name, momentObj.toDate());
            // if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
            //   setValue(key.name, item[key.name]["$oid"]);
      
            // }
            // else

        }
          else {
            setValue(key.name, item[key.name]);
          }
    
        })
      },[item?.ranSupportId])

  return (
    
    <>
              {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} setModal={setModal}/>}

    <form onSubmit={handleSubmit(onSubmit)}>
      <MPAG_CommonForm Form={Form} register={register}/>
      <button type='submit' className='btn btn-primary'>
        Submit
      </button>
    </form>

    </>

  )

}

export default KPIAgeForm