import React, { useEffect, useState } from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable'
import { useDispatch, useSelector } from 'react-redux'
// import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import CIQScriptingActions from '../../../../../store/actions/CIQScripting-actions';
import deleteLogo from "../../../../../images/deleteLogo.png"
import CommonActions from '../../../../../store/actions/common-actions';
import { ALERTS } from '../../../../../store/reducers/component-reducer';
import { Row, Col, Card, Button, Dropdown, ButtonGroup, Modal } from 'react-bootstrap'
import { Urls } from '../../../../../utils/url';
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable';
import ActionsModal from '../../../../components/DATAPLUS_Components/ActionsModal';
import ExpandableTable from '../../CommonTable';
import CommentModal from '../../../../components/DATAPLUS_Components/CommentModal';
const CIQScriptingDashboard = () => {
  const[actions,setActions]=useState(false)
  const[callDelete,setCallDelete] = useState(false)
  // const [itmValue, setItmValue] = useState({})
  
  const [modal, setModal] = useState(false);
  const [itmValue, setItmValue] = useState({})
  const [commentHeading, setCommentHeading] = useState("")
  const dispatch = useDispatch();

let itemUniqueId="";
  useEffect(()=>{
    dispatch(CIQScriptingActions.getCIQScriptingList())
  },[])

    const data =  useSelector((state)=> state?.CIQScriptingReducer?.getCIQScriptingList)

    console.log(data, "jkahbsdkj")
 
    let tableData  = data?.map((itm,index)=>{
        itemUniqueId= itm.uID
      return {
        "sNo":index+1,
        "siteId":itm.siteId,
        "PACEID":itm.PACEID,
        "Scope":itm.Scope,
        "bandRequested":<div
        onClick={() => {
          setModal(true);
          setItmValue(itm?.bandRequested ? itm?.bandRequested : "No Comments");
          setCommentHeading("Band Requested")
        }}
        style={{
          // backgroundColor: 'yellow',
          borderWidth: '2px',
          borderColor: 'black',
          height: "40px",
          maxWidth: '200px',
          minWidth: '200px',
          width: "100px",
          overflow: 'hidden', // Ensure text overflow is handled
          textOverflow: 'ellipsis', // Add ellipsis
          whiteSpace: 'nowrap' // Prevent text from wrapping to a new line
        }}
      >
        {itm?.bandRequested
          ? itm?.bandRequested.length > 15
            ? itm?.bandRequested.substring(0, 50) + '...'
            : itm?.bandRequested
          : "No Comments"}
      </div>,
        "account":itm.account,
        "market":itm.market,
        "CIQEngineerAssigned":itm.CIQEngineerAssigned,
        "dateOfAdd":itm.dateOfAdd,
        'oraclePTN':itm.oraclePTN,
        "LTEID":itm.LTEID,
        // "5GID":itm.5GID,
        "USID":itm.USID,
        "FALocation":itm.FALocation,
        "CurrentConfigurationVerifyfromRFDS":itm.CurrentConfigurationVerifyfromRFDS,
        "FinalConfigurationVerifyFromRFDS":itm.FinalConfigurationVerifyFromRFDS,
        "Vendor":itm.Vendor,
        "Customer":itm.Customer,
        "LTEOSSID":itm.LTEOSSID,
        // "5GOSSID":itm.5GOSSID
        "EDPStatus":itm.EDPStatus,
        "RNDCIQSubmissionDate":itm.RNDCIQSubmissionDate,
        "RNDComments":itm.RNDComments,
        "RNDCIQStatus":itm.RNDCIQStatus,
        'RNDCIQUpload':itm.RNDCIQUpload,
        "siteAddress":itm.siteAddress,
        "city":itm.city,
        "PhysicalCounty":itm.PhysicalCounty,
        "Latitude":itm.Latitude,
        "Longitude":itm.Longitude,
        "ScriptingEngineer":itm.ScriptingEngineer,
        'ScriptingRequest':itm.ScriptingRequest,
        "RNDCIQManualVerification":itm.RNDCIQManualVerification,
        "EDPManualVerification":itm.EDPManualVerification,
        "ScriptingStatus1":itm.ScriptingStatus1,
        "ScriptsUpload":itm.ScriptsUpload,
        "ScriptsUploadStatus":itm.ScriptsUploadStatus,
        "ScriptReleaseDate":itm.ScriptReleaseDate,
        "CIQStatus":itm.CIQStatus,
        "CIQCompletionDate":itm.CIQCompletionDate,
        "ScriptingStatus2":itm.ScriptingStatus2,
        "ScriptingCompletionDate":itm.ScriptingCompletionDate,
        'IntegrationStatus':itm.IntegrationStatus,
        "FEName":itm.FEName,
        'FieldSupport':itm.FieldSupport,
        "KPISupport":itm.KPISupport,
        "UTRANSupport":itm.UTRANSupport,
        "actions":((  <Dropdown>
          <Dropdown.Toggle
            variant="danger"
            className="light sharp i-false"
            onClick={() => {
              setActions(true)
            // setItmValue(itm)
            console.log(callDelete,"callDelete")
            
            }}
          >
            <img src={deleteLogo}/>
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>))
      }
    })
  const filterHeading =  "CIQ Scripting Dashboard Filter"
  const tableHeading =  "CIQ Scripting Dashboard"
  let showFilter = false;
  const tableColumns = [
    {
      Header:"S No.",
      accessor:"sNo",
      Filter: "",
    },
    {
      Header : 'MAIN ID',
      // Footer : 'S No',
      accessor: 'siteId',
      Filter: "",
      
    },
    {
      Header : 'PACE ID',
      // Footer : 'account',
      accessor: "PACEID",
      Filter: "",
    },
    {
      Header : 'SCOPE',
      // Footer : 'account',
      accessor: "Scope",
      Filter: "",
    },
    {
      Header : 'BAND REQUESTED',
      // Footer : 'account',
      accessor: "bandRequested",
      Filter: "",
    },
    {
      Header : 'ACCOUNT',
      // Footer : 'account',
      accessor: "account",
      Filter: "",
    },
    {
      Header : 'MARKET',
      // Footer : 'account',
      accessor: "market",
      Filter: "",
    },
    {
      Header : 'CIQ ENGINEER ASSIGNED',
      // Footer : 'account',
      accessor: "CIQEngineerAssigned",
      Filter: "",
    },
    {
      Header : 'DATE OF ADD',
      // Footer : 'account',
      accessor: "dateOfAdd",
      Filter: "",
    },
    {
      Header : 'ORACLE PTN',
      // Footer : 'account',
      accessor: "oraclePTN",
      Filter: "",
    },
    {
      Header : 'GSM ID',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'UMIS ID',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },

    {
      Header : 'UMIS ID 2ND',
      // Footer : 'S No',
      accessor: '',
      Filter: "",
    },
    {
      Header : 'UMIS ID 3RD',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'RNC',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LTE ID',
      // Footer : 'account',
      accessor: "LTEID",
      Filter: "",
    },
    {
      Header : 'LTE ID 2ND',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LTE ID 3RD',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LTE ID 4TH',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LTE ID 5TH',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LTE ID 6TH',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LTE ID 7TH',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LTE ID 8TH ',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },

    {
      Header : 'LTE ID 9TH',
      // Footer : 'S No',
      accessor: '',
      Filter: "",
    },
    {
      Header : '5G ID',
      // Footer : 'account',
      accessor: "5GID",
      Filter: "",
    },
    {
      Header : '5G ID 2ND',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : '5G ID 3RD',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : '5G ID 4TH',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : '5G ID 5TH',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : '5G ID 6TH',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : '5G ID 7TH',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : '5G ID 8TH',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : '5G ID 9TH',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'USID',
      // Footer : 'account',
      accessor: "USID",
      Filter: "",
    },

    {
      Header : 'FA LOCATION',
      // Footer : 'S No',
      accessor: 'FALocation',
      Filter: "",
    },
    {
      Header : 'CURRENT CONFIGURATION(VERIFY FROM RFDS)',
      // Footer : 'account',
      accessor: "CurrentConfigurationVerifyfromRFDS",
      Filter: "",
    },
    {
      Header : 'FINAL CONFIGURATION (VERIFY FROM RFDS)',
      // Footer : 'account',
      accessor: "FinalConfigurationVerifyFromRFDS",
      Filter: "",
    },
    {
      Header : 'VENDOR',
      // Footer : 'account',
      accessor: "Vendor",
      Filter: "",
    },
    {
      Header : 'CUSTOMER',
      // Footer : 'account',
      accessor: "Customer",
      Filter: "",
    },
    {
      Header : 'LTE OSS ID',
      // Footer : 'account',
      accessor: "LTEOSSID",
      Filter: "",
    },
    {
      Header : '5G OSS ID',
      // Footer : 'account',
      accessor: "5GOSSID",
      Filter: "",
    },
    {
      Header : 'UMTS OSS ID',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'EDP STATUS',
      // Footer : 'account',
      accessor: "EDPStatus",
      Filter: "",
    },
    {
      Header : 'TVW STATUS',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'PCI REQUESTED (DATE)',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },

    {
      Header : 'E911 CIQ SUBMITTED DATE',
      // Footer : 'S No',
      accessor: '',
      Filter: "",
    },
    {
      Header : 'PCI RECIEVED (DATE)',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'RNDCIQ SUBMISSION DATE',
      // Footer : 'account',
      accessor: "RNDCIQSubmissionDate",
      Filter: "",
    },
    {
      Header : 'CIQ SUBMITED TO B&V',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'RNDCIQ VALIDATION DATE',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'RND COMMENTS',
      // Footer : 'account',
      accessor: "RNDComments",
      Filter: "",
    },
    {
      Header : 'TVW/EDP COMMENTS',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'RNDCIQ STATUS',
      // Footer : 'account',
      accessor: "RNDCIQStatus",
      Filter: "",
    },
    {
      Header : 'RNDCIQ UPLOAD',
      // Footer : 'account',
      accessor: "RNDCIQUpload",
      Filter: "",
    },
    {
      Header : 'SITE ADDRESS',
      // Footer : 'account',
      accessor: "siteAddress",
      Filter: "",
    },
    {
      Header : 'CITY',
      // Footer : 'account',
      accessor: "city",
      Filter: "",
    },

    {
      Header : 'PHYSICAL COUNTRY',
      // Footer : 'S No',
      accessor: 'PhysicalCounty',
      Filter: "",
    },
    {
      Header : 'LATITUDE',
      // Footer : 'account',
      accessor: "Latitude",
      Filter: "",
    },
    {
      Header : 'LONGITUDE',
      // Footer : 'account',
      accessor: "Longitude",
      Filter: "",
    },
    {
      Header : 'COMMENTS1 (IF ANY)',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'SCRIPTING ENGINEER',
      // Footer : 'account',
      accessor: "ScriptingEngineer",
      Filter: "",
    },
    {
      Header : 'SCRIPTING REQUEST',
      // Footer : 'account',
      accessor: "ScriptingRequest",
      Filter: "",
    },
    {
      Header : 'RNDCIQ MANUAL VERIFICATION',
      // Footer : 'account',
      accessor: "RNDCIQManualVerification",
      Filter: "",
    },
    
    {
      Header : 'EDP MANUAL VERIFICATION',
      // Footer : 'account',
      accessor: "EDPManualVerification",
      Filter: "",
    },
    {
      Header : 'TVW MANUAL VERIFICATION',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LKF REQUEST DATE',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LKF RECIEVE DATE',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },

    {
      Header : 'SCRIPTING STATUS 1',
      // Footer : 'S No',
      accessor: 'ScriptingStatus1',
      Filter: "",
    },
    {
      Header : 'SCRIPTING UPLOAD STATUS',
      // Footer : 'account',
      accessor: "ScriptsUpload",
      Filter: "",
    },
    {
      Header : 'SCRIPTING UPLOAD STATUS',
      // Footer : 'account',
      accessor: "ScriptsUploadStatus",
      Filter: "",
    },
    {
      Header : 'SCRIPT RELEASE DATE',
      // Footer : 'account',
      accessor: "ScriptReleaseDate",
      Filter: "",
    },
    {
      Header : 'COMMENTS2 (IF ANY)',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'CIQ STATUS',
      // Footer : 'account',
      accessor: "CIQStatus",
      Filter: "",
    },
    {
      Header : 'CIQ COMPLETION DATE',
      // Footer : 'account',
      accessor: "CIQCompletionDate",
      Filter: "",
    },
    {
      Header : 'SCRIPTING STATUS 2',
      // Footer : 'account',
      accessor: "ScriptingStatus2",
      Filter: "",
    },
    {
      Header : 'SCRIPTING COMPLETION DATE',
      // Footer : 'account',
      accessor: "ScriptingCompletionDate",
      Filter: "",
    },
    {
      Header : 'INTEGRATION STATUS',
      // Footer : 'account',
      accessor: "IntegrationStatus",
      Filter: "",
    },
    {
      Header : 'INTEGRATION COMPLETION DATE',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'FE NAME',
      // Footer : 'account',
      accessor: "FEName",
      Filter: "",
    },

    {
      Header : 'FIELD SUPPORT',
      // Footer : 'S No',
      accessor: 'FieldSupport',
      Filter: "",
    },
    {
      Header : 'KPI SUPPORT',
      // Footer : 'account',
      accessor: "KPISupport",
      Filter: "",
    },
    {
      Header : 'KPI SUPPORT COMPLETION DATE',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'UTRAN SUPPORT',
      // Footer : 'account',
      accessor: "UTRANSupport",
      Filter: "",
    },
    {
      Header : 'COMMENTS',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    
    {
      Header : 'Actions',
      // Footer : 'account',
      accessor: "actions",
      Filter: "",
    },
   
  ]

  
if(callDelete)
  {
    dispatch(
      CommonActions.deleteApiCaller(
        `${Urls.Manage_Ciq_Sites_Data}/${itemUniqueId}`,
        () => {
          dispatch(CIQScriptingActions.getCIQScriptingList())
          dispatch(ALERTS({ show: false }));
        }
      )
    );
    setCallDelete(false)
  }

  return(
    <>
    {modal && <CommentModal commentHeading={commentHeading} modal={modal} setModal={setModal} itmValue={itmValue} />}
     {actions && <ActionsModal  actions={actions} setActions={setActions} setCallDelete={setCallDelete}/>}
        <div>
      {/* <SortingTable tableColumns={tableColumns} tableData={tableData} tableHeading ={tableHeading}/> */}
      {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
      <FilteringTable showFilter={showFilter} filterHeading={filterHeading} tableColumns={tableColumns} tableData={tableData} tableHeading={tableHeading}/>
      {/* <ExpandableTable columns={tableColumns} data={tableData}/> */}
    </div>
    </>
  )

}

export default CIQScriptingDashboard