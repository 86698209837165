import React from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable'
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable'
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter'

const RANTracker = () => {
  
  const tableHeading  = "UTRAN Tracker"

  const tableColumns = [
    {
      Header: "SCHEDULE DATE",
      accessor: "scheduleDate",
      Filter: "",
      
    },
    {
      Header: "WEEK #",
      accessor: "week",
      Filter: "",
      
    },
    {
      Header: "SITE ID",
      accessor: "siteId",
      Filter: "",
      
    },
    {
      Header: "PROJECT CODE",
      accessor: "projectCode",
      Filter: "",
    },
    {
      Header: "PROJECT ID",
      accessor: "projectId",
      Filter: "",
    },
    {
      Header: "TECHNOLOGY",
      accessor: "technology",
      Filter: "",
    },
    {
      Header: "ACCOUNT",
      accessor: "account",
      Filter: "",
    },
    {
      Header: "MARKET",
      accessor: "market",
      Filter: "",
    },
    {
      Header: "SCHEDULE SOURCE",
      accessor: "scheduleSource",
      Filter: "",
    },
    {
      Header: "RAN BUCKET",
      accessor: "ranBucket",
      Filter: "",
    },
    {
      Header: "CR NUMBER",
      accessor: "crNumber",
      Filter: "",
    },
    
    {
      Header: "GC TYPE",
      accessor: "gcType",
      Filter: "",
    },
    {
      Header: "GC COMPANY",
      accessor: "gcCompany",
      Filter: "",
    },
    {
      Header: "GC VENDOR",
      accessor: "gcVendor",
      Filter: "",
    },
    {
      Header: "GC NAME",
      accessor: "gcName",
      Filter: "",
    },
    {
      Header: "GC EMAIL",
      accessor: "gcEmail",
      Filter: "",
    },
    {
      Header: "GC CONTACT",
      accessor: "gcContact",
      Filter: "",
    },
    {
      Header: "SERVICE AFFECTING",
      accessor: "serviceAffecting",
      Filter: "",
    },
    {
      Header: "DAY/NIGHT MOP",
      accessor: "dayNightMop",
     Filter: "", 
    },
    {
      Header: "DAY MOP REASON",
      accessor: "dayMopReason",
      Filter: "",
    },
    {
      Header: "CR STATUS",
      accessor: "crStatus",
      Filter: "",
    },
    {
      Header: "OUTAGE APPROVED MW TIME",
      accessor: "tmoOutageApprovedMwTime",
      Filter: "",
    },
    {
      Header: "DEPLOYMENT MANAGER",
      accessor: "tmoDeploymentManager",
      Filter: "",
    },
    {
      Header: "PLANNED SOW",
      accessor: "plannedSow",
      Filter: "",
    },
    {
      Header: "SOW DETAILS",
      accessor: "sowDetails",
      Filter: "",
    },
    {
      Header: "SOW COMMENTS",
      accessor: "sowComments",
     Filter: "", 
    },
    {
      Header: "NESTING TIME",
      accessor: "nestingTime",
      Filter: "",
    },
    {
      Header: "PRE-CHECK NEST INFO",
      accessor: "preCheckNestInfo",
      Filter: "",
    },
    {
      Header: "INTERNAL DATAPLUS WO",
      accessor: "internalPagWo",
      Filter: "",
    },
    {
      Header: "LONG TIME PRE-CHECKS REQUESTED (MOBILE-APP)",
      accessor: "longTimePreChecksRequestedMobileApp",
      Filter: "",
    },
    {
      Header: "PRECHECK ENGINEER",
      accessor: "preCheckEngineer",
      Filter: "",
    },
    {
      Header: "BSC IP CONTACT",
      accessor: "",
      Filter: "",
    },
    {
      Header: "UMTS IP CONTACT",
      accessor: "",
      Filter: "",
    },
    {
      Header: "LTE IP CONTACT",
      accessor: "",
      Filter: "",
    },
    {
      Header: "GSM ALARMS",
      accessor: "gsmAlarms",
      Filter: "",
    },
    {
      Header: "UMTS ALARMS",
      accessor: "umtsAlarms",
      Filter: "",
    },
    {
      Header: "LTE ALARMS",
      accessor: "lteAlarms",
      Filter: "",
    },
    {
      Header: "5G ALARMS",
      accessor: "fivegAlarms",
      Filter: "",
    },
    {
      Header: "PRE CHECK ALARMS/ISSUES",
      accessor: "preCheckAlarmsIssues",
      Filter: "",
    },
    {
      Header: "HO CX CREW VENDOR",
      accessor: "hoCxCrewVendor",
      Filter: "",
    },
    {
      Header: "HO CX CREW LEAD CONTACT NUMBER",
      accessor: "hoCxCrewLeadContactNumber",
      Filter: "",
    },
    {
      Header: "HO CX CREW LEAD NAME",
      accessor: "hoCxCrewLeadName",
      Filter: "",
    },
    {
      Header: "HO CX CREW LEAD COMPANY",
      accessor: "hoCxCrewLeadCompany",
      Filter: "",
    },
    {
      Header: "HO CX CREW LEAD EMAIL ID",
      accessor: "hoCxCrewLeadEmailId",
      Filter: "",
    },
    {
      Header: "HO IX CREW VENDOR",
      accessor: "hoIxCrewVendor",
      Filter: "",
    },
    {
      Header: "HO IX CREW LEAD CONTACT NUMBER",
      accessor: "hoIxCrewLeadContactNumber",
      Filter: "",
    },
    {
      Header: "HO IX CREW LEAD NAME",
      accessor: "hoIxCrewLeadName",
      Filter: "",
    },
    {
      Header: "HO IX CREW LEAD COMPANY",
      accessor: "hoIxCrewLeadCompany",
      Filter: "",
    },
    {
      Header: "HO IX CREW LEAD EMAIL ID",
      accessor: "hoIxCrewLeadEmailId",
      Filter: "",
    },
    {
      Header: "LOGIN TIME PRE-CHECK DELIVERED",
      accessor: "loginTimePreCheckDelivered",
      Filter: "",
    },
    {
      Header: "POST-CHECK ENGINEER",
      accessor: "postCheckEngineer",
      Filter: "",
    },
    {
      Header: "LOGOUT TIME POST-CHECK REQUEST",
      accessor: "logoutTimePostCheckRequest",
      Filter: "",
    },
    {
      Header: "GSM DOWNTIME",
      accessor: "gsmDowntime",
      Filter: "",
    },
    
    {
      Header: "UMTS DOWNTIME",
      accessor: "umtsDowntime",
      Filter: "",
    },
   
    {
      Header: "LTE DOWNTIME",
      accessor: "lteDowntime",
      Filter: "",
    },
    
    {
      Header: "5G DOWNTIME",
      accessor: "fivegDowntime",
      Filter: "",
    },
    {
      Header: "5G IP CONTACT",
      accessor: "fivegIpContact",
      Filter: "",
    },
    {
      Header: "POST GSM ALARMS",
      accessor: "postGsmAlarms",
      Filter: "",
    },
    {
      Header: "POST UMTS ALARMS",
      accessor: "postUmtsAlarms",
      Filter: "",
    },
    {
      Header: "POST LTE ALARMS",
      accessor: "postLteAlarms",
      Filter: "",
    },
    {
      Header: "POST 5G ALARMS",
      accessor: "postFivegAlarms",
     Filter: "", 
    },
    {
      Header: "POST CHECK ALARM ISSUES",
      accessor: "postCheckAlarmIssues",
      Filter: "",
    },
    {
      Header: "ACTIVITY STATUS (POR LEVEL)",
      accessor: "activityStatusPorLevel",
      Filter: "",
    },
    {
      Header: "ACTIVITY STATUS (SITE LEVEL)",
      accessor: "activityStatusSiteLevel",
      Filter: "",
    },
    {
      Header: "NOT COMPLETED/ CANCELLED/ ROLLBACK REASON",
      accessor: "notCompletedCancelledRollbackReason",
      Filter: "",
    },
    {
      Header: "WAS SCRIPTING OR TS REQUIRED",
      accessor: "wasScriptingOrTsRequired",
      Filter: "",
    },
    {
      Header: "ENGINEER RESPONSIBLE FOR SCRIPTING OR TS",
      accessor: "engineerResponsibleForScriptingOrTs",
      Filter: "",
    },
    {
      Header: "TTs OPENED",
      accessor: "ttsOpened",
      Filter: "",
    },
    {
      Header: "TT IDs",
      accessor: "ttIds",
      Filter: "",
    },
    {
      Header: "SITE-UP TIME",
      accessor: "siteUpTime",
      Filter: "",
    },
    {
      Header: "ACTIVITY WENT OUT OF APPROVED WINDOW",
      accessor: "activityWentOutOfApprovedWindow",
      Filter: "",
    },
    {
      Header: "TIME AFTER APPROVED WINDOW",
      accessor: "timeAfterApprovedWindow",
      Filter: "",
    },
    {
      Header: "ACTIVITY NOTES",
      accessor: "activityNotes",
      Filter: "",
    },
    {
      Header: "LOGOUT TIME POST-CHECK DELIVERED",
      accessor: "logoutTimePostcheckDelivered",
      Filter: "",
    },
    
  ]

  const GROUPED_COLUMNS = [
    {
      Header: 'Id',
      Footer: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Name',
      Footer: 'Name',
      columns: [
        {
          Header: 'First Name',
          Footer: 'First Name',
          accessor: 'first_name'
        },
        {
          Header: 'Last Name',
          Footer: 'Last Name',
          accessor: 'last_name'
        },
      ]
    },
    {
      Header: 'Info',
      Footer: 'Info',
      columns: [
        {
          Header: 'Date of  Birth',
          Footer: 'Date of  Birth',
          accessor: 'date_of_birth'
        },
        {
          Header: 'Country',
          Footer: 'Country',
          accessor: 'country',
        },
        {
          Header: 'Phone',
          Footer: 'Phone',
          accessor: 'phone'
        },
      ]
    },
  ]
  return (
    <>
    {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
    <div>
        <FilteringTable tableColumns={tableColumns} tableData={["a","b"]} tableHeading={tableHeading}/>
      </div>
    </>
  )
  
}

export default RANTracker


