import React, { useEffect } from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable'
import { useDispatch, useSelector } from 'react-redux';
// import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import KPIModuleActions from '../../../../../store/actions/KPIModule-actions';
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';

const KPITracker = () => {


  const dispatch =  useDispatch();

  useEffect(()=>{
    dispatch(KPIModuleActions.getKPIDashboardList())
  },[])

  const data = useSelector((state)=> state?.KPIModuleReducer?.getKPIDashboardList)
  console.log(data,"kuygnyugumk")

  let tableData = data?.tableData?.map((itm)=>{
    return itm
  })

  
  const tableHeading  = "KPI Tracker"
  const showFilter = false

  const tableColumns = [
  
    {
      Header: "REQUESTED DATE",
      accessor: "ILogout_timeStamp",
      Filter: ColumnFilter,
    },
    {
      Header: "ACCOUNT",
      accessor: "account",
      Filter: ColumnFilter,
    },
    {
      Header: "MARKET",
      accessor: "market",
      Filter: ColumnFilter,
    },
    //nhi pta api mai kha h
    {
      Header: "SITE ID",
      accessor: "actualSiteId",
      Filter: ColumnFilter,
    },
    {
      Header: "USID",
      accessor: "usID",
      Filter: ColumnFilter,
    },
    {
      Header: "AGING",
      accessor: "kpiAge",
      Filter: ColumnFilter,
    },
    {
      Header: "KPI ENGINEER ASSIGNED",
      accessor: "oneHourKPIEngineer",
      Filter: ColumnFilter,
    },
    {
      Header: "1ST HOUR STATUS",
      accessor: "oneHourformStatus",
      Filter: ColumnFilter,
    },
    {
      Header: "1ST HOUR REPORT DATE&TIME",
      accessor: "",
      Filter: ColumnFilter,
    },
    {
      Header: "1ST HOUR ACTIVE ISSUES(NOTES)",
      accessor: "oneHourActiveIssueNotes",
      Filter: ColumnFilter,
    },
    {
      Header: "1ST HOUR VOLTE ACCESSIBILITY",
      accessor: "oneHourVoLTEAccessibility",
      Filter: ColumnFilter,
    },
    {
      Header: "1ST HOUR VOLTE RETAINABILITY",
      accessor: "oneHourVoLTERetainability",
      Filter: ColumnFilter,
    },
    {
      Header: "1ST HOUR LTE ACCESSIBILITY",
      accessor: "oneHourLTEAccessibility",
      Filter: ColumnFilter,
    },
    {
      Header: "1ST HOUR LTE RETAINABILITY",
      accessor: "oneHourLTERetainability",
      Filter: ColumnFilter,
    },
    {
      Header: "1ST HOUR TRAFFICE/THROUGHOUT",
      accessor: "oneHourTrafficThroughput",
      Filter: ColumnFilter,
    },
    {
      Header: "1ST HOUR MOBILITY",
      accessor: "oneHourMobility",
      Filter: ColumnFilter,
    },
    {
      Header: "KPI ENGINEER ASSIGNED",
      accessor: "secondHourKPIEngineer",
      Filter: ColumnFilter,
    },
    {
        Header: "2ND HOUR STATUS",
        accessor: "secondHourformStatus",
        Filter: ColumnFilter,
      },
      {
        Header: "2ND HOUR REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "2ND HOUR ACTIVE ISSUES(NOTES)",
        accessor: "secondHourActiveIssueNotes",
        Filter: ColumnFilter,
      },
      {
        Header: "2ND HOUR VOLTE ACCESSIBILITY",
        accessor: "secondHourVoLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "2ND HOUR VOLTE RETAINABILITY",
        accessor: "secondHourVoLTERetainability",
        Filter: ColumnFilter, 
      },
      {
        Header: "2ND HOUR LTE ACCESSIBILITY",
        accessor: "secondHourLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "2ND HOUR LTE RETAINABILITY",
        accessor: "secondHourLTERetainability",
        Filter: ColumnFilter,
      },
      {
        Header: "2ND HOUR TRAFFICE/THROUGHOUT",
        accessor: "secondHourTrafficThroughput",
        Filter: ColumnFilter,
      },
      {
        Header: "2ND HOUR MOBILITY",
        accessor: "secondHourMobility",
        Filter: ColumnFilter,
      },

      {
        Header: "KPI ENGINEER ASSIGNED",
        accessor: "threeHourKPIEngineer",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD HOUR STATUS",
        accessor: "threeHourformStatus",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD HOUR REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD HOUR ACTIVE ISSUES(NOTES)",
        accessor: "threeHourActiveIssueNotes",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD HOUR VOLTE ACCESSIBILITY",
        accessor: "threeHourVoLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD HOUR VOLTE RETAINABILITY",
        accessor: "threeHourVoLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD HOUR LTE ACCESSIBILITY",
        accessor: "threeHourLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD HOUR LTE RETAINABILITY",
        accessor: "threeHourLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD HOUR TRAFFICE/THROUGHOUT",
        accessor: "threeHourTrafficThroughput",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD HOUR MOBILITY",
        accessor: "threeHourMobility",
        Filter: ColumnFilter,
      },
      {
        Header: "KPI ENGINEER ASSIGNED",
        accessor: "fourthHourKPIEngineer",
        Filter: ColumnFilter,
      },
      {
        Header: "4th HOUR STATUS",
        accessor: "fourthHourformStatus",
        Filter: ColumnFilter,
      },
      {
        Header: "4th HOUR REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "4th HOUR ACTIVE ISSUES(NOTES)",
        accessor: "fourthHourActiveIssueNotes",
        Filter: ColumnFilter,
      },
      {
        Header: "4th HOUR VOLTE ACCESSIBILITY",
        accessor: "fourthHourVoLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "4th HOUR VOLTE RETAINABILITY",
        accessor: "fourthHourVoLTERetainability",
        Filter: ColumnFilter,
      },
      {
        Header: "4th HOUR LTE ACCESSIBILITY",
        accessor: "fourthHourLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "4th HOUR LTE RETAINABILITY",
        accessor: "fourthHourLTERetainability",
        Filter: ColumnFilter,
      },
      {
        Header: "4th HOUR TRAFFICE/THROUGHOUT",
        accessor: "fourthHourTrafficThroughput",
        Filter: ColumnFilter,
      },
      {
        Header: "4th HOUR MOBILITY",
        accessor: "fourthHourMobility",
        Filter: ColumnFilter,
      },
      {
        Header: "KPI ENGINEER ASSIGNED",
        accessor: "eightHourKPIEngineer",
        Filter: ColumnFilter,
      },
      {
        Header: "8th HOUR STATUS",
        accessor: "eightHourformStatus",
        Filter: ColumnFilter,
      },
      {
        Header: "8th HOUR REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "8th HOUR ACTIVE ISSUES(NOTES)",
        accessor: "eightHourActiveIssueNotes",
        Filter: ColumnFilter,
      },
      {
        Header: "8th HOUR VOLTE ACCESSIBILITY",
        accessor: "eightHourVoLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "8th HOUR VOLTE RETAINABILITY",
        accessor: "eightHourVoLTERetainability",
        Filter: ColumnFilter,
      },
      {
        Header: "8th HOUR LTE ACCESSIBILITY",
        accessor: "eightHourLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "8th HOUR LTE RETAINABILITY",
        accessor: "eightHourLTERetainability",
        Filter: ColumnFilter,
      },
      {
        Header: "8th HOUR TRAFFICE/THROUGHOUT",
        accessor: "eightHourTrafficThroughput",
        Filter: ColumnFilter,
      },
      {
        Header: "8th HOUR MOBILITY",
        accessor: "eightHourMobility",
        Filter: ColumnFilter,
      },
      {
        Header: "KPI ENGINEER ASSIGNED",
        accessor: "oneDayKPIEngineer",
        Filter: ColumnFilter,
      },
      
      {
        Header: "1ST DAY STATUS",
        accessor: "chat",
        Filter: ColumnFilter,
      },
      {
        Header: "1ST DAY REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "1ST DAY ACTIVE ISSUES(NOTES)",
        accessor: "tmoOutageApprovedMwTime",
        Filter: ColumnFilter,
      },
      {
        Header: "1ST DAY VOLTE ACCESSIBILITY",
        accessor: "nestingTime",
        Filter: ColumnFilter,
      },
      {
        Header: "1ST DAY VOLTE RETAINABILITY",
        accessor: "progress",
        Filter: ColumnFilter,
      },
      {
        Header: "1ST DAY LTE ACCESSIBILITY",
        accessor: "scheduleSource",
        Filter: ColumnFilter,
      },
      {
        Header: "1ST DAY LTE RETAINABILITY",
        accessor: "projectCode",
       Filter: ColumnFilter, 
      },
      {
        Header: "1ST DAY TRAFFICE/THROUGHOUT",
        accessor: "technology",
        Filter: ColumnFilter,
      },
      {
        Header: "1ST DAY MOBILITY",
        accessor: "crew",
        Filter: ColumnFilter,
      },

      {
        Header: "KPI ENGINEER ASSIGNED",
        accessor: "secondDayKPIEngineer",
        Filter: ColumnFilter,
      },
      {
        Header: "2nd DAY STATUS",
        accessor: "secondDayformStatus",
        Filter: ColumnFilter,
      },
      {
        Header: "2nd DAY REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "2nd DAY ACTIVE ISSUES(NOTES)",
        accessor: "secondDayActiveIssueNotes",
        Filter: ColumnFilter,
      },
      {
        Header: "2nd DAY VOLTE ACCESSIBILITY",
        accessor: "secondDayVoLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "2nd DAY VOLTE RETAINABILITY",
        accessor: "secondDayVoLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "2nd DAY LTE ACCESSIBILITY",
        accessor: "secondDayLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "2nd DAY LTE RETAINABILITY",
        accessor: "secondDayLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "2nd DAY TRAFFICE/THROUGHOUT",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "2nd DAY MOBILITY",
        accessor: "secondDayMobility",
        Filter: ColumnFilter,
      },
      {
        Header: "KPI ENGINEER ASSIGNED",
        accessor: "threeDayKPIEngineer",
       Filter: ColumnFilter, 
      },
      {
        Header: "3RD DAY STATUS",
        accessor: "threeDayformStatus",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD DAY REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD DAY ACTIVE ISSUES(NOTES)",
        accessor: "threeDayActiveIssueNotes",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD DAY VOLTE ACCESSIBILITY",
        accessor: "threeDayVoLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD DAY VOLTE RETAINABILITY",
        accessor: "threeDayVoLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD DAY LTE ACCESSIBILITY",
        accessor: "threeDayLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD DAY LTE RETAINABILITY",
        accessor: "threeDayLTERetainablity",
       Filter: ColumnFilter, 
      },
      {
        Header: "3RD DAY TRAFFICE/THROUGHOUT",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "3RD DAY MOBILITY",
        accessor: "threeDayMobility",
        Filter: ColumnFilter,
      },
      {
        Header: "KPI ENGINEER ASSIGNED",
        accessor: "fourDayKPIEngineer",
        Filter: ColumnFilter,
      },

      {
        Header: "4TH DAY STATUS",
        accessor: "fourthDayformStatus",
        Filter: ColumnFilter,
      },
      {
        Header: "4TH DAY REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "4TH DAY ACTIVE ISSUES(NOTES)",
        accessor: "fourthDayActiveIssueNotes",
        Filter: ColumnFilter,
      },
      {
        Header: "4TH DAY VOLTE ACCESSIBILITY",
        accessor: "fourthDayVoLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "4TH DAY VOLTE RETAINABILITY",
        accessor: "fourthDayVoLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "4TH DAY LTE ACCESSIBILITY",
        accessor: "fourthDayLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "4TH DAY LTE RETAINABILITY",
        accessor: "fourthDayLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "4TH DAY TRAFFICE/THROUGHOUT",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "4TH DAY MOBILITY",
        accessor: "fourthDayMobility",
        Filter: ColumnFilter,
      },
      {
        Header: "KPI ENGINEER ASSIGNED",
        accessor: "fiveDayKPIEngineer",
        Filter: ColumnFilter,
      },
      {
        Header: "5TH DAY STATUS",
        accessor: "fiveDayformStatus",
       Filter: ColumnFilter, 
      },
      {
        Header: "5TH DAY REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "5TH DAY ACTIVE ISSUES(NOTES)",
        accessor: "fiveDayActiveIssueNotes",
        Filter: ColumnFilter,
      },
      {
        Header: "5TH DAY VOLTE ACCESSIBILITY",
        accessor: "fiveDayVoLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "5TH DAY VOLTE RETAINABILITY",
        accessor: "fiveDayVoLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "5TH DAY LTE ACCESSIBILITY",
        accessor: "fiveDayLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "5TH DAY LTE RETAINABILITY",
        accessor: "fiveDayLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "5TH DAY TRAFFICE/THROUGHOUT",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "5TH DAY MOBILITY",
        accessor: "fiveDayMobility",
        Filter: ColumnFilter,
      },
      {
        Header: "KPI ENGINEER ASSIGNED",
        accessor: "sixDayKPIEngineer",
        Filter: ColumnFilter,
      },
      {
        Header: "6TH DAY STATUS",
        accessor: "sixthDayformStatus",
        Filter: ColumnFilter,
      },
      {
        Header: "6TH DAY REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "6TH DAY ACTIVE ISSUES(NOTES)",
        accessor: "sixthDayActiveIssueNotes",
        Filter: ColumnFilter,
      },
      {
        Header: "6TH DAY VOLTE ACCESSIBILITY",
        accessor: "sixthDayVoLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "6TH DAY VOLTE RETAINABILITY",
        accessor: "sixthDayVoLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "6TH DAY LTE ACCESSIBILITY",
        accessor: "sixthDayLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "6TH DAY LTE RETAINABILITY",
        accessor: "sixthDayLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "6TH DAY TRAFFICE/THROUGHOUT",
        accessor: "",
       Filter: ColumnFilter, 
      },
      {
        Header: "6TH DAY MOBILITY",
        accessor: "sixthDayMobility",
        Filter: ColumnFilter,
      },
      {
        Header: "KPI ENGINEER ASSIGNED",
        accessor: "sevenDayKPIEngineer",
        Filter: ColumnFilter,
      },
      {
        Header: "FINAL KPI 7TH DAY STATUS",
        accessor: "seventhDayformStatus",
        Filter: ColumnFilter,
      },
      {
        Header: "FINAL KPI 7TH DAY REPORT DATE&TIME",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "FINAL KPI 7TH DAY ACTIVE ISSUES(NOTES)",
        accessor: "seventhDayActiveIssueNotes",
       Filter: ColumnFilter, 
      },
      {
        Header: "FINAL KPI 7TH DAY VOLTE ACCESSABILITY",
        accessor: "seventhDayVoLTEAccessibility",
        Filter: ColumnFilter,
      },
      {
        Header: "FINAL KPI 7TH DAY VOLTE RETAINABILITY",
        accessor: "seventhDayVoLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "FINAL KPI 7TH DAY LTE ACCESSABILITY",
        accessor: "seventhDayLTEAccessibility",
       Filter: ColumnFilter, 
      },
      {
        Header: "FINAL KPI 7TH DAY LTE RETAINABILITY",
        accessor: "seventhDayLTERetainablity",
        Filter: ColumnFilter,
      },
      {
        Header: "FINAL KPI 7TH DAY TRAFFICE/THROUGHOUT",
        accessor: "",
        Filter: ColumnFilter,
      },
      {
        Header: "FINAL KPI 7TH DAY MOBILITY",
        accessor: "seventhDayMobility",
        Filter: ColumnFilter,
      },
     
    // {
    //   Header: "ACTIONS",
    //   accessor: "actions",
    //   Filter: "",
    // },
      ]

      const GROUPED_COLUMNS = [
        {
          Header : 'Id',
          Footer : 'Id',
          accessor: 'id'
        },
        {
          Header : 'Name',
          Footer : 'Name',
          columns: [
            {
              Header : 'First Name',
              Footer : 'First Name',
              accessor: 'first_name'
            },
            {
              Header : 'Last Name',
              Footer : 'Last Name',
              accessor: 'last_name'
            },
          ]
        },
        {
          Header: 'Info',
          Footer: 'Info',
          columns: [
            {
              Header : 'Date of  Birth',
              Footer : 'Date of  Birth',
              accessor: 'date_of_birth'
            },
            {
              Header : 'Country',
              Footer : 'Country',
              accessor: 'country',
            },
            {
              Header : 'Phone',
              Footer : 'Phone',
              accessor: 'phone'
            },
          ]
        },
      ]
      
  return (
    <>
    {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
    <div>
        <FilteringTable showFilter={showFilter} tableColumns={tableColumns} tableData={tableData} tableHeading={tableHeading}/>
      </div>
    </>
  )

}

export default KPITracker


// {
//   "ILogout_timeStamp":itm.ILogout_timeStamp,
//   "account":itm.account,
//   "market":itm.market,
//   "actualSiteId":itm?.ndResult?.map((bar)=>{return bar.siteId}),
//   "usID":itm?.ndResult?.map((bar)=>{return bar.USID}),
//   "kpiAge":`${itm.kpiAge}Hrs`,
//   "oneHourKPIEngineer":itm.oneHourKPIEngineer,
//   "oneHourformStatus":itm.oneHourformStatus,
//   "oneHourActiveIssueNotes":itm.oneHourActiveIssueNotes,
//   "oneHourLTEAccessibility":itm.oneHourLTEAccessibility,
//   "oneHourLTERetainability":itm.oneHourLTERetainablity,
//   "oneHourMobility":itm.oneHourMobility,
//   "oneHourTrafficThroughput":itm.oneHourTrafficThroughput,
//   "oneHourVoLTEAccessibility":itm.oneHourVoLTEAccessibility,
//   "oneHourVoLTERetainability":itm.oneHourVoLTERetainablity,
//   "secondHourKPIEngineer":itm.secondHourKPIEngineer,
//   "secondHourformStatus":itm.secondHourformStatus,
//   "secondHourActiveIssueNotes":itm.secondHourActiveIssueNotes,
//   "secondHourLTEAccessibility":itm.secondHourLTEAccessibility,
//   "secondHourLTERetainability":itm.secondHourLTERetainablity,
//   "secondHourMobility":itm.secondHourMobility,
//   "secondHourTrafficThroughput":itm.secondHourTrafficThroughput,
//   "secondHourVoLTEAccessibility":itm.secondHourVoLTEAccessibility,
//   "secondHourVoLTERetainability":itm.secondHourVoLTERetainablity,

//   "threeHourKPIEngineer":itm.threeHourKPIEngineer,
//   "threeHourformStatus":itm.threeHourformStatus,
//   "threeHourActiveIssueNotes":itm.threeHourActiveIssueNotes,
//   "threeHourLTEAccessibility":itm.threeHourLTEAccessibility,
//   "threeHourLTERetainability":itm.threeHourLTERetainablity,
//   "threeHourMobility":itm.threeHourMobility,
//   "threeHourTrafficThroughput":itm.threeHourTrafficThroughput,
//   "threeHourVoLTEAccessibility":itm.threeHourVoLTEAccessibility,
//   "threeHourVoLTERetainability":itm.threeHourVoLTERetainablity,

//   "fourthHourKPIEngineer":itm.fourthHourKPIEngineer,
//   "fourthHourformStatus":itm.fourthHourformStatus,
//   "fourthHourActiveIssueNotes":itm.fourthHourActiveIssueNotes,
//   "fourthHourLTEAccessibility":itm.fourthHourLTEAccessibility,
//   "fourthHourLTERetainability":itm.fourthHourLTERetainablity,
//   "fourthHourMobility":itm.fourthHourMobility,
//   "fourthHourTrafficThroughput":itm.fourthHourTrafficThroughput,
//   "fourthHourVoLTEAccessibility":itm.fourthHourVoLTEAccessibility,
//   "fourthHourVoLTERetainability":itm.fourthHourVoLTERetainablity,


//   "eightHourKPIEngineer":itm.eightHourKPIEngineer,
//   "eightHourformStatus":itm.eightHourformStatus,
//   "eightHourActiveIssueNotes":itm.eightHourActiveIssueNotes,
//   "eightHourLTEAccessibility":itm.eightHourLTEAccessibility,
//   "eightHourLTERetainability":itm.eightHourLTERetainablity,
//   "eightHourMobility":itm.eightHourMobility,
//   "eightHourTrafficThroughput":itm.eightHourTrafficThroughput,
//   "eightHourVoLTEAccessibility":itm.eightHourVoLTEAccessibility,
//   "eightHourVoLTERetainability":itm.eightHourVoLTERetainablity,

//   "oneDayKPIEngineer":itm.oneDayKPIEngineer,
//   "oneDayActiveIssueNotes":itm.oneDayActiveIssueNotes,
//   "oneDayLTEAccessibility":itm.oneDayLTEAccessibility,
//   "oneDayLTERetainablity":itm.oneDayLTERetainablity,
//    "oneDayMobility":itm.oneDayMobility,
//    "oneDayTrafficThroughput":itm.oneDayTrafficThroughput,
//    "oneDayVoLTEAccessibility":itm.oneDayVoLTEAccessibility,
//    "oneDayVoLTERetainablity":itm.oneDayVoLTERetainablity,
//    "oneDayformStatus":itm.oneDayformStatus,
  
//   "secondDayKPIEngineer":itm.secondDayKPIEngineer,
//   "secondDayActiveIssueNotes":itm.secondDayActiveIssueNotes,
//   "secondDayLTEAccessibility":itm.secondDayLTEAccessibility,
//   "secondDayLTERetainablity":itm.secondDayLTERetainablity,
//    "secondDayMobility":itm.secondDayMobility,
//    "secondDayTrafficThroughput":itm.secondDayTrafficThroughput,
//    "secondDayVoLTEAccessibility":itm.secondDayVoLTEAccessibility,
//    "secondDayVoLTERetainablity":itm.secondDayVoLTERetainablity,
//    "secondDayformStatus":itm.secondDayformStatus,

//   //  3rd day
//   "threeDayKPIEngineer":itm.threeDayKPIEngineer,
//   "threeDayLTEAccessibility":itm.threeDayLTEAccessibility,
//   "threeDayLTERetainablity":itm.threeDayLTERetainablity,
//   "threeDayMobility":itm.threeDayMobility,
//   "threeDayTrafficThroughput":itm.threeDayTrafficThroughput,
//   "threeDayVoLTEAccessibility":itm.threeDayVoLTEAccessibility,
//   "threeDayVoLTERetainablity":itm.threeDayVoLTERetainablity,
//   "threeDayformStatus":itm.threeDayformStatus,

//   "fourthDayKPIEngineer":itm.fourthDayKPIEngineer,
//   "fourthDayLTEAccessibility":itm.fourthDayLTEAccessibility,
//   "fourthDayLTERetainablity":itm.fourthDayLTERetainablity,
//   "fourthDayMobility":itm.fourthDayMobility,
//   "fourthDayTrafficThroughput":itm.fourthDayTrafficThroughput,
//   "fourthDayVoLTEAccessibility":itm.fourthDayVoLTEAccessibility,
//   "fourthDayVoLTERetainablity":itm.fourthDayVoLTERetainablity,
//   "fourthDayformStatus":itm.fourthDayformStatus,

//   "fiveDayKPIEngineer":itm.fiveDayKPIEngineer,
//   "fiveDayLTEAccessibility":itm.fiveDayLTEAccessibility,
//   "fiveDayLTERetainablity":itm.fiveDayLTERetainablity,
//   "fiveDayMobility":itm.fiveDayMobility,
//   "fiveDayTrafficThroughput":itm.fiveDayTrafficThroughput,
//   "fiveDayVoLTEAccessibility":itm.fiveDayVoLTEAccessibility,
//   "fiveDayVoLTERetainablity":itm.fiveDayVoLTERetainablity,
//   "fiveDayformStatus":itm.fiveDayformStatus,

//   "sixDayKPIEngineer":itm.sixDayKPIEngineer,
//   "sixDayLTEAccessibility":itm.sixDayLTEAccessibility,
//   "sixDayLTERetainablity":itm.sixDayLTERetainablity,
//   "sixDayMobility":itm.sixDayMobility,
//   "sixDayTrafficThroughput":itm.sixDayTrafficThroughput,
//   "sixDayVoLTEAccessibility":itm.sixDayVoLTEAccessibility,
//   "sixDayVoLTERetainablity":itm.sixDayVoLTERetainablity,
//   "sixDayformStatus":itm.sixDayformStatus,

//   "sixDayKPIEngineer":itm.sixDayKPIEngineer,
//   "sixDayLTEAccessibility":itm.sixDayLTEAccessibility,
//   "sixDayLTERetainablity":itm.sixDayLTERetainablity,
//   "sixDayMobility":itm.sixDayMobility,
//   "sixDayTrafficThroughput":itm.sixDayTrafficThroughput,
//   "sixDayVoLTEAccessibility":itm.sixDayVoLTEAccessibility,
//   "sixDayVoLTERetainablity":itm.sixDayVoLTERetainablity,
//   "sixDayformStatus":itm.sixDayformStatus,

//   "sevenDayKPIEngineer":itm.sevenDayKPIEngineer,
//   "sevenDayLTEAccessibility":itm.sevenDayLTEAccessibility,
//   "sevenDayLTERetainablity":itm.sevenDayLTERetainablity,
//   "sevenDayMobility":itm.sevenDayMobility,
//   "sevenDayTrafficThroughput":itm.sevenDayTrafficThroughput,
//   "sevenDayVoLTEAccessibility":itm.sevenDayVoLTEAccessibility,
//   "sevenDayVoLTERetainablity":itm.sevenDayVoLTERetainablity,
//   "sevenDayformStatus":itm.sevenDayformStatus
  
// }