import React from 'react'
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";

const CommentModal = ({commentHeading="",modal,setModal,itmValue}) => {
    return (
        <>
            <Modal className="fade" 
            show={modal}
            >
                               <Modal.Header style={{backgroundColor:'#470468'}}>
                                  <Modal.Title>{commentHeading}</Modal.Title>
                                  <Button
                                     onClick={() => setModal(false)}
                                     variant=""
                                     className="close"
                                  >
                                     <span>&times;</span>
                                  </Button>
                               </Modal.Header>
                               <Modal.Body style={{textAlign:"center", backgroundColor:'#FEF9FF',color:'black'}}>
                             {itmValue}
                                  
                               </Modal.Body>
                              
                            </Modal>
    
        </>
      )
}

export default CommentModal