import React, { useEffect, useState } from 'react'
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter'
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable'
import { useDispatch, useSelector } from 'react-redux'
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions'
import ExcelUploadModal from '../../../../components/DATAPLUS_Components/ExcelUploadModal'
import SiteIdExcelUploadForm from './SiteIdExcelUploadForm'
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Button,
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal'
import ActionsModal from '../../../../components/DATAPLUS_Components/ActionsModal'
import CommonActions from '../../../../../store/actions/common-actions'
import { Urls } from '../../../../../utils/url'
import ExpandableTable from '../../../../components/table/FilteringTable/ExpandableTable'
import "../EditDelete.css"

const SiteID = () => {

  const [excelModal, setExcelModal] = useState(false)
  const [alerts, setAlerts] = useState(false)
  const [actions, setActions] = useState(false)
  const [callDelete, setCallDelete] = useState(false)
  const [expandedRow, setExpandedRow] = useState(null);
  const[deleteIndex,setDeleteIndex] = useState(null)
  const uploadExcel = true
  let ExcelForm = (SiteIdExcelUploadForm)


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const AddFormButtonName = '+ Data'
  const AddFormNavigate = '/DATAPLUS/Add/site/config'


  // tabledata
  useEffect(() => {
    dispatch(adminDashboardActions.getSiteIdList())
  }, [])

  const data = useSelector((state) => state?.AdminDashboard_reducer?.getSiteIdList)

  let itemUniqueId = []

  const tableData = data.map((itm,index) => {
    itemUniqueId[index] = itm.uniqueid
    return {
      ...itm,
      "sNo.":index+1,
      "statusNode1": itm?.statusNode1 === true ? 'Active' : "Deactive",
      "statusNode2": itm?.statusNode2 === true ? 'Active' : "Deactive",
      "statusNode3": itm?.statusNode3 === true ? 'Active' : "Deactive",
      "statusNode4": itm?.statusNode4 === true ? 'Active' : "Deactive",
      "statusNode5": itm?.statusNode5 === true ? 'Active' : "Deactive",
      "statusNode6": itm?.statusNode6 === true ? 'Active' : "Deactive",
      "statusNode7": itm?.statusNode7 === true ? 'Active' : "Deactive",
      "statusNode8": itm?.statusNode8 === true ? 'Active' : "Deactive",
      "statusNode9": itm?.statusNode9 === true ? 'Active' : "Deactive",

      "actions": (<div style={{display:'flex', gap:"5px"}}>
        <div  onClick={() => {
            navigate(`/DATAPLUS/Edit/site/config/${itm.uniqueid}`)

          }}><img src='/editIcon2.png' style={{width:"25px",width:"25px",cursor:'pointer'}}/></div>


          <div  onClick={() => {
              setActions(true)
              setDeleteIndex(index)

          }}><img src='/delete2.png' style={{width:"25px",width:"25px",cursor:'pointer'}}/></div>
      </div>)
    }
  })

  const handleRowClick = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  const tableColumns = [

    {
      Header: 'Site ID',
      accessor: 'siteId',
      Filter: ColumnFilter,
    },
    {
      Header: 'Node 1',
      accessor: "node1",
      Filter: ColumnFilter,
    },
    {
      Header: 'ENM 1',
      accessor: 'enm1',
      Filter: ColumnFilter,
    },
    {
      Header: 'Status Node 1',
      accessor: "statusNode1",
      Filter: ColumnFilter,
    },

    {
      Header: 'Node 2',
      accessor: "node2",
      Filter: ColumnFilter,
    },
    {
      Header: 'ENM 2',
      // Footer: 'S No',
      accessor: 'enm2',
      Filter: ColumnFilter,
    },
    {
      Header: 'Status Node 2',
      accessor: "statusNode2",
      Filter: ColumnFilter,
    },

    {
      Header: 'Actions',
      accessor: 'actions',
      Filter: ColumnFilter,
    },
  ]

  const tableColumns2 = [
    {
      Header: 'Node 3',
      // Footer: 'account',
      accessor: "node3",
      Filter: ColumnFilter,
    },
    {
      Header: 'ENM 3',
      // Footer: 'S No',
      accessor: 'enm3',
      Filter: ColumnFilter,
    },
    {
      Header: 'Status Node 3',
      // Footer: 'account',
      accessor: "statusNode3",
      Filter: ColumnFilter,
    },

    {
      Header: 'Node 4',
      // Footer: 'account',
      accessor: "node4",
      Filter: ColumnFilter,
    },
    {
      Header: 'ENM 4',
      // Footer: 'S No',
      accessor: 'enm4',
      Filter: ColumnFilter,
    },
    {
      Header: 'Status Node 4',
      // Footer: 'account',
      accessor: "statusNode4",
      Filter: ColumnFilter,
    },

    {
      Header: 'Node 5',
      // Footer: 'account',
      accessor: "node5",
      Filter: ColumnFilter,
    },
    {
      Header: 'ENM 5',
      // Footer: 'S No',
      accessor: 'enm5',
      Filter: ColumnFilter,
    },
    {
      Header: 'Status Node 5',
      // Footer: 'account',
      accessor: "statusNode5",
      Filter: ColumnFilter,
    },

    {
      Header: 'Node 6',
      // Footer: 'account',
      accessor: "node6",
      Filter: ColumnFilter,
    },
    {
      Header: 'ENM 6',
      // Footer: 'S No',
      accessor: 'enm6',
      Filter: ColumnFilter,
    },
    {
      Header: 'Status Node 6',
      // Footer: 'account',
      accessor: "statusNode6",
      Filter: ColumnFilter,
    },

    {
      Header: 'Node 7',
      // Footer: 'account',
      accessor: "node7",
      Filter: ColumnFilter,
    },
    {
      Header: 'ENM 7',
      // Footer: 'S No',
      accessor: 'enm7',
      Filter: ColumnFilter,
    },
    {
      Header: 'Status Node 7',
      // Footer: 'account',
      accessor: "statusNode7",
      Filter: ColumnFilter,
    },
    {
      Header: 'Node 8',
      // Footer: 'account',
      accessor: "node8",
      Filter: ColumnFilter,
    },
    {
      Header: 'ENM 8',
      // Footer: 'S No',
      accessor: 'enm8',
      Filter: ColumnFilter,
    },
    {
      Header: 'Status Node 8',
      // Footer: 'account',
      accessor: "statusNode8",
      Filter: ColumnFilter,
    },
    {
      Header: 'Node 9',
      // Footer: 'account',
      accessor: "node9",
      Filter: ColumnFilter,
    },
    {
      Header: 'ENM 9',
      // Footer: 'S No',
      accessor: 'enm9',
      Filter: ColumnFilter,
    },
    {
      Header: 'Status Node 9',
      // Footer: 'account',
      accessor: "statusNode9",
      Filter: ColumnFilter,
    },


  ]



  const expandedRowContent = (row) => {
    return (
      <tr>
        <td colSpan={tableColumns2.length}>
          <table>
            <tbody>

            
              {tableColumns2.map(col => (
                <tr key={col.accessor}>
                  <td>{col.Header}</td>
                  <td>{row[col.accessor]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    );
  };


  if (callDelete) {
    dispatch(
      CommonActions.deleteApiCaller(
        `${Urls.Post_Site_Id_Data}/${itemUniqueId[deleteIndex]}`,
        () => {
          dispatch(
            adminDashboardActions.getSiteIdList()
          );
        }
      )
    );
    setCallDelete(false)

  }
  return (
    <>
      {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} />}
      {actions && <ActionsModal setAlerts={setAlerts} actions={actions} setCallDelete={setCallDelete} setActions={setActions} />}
      {excelModal && <ExcelUploadModal FormComponent={ExcelForm} excelModal={excelModal} setExcelModal={setExcelModal} />}

      <div>
        <ExpandableTable
         expandKey={tableColumns[0].accessor}
          AddFormNavigate={AddFormNavigate}
          AddFormButtonName={AddFormButtonName}
          //  showFilter={showFilter} 
          tableColumns={tableColumns}
          tableColumns2={tableColumns2}
          tableData={tableData}
          expandedRowContent={expandedRowContent}
          onRowClick={handleRowClick}
          setExcelModal={setExcelModal}
          uploadExcel={uploadExcel}
        />
      </div>
    </>
  )
}

export default SiteID