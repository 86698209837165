import React from 'react'
// style={{display:'flex',gap:'10px'}}
import './DataBar.css'
const DataBar = ({approvedData}) => {
  return (
    <div  className='container' style={{backgroundColor:"#470468"}}  >
      {  approvedData.map((bar)=>{
            return(
                <div className='item'>
                {/* NEW */}
                <p
                //   className={`text-[12px] md:text-sm  ${bar.head.heading_color}`}
                className='heading'
                >
                  {bar.head.heading}
                </p>
                <div
                //   className={`my-1 h-[1.5px] w-full  ${bar.head.headingbar_color}`}
                style={{height:"1.5px", width:"100%", backgroundColor:`${bar.head.headingbar_color}`,marginBottom:"10px"}}
                ></div>

                {bar.sub.map((subpart) => {
                  console.log(subpart, "kjhbasdj");
                  return (
                    <>
                      <div style={{display:"flex",justifyContent:'space-between'}}
                        // className="flex justify-between hover:cursor-pointer"
                        // onClick={() =>
                        //   setFilterParam((prev) => ({
                        //     ...prev,
                        //     databarFilter: subpart.uniqueId,
                        //   }))
                        // }
                      >
                        <p
                        style={{fontSize:"12px"}}
                        //   className={`text-[11px] md:text-xs font-lato font-bold pt-2 ${subpart?.sub_heading_color}`}
                        >
                          {subpart?.sub_heading}
                        </p>
                        <div
                        //   className={`text-[11px] md:text-xs rounded-full  h-6 w-6 text-center
                        //  text-white pt-1`}
                          style={{
                            backgroundColor: `${subpart?.subdatabar_count_bgcolor}`,
                                // Set the width
           // Set the height (same as width)
        borderRadius:'50%', // Set the border radius to 50% to make it circular
        backgroundColor: '#3498db', // Set a background color
        height:"25px",
        width:"25px",
        textAlign:"center",
        paddingTop:"4px",
        fontSize:"12px"

                          }}
                        >
                          {subpart?.subdatabar_count}
                        </div>
                      </div>

                      <div >
                        <div
                        //   className={`my-2 h-[1.5px] w-full ${subpart?.databar_color}`}
                        ></div>
                      </div>
                    </>
                  );
                })}
              </div>
            )
        })}
    </div>
  )
}

export default DataBar