import React, { useState } from 'react'
import DATAPLUS_CommonForm from '../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import GoNoGoSupportActions from '../../../../store/actions/GoNoGoSupport-actions';
import AlertsModal from '../../../components/DATAPLUS_Components/AlertsModal';

const GONOGOSupportForm = ({setModal}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const[alerts,setAlerts] = useState(false)
  const navigateLink  =("/DATAPLUS/GO-NOGO/dashboard")
 
//     const  dispatch  = useDispatch();

//     useEffect(()=>{
//         dispatch(KPIModuleActions.getManageCIQSitesKPIlist())
//     },[])


//     const data  =  useSelector((state)=> state?.KPIModuleReducer?.getManageCIQSitesKPIlist)
//     console.log(data,"jyubuynk")

//     const siteIDOption  = data.map((itm)=>{return {label:itm._id, value:itm._id}})

// // kpi dashboard form data fetching



const { register, handleSubmit,setValue,getValues,setError,formState: { errors }  } = useForm();

const Form =[
  // {
  //   label:"Ticket ID",
  //   value:"",
  //   name:"siteid",
  //   type:"text",
  //   // option:siteIDOption,
  //   // placeholder:"Please Select SiteID"
  // },
  {
    label:"PACE ID",
    value:"",
    name:"paceId",
    type:"text",
    required:true
    // option:siteIDOption,
    // placeholder:"Please Select SiteID"
  },
  {
    label:"Site ID",
    value:"",
    name:"siteId",
    type:"text",
    required:true
    // option:siteIDOption,
    // placeholder:"Please Select SiteID"
  },
  {
    label:"GC Company",
    value:"",
    name:"gcCompany",
    type:"text",
    required:true
    // option:siteIDOption,
    // placeholder:"Please Select SiteID"
  },
  {
    label:"SOW",
    value:"",
    name:"sow",
    type:"select",
    option:[
      {label:'CX',value:'CX'},
    {label:'Power Upgrade',value:'Power Upgrade'},
    {label:'Antenna Cutover',value:'Antenna Cutover'},
    {label:'Hybrid Cutover',value:'Hybrid Cutover'},
    {label:'GSM Mitigation',value:'GSM Mitigation'},
    {label:'Sector Mount Replace',value:'Sector Mount Replace'},
    {label:'TS Hybrid Cable Replace',value:'TS Hybrid Cable Replace'},
    {label:'TS Antenna Replace',value:'TS Antenna Replace'},
    {label:'TS Radio Replace',value:'TS Radio Replace'},
    {label:'TS RET Corrections',value:'TS RET Correc'},
    {label:'TS Power',value:'TS Power'},
    {label:'TS Other',value:'TS Other'},
    {label:'NSI Prep Work',value:'NSI Prep Work'},
    {label:'NSI Punch List Corrections',value:'NSI Punch List Corrections'},
    {label:'NSI Close Out Activities',value:'NSI Close Out Activities'},
    {label:'NSI Other',value:'NSI Other'},
    {label:'IX',value:'IX'},
    {label:'Sector SWAP',value:'Sector SWAP'},
    
    ],
    required:true
    // placeholder:"Please Select SiteID"
  }, 
  {
    label:"Sector",
    value:"",
    name:"sector",
    type:"text",
    required:true
    // option:siteIDOption,
    // placeholder:"Please Select SiteID"
  },    
  
  {
    label:"Technology Affected",
    value:"",
    name:"technologyAffected",
    type:"text",
    required:true
    // option:siteIDOption,
    // placeholder:"Please Select SiteID"
  },  
  // {
  //   label:"Activity Start Time",
  //   value:"",
  //   name:"activityStartTime",
  //   type:"DateTime",
  //   required: true,
  //     setError:setError,
  //     getValues:getValues,
  //     setValue:setValue,
  //   // option:siteIDOption,
  //   // placeholder:"Please Select SiteID"
  // },  
  // {
  //   label:"Activity End Time",
  //   value:"",
  //   name:"activityEndTime",
  //   type:"DateTime",
  //   required: true,
  //     setError:setError,
  //     getValues:getValues,
  //     setValue:setValue,
  //   // option:siteIDOption,
  //   // placeholder:"Please Select SiteID"
  // }, 
  {
    label:"SA/NSA",
    value:"",
    name:"saNsa",
    type:"text",
    required:true
    // option:siteIDOption,
    // placeholder:"Please Select SiteID"
  },  
  
    
]

const onSubmit = (data) => {
    // Handle form submission here, you can access form data in the 'data' object
    console.log(data,"rdctfvgbhjkl");
 
    

    // const uniqueId = data.siteid
    //     console.log(uniqueId,"kjjasbdyn")
  
      dispatch(GoNoGoSupportActions.postGoNoGoSupportList(null,data,
        ()=>{
          dispatch(GoNoGoSupportActions.getGoNoGoSupportList())
      }
    ))
      setAlerts(true)
    // setModal(false)
    // navigate('/DATAPLUS/GO-NOGO/dashboard')

  };

 

return (
  
  <>
       {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink}/>}

  <form onSubmit={handleSubmit(onSubmit)}>
    <DATAPLUS_CommonForm Form={Form} register={register} errors={errors}/>
    <button type='submit' className='btn btn-primary'>
      Submit
    </button>
  </form>

  </>

)

}

export default GONOGOSupportForm