// export const baseUrl="http://10.5.50.198:7080"
// export const backendassetUrl="http://10.5.50.198:7080"

// export const baseUrl="http://192.168.29.71:7080"

// emdataPlus
// export const baseUrl = "https://emdataPlusdevapi.mpulsenet.com"; 

// mpulse by nitish
// export const baseUrl = "https://bnvapi.mpulsenet.com";

// export const baseUrl = "http://192.168.0.117:8095/";
// export const backendassetUrl = "http://192.168.0.117:8095";


export const baseUrl = "https://warroomapi.fourbrick.in/";
export const backendassetUrl = "https://warroomapi.fourbrick.in/"; 

// export const backendassetUrl = "http://192.168.29.71:7080/";


// export const backendassetUrl = "https://bnvapi.mpulsenet.com/"; 

// export const baseUrl="http://10.5.50.198:7080"
// export const backendassetUrl="http://10.5.50.198:7080/"

export const Urls = {
  login: "/login",
  sendMail: "/sendMail",
  logout: "/logout",
  admin_userList: "/admin/users",
  admin_roleList: "/admin/roles",

  // new url

  admin_customer: "/admin/manageCustomer",
  admin_projecttype: "/admin/manageProjectType",
  admin_circle: "/admin/manageCircle",
  admin_zone: "/admin/manageZone",
  admin_cost_center: "/admin/manageCostCenter",
  admin_project_group: "/admin/manageProjectGroup",

  MyHome: "/myHome/Cards",
  admin_assetRegistration: "/myHome/assetRegistration",
  common_file_uploadr: "/commonUploadFile",

  // MOP Dashboard Data Starts

  // 1. Datbar data
  Mop_Data: "/DATAPLUS/mop/siteStatus",

  // 2. Table data
  Mop_Table_Data: "/listMops",
  Mop_Filters: "/mopfilters",

  // create request form api start here

  // Create request siteid api
  Create_Request_Site_Id: "/dataPlus/sitedblistSite",
  // create request Proejct Id
  Create_Request_Project_Code: "/dataPlus/OneSiteDatabaseProjectCode/",
  Create_Request_Project_Id: "/dataPlus/OneSiteDatabaseprojectId/",
  Create_OnSite_Database: "/dataPlus/OneSiteDatabase/",
  Create_Account_Data: "/dataPlus/account",
  Create_List_User_Data: "/listUser?userrole=Tower Crew,GC Login",
  Create_Technology_Data: "/dataPlus/technology",

  // create request form api end here

  // ends here MOP dashboard

  // RAN Support starts here
  ran_support_dashboard_siteStatus: "/dataPlus/ran/siteStatus",
  ran_support_dashboard_table: "/dataPlus/RanDashboard",
  ran_support_tracker_table: "/dataPlus/RanTracker",
  ran_support_nesting_report: "/dataPlus/ranNesting/report",
  ran_support_nesting_tracker: "/dataPlus/ranNesting/tracker",
  ran_filters: "/ranfilters",
  on_the_fly_tmo_manager: "/dataPlus/tmoManager",
  list_user_data: "/listUser?userrole=Tower%20Crew,GC%20Login",
  ran_sla_report: "/dataPlus/RanSla",
  executive_spillover_report: "/dataPlus/RanSpilOver",

  // RAN Support ends here

  // UTRAN Dashboard
  UTRAN_Dashboard_Data:"/manageRanSupport",
  UTRAN_Add_Engineer:'dataPlus/assignRanEngineer',

  // goNOgO sUPPORT
  GONOGO_Support_Data:"/dataPlus/gonogoSupport",
  CXSupport_Data:"/dataPlus/cxSupport",

  // KPI Dashboard Starts Here
  KPI_Dashboard_Data:"/dataPlus/kpiDashboard",
  KPI_Trouble_Ticket_Data:"dataPlus/listKPITroubleTicket",
  Post_KPI_Trouble_Ticket_Data:"/dataPlus/KPItroubleTicket",
  Post_KPI_Engineer_Data:"/dataPlus/addFDatatoran",
 
  // KPI Dashboard Ends Here

  // CIQ DASHBOAFRD
  Manage_Ciq_Sites_Data:"/ciqScriptingSites",
  Manage_CIQ_Data:"/dataPlus/ciqSites",

  // Manage CIq Sites KPI Dashboard form
  Manage_CIQ_Sites_KPI_Data:"/manageCiqSites",
  // Post_Manage_CIQ_Sites_KPI_Data:"Manage_CIQ_Sites_KPI_Data",
  Manage_CIQ_Sites_KPI_ID_Data:"/wildKPISite",
  
  // IX Dashboard
  Manage_IX_Dashboard_Data:"/dataPlus/ciqSites",

  // E911 Dashboard
  Manage_E911_Dashboard_Data:"/dataPlus/psapBnV",


  // 72 hours alarm starts here
  hours_72_alarm: "/dataPlus/72alarm/",
  hours_72_form_submit: "/dataPlus/sevenTwo/add",
  // 72 hours alarm ends here

  // IX Dashboard data start

  // 1.databar data
  IX_Data: "/dataPlus/ix/siteStatus",

  // 2. table data
  IX_Table_Data: "/dataPlus/ixDashboard",
  IX_Calendar_Data: "/dataPlus/ixDashboard",
  IX_Delete_Url: "/dataPlus/operation_ix",
  IX_Dashboard_Engineer:"/dataPlus/addFDatatoran",

  // IX Dashboard data end

  // Trouble Tickets Starts here

  // 1.databar data
  TT_Dashboard_Data: "/dataPlus/tt/siteStatus",

  // 2. TTT dashboard table heading data
  TT_Dashboard_Table_Heading_data: "/dataPlus/userTableData",

  // 3. TT Dashboardb Table data

  TT_Dashboard_Table_data: "/dataPlus/listTroubleTicket",
  Post_TT_Dashboard_Data:"dataPlus/troubleTicket",
  // TT Dashboard Manage Site Id
  Manage_Site_Data:"/manageSite",

  TT_Tracker_Table_data: "/dataPlus/listTroubleTicket",
  TT_Filters: "/ttfilters",

  //Add Trouble ticket form fields
  TT_Type: "/dataPlus/manage/dropDowns/TT%20type",
  TT_Category: "/dataPlus/manage/dropDowns/TT%20Category",
  Pier_TT_Group: "/dataPlus/pierTTGroup",

  // Trouble Tickets Ends here

  //IX Support Starts Here
  Manage_Engineer_Data: "/dataPlus/manageEngineer",
  market_data: "/dataPlus/market",

  // E911 Call test Starts here
  E911_Dashboard_Table_data: "/dataPlus/listenno",
  E911_Tracker_Table_data: "/dataPlus/listenno",
  E911_Filters: "/enoofilters",
  // E911 Call test ends here

  // E911 Call test Starts here
  RTT_Dashboard_Table_data: "/dataPlus/rttDashboard",
  // E911 Call test ends here

  // RF PErformance Start here
  SSV_Dashboard_Table_data: "/dataPlus/ssvDashboard",
  LAUNCH_SSS_NNP_Table_data: "/dataPlus/lSSVDashboard",
  SSV_SSS_Approval_Table_data: "/dataPlus/mergedlSSVApproverDashboard",
  Common_Filters_data: "/commonfilters",
  // RF PErformance ends here

  //  Network Database start here
  Network_Database_Table_data: "/networkDatabase/dashboard",
  Network_Database_Filter_data: "/nwfilters",
  Project_Database_Table_data: "/dataPlus/projectList",
  // Network database ends here

  // Admin Dashboard Starts here

// project dtatabse dataplus
  Project_Database_Data:"/dataPlus/projectDatabase",
  Project_Database_Excel:"/dataPlus/projectDatabaseExcel",

  // customer dattaplus
  Customer_Data:'/dataPlus/customer',
  Post_Customer_Data:'/dataPlus/customer',

  // engineer dataplus
  Engineer_data:'/dataPlus/engineer',
  Post_Engineer_data:'/dataPlus/engineer',

  // MANAGE ACOOUNTS dataPlusE
  Manage_Account_Data: "/dataPlus/account",

  //  manage filtered markets
  Filtered_Markets_Data: "/dataPlus/filteredMarkets",

  // MANAGE MARKETS dataPlusE
  Manage_Markets_Data: "/dataPlus/markets",
  Time_Zone_Data: "/dataPlus/timeZone",

  // manage vendor dataPluse
  Manage_Vendor_Data: "/dataPlus/vendor",

  // Manage GC
  Manage_GC:"/listUser?userrole=Tower%20Crew,GC%20Login",
  Post_Manage_GC_Data:"/addUser",
  Delete_GC_Item:"/deleteUser",
  Update_GC_Item:"/updateUser",

  // oss
  OSS_Data:'/dataPlus/enm',
  Post_OSS_Data:'/dataPlus/enm',

  // site id
  Site_Id_Data:"/dataPlus/siteId",
  Post_Site_Id_Data:"/dataPlus/siteId",
  Post_Site_Id_Excel_Data:'/dataPlus/siteIdExcel',

  // manage crew company
  Manage_Crew_Company_Data: "/dataPlus/crewCompany",
  Manage_List_Role_Two_Data :"/listRole_two",


  // manage crew
  Manage_Crew_Data: "/listUser?userrole=Tower%20Crew,GC%20Login",
  Post_Manage_Crew_Data: "/addUser",
  Manage_List_Two_Data: "/listRole_two",

  // TMO deploymwnt managers
  TMO_Deployment_Managers_Data:
    "/listUser?userrole=RF%20Engineer,FOPS,Development,dataPlus%20Admin,dataPlus%20Engineer,No%20MOP%20Access,T-Mobile%20Manager,TruNorthe",
  Dropdown_Group_Data: "/dataPlus/manage/dropDowns/Group",
  User_Role_Data: "/listRole_tmobile",

  // TT Pier Group
  TT_Pier_Group_Data: "/dataPlus/pierTTGroup",
  Group_Dropdown_Data: "/dataPlus/manage/dropDowns/Group",

  // manage score card data
  Manage_Score_Card_Data: "/dataPlus/manage/vendorscorecard",

  // manage dropdown lists starts here

  // TT TYPE Start
  TT_Type_Data: "/dataPlus/manage/dropDowns/TT%20type",
  Post_TT_Type_Data: "/dataPlus/manage/dropDownlist",
  // TT TYPE ends

  //  TT category Data start
  TT_Category_Data: "/dataPlus/manage/dropDowns/TT%20Category",
  Post_TT_Category_Data: "/dataPlus/manage/dropDownlist",
  //  TT category Data end

  //  SOW Details start here
  SOW_Details_Data: "/dataPlus/manage/dropDowns/SOW%20Details",
  Post_SOW_Details_Data: "/dataPlus/manage/dropDownlist",
  //  SOW Details end here

  // mobile app pre start
  Mobile_App_COP_Pre_Data: "/dataPlus/manage/dropDowns/COP%20Pre",
  Post_Mobile_App_COP_Pre_Data: "/dataPlus/manage/dropDownlist",
  // mobile app pre end

  // mobile app post start
  Mobile_App_COP_Post_Data: "/dataPlus/manage/dropDowns/COP%20Post",
  Post_Mobile_App_COP_Post_Data: "/dataPlus/manage/dropDownlist",
  // mobile app post end

  Technology_Master_Data: "/dataPlus/technology",

  // pre existing tm start
  Pre_Existing_TM_Data: "/dataPlus/manage/dropDowns/Pre-Existing%20TM",
  Post_Pre_Existing_TM_Data: "/dataPlus/manage/dropDownlist",
  // pre existing tm end

  Group_Master_Data: "/dataPlus/manage/dropDowns/Group",
  Post_Group_Master_Data: "/dataPlus/manage/dropDownlist",
  // manage dropdown lists here here



  // Admin Dashboard Ends here

  // mobile geofencing start here
  Manage_Mobile_Geofencing_Data: "/dataPlus/geoFencing",
};

export const WebSocketUrls = { siteAnalytics: "siteanalytics" };
