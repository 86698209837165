import React, { useEffect, useState } from 'react'
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter'
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable'
import { useNavigate } from 'react-router-dom'

import ExcelUploadModal from '../../../../components/DATAPLUS_Components/ExcelUploadModal';
import AlertsModal from "../../../../components/DATAPLUS_Components/AlertsModal"
import { useDispatch, useSelector } from 'react-redux';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import ExcelUploadForm from './ExcelUploadForm';
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Button,
} from "react-bootstrap";
import ActionsModal from '../../../../components/DATAPLUS_Components/ActionsModal';
import CommonActions from '../../../../../store/actions/common-actions';
import { Urls } from '../../../../../utils/url';
import ExpandableTable from '../../../../components/table/FilteringTable/ExpandableTable';
import ExpandableTablePD from '../../../../components/table/FilteringTable/ExpandableTablePD';
import CommentModal from '../../../../components/DATAPLUS_Components/CommentModal';
import EditTableDataPD from './EditTableDataPD';
import FormModal from '../../../../components/DATAPLUS_Components/FormModal';


const ProjectDatabse = () => {

  const [alerts, setAlerts] = useState(false)
  const [actions, setActions] = useState(false)
  const [callDelete, setCallDelete] = useState(false)
  const [modal, setModal] = useState(false);
  const [itmValue, setItmValue] = useState({})
  const [type, setType] = useState([]);
  const [commentHeading, setCommentHeading] = useState("")
  const [deleteIndex, setDeleteIndex] = useState(null)


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [excelModal, setExcelModal] = useState(false)

  const AddFormButtonName = '+ Data'
  const AddFormNavigate = "/DATAPLUS/Add/Project/Progress"
  const uploadExcel = true
  let ExcelForm = (ExcelUploadForm)
  let AddCommentsForm = (EditTableDataPD)


  console.log(excelModal, ExcelForm, "____excelModal")


  useEffect(() => {
    dispatch(adminDashboardActions.getProjectDatabaseList())
  }, [])


  const Data = useSelector((state) => state?.AdminDashboard_reducer?.getProjectDatabaseList)
  console.log(Data, "__dataProject")


  let itemUniqueId = []
  let tableData = Data?.map((itm, index) => {
    itemUniqueId[index] = itm.uniqueid

    const commentKeys = [
      "USID",
      "FA_Location_Code",
      "PACE Job Number",
      "IWM Job Number",
      "Job Family Status Name",
      "Oracle PTN",
      "National Program",
      "PACE Name",
      "I-Plan Spectrum",
      "Pre-Config",
      "Post Config",
      "Current Config",
      "PSAP Comment",
      "e911 Comment",
      "TVW Comment",
      "EDP Comment",
      "IX Comment",
      "CIQ Comment",
      "RFDS Comment",
      "IWOS Comment",
    ]

    const selectKeys = [
      "PSAP Status",
      "e911 Status",
      "TVW Status",
      "EDP Status",
      "IX Status",
      "CIQ Status",
      "RFDS Status",
      "IWOS Status",
    ];


    let finalKeys = {};

    for (let i = 0; i < selectKeys.length; i++) {
      finalKeys = {
        ...finalKeys, [selectKeys[i]]: (<div
          onClick={() => {
            setModal(true);
            setItmValue(itm);
            setType({
              inputType: "select",
              option: [{ label: 'OK', value: 'OK' },
              { label: 'NOK', value: 'NOK' },
              { label: 'NA', value: 'NA' }
              ],
            })
            // [selectKeys[i]] ? itm[selectKeys[i]] : "No Comments"
            setCommentHeading(selectKeys[i])
          }}
          style={{
            // backgroundColor: 'yellow',
            borderWidth: '2px',
            borderColor: 'black',
            height: "40px",
            maxWidth: '200px',
            minWidth: '200px',
            width: "100px",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {itm[selectKeys[i]]
            ?  itm[selectKeys[i]]
            : "NA"}
        </div>)
      }
    }

    for (let i = 0; i < commentKeys.length; i++) {
      finalKeys = {
        ...finalKeys, [commentKeys[i]]: (<div
          onClick={() => {
            setModal(true);
            setItmValue(itm);
            setType({
              inputType: "textArea",
            });
            setCommentHeading(commentKeys[i]);
          }}
          style={{
            borderWidth: '2px',
            borderColor: 'black',
            width: "400px",        // Fixed width
            height: "auto",        // Height will adjust based on content
            overflow: 'hidden',    // Prevents overflow, but content will wrap
            textOverflow: 'ellipsis', // Text overflow handling
            whiteSpace: 'normal',  // Allows text to wrap, increasing div height
            wordWrap: 'break-word', // Prevents long words from overflowing
          }}
        >
          {itm[commentKeys[i]]
            ? itm[commentKeys[i]]
            : ""}
        </div>
        )
      }
    }

    console.log(finalKeys, "_____keyyyyss")
    return {
      ...itm,
      // sNo: index + 1,
      "PSAP Comment": finalKeys["PSAP Comment"],
      "e911 Comment": finalKeys["e911 Comment"],
      "TVW Comment": finalKeys["TVW Comment"],
      "EDP Comment": finalKeys["EDP Comment"],
      "IX Comment": finalKeys["IX Comment"],
      "CIQ Comment": finalKeys["CIQ Comment"],
      "RFDS Comment": finalKeys["RFDS Comment"],
      "IWOS Comment": finalKeys["IWOS Comment"],


      "USID": finalKeys["USID"],
      "FA_Location_Code": finalKeys["FA_Location_Code"],
      "PACE Job Number": finalKeys["PACE Job Number"],
      "IWM Job Number": finalKeys["IWM Job Number"],
      "Job Family Status Name": finalKeys["Job Family Status Name"],
      "Oracle PTN": finalKeys["Oracle PTN"],
      "National Program": finalKeys["National Program"],
      "PACE Name": finalKeys["PACE Name"],
      "I-Plan Spectrum": finalKeys["I-Plan Spectrum"],
      "Pre-Config": finalKeys["Pre-Config"],
      "Post Config": finalKeys["Post Config"],
      "Current Config": finalKeys["Current Config"],




      "PSAP Status": finalKeys["PSAP Status"],
      "e911 Status": finalKeys["e911 Status"],
      "TVW Status": finalKeys["TVW Status"],
      "EDP Status": finalKeys["EDP Status"],
      "IX Status": finalKeys["IX Status"],
      "CIQ Status": finalKeys["CIQ Status"],
      "RFDS Status": finalKeys["RFDS Status"],
      "IWOS Status": finalKeys["IWOS Status"],



      "actions": (<div style={{ display: 'flex', gap: "5px", cursor: 'pointer' }}>
        <div onClick={() => {
          navigate(`/DATAPLUS/Edit/Project/Progress/${itm.uniqueid}`)

        }}><img src='/editIcon2.png' style={{ width: "25px", width: "25px" }} /></div>


        <div onClick={() => {
          setActions(true)
          setDeleteIndex(index)

        }}><img src='/delete2.png' style={{ width: "25px", width: "25px", cursor: 'pointer' }} /></div>
      </div>)



      // ((<Dropdown style={{height:"30px !important",width:'20px !important'}}>
      //   <Dropdown.Toggle
      //     variant="danger"
      //     className="light sharp i-false"
      //   >
      //     {svg1}
      //   </Dropdown.Toggle>
      //   <Dropdown.Menu>
      //     <Dropdown.Item onClick={() => {
      //       navigate(`/DATAPLUS/Edit/Project/Progress/${itm.uniqueid}`)

      //     }}>Edit</Dropdown.Item>

      //     <Dropdown.Item >Delete</Dropdown.Item>
      //   </Dropdown.Menu>
      // </Dropdown>))

    }
  })

  const tableColumns = [
    // { Header: 'S No.', accessor: 'sNo', Filter: ColumnFilter },
    { Header: 'ACCOUNT', accessor: "Account", Filter: ColumnFilter },
    { Header: 'MARKET', accessor: 'Market', Filter: ColumnFilter },
    { Header: 'Site ID', accessor: "SiteID", Filter: ColumnFilter },
    { Header: 'Project Status', accessor: "Project Status", Filter: "" },
    { Header: 'Remarks', accessor: "Remarks", Filter: "" },
    { Header: 'Actions', accessor: "actions", Filter: "" }
  ];


  const tableColumns2 = [
    {
      Header: 'USID_',
      accessor: 'USID',
      Filter: ColumnFilter,
    },
    {
      Header: 'FA_Location_Code_',
      accessor: "FA_Location_Code",
      Filter: ColumnFilter,
    },
    {
      Header: 'PACE Job Number_',
      accessor: 'PACE Job Number',
      Filter: ColumnFilter,
    },
    {
      Header: 'IWM Job Number_',
      accessor: "IWM Job Number",
      Filter: ColumnFilter,
    },

    {
      Header: 'Job Family Status Name_',
      accessor: "Job Family Status Name",
      Filter: "",
    },
    {
      Header: 'Oracle PTN_',
      accessor: "Oracle PTN",
      Filter: "",
    },
    {
      Header: 'National Program_',
      accessor: "National Program",
      Filter: "",
    },
    {
      Header: 'PACE Name_',
      accessor: "PACE Name",
      Filter: "",
    },
    {
      Header: 'I-Plan Spectrum_',
      accessor: "I-Plan Spectrum",
      Filter: "",
    },
    {
      Header: ' Pre-Config_',
      accessor: " Pre-Config",
      Filter: "",
    },
    {
      Header: 'Post Config_',
      accessor: "Post Config",
      Filter: "",
    },
    {
      Header: 'Current Config_',
      accessor: "Current Config",
      Filter: "",
    },
    {
      Header: 'RFDS Status',

      accessor: "RFDS Status",
      Filter: "",
    },
    {
      Header: 'RFDS Type',
      accessor: "RFDS Type",
      Filter: "",

    },
    {
      Header: 'RFDS Comment',
      accessor: "RFDS Comment",
      Filter: "",

    },
    {
      Header: 'CIQ Status',
      accessor: "CIQ Status",
      Filter: "",

    },
    {
      Header: 'CIQ Comment',
      accessor: "CIQ Comment",
      Filter: "",

    },
    {
      Header: 'IX Status',
      accessor: "IX Status",
      Filter: "",

    },
    {
      Header: 'IX Comment',
      accessor: "IX Comment",
      Filter: "",

    },
    {
      Header: 'EDP Status',
      accessor: "EDP Status",
      Filter: "",

    },
    {
      Header: 'EDP Comment',
      accessor: "EDP Comment",
      Filter: "",

    },
    {
      Header: 'TVW Status',
      accessor: "TVW Status",
      Filter: "",

    },
    {
      Header: 'TVW Comment',
      accessor: "TVW Comment",
      Filter: "",

    },
    {
      Header: 'IWOS Status',
      accessor: "IWOS Status",
      Filter: "",

    },
    {
      Header: 'IWOS Comment',
      accessor: "IWOS Comment",
      Filter: "",

    },
    {
      Header: 'e911 Status',
      accessor: "e911 Status",
      Filter: "",

    },
    {
      Header: 'e911 Comment',
      accessor: "e911 Comment",
      Filter: "",

    },
    {
      Header: 'PSAP Status',
      accessor: "PSAP Status",
      Filter: "",

    },
    {
      Header: 'PSAP Comment',
      accessor: "PSAP Comment",
      Filter: "",

    },
  ]

  const expandedRowContent = (row) => {
    console.log(row, "expapdpspapd"); // Check if row contains the right data

    return (
      <tr>
        <td colSpan={tableColumns2.length}>
          <table>
            <tbody>
              {tableColumns2.map(col => (

                console.log(row[col.accessor], "______rowdata")
              ))}
              {tableColumns2.map(col => (

                <tr key={col.accessor}>
                  <td>{col.Header}</td>
                  <td>{row[col.accessor]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    );
  };


  if (callDelete) {
    dispatch(
      CommonActions.deleteApiCaller(
        `${Urls.Project_Database_Data}/${itemUniqueId[deleteIndex]}`,
        () => {

          console.log(itemUniqueId, "dgcfk")
          dispatch(
            adminDashboardActions.getProjectDatabaseList()
          );
          // dispatch(ALERTS({ show: false }));
        }
      )
    );
    setCallDelete(false)

  }

  console.log(actions, alerts, "___actions")

  return (


    <>
      {/* {modal && <CommentModal commentHeading={commentHeading} modal={modal} setModal={setModal} itmValue={itmValue} />} */}
      {modal && <FormModal type={type} modalHeading={commentHeading} isOpen={modal} modal={modal} setModal={setModal} FormComponent={AddCommentsForm} itmValue={itmValue} />}
      {excelModal && <ExcelUploadModal FormComponent={ExcelForm} excelModal={excelModal} setExcelModal={setExcelModal} />}
      {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} />}
      {actions && <ActionsModal setAlerts={setAlerts} actions={actions} setCallDelete={setCallDelete} setActions={setActions} />}
      <div>
        <ExpandableTablePD
          expandKey={tableColumns[2].accessor}
          AddFormNavigate={AddFormNavigate}
          AddFormButtonName={AddFormButtonName}
          //  showFilter={showFilter} 
          tableColumns={tableColumns}
          tableColumns2={tableColumns2}
          tableData={tableData}
          expandedRowContent={expandedRowContent}
          //  onRowClick={handleRowClick} 
          setExcelModal={setExcelModal}
          uploadExcel={uploadExcel}

        //  item={itemUniqueId}
        />
      </div>
    </>
  )
}

export default ProjectDatabse


// div
{/* <div
          onClick={() => {
            setModal(true);
            setItmValue(itm);
            setType({
              inputType: "textArea",
            })
            // [commentKeys[i]] ? itm[commentKeys[i]] : "No Comments"
            setCommentHeading(commentKeys[i])
          }}
          style={{
            // backgroundColor: 'yellow',
            borderWidth: '2px',
            borderColor: 'black',
            height: "40px",
            maxWidth: 'fit-content',
            minWidth: 'fit-content',
            width: "100px",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {itm[commentKeys[i]]
            ? itm[commentKeys[i]].length > 15
              ? itm[commentKeys[i]].substring(0, 100) + '...'
              : itm[commentKeys[i]]
            : ""}
        </div> */}