import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    getIXDashboardList: [],
    getIXSchedulerSiteIdList: [],
    getIXSchedulerEngineerList: [],
    getIXSchedulerTechnologyList:[],
    getIXSchedulerListUserList:[],
    getIXCalendarDataList:[],
    getIXCalenderIDDataList:[],
    getIXDashboardIDList:[]

}

const IXSupportData = createSlice({
    name: "IXSupportData",
    initialState,
    reducers: {

        SET_IX_DASHBOARD: (state, { payload }) => {
            if (payload.reset) {
                state.getIXDashboardList = payload.dataAll;
            } else {
                state.getIXDashboardList = [
                    ...state.getIXDashboardList,
                    ...payload.dataAll,
                ];
            }
        },
    
        SET_IX_SECHEDULER_SITE_ID: (state, { payload }) => {
        console.log("SET_IX_SECHEDULER_SITE_ID", payload)
        if (payload.reset) {
        state.getIXSchedulerSiteIdList = payload.dataAll;
        } else {
        state.getIXSchedulerSiteIdList = [
            ...state.getIXSchedulerSiteIdList,
            ...payload.dataAll,
        ];
        }
    },

    SET_IX_SECHEDULER_ENGINEER: (state, { payload }) => {
        console.log('callled...')
        if (payload.reset) {
            state.getIXSchedulerEngineerList = payload.dataAll;
        } else {
            state.getIXSchedulerEngineerList = [
                ...state.getIXSchedulerEngineerList,
                ...payload.dataAll,
            ];
        }
    }
    ,
    SET_IX_SECHEDULER_TECHNOLOGY: (state, { payload }) => {
        console.log('callled...')
        if (payload.reset) {
            state.getIXSchedulerTechnologyList = payload.dataAll;
        } else {
            state.getIXSchedulerTechnologyList = [
                ...state.getIXSchedulerTechnologyList,
                ...payload.dataAll,
            ];
        }
    },
    SET_IX_SECHEDULER_LIST_USER: (state, { payload }) => {
        console.log('callled...')
        if (payload.reset) {
            state.getIXSchedulerListUserList = payload.dataAll;
        } else {
            state.getIXSchedulerListUserList = [
                ...state.getIXSchedulerListUserList,
                ...payload.dataAll,
            ];
        }
    },
    SET_IX_CALENDAR_DATA: (state, { payload }) => {
        console.log("habshd");
        if (payload.reset) {
          state.getIXCalendarDataList = payload.dataAll;
        } else {
          state.getIXCalendarDataList = [...state.getIXCalendarDataList, ...payload.dataAll];
        }
      },

      SET_IX_CALENDAR_ID_DATA: (state, { payload }) => {
        console.log("habshd");
        if (payload.reset) {
          state.getIXCalenderIDDataList = payload.dataAll;
        } else {
          state.getIXCalenderIDDataList = [...state.getIXCalenderIDDataList, ...payload.dataAll];
        }
      },
      SET_IX_DASHBOARD_ID_DATA: (state, { payload }) => {
        console.log("habshd");
        if (payload.reset) {
          state.getIXDashboardIDList = payload.dataAll;
        } else {
          state.getIXDashboardIDList = [...state.getIXDashboardIDList, ...payload.dataAll];
        }
      },

}})


export const { SET_IX_DASHBOARD, SET_IX_SECHEDULER_ENGINEER, SET_IX_SECHEDULER_SITE_ID,
    SET_IX_SECHEDULER_TECHNOLOGY,SET_IX_SECHEDULER_LIST_USER,SET_IX_CALENDAR_DATA,SET_IX_CALENDAR_ID_DATA ,
    SET_IX_DASHBOARD_ID_DATA} = IXSupportData.actions;
export default IXSupportData.reducer;