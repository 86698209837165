import React, { useEffect, useMemo, useState } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import MOCK_DATA from './MOCK_DATA_2.json';
import { COLUMNS } from './Columns';
import { GlobalFilter } from './GlobalFilter';
import './filtering.css';
import { ColumnFilter } from './ColumnFilter';
import MPAG_Modal from '../../DATAPLUS_Components/DATAPLUS_Modal';
import FilterModal from '../../DATAPLUS_Components/FilterModal';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import adminDashboardActions from '../../../../store/actions/AdminDashboard-actions';



export const FilteringTable = ({ uploadExcel=false,showMarket = "", filterHeading = "", AddFormButton = (<></>),
	tableColumns, tableData, setForm = "false", tableHeading, showFilter = true, showButton,
	AddFormButtonName = "", AddFormNavigate = "", setExcelModal="false" }) => {

	const [expand, setExpand] = useState(7)


	console.log(tableData, "tableData")
	const columns = useMemo(() => tableColumns, [])
	const data = useMemo(() => Array?.isArray(tableData) ? tableData : [], [tableData])
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, usePagination)



	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance


	// form market start
	const dispatch = useDispatch()
	// useEffect(()=>{
	// 	dispatch(adminDashboardActions.getManageMarketsList())
	//   },[])

	//   const marketsData  =  useSelector((state)=> state?.AdminDashboard_reducer?.getManageMarketsList)

	//   console.log(marketsData,"yhviyubv6f7")

	//   let marketOptions = marketsData?.map((itm, index) => {

	// 	return {

	// 	  label:itm.marketName,value:itm.marketName


	// 	}

	//   })

	// 	const{register, handleSubmit}= useForm()

	// const 	Form=[{
	// 		label: "Market :",
	// 		value: "",
	// 		name: "markets",
	// 		type: "select",
	// 		option: marketOptions,
	// 		placeholder: "",
	// 		// control:control
	// 	  },]
	// form market end



	console.log(headerGroups, "headerGroups")
	const { globalFilter, pageIndex } = state

	const navigate = useNavigate()
	const modalButton = "Filter";
	const Filter = (<><Card className='' >
		{/* <Card.Header>
			<Card.Title>{filterHeading}</Card.Title>
		</Card.Header> */}
		<Card.Body className=''>
			{headerGroups.map(headerGroup => (
				<div style={{ margin: "10px", }} className='col-sm-12 ' {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map(column => (

						<div {...column.getHeaderProps()}>
							{(column.Header !== "Actions" && column.Filter !== "") && column.render('Header')}
							{column?.canFilter ? column.render('Filter') : null}
						</div>
					))}
				</div>
			))}
		</Card.Body>
	</Card></>)

	return (
		<>


			{/* <PageTitle activeMenu="Filtering" motherMenu="Table" /> */}
			<div className="card">
				<div className="card-header" style={{ display: "flex", justifyContent: "end", justifyContent: "space-between", height: "80px", }}>

					<div style={{ display: "flex", gap: "10px" }}>
						<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

						{/* market Form start */}
						{/* {showMarket==="Market" && (Form.map((itm)=>{
						return (
								<div style={{display:'grid',marginBottom:"10px"}}>
								<label style={{fontSize:"15px",color:"#b7b7b7",paddingLeft:"10px",fontWeight:"normal"}}>{itm.label}</label>
                                <select style={{width:'150px',backgroundColor:"#f78f13", marginRight:"10px",height:"35px",borderRadius:"7px"}}
                                    id='selectOption'
                                    className='form-control'
                                    disabled={itm.disabled}
                                    {...register(itm.name)}
                                >
                                    <option selected value={""} disabled >Select</option>
                                    {itm?.option?.map((bar) => {
                                        return (

                                            <option style={{color:'white'}} value={bar.value}>{bar.label}</option>
                                        )
                                    })}


                                </select>
								</div>
                            )
					}))}   */}
						{/* market Form end */}


					</div>

					<div className='add-form-button'>
						{showFilter && <FilterModal
							FilteringComponent={Filter} modalButton={modalButton} />}

						{console.log(AddFormNavigate, "AddFormNavigate")}

						{uploadExcel ?	<Button style={{ marginLeft: "10px" }}
							variant="primary"
							className="close"
							onClick={() => setExcelModal(true)}
						>
							Upload File
						</Button> :""}

						{AddFormButtonName !== "" && (<Button style={{ marginLeft: "10px" }}
							variant="primary"
							className="close"
							onClick={() => navigate(AddFormNavigate)}
						>
							{AddFormButtonName}
						</Button>)}
						{AddFormButtonName === "" && (AddFormButton)}
					</div>


				</div>
				<div className="card-body">
					<div className="table-responsive">

						<table {...getTableProps()} className="table filtering-table table-responsive-lg">
							<thead style={{padding:'0px'}} >
								{console.log(headerGroups, "______headerGrp")}
								{headerGroups.map((headerGroup, index) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column, index) => (

											<th {...column.getHeaderProps()}>
												{column.render('Header')}
												{/* {column?.canFilter ? column.render('Filter') : null} */}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()} className="" >

								{page.map((row) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return <td style={{ textAlign: "left", fontSize: "14px", height: "8px !important" }} {...cell.getCellProps()}> {cell.render('Cell')} </td>
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number"
									className="ms-2"
									defaultValue={pageIndex + 1}
									onChange={e => {
										const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
										gotoPage(pageNumber)
									}}
								/>
							</span>
						</div>

						<div  className="text-center">
							<div className="filter-pagination  mt-3"style={{backgroundColor:"#fff"}}>
								<button  style={{color:'black'}} className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

								<button style={{color:'black'}} className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button  style={{color:'black'}} className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button  style={{color:'black'}} className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)

}
export default FilteringTable;