import { useNavigate } from "react-router-dom";
import Api from "../../utils/api";
import { Urls } from "../../utils/url";
import {
  
    SET_GONOGO_SUPPORT
} from "../reducers/GoNoGoSupport-reducer";
import { ALERTS } from "../reducers/component-reducer";
// import { ALERTS } from "../reducers/component-reducer";

const GoNoGoSupportActions = {

    getGoNoGoSupportList:
    (reset = true, args = "") =>
    async (dispatch, _) => {
      try {
        const res = await Api.get({
          url: `${Urls.GONOGO_Support_Data}${args != "" ? "?" + args : ""}`,
        });
        console.log(res,"result")
        if (res?.status !== 200) return ;
        let dataAll = res?.data?.data;
        console.log(dataAll,"casdasd")

        dispatch(SET_GONOGO_SUPPORT({ dataAll, reset }));
      } catch (error) {
        console.log(error,"error")
      }
    },
  //  manager account starts here
 
  postGoNoGoSupportList: (uniqueId=null,data, cb) => async (dispatch, _) => {
    try {
      const res = await Api.post({
        data: data,
        url:
          uniqueId == null
            ? Urls.GONOGO_Support_Data
            : Urls.GONOGO_Support_Data + "/" + uniqueId,
      });
      if (res?.status !== 201 && res?.status !== 200) {
        let msgdata = {
          show: false,
          icon: "",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        console.log(msgdata,"1111")
        dispatch(ALERTS(msgdata));
      } else {
        console.log(res,"resdata")
        let msgdata = {
          show: true,
          icon: "",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        console.log(msgdata,"22222")
        dispatch(ALERTS(msgdata));
        cb();
       
      }
      
    } catch (error) {
      
      return;
    }
  },




};
export default GoNoGoSupportActions;



//   // manage vendor starts here
//   getVendorList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Vendor_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjddsfsdfbk");
//         dispatch(GET_VENDOR({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postVendorList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Manage_Vendor_Data
//             : Urls.Manage_Vendor_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },
//   // manage vendor ends here

//   // manage crew company starts here
//   getManageCrewCompanyList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Crew_Company_Data}${
//             args != "" ? "?" + args : ""
//           }`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MANAGE_CREW_COMPANY({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postManageCrewCompanyList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Manage_Crew_Company_Data
//               : Urls.Manage_Crew_Company_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },
//   // manage crew company endds here

//   // manage engineer starts here
//   getManageEngineerList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Engineer_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MANAGE_ENGINEER({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postManageEngineerList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Manage_Engineer_Data
//               : Urls.Manage_Engineer_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },
//   // manage engineer ends here

//   // TT PIER GROUP Starts here
//   getTTPierGroupList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.TT_Pier_Group_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_TT_PIER_GROUP({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postTTPierGroupList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.TT_Pier_Group_Data
//             : Urls.TT_Pier_Group_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },

//   // group fropdown data

//   getGroupDataList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Group_Dropdown_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_GROUP_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },

//   // TT Pier GROUP ends here

//   // MAnage Score Card starts here

//   getManageScoreCardList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Score_Card_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MANAGE_SCORE_CARD_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postManageScoreCardList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Manage_Score_Card_Data
//               : Urls.Manage_Score_Card_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },
//   // manage score card ends here

//   // manage crew start here
//   getManageCrewList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Crew_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MANAGE_CREW_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   // list two api data
//   getListTwoList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_List_Two_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_LIST_TWO_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },

//   postManageCrewList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_Manage_Crew_Data
//             : Urls.Post_Manage_Crew_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },
//   // manage crew ends here

//   // TMO deployment managers start here
//   getTMODeploymentManagersList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.TMO_Deployment_Managers_Data}${
//             args != "" ? "?" + args : ""
//           }`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_TMO_DEPLOYMENT_MANAGERS_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },

//   postTMODeploymentManagersList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.TMO_Deployment_Managers_Data
//               : Urls.TMO_Deployment_Managers_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },
//   // TMO deployment managers ends here

//   // DROPDOWN GROUP START HERE
//   getDropdownGroupList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Dropdown_Group_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_DROPDOWN_GROUP_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   // DROPDOWN GROUP ENDS HERE

//   // USER ROLE START HERE
//   getUserRoleList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.User_Role_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_USER_ROLE_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   // USER ROLE ENDS HERE

//   // DROPDOWN LIST STARTS HERE

//   //TT TYPE DATA
//   getTTTypeList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.TT_Type_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_TT_TYPE_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postTTTypeList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_TT_Type_Data
//             : Urls.Post_TT_Type_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },

//   // TT Category list data

//   getTTCategoryList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.TT_Category_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_TT_CATEGORY_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postTTCategoryList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_TT_Category_Data
//             : Urls.Post_TT_Category_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },

//   // SOW details data

//   getSOWDetailsList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.SOW_Details_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_SOW_DETAILS_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postSOWDetailsList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_SOW_Details_Data
//             : Urls.Post_SOW_Details_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },

//   // COP PRE NAME DATA
//   getMobileAppCOPPreList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Mobile_App_COP_Pre_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MOBILE_APP_COP_PRE_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postMobileAppCOPPreList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Post_Mobile_App_COP_Pre_Data
//               : Urls.Post_Mobile_App_COP_Pre_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },

//   // COP POST NAME DATA
//   getMobileAppCOPPostList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Mobile_App_COP_Post_Data}${
//             args != "" ? "?" + args : ""
//           }`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MOBILE_APP_COP_POST_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postMobileAppCOPPostList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Post_Mobile_App_COP_Post_Data
//               : Urls.Post_Mobile_App_COP_Post_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },

//   // TECHNOLOGY MASTER DATA
//   getTechnologyMasterList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Technology_Master_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_TECHNOLOGY_MASTER_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postTechnologyMasterList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Technology_Master_Data
//               : Urls.Technology_Master_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },

//   // pre existing TM DATA
//   getPreExistingTMList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Pre_Existing_TM_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_PRE_EXISTING_TM_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postPreExistingTMList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_Pre_Existing_TM_Data
//             : Urls.Post_Pre_Existing_TM_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },
//   // group master data
//   getGroupMasterList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Group_Master_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_GROUP_MASTER_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postGroupMasterList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_Group_Master_Data
//             : Urls.Post_Group_Master_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },

//   // DROPDOWN LIST ENDS HERE

//   // mobile geofenciing start here
//   getManageMobileFencingList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Mobile_Geofencing_Data}${
//             args != "" ? "?" + args : ""
//           }`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MANAGE_MOBILE_FENCING_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postManageMobileFencingList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Manage_Mobile_Geofencing_Data
//               : Urls.Manage_Mobile_Geofencing_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },
//   // mobile geofenciing end here