import React, { useEffect, useState } from 'react'
import MPAG_CommonForm from '../../../components/DATAPLUS_Components/DATAPLUS_CommonForm'
import { useForm } from 'react-hook-form';
import troubleTicketActions from '../../../../store/actions/TroubleTicket-actions';
import { useDispatch } from 'react-redux';
import { ALERTS } from '../../../../store/reducers/component-reducer';
import moment from 'moment'
import { useParams } from 'react-router-dom';
import AlertsModal from '../../../components/DATAPLUS_Components/AlertsModal';

const TTDashboardAddComments = ({item,modal,setModal}) => {

    const dispatch = useDispatch()
    const[alerts,setAlerts] = useState(false)
  //   const {id} = useParams()
  //   console.log(id,"id")

  //   const[alerts,setAlerts] = useState(false)
  // const navigateLink  =("/DATAPLUS/trouble/Ticket/dashboard")


  //   const data =  useSelector((state)=> state?.TroubleTicketReducer?.getTTDashboardList)
  //     console.log(data,"jhfbtvy")

  //     let item  = data.filter((itm)=>{

  //       if(itm.ttUniqueId === id){
  //         return itm
  //       }
      
  //     })


    const
    { register,
      handleSubmit,
      setError,
      watch,
      getValues,
      setValue,
      } = useForm();
   

      const Form =[
        {
            label:"Add Comment",
            type:"textArea",
            name:"ttNoteComments",
            required:true,
            disabled:false,

        }
      ]

    const onSubmit = (data) => {
        // Handle form submission here, you can access form data in the 'data' object
      
            console.log(data,item.ttUniqueId,"gcjvhbkl")
        
            dispatch(
                troubleTicketActions.postTTDashboardList(
                    item.ttUniqueId,data,
                  () => {
                    dispatch(troubleTicketActions.getTTDashboardList())
                    // dispatch(ALERTS({ show: false }));
                  }
                )
              );
              // setModal(false)
              setAlerts(true)
      };
      useEffect(()=>{
        Form.forEach((key) => {
          console.log(`sdxghbj`,key.name, item[key.name])
          if (["endAt", "startAt"].indexOf(key.name) != -1) {
            console.log("date formValuekey", key.name, item[key.name])
            const momentObj = moment(item[key.name]);
            setValue(key.name, momentObj.toDate());
            // if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
            //   setValue(key.name, item[key.name]["$oid"]);
      
            // }
            // else

        }
          else {
            setValue(key.name, item[key.name]);
          }
    
        })
      },[item?.ttUniqueId]
    )
      
  return (
    <>
     {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} setModal={setModal}/>}
        <form onSubmit={handleSubmit(onSubmit)}>
     <MPAG_CommonForm Form={Form} register={register}/>
     <button type='submit' className='btn btn-primary'>
       Submit
     </button>
   </form>
    </>
  )
}

export default TTDashboardAddComments