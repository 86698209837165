import React, { useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import IXSupportActions from '../../../../../store/actions/IXSupport-actions';
import './Calender.css'
import { Row, Card, Col, Button, Nav } from 'react-bootstrap'
import PageTitle from '../../../../layouts/PageTitle'

const localizer = momentLocalizer(moment);

const IXCalender = () => {

  const dispatch  =  useDispatch();

  // const tableHeading  = "IX Calender"

  useEffect(()=>{
    dispatch(IXSupportActions.getIXCalendarDataList())
  },[])

  let eventData = useSelector(
    (state) => state.IXSupportReducer.getIXCalendarDataList
  );
  console.log(eventData,"dgh")
  eventData = eventData?.map((item) => {
    return {
      ...item,
      title: item?.actualSiteID,
      start: item?.scheduleDate,
      end: item?.scheduleDate,
      ranSupportId:item?.ranSupportId
    };
  });

  // const customTitleAccessor = (event) => {
  //   return (
  //     <div style={{ color: 'white' }}>
  //       {eventData.title}
  //     </div>
  //   );
  // };
  
  const dayPropGetter = (date) => {
    const currentDate = new Date();
    if ( date.getMonth() !== currentDate.getMonth()) {
      // For dates outside the current month
      return {
        className: 'rbc-off-range',
        style: { backgroundColor: '#E0FFFF', color: 'black !important'  },
      };
    }
    else if(date.getDate() === currentDate.getDate())
    {
      return {
        className: 'rbc-off-range',
        style: { backgroundColor: '#F0FFFF', color: '#00BFFF' },
      };
    }
    return {};
  };

  let uniqueId ='';
   // Function to handle slot selection
   const handleSlotSelect = (event) => {
    const { title,action } = event;
    console.log(title,"event")

        eventData.map((itm) => { if(itm.title === title)
        return uniqueId =  itm.ranSupportId})
        console.log(uniqueId,"uniqye")
    if (action === 'click') {
      // Handle click action
      // Example: Hit an API using fetch or axios
      dispatch(IXSupportActions.getIXCalenderIDDataList(true))
      // fetch('your-api-url', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ start, end }),
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
      //     // Process API response data
      //     console.log(data);
      //   })
      //   .catch((error) => {
      //     // Handle errors
      //     console.error('Error:', error);
      //   });
    }
  };

  return (
    <>
    {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
    <div style={{ height: 500 ,display:"flex"}}>
      <Calendar
        localizer={localizer}
        events={eventData}
        // title='Dharmender',
        // titleAccessor={customTitleAccessor} // Apply the custom title accessor
        startAccessor="start"
        endAccessor="end"
        style={{  height: "100%", width: "100%", padding: "2rem",color: '#00BFFF' }}
        dayPropGetter={dayPropGetter} 
        className="custom-calendar"
        onSelectSlot={handleSlotSelect} // Handle slot selection
      />
    

    {/* <div><Card className='text-white bg-primary col-sm-6 'xl='6' 
    style={{height:"250px",maxHeight:"full", width:"350px", 
    marginTop:"30px", backgroundColor:'red !important'}}>
            <Card.Header>
              <Card.Title className='text-white'>Selected Schedule:DTL01257</Card.Title>
            </Card.Header>
            <Card.Body className=' mb-0'>
              <Card.Text>
               
              </Card.Text>
              <Button 
                as='a'
                variant='primary light'
                href='#'
                className='btn-card mt-3'
              >
                Go somewhere
              </Button>
            </Card.Body>
            
          </Card></div> */}
          </div>
    </>
  );
}

export default IXCalender