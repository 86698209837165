export const starOne = (
   <ul className="star-rating">
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fas fa-star-half-alt" />
      </li>{" "}
      <li>
         <i className="fas fa-star-half-alt" />
      </li>{" "}
      <li>
         <i className="fas fa-star-half-alt" />
         
      </li>{" "}
      <li>
         <i className="fas fa-star-half-alt" />
      </li>{" "}
   </ul>
);

export const starTwo = (
   <ul className="star-rating">
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fas fa-star-half-alt" />
      </li>{" "}
      <li>
         <i className="fas fa-star-half-alt" />
      </li>{" "}
      <li>
         <i className="fas fa-star-half-alt" />
      </li>{" "}
   </ul>
);

export const starThree = (
   <ul className="star-rating">
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fas fa-star-half-alt" />
      </li>{" "}
      <li>
         <i className="fas fa-star-half-alt" />
      </li>{" "}
   </ul>
);

export const starFour = (
   <ul className="star-rating">
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fas fa-star-half-alt" />
      </li>{" "}
   </ul>
);

export const starFive = (
   <ul className="star-rating">
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fa fa-star" />
      </li>{" "}
      <li>
         <i className="fa fa-star" />
      </li>{" "}
   </ul>
);
