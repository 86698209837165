import React, { useEffect } from 'react'
import MPAG_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CXSupportActions from '../../../../../store/actions/CXSupport-actions';

const RunPreCheckForm = ({item,Heading={},setModal}) => {

  const navigate= useNavigate()
  const dispatch = useDispatch();
 
  useEffect(()=>{
    dispatch(CXSupportActions.getCXSupportList())
  },[])

    const data  = useSelector((state)=> state?.CXSupportReducer?.getCXSupportList)

    console.log(data,"cx data")

    const { register, handleSubmit ,setValue,formState: { errors }} = useForm();

    const Form =[
        {
          label: "GC Company",
          value: "",
          name: "gcCompany",
          type: "text",
          // placeholder:"Enter Account",
          required: true,
          disbabled:false
        }, 
        {
          label:`GC Name`,
          value:"",
          name:`gcName`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`GC Email`,
          value:"",
          name:`gcEmail`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`GC Contact`,
          value:"",
          name:`gcContract`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`Activity Start Time`,
          value:"",
          name:`activityStartTime`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`Activity End Time`,
          value:"",
          name:`activityEndTime`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`Activity Hours`,
          value:"",
          name:`activityHours`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`SA/NSA`,
          value:"",
          name:`saNsa`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`NFSD Login`,
          value:"",
          name:`${Heading.key}TrafficThroughput`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`NFSD Snapshot`,
          value:"",
          name:`${Heading.key}TrafficThroughput`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`EIM Login`,
          value:"",
          name:`${Heading.key}TrafficThroughput`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`Request Source`,
          value:"",
          name:`${Heading.key}TrafficThroughput`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        {
          label:`Go/No Go Approval`,
          value:"",
          name:`${Heading.key}TrafficThroughput`,
          type:"text",
          required:true,
          disabled:false,
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        },
        // {
        //   label:`${Heading.heading} Mobility`,
        //   value:"",
        //   name:`${Heading.key}Mobility`,
        //   type:"select",
        //   option:[{label:"OK",value:"OK"},
        //     {label:"Critical",value:"Critical"},
        //     {label:"Major Degradation",value:"Major Degradation"},
        //     {label:"Minor Degradation",value:"Minor Degradation"}
        // ],
          
        // },
        // {
        //   label:`${Heading.heading} Active Issue(Notes)`,
        //   value:"",
        //   name:`${Heading.key}ActiveIssueNotes`,
        //   type:"text",
          
          
        // },
        
        
          
      ]


      const onSubmit = (data) => {
        // Handle form submission here, you can access form data in the 'data' object
        
        console.log(data,item.ranSupportId,"submitData");
  
        // // const uniqueId = data.siteid
        // //     console.log(uniqueId,"kjjasbdyn")
      
        //   dispatch(KPIModuleActions.postKPIReport(data,()=>{
        //       dispatch(KPIModuleActions.getKPIDashboardList())
        //   },item.ranSupportId,""))
        // setModal(false)
  
        navigate("/DATAPLUS/cx/support/dashboard")
  
      };

    //   useEffect(()=>{
    //     Form.forEach((key) => {
    //       console.log(`sdxghbj`,key.name, item[key.name])
    //       if (["endAt", "startAt"].indexOf(key.name) != -1) {
    //         console.log("date formValuekey", key.name, item[key.name])
    //         const momentObj = moment(item[key.name]);
    //         setValue(key.name, momentObj.toDate());
    //         // if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
    //         //   setValue(key.name, item[key.name]["$oid"]);
      
    //         // }
    //         // else

    //     }
    //       else {
    //         setValue(key.name, item[key.name]);
    //       }
    
    //     })
    //   },[item?.ranSupportId])

  return (
    
    <>
    
    <form onSubmit={handleSubmit(onSubmit)}>
      <MPAG_CommonForm Form={Form} register={register} errors={errors}/>
      <button type='submit' className='btn btn-primary'>
        Submit
      </button>
    </form>

    </>

  )

}

export default RunPreCheckForm