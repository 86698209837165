import React from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable'
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable'
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter'

const E911Dashboard = () => {
  
  const tableHeading =  "E911 Dashboard"
 
  const tableColumns = [
    {
      Header : 'E911 ID',
      // Footer : 'S No',
      accessor: '',
      Filter: "",
    },
    {
      Header : 'SITE ID',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'PACE ID',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'SCOPE',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'BAND REQUESTED',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'ACCOUNT ',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'MARKET',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'ALPHA SECTOR',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'BETA SECTOR',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'GAMMA SECTOR',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'DELTA SECTOR',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'EPSILON SECTOR ',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'ZETA SECTOR',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },

    {
      Header : 'INTEGRATION DATE',
      // Footer : 'S No',
      accessor: '',
      Filter: "",
    },
    {
      Header : 'E911 CALL TEST DATE',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'REMOTE ENGINEER',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'CALL TEST ENGINEER',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'TT OPENED?',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'TT ID(s) ',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'TEST PHONE NO.',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'REMARKS',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'ACTIONS',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
  
  ]


  return(
    <>
    {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
        <div>
      <FilteringTable tableColumns={tableColumns} tableData={["a","b"]} tableHeading ={tableHeading}/>
    </div>
    </>
  )

}

export default E911Dashboard