import Api from "../../utils/api";
import { Urls } from "../../utils/url";
import { ALERTS } from "../reducers/component-reducer";
import { SET_IX_DASHBOARD, SET_IX_SECHEDULER_ENGINEER, SET_IX_SECHEDULER_SITE_ID,SET_IX_SECHEDULER_TECHNOLOGY,
  SET_IX_SECHEDULER_LIST_USER, 
  SET_IX_CALENDAR_DATA,SET_IX_CALENDAR_ID_DATA,SET_IX_DASHBOARD_ID_DATA} from "../reducers/IXSupport-reducer";

const IXSupportActions = {
  //  manager account starts here
  // KPI DASHBOARD STARTS HERE

  // IX DASHBOARD STARTS HERE

  getIXDashboardList:
    (reset = true, args = "") =>
      async (dispatch, _) => {
        try {
          
          const res = await Api.get({
            url: `${Urls.Manage_IX_Dashboard_Data}${args != "" ? "?" + args : ""}`,
          });
          if (res?.status !== 200) return;
          let dataAll = res?.data?.data;
          console.log(dataAll, "sjdbk");
          dispatch(SET_IX_DASHBOARD({ dataAll, reset }));
        } catch (error) { }
      },

      getIXDashboardIDList:
         (uniqueId=null,reset = true, ) =>
         async (dispatch, _) => {
           try {
             const res = await Api.get({
               url: `${Urls.Manage_IX_Dashboard_Data}/${uniqueId}`,
             });
             if (res?.status !== 200) return;
             let dataAll = res?.data?.data;
             console.log(dataAll, "sjdbk");
             dispatch(SET_IX_DASHBOARD_ID_DATA({ dataAll, reset }));
           } catch (error) {}
         },


      postIXDashboardEngineer: ( data, cb, uniqueId=null,args="") => async (dispatch, _) => {
        try {
          const res = await Api.post({
            data: data,
            url:
              uniqueId === null
                ?
                Urls.Post_KPI_Engineer_Data
                : `${Urls.Post_KPI_Engineer_Data + "/" + uniqueId }${args != "" ? "?" + args : ""}`,
          });
          if (res?.status !== 201 && res?.status !== 200) {
            let msgdata = {
              show: false,
              icon: "",
              buttons: [],
              type: 1,
              text: res?.data?.msg,
            };
            console.log(msgdata,"1111")
            dispatch(ALERTS(msgdata));
          } else {
            console.log(res,"resdata")
            let msgdata = {
              show: true,
              icon: "",
              buttons: [],
              type: 1,
              text: res?.data?.msg,
            };
            console.log(msgdata,"22222")
            dispatch(ALERTS(msgdata));
            cb();
           
          }
          
        } catch (error) {
          
          return;
        }
      },

  //      
  getIXSchedulerSiteIdList:
    (reset = true, args = "") =>
      async (dispatch, _) => {
        try {
          const res = await Api.get({
            url: `${Urls.Manage_CIQ_Sites_KPI_Data}${args != "" ? "?" + args : ""}`,
          });
          if (res?.status !== 200) return;
          let dataAll = res?.data?.data;
          dispatch(SET_IX_SECHEDULER_SITE_ID({ dataAll, reset }));
        } catch (error) {

        }
      },
  getIXSchedulerEngineerList:
    (reset = true, args = "") =>
      async (dispatch, _) => {
        try {
          const res = await Api.get({
            url: `${Urls.Manage_Engineer_Data}${args != "" ? "?" + args : ""}`,
          });
          if (res?.status !== 200) return;
          let dataAll = res?.data?.data;
          console.log(dataAll, "sjdbk");
          dispatch(SET_IX_SECHEDULER_ENGINEER({ dataAll, reset }));
        } catch (error) { }
      },
      getIXSchedulerTechnologyList:
    (reset = true, args = "") =>
      async (dispatch, _) => {
        try {
          const res = await Api.get({
            url: `${Urls.Create_Technology_Data}${args != "" ? "?" + args : ""}`,
          });
          if (res?.status !== 200) return;
          let dataAll = res?.data?.data;
          console.log(dataAll, "sjdbk");
          dispatch(SET_IX_SECHEDULER_TECHNOLOGY({ dataAll, reset }));
        } catch (error) { }
      },

      getIXSchedulerListUserList:
      (reset = true, args = "") =>
        async (dispatch, _) => {
          try {
            const res = await Api.get({
              url: `${Urls.list_user_data}${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 200) return;
            let dataAll = res?.data?.data;
            console.log(dataAll, "sjdbk");
            dispatch(SET_IX_SECHEDULER_LIST_USER({ dataAll, reset }));
          } catch (error) { }
        },
        
        postIXScheduler: ( data, cb, uniqueId=null,args="") => async (dispatch, _) => {
          try {
            const res = await Api.post({
              data: data,
              url:
                uniqueId === null
                  ?
                  Urls.Manage_CIQ_Data
                  : `${Urls.Manage_CIQ_Data + "/" + uniqueId }${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 201 && res?.status !== 200) {
              let msgdata = {
                show: false,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"1111")
              dispatch(ALERTS(msgdata));
            } else {
              console.log(res,"resdata")
              let msgdata = {
                show: true,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"22222")
              dispatch(ALERTS(msgdata));
              cb();
             
            }
            
          } catch (error) {
            
            return;
          }
        },

       
        getIXCalendarDataList:
      (reset = true, args = "") =>
        async (dispatch, _) => {
          try {
            const res = await Api.get({
              url: `${Urls.Manage_IX_Dashboard_Data}${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 200) return;
            let dataAll = res?.data?.data;
            console.log(dataAll, "sjdbk");
            dispatch(SET_IX_CALENDAR_DATA({ dataAll, reset }));
          } catch (error) { }
        },

        getIXCalenderIDDataList:
        (uniqueId=null,reset = true, args = "") =>
          async (dispatch, _) => {
            try {
              const res = await Api.get({
                url: `${Urls.Manage_IX_Dashboard_Data+ "/" + uniqueId}${args != "" ? "?" + args : ""}`,
              });
              if (res?.status !== 200) return;
              let dataAll = res?.data?.data;
              console.log(dataAll, "sjdbk");
              dispatch(SET_IX_CALENDAR_ID_DATA({ dataAll, reset }));
            } catch (error) { }
          },

      

};
export default IXSupportActions;
