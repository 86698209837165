import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import './MultiSelectInput.css';

function MultiSelectInput({errors, required,register,options,validation, name, control, disabled, defaultValue }) {
  // Custom styles for react-select
  console.log(name,"__name")
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      display: 'flex',
      minHeight: '40px',
      width: '100%',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: 'black',
      backgroundColor: '#FFFFFF', 
      backgroundClip: 'padding-box',
      border: '1px solid black',
      borderRadius: '0.20rem',
      transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        boxShadow: 'none', 
      },
      '@media (min-width: 1500px)': {
        minHeight: '46px', 
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#FEF9FF',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'black',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'black',
      ':hover': {
        backgroundColor: '#3e454d',
        color: 'black',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FEF9FF',
      borderRadius: '0.75rem',
      marginTop: '0',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#FEF9FF'
        : state.isFocused
        ? '#D3D3D3'
        : '#FEF9FF',
      color: 'black',
      ':active': {
        backgroundColor: '#D3D3D3',
        color: 'black',
      },
    }),
  };

  return (
    <>
      <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      {...register(name, {
        required:required ? "This field is required" : false,
        pattern: validation ? validation : undefined,
    })}
      render={({ field }) => (
        <Select
          {...field}
          options={options}
          isMulti
          styles={customStyles}
          onChange={(value) => field.onChange(value)}
          isDisabled={disabled}
        
        />
      )}
    />
    {errors[name] && <p style={{color:'red'}}className="error-message">{errors[name]?.message}</p>}
    </>
  );
}

export default MultiSelectInput;
