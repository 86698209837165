import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import OSSForm from './OSSForm'
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable'
import FormModal from '../../../../components/DATAPLUS_Components/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Button,
} from "react-bootstrap";
import ActionsModal from '../../../../components/DATAPLUS_Components/ActionsModal';
import CommonActions from '../../../../../store/actions/common-actions';
import { Urls } from '../../../../../utils/url';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';

const OSS = () => {

  const navigate = useNavigate()
  
 const tableHeading="OSS's"

 const[form,setForm]  = useState(false)
 const[alerts,setAlerts] = useState(false)
 const [actions, setActions] = useState(false)
 const [callDelete, setCallDelete] = useState(false)
  const [itmValue, setItmValue] = useState({})
  const[deleteIndex,setDeleteIndex] = useState(null)

 let FormComponent  =  (OSSForm)

  
  const modalButton ="+ Add ENM";
  const AddFormButtonName= '+ ENM'
  const AddFormNavigate ="/DATAPLUS/Add/enm"


const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminDashboardActions.getOSSList())
  }, [])

  const Data = useSelector((state) => state?.AdminDashboard_reducer?.getOSSList)
  let itemUniqueId =[]
  const tableData  = Data.map((itm,index)=>{
    itemUniqueId[index] = itm.uniqueid
    return{
      sNo:index+1,
      ...itm,
      "actions": (<div style={{display:'flex', gap:"5px",cursor:'pointer'}}>
        <div  onClick={() => {
            navigate(`/DATAPLUS/Edit/enm/${itm.uniqueid}`)

          }}><img src='/editIcon2.png' style={{width:"25px",width:"25px"}}/></div>


          <div  onClick={() => {
            setActions(true)
            setDeleteIndex(index)

          }}><img src='/delete2.png' style={{width:"25px",width:"25px",cursor:'pointer'}}/></div>
      </div>)
    }
  })

  const tableColumns = [
    {
      Header: 'S No.',
      accessor: 'sNo',
      Filter: "",
    },
    {
      Header: 'ENM',
      accessor: "enm",
      Filter: "",
    },
    {
      Header: 'IP',
      accessor: "ip",
      Filter: "",
    },
    {
      Header: 'PORT',
      accessor: "port",
      Filter: "",
    },
    {
      Header: 'Actions',
      accessor: "actions",
      Filter: "",
    },
  ]

  if (callDelete) {
    dispatch(
      CommonActions.deleteApiCaller(
        `${Urls.Post_OSS_Data}/${itemUniqueId[deleteIndex]}`,
        () => {
          dispatch(
            adminDashboardActions.getOSSList()
          );
        }
      )
    );
    setCallDelete(false)

  }

  const GROUPED_COLUMNS = [
    {
      Header: 'Id',
      Footer: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Name',
      Footer: 'Name',
      columns: [
        {
          Header: 'First Name',
          Footer: 'First Name',
          accessor: 'first_name'
        },
        {
          Header: 'Last Name',
          Footer: 'Last Name',
          accessor: 'last_name'
        },
      ]
    },
    {
      Header: 'Info',
      Footer: 'Info',
      columns: [
        {
          Header: 'Date of  Birth',
          Footer: 'Date of  Birth',
          accessor: 'date_of_birth'
        },
        {
          Header: 'Country',
          Footer: 'Country',
          accessor: 'country',
        },
        {
          Header: 'Phone',
          Footer: 'Phone',
          accessor: 'phone'
        },
      ]
    },
  ]
  return (

    <>
    
    {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} />}
    {form && <FormModal modalHeading={modalButton} isOpen={form} setModal={setForm} FormComponent={FormComponent} itmValue={itmValue}/>}
    {actions && <ActionsModal setAlerts={setAlerts} actions={actions} setCallDelete={setCallDelete} setActions={setActions} />}
    <div>
        <FilteringTable
         AddFormButtonName={AddFormButtonName} 
        AddFormNavigate={AddFormNavigate}
        tableColumns={tableColumns} 
        tableData={tableData ? tableData : ['a','b']} 
        tableHeading={tableHeading} />
      </div>
    </>
  )
}

export default OSS