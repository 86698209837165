import {createSlice} from "@reduxjs/toolkit"

const initialState={
    getKPIDashboardList:[],
    getManageCIQSitesKPIlist:[],
    getManageCIQSitesKPIIdlist:[],
    getKPITroubleTicketList:[],
    getKPICIQSitesList:[],
    getKPIEngineerList:[],
    getKPIDashboardIDList:[],
}

const KPIModuleData = createSlice({
    name:"KPIModuleData",
    initialState,
    reducers:{

        SET_KPI_DASHBOARD: (state, { payload }) => {
            if (payload.reset) {
            state.getKPIDashboardList = payload.dataAll;
            } else {
            state.getKPIDashboardList = [
                ...state.getKPIDashboardList,
                ...payload.dataAll,
            ];
            }
        },


        // ciq kpi data
        SET_CIQ_SITES_KPI: (state, { payload }) => {
            if (payload.reset) {
            state.getManageCIQSitesKPIlist = payload.dataAll;
            } else {
            state.getManageCIQSitesKPIlist = [
                ...state.getManageCIQSitesKPIlist,
                ...payload.dataAll,
            ];
            }
        },

        // ciq kpi id
        // Manage_CIQ_Sites_KPI id data

        SET_CIQ_SITES_KPI_ID: (state, { payload }) => {
            if (payload.reset) {
            state.getManageCIQSitesKPIIdlist = payload.dataAll;
            } else {
            state.getManageCIQSitesKPIIdlist = [
                ...state.getManageCIQSitesKPIIdlist,
                ...payload.dataAll,
            ];
            }
        },


        // kpi trouble ticket  data

        SET_KPI_TROUBLE_TICKET: (state, { payload }) => {
            if (payload.reset) {
            state.getKPITroubleTicketList = payload.dataAll;
            } else {
            state.getKPITroubleTicketList = [
                ...state.getKPITroubleTicketList,
                ...payload.dataAll,
            ];
            }
        },

        // kpi ciq sites  data

        SET_KPI_CIQ_SITES: (state, { payload }) => {
            if (payload.reset) {
            state.getKPICIQSitesList = payload.dataAll;
            } else {
            state.getKPICIQSitesList = [
                ...state.getKPICIQSitesList,
                ...payload.dataAll,
            ];
            }
        },

        // kpi engineer
        SET_KPI_ENGINEER: (state, { payload }) => {
            if (payload.reset) {
            state.getKPIEngineerList = payload.dataAll;
            } else {
            state.getKPIEngineerList = [
                ...state.getKPIEngineerList,
                ...payload.dataAll,
            ];
            }
        },
        SET_KPI_ID: (state, { payload }) => {
            if (payload.reset) {
            state.getKPIDashboardIDList = payload.dataAll;
            } else {
            state.getKPIDashboardIDList = [
                ...state.getKPIDashboardIDList,
                ...payload.dataAll,
            ];
            }
        },
    }
})

export const {SET_KPI_DASHBOARD,SET_CIQ_SITES_KPI,SET_CIQ_SITES_KPI_ID,SET_KPI_TROUBLE_TICKET,
    SET_KPI_CIQ_SITES,SET_KPI_ENGINEER,SET_KPI_ID} = KPIModuleData.actions;
export default KPIModuleData.reducer;