import React,{useEffect, useState} from 'react'
import FilteringTable from '../../../components/table/FilteringTable/FilteringTable';
import { ColumnFilter } from '../../../components/table/FilteringTable/ColumnFilter';
import FormModal from '../../../components/DATAPLUS_Components/FormModal';
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Modal,
  ProgressBar,
  Button,
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import GONOGOSupportForm from './GONOGOSupportForm';
import { useDispatch, useSelector } from 'react-redux';
import GoNoGoSupportActions from '../../../../store/actions/GoNoGoSupport-actions';

const GONOGOSupport = () => {

    const navigate  = useNavigate();
    const dispatch = useDispatch()


    // useEffect(()=>{
    //   dispatch(GoNoGoSupportActions.getGoNoGoSupportList())
    // },[])
    useEffect(()=>{
      dispatch(GoNoGoSupportActions.getGoNoGoSupportList())
    },[])

    const data  = useSelector((state)=> state?.GoNoGoSupportReducer?.getGoNoGoSupportList)
    console.log(data,"newData")

    let tableData = data.map((itm)=> {return {...itm}})

    const tableHeading = ''
    const[form,setForm]  = useState(false)
    const[actions,setActions]=useState(false)
    const[callDelete,setCallDelete] = useState(false)
    const [itmValue, setItmValue] = useState({})


    const FormComponent =(GONOGOSupportForm)
    

    const filterHeading  = "Tickets Filter"
    const modalButton = "Create Ticket"
    const showButton  =  "Tickets"
    const AddFormButtonName= 'Create Ticket'
    const AddFormNavigate ='/DATAPLUS/add/GO-NOGO'
    const AddFormButton = ''
  //   (<Button style={{marginLeft:"10px"}}
  //   variant="primary"
  //   className="close"
  //   onClick={() => setForm(true)}
  // >
  //   Create Ticket
  // </Button>)
    const tableColumns = [
        {
          Header: 'Ticket ID',
          // Footer: 'S No',
          accessor: 'ticketId',
          Filter: "",
        },
        {
          Header: 'PACE ID',
          // Footer: 'account',
          accessor: "paceId",
          Filter: ColumnFilter,
        },
        {
          Header: 'Site ID',
          // Footer: 'S No',
          accessor: 'siteId',
          Filter: ColumnFilter,
        },
        {
          Header: 'GC Company',
          // Footer: 'account',
          accessor: "gcCompany",
          Filter: ColumnFilter,
        },
        {
          Header: 'SOW',
          // Footer: 'S No',
          accessor: 'sow',
          Filter: ColumnFilter,
        },
        {
          Header: 'Sector',
          // Footer: 'account',
          accessor: "sector",
          Filter: ColumnFilter,
        },
        {
          Header: 'Technology Affected',
          // Footer: 'S No',
          accessor: 'technologyAffected',
          Filter: "",
        },
        {
          Header: 'Activity Start Time',
          // Footer: 'account',
          accessor: "activityStartTime",
          Filter: "",
        },
        {
          Header: 'Activity End Time',
          // Footer: 'S No',
          accessor: 'activityEndTime',
          Filter: "",
        },
        {
          Header: 'SA/NSA',
          // Footer: 'account',
          accessor: "saNsa",
          Filter: "",
        },
        // {
        //   Header: 'SOW',
        //   // Footer: 'S No',
        //   accessor: 'crewType',
        //   Filter: "",
        // },
        // {
        //   Header: 'Pag Status',
        //   // Footer: 'account',
        //   accessor: "",
        //   Filter: "",
        // },
    
    
      ]
    
    
    
      const GROUPED_COLUMNS = [
        {
          Header: 'Id',
          Footer: 'Id',
          accessor: 'id'
        },
        {
          Header: 'Name',
          Footer: 'Name',
          columns: [
            {
              Header: 'First Name',
              Footer: 'First Name',
              accessor: 'first_name'
            },
            {
              Header: 'Last Name',
              Footer: 'Last Name',
              accessor: 'last_name'
            },
          ]
        },
        {
          Header: 'Info',
          Footer: 'Info',
          columns: [
            {
              Header: 'Date of  Birth',
              Footer: 'Date of  Birth',
              accessor: 'date_of_birth'
            },
            {
              Header: 'Country',
              Footer: 'Country',
              accessor: 'country',
            },
            {
              Header: 'Phone',
              Footer: 'Phone',
              accessor: 'phone'
            },
          ]
        },
      ]
  return (
   <>
    


   
     
      {/* {modal && <EditModal modalHeading={modalHeading} setModal={setModal} isOpen={modal} FormComponent={FormComponent} itmValue={itmValue} />} */}

      
       {form && <FormModal modalHeading={modalButton} isOpen={form} setModal={setForm} FormComponent={FormComponent} itmValue={itmValue}/>}

      {/* <MPAG_Modal modalButton={modalButton} FormComponent={FormComponent} /> */}
      {/* <MPAG_Modal tableColumns={tableColumns} tableData={tableData}  /> */}
  {/* <FilterModal FormComponent={Filter} tableColumns={tableColumns} tableData={tableData} modalButton={filterButton}/> */}
  <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4>
      <div>
        <FilteringTable  AddFormNavigate={AddFormNavigate}
         AddFormButtonName={AddFormButtonName}  showButton={showButton} AddFormButton={AddFormButton}  tableColumns={tableColumns} tableData={tableData} setForm={setForm} tableHeading={tableHeading} />
      </div>
   </>
  )

}

export default GONOGOSupport