import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import AlertsModal from "../../../../components/DATAPLUS_Components/AlertsModal"
import { useNavigate, useParams } from 'react-router-dom';
import "./siteIdPage.css"

const SiteIdForm = () => {

    const [alerts, setAlerts] = useState(false)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const
        { register,
            handleSubmit,
            setValue,
            watch
        } = useForm();






    const { id } = useParams()
    let navigateLink = "/DATAPLUS/site/config";
    useEffect(() => {
        dispatch(adminDashboardActions.getOSSList())
    }, [])
    const Data = useSelector((state) => state?.AdminDashboard_reducer?.getSiteIdList)
    let item = Data.filter((itm) => {

        if (itm.uniqueid === id) {
            return itm
        }

    })

    item = item[0];

    const data = useSelector((state) => state?.AdminDashboard_reducer?.getOSSList)
    const ENM1Options = data.map((itm) => {
        return {
            label: itm.enm,
            value: itm.uniqueid
        }
    })


    const enmData = watch('enmUniqueId1');
    useEffect(() => {
        if (enmData !== 'Select') {
            for (let i = 1; i < 10; i++) {
                setValue(`enmUniqueId${i}`, enmData)

            }
        }
    }, [enmData])

    let nodeForm = [];
    let enmForm = [];
    let statusNodeForm = []
    for (let i = 1; i < 10; i++) {
        nodeForm.push({
            label: `Node ${i}`,
            value: "",
            name: `node${i}`,
            type: "text",
            placeholder: "Enter Node",
            required: false,
            disabled: false,
            className: { width: '180px', margin: '10px', border: '1px solid #ccc', borderColor: '#470468', boxShadow: '0 0 10px #470468', borderRadius: '5px' },
            labelClassName: { marginTop: '15px', fontWeight: "bold", marginLeft: '5px', display: 'flex', height: '40px', alignItems: 'right', color: 'black' },
            coverDivClassName: {
                display: 'flex', gap: "1rem", '@media (max-width: 767px)': {
                    flexDirection: 'column', display: 'grid'
                },
            }
        })
        enmForm.push(
            {
                label: `ENM ${i}`,
                value: "",
                name: `enmUniqueId${i}`,
                type: "select",
                option: ENM1Options,
                // placeholder: "Enter Your Name",
                required: false,
                disabled: false,
                className: { width: '180px', margin: '10px', border: '1px solid #ccc', borderColor: '#470468', boxShadow: '0 0 10px #470468', borderRadius: '5px' },
                labelClassName: { marginTop: '15px', height: '40px', alignItems: 'right', color: 'black' },
                coverDivClassName: { display: 'flex', gap: "1rem" }
            },
        )
        statusNodeForm.push(
            {
                label: `Status Node ${i}`,
                value: "",
                name: `statusNode${i}`,
                type: "select",
                // placeholder: "Enter Your Name",
                option: [{ label: 'Activate', value: "Activate" },
                { label: "Deactivate", value: "Deactivate" }
                ],
                required: false,
                disable: false,
                className: { width: '180px', margin: '10px', border: '1px solid #ccc', borderColor: '#470468', boxShadow: '0 0 10px #470468', borderRadius: '5px' },
                labelClassName: { marginTop: '15px', fontWeight: "bold", marginLeft: '5px', height: '40px', alignItems: 'right', color: 'black' },
                coverDivClassName: {
                    display: 'flex', gap: "1rem", '@media (max-width: 767px)': {
                        flexDirection: 'column',

                    },
                }
            },
        )
    }



    const Form = [
        {
            label: "Site ID",
            value: "",
            name: "siteId",
            type: "text",
            // placeholder: "Enter Your Name",
            required: true
        },
        {
            label: "Node 1",
            value: "",
            name: "node1",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },

        {
            label: "ENM 1",
            value: "",
            name: "enm1",
            type: "select",
            option: ENM1Options,
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Status Node 1",
            value: "",
            name: "statusNode1",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },

        {
            label: "Node 2",
            value: "",
            name: "node2",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "ENM 2",
            value: "",
            name: "enm2",
            type: "select",
            option: ENM1Options,
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Status Node 2",
            value: "",
            name: "statusNode2",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },

        {
            label: "Node 3",
            value: "",
            name: "node3",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "ENM 3",
            value: "",
            name: "enm3",
            type: "select",
            option: ENM1Options,
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Status Node 3",
            value: "",
            name: "statusNode3",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },

        {
            label: "Node 4",
            value: "",
            name: "node4",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "ENM 4",
            value: "",
            name: "enm4",
            type: "select",
            option: ENM1Options,
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Status Node 4",
            value: "",
            name: "statusNode4",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Node 5",
            value: "",
            name: "node5",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "ENM 5",
            value: "",
            name: "enm5",
            type: "select",
            option: ENM1Options,
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Status Node 5",
            value: "",
            name: "statusNode5",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Node 6",
            value: "",
            name: "node6",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "ENM 6",
            value: "",
            name: "enm6",
            type: "select",
            option: ENM1Options,
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Status Node 6",
            value: "",
            name: "statusNode6",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Node 7",
            value: "",
            name: "node7",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "ENM 7",
            value: "",
            name: "enm7",
            type: "select",
            option: ENM1Options,
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Status Node 7",
            value: "",
            name: "statusNode7",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Node 8",
            value: "",
            name: "node8",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "ENM 8",
            value: "",
            name: "enm8",
            type: "select",
            option: ENM1Options,
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Status Node 8",
            value: "",
            name: "statusNode8",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Node 9",
            value: "",
            name: "node9",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "ENM 9",
            value: "",
            name: "enm9",
            type: "select",
            option: ENM1Options,
            // placeholder: "Enter Your Name",
            required: false
        },
        {
            label: "Status Node 9",
            value: "",
            name: "statusNode9",
            type: "text",
            // placeholder: "Enter Your Name",
            required: false
        },
        // {
        //     label: "Node 10",
        //     value: "",
        //     name: "node10",
        //     type: "text",
        //     // placeholder: "Enter Your Name",
        //     required: false
        // },
        // {
        //     label: "ENM 10",
        //     value: "",
        //     name: "enm10",
        //     type: "select",
        //     option: ENM1Options,
        //     // placeholder: "Enter Your Name",
        //     required: true
        // },

    ]
    
    const onSubmit = (data) => {
        for (let i = 1; i <= 10; i++) {
            if (data[`enm${i}`] === '') {
                data[`node${i}`] = '';
            }
            else if (data[`node${i}`] === '') {
                data[`enm${i}`] = '';
            }
        }


        for (let i = 1; i < 10; i++) {
            if (data[`statusNode${i}`] === 'Activate') {
                data[`statusNode${i}`] = true
            } else {
                data[`statusNode${i}`] = false
            }
        }

       
        if (item) {

            dispatch(adminDashboardActions.postSiteIdList(item.uniqueid, data,

                () => {
                    adminDashboardActions.getSiteIdList()
                }
            ))
          }
          else {
            console.log("called", "dfcgvh")
            dispatch(adminDashboardActions.postSiteIdList("", data,

                () => {
                    adminDashboardActions.getSiteIdList()
                }
            ))
          }
          setTimeout(()=>{
            setAlerts(true)
          },1000)
    };

    useEffect(() => {
        if (item?.uniqueid) {
            Form.forEach((key, index) => {
                if (typeof item[key.name] === 'object' && item[key.name] !== null && "$oid" in item[key.name]) {
                    setValue(key.name, item[key.name]["$oid"]);
                } else {

                    if (item[key.name] === true) {
                        setValue(key.name, 'Activate');
                    }
                    else if (item[key.name] === false) {
                        setValue(key.name, 'Deactivate');
                    }
                    else
                        setValue(key.name, item[key.name]);
                }
            });
        }
    }, [item?.uniqueid]);


    
    return (
        <>
            {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink} />}


            <form onSubmit={handleSubmit(onSubmit)}>


                {/* Site Id */}

                {/* siteId input */}
                <div className=' form-inputs-inside' style={{ width: '97%', display: 'flex', justifyContent: 'center' }}>

                    <div><label style={{ marginTop: '15px', height: '40px', alignItems: 'right', color: 'black' }}>Site ID</label></div>

                    <div>
                        <input
                            style={{ width: '180px', margin: '10px', border: '1px solid #ccc', borderColor: '#470468', boxShadow: '0 0 10px #470468', borderRadius: '5px' }}
                            type='text'
                            className='form-control'

                            placeholder="Enter Site Id"
                            {...register('siteId', {
                                required: true ? "This Field is required" : false,

                            })}
                            disabled={false}
                        />
                    </div>


                </div>


                {/* all iputs div */}

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{
                        display: 'flex',
                        gap: '50px',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        '@media (min-width: 1200px)': {
                            flexDirection: 'row',
                        },

                        '@media (min-width: 992px) and (max-width: 1199px)': {
                            flexDirection: 'row',

                        },

                        '@media (min-width: 768px) and (max-width: 991px)': {
                            flexDirection: 'row',

                        },
                        '@media (max-width: 767px)': {
                            flexDirection: 'column',
                            justifyContent: 'center',
                        },
                    }}>
                        <div style={{}}>
                            {nodeForm.map((itm) =>
                                <div style={itm?.coverDivClassName} className=''>
                                    <div style={itm?.labelClassName}><label>{itm?.label}</label></div>
                                    <div>
                                        <input
                                            style={itm?.className}
                                            type={itm?.type}
                                            className='form-control'
                                            {...register(itm?.name, {
                                                required: false ? "This Field is required" : false,
                                            })}
                                            disabled={itm?.disabled}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div>
                            < div style={{ margin: "5px" }} className='form-item'>


                            </div>
                            {enmForm.map((itm) =>
                                <div style={itm?.coverDivClassName}>
                                    <div>
                                        <label style={itm?.labelClassName}>
                                            {itm?.label}
                                        </label>
                                    </div>
                                    <div>
                                        <select
                                            style={itm?.className}
                                            id='statusOptionNode9'
                                            className='form-control'
                                            disabled={false}
                                            {...register(itm?.name)}
                                        >
                                            <option selected value={""}>Select</option>
                                            {itm?.option.map((bar) =>
                                                <option value={bar?.value}>{bar?.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div>
                            {statusNodeForm.map((itm) =>
                                <div style={itm?.coverDivClassName} className=''>
                                    <div style={itm?.labelClassName}><label>{itm?.label}</label></div>
                                    <div>
                                        <select
                                            style={itm?.className}
                                            id='statusOptionNode9'
                                            className='form-control'
                                            disabled={false}
                                            {...register(itm?.name)}
                                        >
                                            <option selected value={""}>Select</option>
                                            {itm?.option.map((bar) =>
                                                <option value={bar?.value}>{bar?.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>



                <div style={{ width: 'full', display: 'flex', gap: '10px', justifyContent: 'center', marginTop: "20px" }}>
                    <button style={{ margintop: "10" }} className='btn btn-info' onClick={() => navigate(-1)}>
                        Back
                    </button>

                    <button style={{ margintop: "10" }} type='submit' className='btn btn-primary'>
                        Submit
                    </button>

                </div>
            </form>
        </>
    )
}

export default SiteIdForm
