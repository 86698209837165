import React, { useEffect, useState } from 'react'
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import KPIModuleActions from '../../../../../store/actions/KPIModule-actions';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';

const KPIDashboardForm = ({setModal}) => {

        const  dispatch  = useDispatch();
        const[alerts,setAlerts] = useState(false)
        const navigateLink  =("/DATAPLUS/trouble/Ticket/dashboard")

        useEffect(()=>{
            dispatch(KPIModuleActions.getManageCIQSitesKPIlist())
        },[])


        const data  =  useSelector((state)=> state?.KPIModuleReducer?.getManageCIQSitesKPIlist)
        console.log(data,"jyubuynk")

        const siteIDOption  = data.map((itm)=>{return {label:itm._id, value:itm._id}})

    // kpi dashboard form data fetching



    const { register, handleSubmit } = useForm();
 
    const onSubmit = (data) => {
        // Handle form submission here, you can access form data in the 'data' object
        console.log(data,"UAYNVSD");

        const uniqueId = data.siteid
            console.log(uniqueId,"kjjasbdyn")
      
          dispatch(KPIModuleActions.postKPIDashboardList(uniqueId,()=>{
              dispatch(KPIModuleActions.getKPIDashboardList())
          }))

        // setModal(false)
        setAlerts(true)
  
      };
  
      const Form =[
          {
            label:"Select Site Id",
            value:"",
            name:"siteid",
            type:"select",
            option:siteIDOption,
            placeholder:"Please Select SiteID"
          },
          
          
            
        ]
  
    return (
      
      <>
        {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} setModal={setModal}/>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <DATAPLUS_CommonForm Form={Form} register={register}/>
        <button type='submit' className='btn btn-primary'>
          Submit
        </button>
      </form>
  
      </>
  
    )

}

export default KPIDashboardForm