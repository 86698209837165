import React, { useState } from 'react'
// import {MultiSelectInput} from 'react-hook-form'
import MultiSelectInput from '../MultiSelectInput';
import './DATAPLUS_CommonForm.css'


import moment from "moment/moment";
import DatePicking from "../DatePicking"
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const DATAPLUS_CommonForm = ({ Form = [], register, errors, control }) => {
    const [dateTime, setDateTime] = useState(null);

    const handleDateTimeChange = (date) => {
        setDateTime(date);
    };

    return (
        <>
            <div className='form-row'>
                {Form.map((itm, index) => (


                    <div key={index} className='form-item'>
                        {/* {console.log(itm?.name)} */}
                        <label style={{ marginTop: '10px', marginLeft: '5px', display: 'flex', gap: "5px" }}>
                            {itm?.label}
                            {itm.required && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="red" className="bi bi-asterisk" viewBox="0 0 16 16">
                                    <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                                </svg>
                            )}
                        </label>
                        <div className='col-form-label' style={{ gap: "10px" }}>
                            {console.log(itm, "____validation")}
                            
                            {itm.type === "text" && (
                                <>
                                    <input
                                        type={itm?.type}
                                        className='form-control'
                                        placeholder={itm?.placeholder}
                                        {...register(itm?.name, {
                                            required: itm?.required ? "This field is required" : false,
                                            pattern: itm?.validation ? itm?.validation : undefined,
                                        })}
                                        disabled={itm?.disabled}
                                    />
                                    {errors[itm?.name] && <p style={{ color: 'red' }} className="error-message">{errors[itm?.name]?.message}</p>}
                                </>
                            )}

                            {itm.type === 'number' && (
                                <>
                                    <input
                                    style={{backgroundColor:'white'}}
                                        type={itm?.type}
                                        className='form-control'
                                        placeholder={itm?.placeholder}
                                        {...register(itm?.name, {
                                            required: itm?.required ? "This field is required" : false,
                                            pattern: itm?.validation ? itm?.validation : undefined,
                                        })}
                                        disabled={itm?.disabled}
                                    />
                                    {errors[itm?.name] && <p style={{ color: 'red' }} className="error-message">{errors[itm?.name]?.message}</p>}
                                </>
                            )}

                            {itm.type === "DateTime" && (
                                <DatePicker
                                    selected={dateTime}
                                    onChange={handleDateTimeChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    timeCaption="time"
                                    placeholderText="Select Date and Time"
                                    id="datetime"
                                    className="datepicker-input"
                                />
                            )}

                            {itm.type === "select" && (
                                <>
                                    <select
                                    
                                        id='selectOption'
                                        className='form-control'
                                        disabled={itm?.disabled}
                                        {...register(itm?.name, {
                                            required: itm?.required ? "This field is required" : false,
                                            pattern: itm?.validation ? itm?.validation : undefined,
                                        })}
                                    >
                                        <option value="">Select an Option</option>
                                        {itm?.option?.map((bar, idx) => (
                                            <option style={{color:'black'}} key={idx} value={bar.value}>{bar.label}</option>
                                        ))}
                                    </select>
                                    {errors[itm?.name] && <p style={{ color: 'red' }} className="error-message">{errors[itm?.name]?.message}</p>}
                                </>
                            )}

                            {itm.type === "multiSelect" && (
                                <MultiSelectInput
                                    disabled={itm?.disabled}
                                    name={itm?.name}
                                    options={itm.option}
                                    control={itm.control}
                                    validation={itm.validation}
                                    required={itm.required}
                                    register={register}
                                    errors={errors}

                                />
                            )}

                            {itm.type === "textArea" && (
                                <>
                                    <textarea
                                        {...register(itm?.name, {
                                            required: itm?.required ? "This field is required" : false,
                                            pattern: itm?.validation ? itm?.validation : undefined,
                                        })}
                                        rows={8}
                                        className="form-control"
                                        disabled={itm?.disabled}
                                    />
                                    {errors[itm?.name] && <p style={{color:'red'}} className="error-message">{errors[itm?.name]?.message}</p>}
                                </>
                            )}

                            {itm.type === "file" && (
                                <Controller
                                    control={itm?.control}
                                    name={itm?.name}
                                    render={({ field }) => (
                                        <input
                                        style={{width:"250px",backgroundColor:"#ff99c8"}}
                                            {...register(itm?.name, {
                                                required: itm?.required ? "This field is required" : false,
                                                pattern: itm?.validation ? itm?.validation : undefined,
                                            })}
                                            type={itm.type}
                                            multiple
                                            onChange={(e) => field.onChange([...e.target.files])}
                                        />
                                        
                                    )}
                                />
                                
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}


export default DATAPLUS_CommonForm