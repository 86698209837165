import React, { useEffect,useState } from 'react'
import { useForm } from 'react-hook-form';
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';

const AddProjectDatabseForm = () => {


  const[alerts,setAlerts] = useState(false)
  // const [selectedAccount, setSelectedAccount] = useState('');

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {id} = useParams()
    console.log(id,"______id")
      let navigateLink ='/DATAPLUS/Project/Progress'


    const
    { register,
      handleSubmit,
      setValue,
      watch,
      formState: { errors }
    } = useForm();
    

  //  if(item.uniqueid)
  //   {
  //     setValue("name",item.name)

  //   }


  useEffect(() => {
    dispatch(adminDashboardActions.getProjectDatabaseList())
  }, [])

  const Data = useSelector((state) => state?.AdminDashboard_reducer?.getProjectDatabaseList)
  console.log(Data, "__dataProject")


  let item  = Data.filter((itm)=>{

    if(itm.uniqueid === id){
      return itm
    }
  
  })
  
  item =item[0];
  console.log(item, "__iteems")





 // ACCOUNT Options
        useEffect(()=>{
          dispatch(adminDashboardActions.getManageAccountList())
        },[])

        const accountData  = useSelector((state)=>state?.AdminDashboard_reducer?.getManageAccountList)
        console.log(accountData,"________datata")

        const accountOptions = accountData.map((itm)=>{return{label:itm.accountName,value:itm.uniqueid}})
        console.log(accountOptions,"___options")
      
  // marketOptions
        const selectedAccount = watch('Account');

        useEffect(()=>{
          if(selectedAccount!=="Select" || selectedAccount!=="")
          {
            dispatch(adminDashboardActions.getFilteredMarkets(true,`account=${selectedAccount}`))
          }
        },[selectedAccount])

        let marketData= useSelector((state)=>state?.AdminDashboard_reducer?.getFilteredMarkets)
          console.log(marketData,"_makrt")
       

        console.log(marketData,"______datatata")
        const marketOptions = marketData.map((itm)=>{
          
          return {label:itm.marketName,value:itm.uniqueid }})
        
          // setValue('Account',marketData[0]?.accountName)

  const Form = [
    {
      label: "Account",
      value: "",
      name: "Account",
      type: "select",
      option:accountOptions,
      required: true
    },
    {
      label: "Market",
      value: "",
      name: "Market",
      type: "select",
      option:marketOptions,
      required: true
    },
    {
      label: "SiteID",
      value: "",
      name: "SiteID",
      type: "text",
      required: true
    },
    {
      label: "USID",
      value: "",
      name: "USID_",
      type: "text",
      required: true
    },
    {
      label: "FA_Location_Code",
      value: "",
      name: "FA_Location_Code",
      type: "text",
      required: true
    },
    {
      label: "PACE Job Number",
      value: "",
      name: "PACE Job Number",
      type: "text",
      required: true,
      placeholder:"MRSFR+6 Digits",
      validation:{
        value: /^MRSFR+[0-9]{6}$/,
        message: "Please Enter A Valid PACE Job Number"
    }
    },
    {
      label: "IWM Job Number",
      value: "",
      name: "IWM Job Number",
      type: "text",
      required: true
    },
    {
      label: "Job Family Status Name",
      value: "",
      name: "Job Family Status Name",
      type: "text",
      required: true
    },
    {
      label: "Oracle PTN",
      value: "",
      name: "Oracle PTN_",
      type: "text",
      required: true
    },
    {
      label: "National Program",
      value: "",
      name: "National Program",
      type: "text",
      required: true
    },
    {
      label: "PACE Name",
      value: "",
      name: "PACE Name",
      type: "text",
      required: true
    },
    {
      label: "I-Plan Spectrum",
      value: "",
      name: "I-Plan Spectrum",
      type: "text",
      required: true
    },
    {
      label: " Pre-Config",
      value: "",
      name: " Pre-Config",
      type: "text",
      required: false
    },
    {
      label: "Post Config",
      value: "",
      name: "Post Config",
      type: "text",
      required: false
    },
    {
      label: "Current Config",
      value: "",
      name: "Current Config",
      type: "text",
      required: false
    },
    {
      label: "RFDS Status",
      value: "",
      name: "RFDS Status",
      type: "select",
      option:[{label:'OK',value:'OK'},
        {label:'NOK',value:'NOK'},
        {label:'NA',value:'NA'}
      ],
      required: false
    },
    {
      label: "RFDS Type",
      value: "",
      name: "RFDS Type",
      type: "text",
      required: false
    },
    {
      label: "RFDS Comment",
      value: "",
      name: "RFDS Comment",
      type: "text",
      required: false
    },
    {
      label: "CIQ Status",
      value: "",
      name: "CIQ Status",
      type: "select",
      option:[{label:'OK',value:'OK'},
        {label:'NOK',value:'NOK'},
        {label:'NA',value:'NA'}
      ],
      required: false
    },
    {
      label: "CIQ Comment",
      value: "",
      name: "CIQ Comment",
      type: "text",
      required: false
    },
    {
      label: "IX Status",
      value: "",
      name: "IX Status",
      type: "select",
      option:[{label:'OK',value:'OK'},
        {label:'NOK',value:'NOK'},
        {label:'NA',value:'NA'}
      ],
      required: false
    },
    {
      label: "IX Comment",
      value: "",
      name: "IX Comment",
      type: "text",
      required: false
    },
    {
      label: "EDP Status",
      value: "",
      name: "EDP Status",
      type: "select",
      option:[{label:'OK',value:'OK'},
        {label:'NOK',value:'NOK'},
        {label:'NA',value:'NA'}
      ],
      required: false
    },
    {
      label: "EDP Comment",
      value: "",
      name: "EDP Comment",
      type: "text",
      required: false
    },
    {
      label: "TVW Status",
      value: "",
      name: "TVW Status",
      type: "select",
      option:[{label:'OK',value:'OK'},
        {label:'NOK',value:'NOK'},
        {label:'NA',value:'NA'}
      ],
      required: false
    },
    {
      label: "TVW Comment",
      value: "",
      name: "TVW Comment",
      type: "text",
      required: false
    },
    {
      label: "IWOS Status",
      value: "",
      name: "IWOS Status",
      type: "select",
      option:[{label:'OK',value:'OK'},
        {label:'NOK',value:'NOK'},
        {label:'NA',value:'NA'}
      ],
      required: false
    },
    {
      label: "IWOS Comment",
      value: "",
      name: "IWOS Comment",
      type: "text",
      required: false
    },
    {
      label: "E911 Status",
      value: "",
      name: "E911 Status",
      type: "select",
      option:[{label:'OK',value:'OK'},
        {label:'NOK',value:'NOK'},
        {label:'NA',value:'NA'}
      ],
      required: false
    },
    {
      label: "E911 Comment",
      value: "",
      name: "E911 Comment",
      type: "text",
      required: false
    },
    {
      label: "PSAP Status",
      value: "",
      name: "PSAP Status",
      type: "select",
      option:[{label:'OK',value:'OK'},
        {label:'NOK',value:'NOK'},
        {label:'NA',value:'NA'}
      ],
      required: false
    },
    {
      label: "PSAP Comment",
      value: "",
      name: "PSAP Comment",
      type: "text",
      required: false
    },
    {
      label: "Project Status",
      value: "",
      name: "Project Status",
      type: "text",
      required: false
    },
    {
      label: "Remarks",
      value: "",
      name: "Remarks",
      type: "text",
      required: false
    }
  ];
  
  const onSubmit = (data) => {
    console.log("_called")
    // Handle form submission here, you can access form data in the 'data' object
    // console.log(data,item.uniqueid, "oashudnm");
    console.log(data,"______dddddddddd")

    // dispatch(adminDashboardActions.postProjectDatabaseList("", data, () => {
    //             dispatch(adminDashboardActions.getProjectDatabaseList())
    //           }))

            

              


    if (item) {

      console.log("called","kabsd")
      dispatch(adminDashboardActions.postProjectDatabaseList(item.uniqueid, data, () => {
        dispatch(adminDashboardActions.getProjectDatabaseList())
      }))
    }
    else {
      console.log("called","dfcgvh")
      dispatch(adminDashboardActions.postProjectDatabaseList("",data, () => {
        dispatch(adminDashboardActions.getProjectDatabaseList())
      }))
    }
   setTimeout(()=>{
    setAlerts(true)
   },1000)
    
  };

  useEffect(() => {
    console.log(item,item?.uniqueid,"________itemmss")
    if (item?.uniqueid) {
      console.log(Form, item, "xdrcg");
      console.log("called_called");
      Form.forEach((key) => {
        console.log(`sdxghbj`, key.name, item[key.name]);
        if (item[key.name] && typeof item[key.name] === 'object' && "$oid" in item[key.name]) {
          setValue(key.name, item[key.name]["$oid"]);
        } else {
          console.log(key.name, item[key.name],"______key_name")
          setValue(key.name, item[key.name]);
        }
      });
    }
  }, [item?.uniqueid]);
  
  



  return (
    <>

      {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink}/>}
    
    <form onSubmit={handleSubmit(onSubmit) }>
        <DATAPLUS_CommonForm Form={Form} register={register} errors={errors} />
        <div style={{ width: 'full', display: 'flex', gap:'10px',justifyContent: 'center', marginTop: "20px" }}>
                <button style={{ margintop: "10" }}  className='btn btn-info'  onClick={()=>navigate(-1)}>
                    Back
                </button>
                    
                    <button style={{ margintop: "10" }} type='submit' className='btn btn-primary'>
                    Submit
                </button>
                
                </div>
      </form>
    </>
  )
}

export default AddProjectDatabseForm