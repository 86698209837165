import React, { useState,useEffect } from 'react'
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useForm } from 'react-hook-form';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import { useDispatch, useSelector } from 'react-redux';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';
import { useNavigate, useParams } from 'react-router-dom';

const OSSForm = ({setModal}) => {

  const[alerts,setAlerts] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {id} = useParams()
  let navigateLink ="/DATAPLUS/enm";


    useEffect(() => {
      dispatch(adminDashboardActions.getOSSList())
    }, [])
  
    const Data = useSelector((state) => state?.AdminDashboard_reducer?.getOSSList)
    let item  = Data.filter((itm)=>{

  if(itm.uniqueid === id){
    return itm
  }

})

item =item[0];


    const { register, handleSubmit,setValue ,formState: { errors }} = useForm();

    const onSubmit = (data) => {
      console.log(data,"____ossData");

      if (item) {

        console.log("called","kabsd")
        dispatch(adminDashboardActions.postOSSList(item.uniqueid, data, () => {
          dispatch(adminDashboardActions.getOSSList())
        }))
      }
      else {
        console.log("called","dfcgvh")
        dispatch(adminDashboardActions.postOSSList("",data, () => {
          dispatch(adminDashboardActions.getOSSList())
        }))
      }
  
      
      setTimeout(()=>{
        setAlerts(true)
      },1000)

     
      
      

    };
  
    const Form =[
        
        {
          label:"ENM",
          value:"",
          name:"enm",
          type:"text",
          required:true
        },
        {
          label:"IP",
          name:"ip",
          type:"text",
          required:true
        },
        {
          label:"Port",
          name:"port",
          type:"text",
          required:true
        },
       
      ]

      
useEffect(()=>{
  if(item?.uniqueid)
    {
      console.log(Form, item, "xdrcg")
      console.log("called_called")
      Form.forEach((key) => {
        console.log(`sdxghbj`,key.name, item[key.name])
        if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
          setValue(key.name, item[key.name]["$oid"]);
  
        }
        else {
          setValue(key.name, item[key.name]);
        }
  
      })
    }
},[item?.uniqueid])

  return (
    
    <>
    {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink}/>}

    <form onSubmit={handleSubmit(onSubmit)}>
      <DATAPLUS_CommonForm Form={Form} register={register} errors={errors}  />
      <div style={{ width: 'full', display: 'flex', gap:'10px',justifyContent: 'center', marginTop: "20px" }}>
                <button style={{ margintop: "10" }}  className='btn btn-info'  onClick={()=>navigate(-1)}>
                    Back
                </button>
                    
                    <button style={{ margintop: "10" }} type='submit' className='btn btn-primary'>
                    Submit
                </button>
                
                </div>
    </form>

  </>


  )

}

export default OSSForm