import React from 'react'
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm'
import { useForm } from 'react-hook-form';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import { useDispatch } from 'react-redux';

const SiteIdExcelUploadForm = ({setExcelModal}) => {

    const dispatch = useDispatch()

    const { control,
        register,
        handleSubmit,
        setValue
    } = useForm();


    const Form = [
        {
            label: "Upload Here",
            value: "",
            name: "siteIDExcelFile",
            type: "file",
            // placeholder: "Enter Your Name",
            required:true,
            control:control
          },
    ]

    const onSubmit = (data) => {
        // Handle form submission here, you can access form data in the 'data' object
        // console.log(data,item.uniqueid, "oashudnm");

       
        console.log(data,"______excelUploadData")
        //   dispatch(adminDashboardActions.postSiteIdExcelList("", data,
        //     () => {
        //         dispatch(adminDashboardActions.getSiteIdList())
        //       }
        // ))
        setExcelModal(false)
        // if (item) {
    
        //   console.log("called","kabsd")
        //   dispatch(adminDashboardActions.postGCList(item.uniqueid, data, () => {
        //     dispatch(adminDashboardActions.getGCList())
        //   }))
        // }
        // else {
        //   console.log("called","dfcgvh")
        //   dispatch(adminDashboardActions.postGCList("",data, () => {
        //     dispatch(adminDashboardActions.getGCList())
        //   }))
        // }
        // setAlerts(true)
        
      };
    
     
     
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DATAPLUS_CommonForm Form={Form} register={register} />
                <button type='submit' className='btn btn-primary'>
                    Submit
                </button>
            </form>
        </>
    )
}

export default SiteIdExcelUploadForm