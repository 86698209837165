import { useNavigate } from "react-router-dom";
import Api from "../../utils/api";
import { Urls } from "../../utils/url";
import {
  SET_PROJECT_DATABASE,
  SET_MANAGE_ACCOUNT,
  SET_MANAGE_MARKETS,
  SET_TIME_ZONE,
  SET_GC,
  SET_UTRAN_ENGINEERS,

  // SET_KPI_DASHBOARD,
  // SET_PSAP_DASHBOARD,
  // SET_CIQ_SCRIPTING_DASHBOARD,
  SET_MANAGE_CREW_COMPANY,
  SET_LIST_USER_ROLE_TWO,
  SET_CUSTOMER,
  SET_ENGINEER,
  SET_OSS,
  SET_SITE_ID,
  SET_FILTERED_MARKETS,
  SET_CIQ_SITES_KPI,





  SET_CIQ_SITES_KPI_ID,
  GET_VENDOR,
  GET_MANAGE_CREW_COMPANY,
  GET_MANAGE_ENGINEER,
  GET_TT_PIER_GROUP,
  GET_GROUP_DATA,
  GET_MANAGE_SCORE_CARD_DATA,
  GET_MANAGE_CREW_DATA,
  GET_LIST_TWO_DATA,
  GET_TMO_DEPLOYMENT_MANAGERS_DATA,
  GET_DROPDOWN_GROUP_DATA,
  GET_USER_ROLE_DATA,
  GET_TT_TYPE_DATA,
  GET_TT_CATEGORY_DATA,
  GET_SOW_DETAILS_DATA,
  GET_MOBILE_APP_COP_PRE_DATA,
  GET_MOBILE_APP_COP_POST_DATA,
  GET_TECHNOLOGY_MASTER_DATA,
  GET_PRE_EXISTING_TM_DATA,
  GET_GROUP_MASTER_DATA,
  GET_MANAGE_MOBILE_FENCING_DATA,
  SET_IX_DASHBOARD,
  
} from "../reducers/AdminDashboard_reducer";
import { ALERTS } from "../reducers/component-reducer";
// import { ALERTS } from "../reducers/component-reducer";

const adminDashboardActions = {
  //  manager account starts here
  getManageAccountList:
    (reset = true, args = "") =>
    async (dispatch, _) => {
      try {
        const res = await Api.get({
          url: `${Urls.Manage_Account_Data}${args != "" ? "?" + args : ""}`,
        });
        // console.log(res,"casdasd")
        if (res?.status !== 200) return;
        let dataAll = res?.data?.data;
        console.log(dataAll,"casdasd")

        dispatch(SET_MANAGE_ACCOUNT({ dataAll, reset }));
      } catch (error) {}
    },

  postManageAccountList: ( data, cb, uniqueId="") => async (dispatch, _) => {
    try {
      const res = await Api.post({
        data: data,
        url:
          uniqueId == ""
            ? Urls.Manage_Account_Data
            : Urls.Manage_Account_Data + "/" + uniqueId,
      });
      if (res?.status !== 201 && res?.status !== 200) {
        let msgdata = {
          show: false,
          icon: "",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        console.log(msgdata,"1111")
        dispatch(ALERTS(msgdata));
      } else {
        console.log(res,"resdata")
        let msgdata = {
          show: true,
          icon: "",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        console.log(msgdata,"22222")
        dispatch(ALERTS(msgdata));
        cb();
       
      }
    } catch (error) {
      return;
    }
  },

  // manage account ends here

//   // manage markets starts here
      getManageMarketsList:
        (reset = true, args = "") =>
        async (dispatch, _) => {
          try {
            const res = await Api.get({
              url: `${Urls.Manage_Markets_Data}${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 200) return;
            let dataAll = res?.data?.data;
            console.log(dataAll, "sjdbk");
            dispatch(SET_MANAGE_MARKETS({ dataAll, reset }));
          } catch (error) {
            // dispatch(ALERTS(msgdata));
          }
        },

        // FILTERED MARKETS
        getFilteredMarkets:
        (reset = true, args = "") =>
        async (dispatch, _) => {
          try {
            const res = await Api.get({
              url: `${Urls.Filtered_Markets_Data}${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 200) return;
            let dataAll = res?.data?.data;
            console.log(dataAll, "sjdbk");
            dispatch(SET_FILTERED_MARKETS({ dataAll, reset }));
          } catch (error) {
            // dispatch(ALERTS(msgdata));
          }
        },
  postManageMarketsList: ( data, cb, uniqueId) => async (dispatch, _) => {
    try {
      const res = await Api.post({
        data: data,
        url:
          uniqueId === ""
            ? Urls.Manage_Markets_Data
            : Urls.Manage_Markets_Data + "/" + uniqueId,
      });
      if (res?.status !== 201 && res?.status !== 200) {
        let msgdata = {
          show: false,
          icon: "",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        console.log(msgdata,"1111")
        dispatch(ALERTS(msgdata));
      } else {
        console.log(res,"resdata")
        let msgdata = {
          show: true,
          icon: "",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        console.log(msgdata,"22222")
        dispatch(ALERTS(msgdata));
        cb();
       
      }
    } catch (error) {
      return;
    }
  },

//   // Time zone
  getTimeZoneList:
    (reset = true, args = "") =>
    async (dispatch, _) => {
      try {
        const res = await Api.get({
          url: `${Urls.Time_Zone_Data}${args != "" ? "?" + args : ""}`,
        });
        if (res?.status !== 200) return;
        let dataAll = res?.data?.data;
        console.log(dataAll, "sjdbk");
        dispatch(SET_TIME_ZONE({ dataAll, reset }));
      } catch (error) {}
    },

    //   // Time zone


    // project databse start here

    getProjectDatabaseList:
    (reset = true, args = "") =>
    async (dispatch, _) => {
      try {
        const res = await Api.get({
          url: `${Urls.Project_Database_Data}${args != "" ? "?" + args : ""}`,
        });
        if (res?.status !== 200) return;
        let dataAll = res?.data?.data;
        console.log(dataAll, "sjdbk");
        dispatch(SET_PROJECT_DATABASE({ dataAll, reset }));
        
      } catch (error) {}
    },


    postProjectDatabaseList: (uniqueId="", data, cb) => async (dispatch, _) => {
      try {
        const res = await Api.post({
          data: data,
          url:
            uniqueId === ""
              ?
              Urls.Project_Database_Data
              : Urls.Project_Database_Data + "/" + uniqueId,
        });
        if (res?.status !== 201 && res?.status !== 200) {
          let msgdata = {
            show: false,
            icon: "",
            buttons: [],
            type: 1,
            text: res?.data?.msg,
          };
          console.log(msgdata,"1111")
          dispatch(ALERTS(msgdata));
        } else {
          console.log(res,"resdata")
          let msgdata = {
            show: true,
            icon: "",
            buttons: [],
            type: 1,
            text: res?.data?.msg,
          };
          console.log(msgdata,"22222")
          dispatch(ALERTS(msgdata));
          cb();
         
        }
        
      } catch (error) {
        
        return;
      }
    },


    postProjectDatabaseExcelList: (uniqueId="", data, cb) => async (dispatch, _) => {
      try {
        const res = await Api.post({
          data: data,
          url:
            uniqueId === ""
              ?
              Urls.Project_Database_Excel
              : Urls.Update_GC_Item + "/" + uniqueId,
              contentType:"multipart/form-data"
        });
        console.log(res,"________")
        if (res?.status !== 201 && res?.status !== 200) {
          let msgdata = {
            show: false,
            icon: "",
            buttons: [],
            type: 1,
            text: res?.data?.msg,
          };
          console.log(msgdata,"1111")
          dispatch(ALERTS(msgdata));
        } else {
          console.log(res,"resdata")
          let msgdata = {
            show: true,
            icon: "",
            buttons: [],
            type: 1,
            text: res?.data?.msg,
          };
          console.log(msgdata,"22222")
          dispatch(ALERTS(msgdata));
          cb();
         
        }
        
      } catch (error) {
        
        return;
      }
    },
    // project databse ends here

    // Customer Starts here
    
    getCustomerList:
    (reset = true, args = "") =>
    async (dispatch, _) => {
      try {
        const res = await Api.get({
          url: `${Urls.Customer_Data}${args != "" ? "?" + args : ""}`,
        });
        if (res?.status !== 200) return;
        let dataAll = res?.data?.data;
        console.log(dataAll, "sjdbk");
        dispatch(SET_CUSTOMER({ dataAll, reset }));
        
      } catch (error) {}
    },



    postCustomerList: (uniqueId="", data, cb,args="") => async (dispatch, _) => {
      try {
        const res = await Api.post({
          data: data,
          url:
            uniqueId === ""
              ?
              Urls.Post_Customer_Data
              :args===""? Urls.Post_Customer_Data + "/" + uniqueId : Urls.Post_Customer_Data + "/" + uniqueId+`${args != "" ? "?" + args : ""}`,
        });
        if (res?.status !== 201 && res?.status !== 200) {
          let msgdata = {
            show: false,
            icon: "",
            buttons: [],
            type: 1,
            text: res?.data?.msg,
          };
          console.log(msgdata,"1111")
          dispatch(ALERTS(msgdata));
        } else {
          console.log(res,"resdata")
          let msgdata = {
            show: true,
            icon: "",
            buttons: [],
            type: 1,
            text: res?.data?.msg,
          };
          console.log(msgdata,"22222")
          dispatch(ALERTS(msgdata));
          cb();
         
        }
        
      } catch (error) {
        
        return;
      }
    },
    // Customer Ends here


    // engineer start here

    getEngineerList:
    (reset = true, args = "") =>
    async (dispatch, _) => {
      try {
        const res = await Api.get({
          url: `${Urls.Engineer_data}${args != "" ? "?" + args : ""}`,
        });
        if (res?.status !== 200) return;
        let dataAll = res?.data?.data;
        console.log(dataAll, "sjdbk");
        dispatch(SET_ENGINEER({ dataAll, reset }));
        
      } catch (error) {}
    },

    postEngineerList: (uniqueId="", data, cb) => async (dispatch, _) => {
      try {
        const res = await Api.post({
          data: data,
          url:
            uniqueId === ""
              ?
              Urls.Post_Engineer_data
              : Urls.Post_Engineer_data + "/" + uniqueId,
        });
        if (res?.status !== 201 && res?.status !== 200) {
          let msgdata = {
            show: false,
            icon: "",
            buttons: [],
            type: 1,
            text: res?.data?.msg,
          };
          console.log(msgdata,"1111")
          dispatch(ALERTS(msgdata));
        } else {
          console.log(res,"resdata")
          let msgdata = {
            show: true,
            icon: "",
            buttons: [],
            type: 1,
            text: res?.data?.msg,
          };
          console.log(msgdata,"22222")
          dispatch(ALERTS(msgdata));
          cb();
         
        }
        
      } catch (error) {
        
        return;
      }
    },
    // engineer ends here

    // GET GC DATA STARTS HERE
    
    getGCList:
  (reset = true, args = "") =>
  async (dispatch, _) => {
    try {
      const res = await Api.get({
        url: `${Urls.Manage_GC}${args != "" ? "?" + args : ""}`,
      });
      if (res?.status !== 200) return;
      let dataAll = res?.data?.data;
      console.log(dataAll, "sjdbk");
      dispatch(SET_GC({ dataAll, reset }));
      
    } catch (error) {}
  },
// /wildKPISite
  postGCList: (uniqueId="", data, cb) => async (dispatch, _) => {
        try {
          const res = await Api.post({
            data: data,
            url:
              uniqueId === ""
                ?
                Urls.Post_Manage_GC_Data
                : Urls.Update_GC_Item + "/" + uniqueId,
          });
          if (res?.status !== 201 && res?.status !== 200) {
            let msgdata = {
              show: false,
              icon: "",
              buttons: [],
              type: 1,
              text: res?.data?.msg,
            };
            console.log(msgdata,"1111")
            dispatch(ALERTS(msgdata));
          } else {
            console.log(res,"resdata")
            let msgdata = {
              show: true,
              icon: "",
              buttons: [],
              type: 1,
              text: res?.data?.msg,
            };
            console.log(msgdata,"22222")
            dispatch(ALERTS(msgdata));
            cb();
           
          }
          
        } catch (error) {
          
          return;
        }
      },

      


      deleteGCItem:(uniqueId=null,reset = true, args = "") =>
        async (dispatch, _) => {
          try {
            const res = await Api.get({
              url: `${Urls.Manage_GC}${uniqueId ? "/"+uniqueId :""}`,
            });
            if (res?.status !== 200) return;
            if (res?.status !== 201 && res?.status !== 200) {
              let msgdata = {
                show: false,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"1111")
              dispatch(ALERTS(msgdata));
            } else {
              console.log(res,"resdata")
              let msgdata = {
                show: true,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"22222")
              dispatch(ALERTS(msgdata));
             
            }
          } catch (error) {}
        },

      // GET GC DATA ends HERE

//   // manage markets ends here


// UTRAN ENGINEERS STARTS HERE
getUTRANEngineersList:
  (reset = true, args = "") =>
  async (dispatch, _) => {
    try {
      const res = await Api.get({
        url: `${Urls.Manage_Engineer_Data}${args != "" ? "?" + args : ""}`,
      });
      if (res?.status !== 200) return;
      let dataAll = res?.data?.data;
      console.log(dataAll, "sjdbk");
      dispatch(SET_UTRAN_ENGINEERS({ dataAll, reset }));
    } catch (error) {}
  },

  postUTRANEngineersList: (uniqueId="", data, cb) => async (dispatch, _) => {
    try {
      const res = await Api.post({
        data: data,
        url: Urls.Manage_Engineer_Data + "/" + uniqueId,
      });
      if (res?.status !== 201 && res?.status !== 200) {
        let msgdata = {
          show: false,
          icon: "",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        console.log(msgdata,"1111")
        dispatch(ALERTS(msgdata));
      } else {
        console.log(res,"resdata")
        let msgdata = {
          show: true,
          icon: "",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        console.log(msgdata,"22222")
        dispatch(ALERTS(msgdata));
        cb();
       
      }
      
    } catch (error) {
      
      return;
    }
  },
// UTRAN ENGINEERS ENDS HERE


      // MANAGE CREW COMPANY STARTS HERE

      getManageCrewCompanyList:
      (reset = true, args = "") =>
      async (dispatch, _) => {
        try {
          const res = await Api.get({
            url: `${Urls.Manage_Crew_Company_Data}${args != "" ? "?" + args : ""}`,
          });
          if (res?.status !== 200) return;
          let dataAll = res?.data?.data;
          console.log(dataAll, "sjdbk");
          dispatch(SET_MANAGE_CREW_COMPANY({ dataAll, reset }));
        } catch (error) {}
      },

      // MANAGE CREW COMPANY ENDS HERE

      // list user role two starts here

      getManageListRoleTwoList:
      (reset = true, args = "") =>
      async (dispatch, _) => {
        try {
          const res = await Api.get({
            url: `${Urls.Manage_List_Role_Two_Data}${args != "" ? "?" + args : ""}`,
          });
          if (res?.status !== 200) return;
          let dataAll = res?.data?.data;
          console.log(dataAll, "sjdbk");
          dispatch(SET_LIST_USER_ROLE_TWO({ dataAll, reset }));
        } catch (error) {}
      },
      // list user role two ends here

        // Oss starts here
        getOSSList:
        (reset = true, args = "") =>
        async (dispatch, _) => {
          try {
            const res = await Api.get({
              url: `${Urls.OSS_Data}${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 200) return;
            let dataAll = res?.data?.data;
            console.log(dataAll, "sjdbk");
            dispatch(SET_OSS({ dataAll, reset }));
            
          } catch (error) {}
        },
  
        postOSSList: (uniqueId="", data, cb) => async (dispatch, _) => {
              try {
                const res = await Api.post({
                  data: data,
                  url:
                    uniqueId === ""
                      ?
                      Urls.Post_OSS_Data
                      : Urls.Post_OSS_Data + "/" + uniqueId,
                });
                if (res?.status !== 201 && res?.status !== 200) {
                  let msgdata = {
                    show: false,
                    icon: "",
                    buttons: [],
                    type: 1,
                    text: res?.data?.msg,
                  };
                  console.log(msgdata,"1111")
                  dispatch(ALERTS(msgdata));
                } else {
                  console.log(res,"resdata")
                  let msgdata = {
                    show: true,
                    icon: "",
                    buttons: [],
                    type: 1,
                    text: res?.data?.msg,
                  };
                  console.log(msgdata,"22222")
                  dispatch(ALERTS(msgdata));
                  cb();
                 
                }
                
              } catch (error) {
                
                return;
              }
            },
        // Oss ends here

        // siteid starts here
        getSiteIdList:
        (reset = true, args = "") =>
        async (dispatch, _) => {
          try {
            const res = await Api.get({
              url: `${Urls.Site_Id_Data}${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 200) return;
            let dataAll = res?.data?.data;
            console.log(dataAll, "____dsysy");
            dispatch(SET_SITE_ID({ dataAll, reset }));
            
          } catch (error) {}
        },

        postSiteIdList: (uniqueId="", data, cb) => async (dispatch, _) => {
          try {
            const res = await Api.post({
              data: data,
              url:
                uniqueId === ""
                  ?
                  Urls.Post_Site_Id_Data
                  : Urls.Post_Site_Id_Data + "/" + uniqueId,
            });
            if (res?.status !== 201 && res?.status !== 200) {
              let msgdata = {
                show: false,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"1111")
              dispatch(ALERTS(msgdata));
            } else {
              console.log(res,"resdata")
              let msgdata = {
                show: true,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"22222")
              dispatch(ALERTS(msgdata));
              cb();
             
            }
            
          } catch (error) {
            
            return;
          }
        },

        postSiteIdExcelList: (uniqueId="", data, cb) => async (dispatch, _) => {
          try {
            const res = await Api.post({
              data: data,
              url:
                uniqueId === ""
                  ?
                  Urls.Post_Site_Id_Excel_Data
                  : Urls.Update_GC_Item + "/" + uniqueId,
                  contentType:"multipart/form-data"
            });
            if (res?.status !== 201 && res?.status !== 200) {
              let msgdata = {
                show: false,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"1111")
              dispatch(ALERTS(msgdata));
            } else {
              console.log(res,"resdata")
              let msgdata = {
                show: true,
                icon: "",
                buttons: [],
                type: 1,
                text: res?.data?.msg,
              };
              console.log(msgdata,"22222")
              dispatch(ALERTS(msgdata));
              cb();
             
            }
            
          } catch (error) {
            
            return;
          }
        },
        // siteid ends here
      

     
      // /wildKPISite
  // postGCList: ( data, cb, uniqueId=null) => async (dispatch, _) => {
  //   try {
  //     const res = await Api.post({
  //       data: data,
  //       url:
  //         uniqueId === null
  //           ?
  //           Urls.Post_Manage_GC_Data
  //           : Urls.Post_Manage_GC_Data + "/" + uniqueId,
  //     });
  //     if (res?.status !== 201 && res?.status !== 200) {
  //       let msgdata = {
  //         show: true,
  //         icon: "error",
  //         buttons: [],
  //         type: 1,
  //         text: res?.data?.msg,
  //       };
  //       // dispatch(ALERTS(msgdata));
  //     } else {
  //       cb();
  //     }
  //   } catch (error) {
  //     return;
  //   }
  // },
      // CIQ SITES KPI ends here


};
export default adminDashboardActions;



//   // manage vendor starts here
//   getVendorList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Vendor_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjddsfsdfbk");
//         dispatch(GET_VENDOR({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postVendorList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Manage_Vendor_Data
//             : Urls.Manage_Vendor_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },
//   // manage vendor ends here

//   // manage crew company starts here
//   getManageCrewCompanyList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Crew_Company_Data}${
//             args != "" ? "?" + args : ""
//           }`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MANAGE_CREW_COMPANY({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postManageCrewCompanyList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Manage_Crew_Company_Data
//               : Urls.Manage_Crew_Company_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },
//   // manage crew company endds here

//   // manage engineer starts here
//   getManageEngineerList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Engineer_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MANAGE_ENGINEER({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postManageEngineerList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Manage_Engineer_Data
//               : Urls.Manage_Engineer_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },
//   // manage engineer ends here

//   // TT PIER GROUP Starts here
//   getTTPierGroupList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.TT_Pier_Group_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_TT_PIER_GROUP({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postTTPierGroupList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.TT_Pier_Group_Data
//             : Urls.TT_Pier_Group_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },

//   // group fropdown data

//   getGroupDataList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Group_Dropdown_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_GROUP_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },

//   // TT Pier GROUP ends here

//   // MAnage Score Card starts here

//   getManageScoreCardList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Score_Card_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MANAGE_SCORE_CARD_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postManageScoreCardList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Manage_Score_Card_Data
//               : Urls.Manage_Score_Card_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },
//   // manage score card ends here

//   // manage crew start here
//   getManageCrewList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Crew_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MANAGE_CREW_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   // list two api data
//   getListTwoList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_List_Two_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_LIST_TWO_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },

//   postManageCrewList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_Manage_Crew_Data
//             : Urls.Post_Manage_Crew_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },
//   // manage crew ends here

//   // TMO deployment managers start here
//   getTMODeploymentManagersList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.TMO_Deployment_Managers_Data}${
//             args != "" ? "?" + args : ""
//           }`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_TMO_DEPLOYMENT_MANAGERS_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },

//   postTMODeploymentManagersList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.TMO_Deployment_Managers_Data
//               : Urls.TMO_Deployment_Managers_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },
//   // TMO deployment managers ends here

//   // DROPDOWN GROUP START HERE
//   getDropdownGroupList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Dropdown_Group_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_DROPDOWN_GROUP_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   // DROPDOWN GROUP ENDS HERE

//   // USER ROLE START HERE
//   getUserRoleList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.User_Role_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_USER_ROLE_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   // USER ROLE ENDS HERE

//   // DROPDOWN LIST STARTS HERE

//   //TT TYPE DATA
//   getTTTypeList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.TT_Type_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_TT_TYPE_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postTTTypeList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_TT_Type_Data
//             : Urls.Post_TT_Type_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },

//   // TT Category list data

//   getTTCategoryList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.TT_Category_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_TT_CATEGORY_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postTTCategoryList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_TT_Category_Data
//             : Urls.Post_TT_Category_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },

//   // SOW details data

//   getSOWDetailsList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.SOW_Details_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_SOW_DETAILS_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postSOWDetailsList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_SOW_Details_Data
//             : Urls.Post_SOW_Details_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },

//   // COP PRE NAME DATA
//   getMobileAppCOPPreList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Mobile_App_COP_Pre_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MOBILE_APP_COP_PRE_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postMobileAppCOPPreList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Post_Mobile_App_COP_Pre_Data
//               : Urls.Post_Mobile_App_COP_Pre_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },

//   // COP POST NAME DATA
//   getMobileAppCOPPostList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Mobile_App_COP_Post_Data}${
//             args != "" ? "?" + args : ""
//           }`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MOBILE_APP_COP_POST_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postMobileAppCOPPostList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Post_Mobile_App_COP_Post_Data
//               : Urls.Post_Mobile_App_COP_Post_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },

//   // TECHNOLOGY MASTER DATA
//   getTechnologyMasterList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Technology_Master_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_TECHNOLOGY_MASTER_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postTechnologyMasterList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Technology_Master_Data
//               : Urls.Technology_Master_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },

//   // pre existing TM DATA
//   getPreExistingTMList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Pre_Existing_TM_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_PRE_EXISTING_TM_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postPreExistingTMList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_Pre_Existing_TM_Data
//             : Urls.Post_Pre_Existing_TM_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },
//   // group master data
//   getGroupMasterList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Group_Master_Data}${args != "" ? "?" + args : ""}`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_GROUP_MASTER_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postGroupMasterList: (reset, data, cb, uniqueId) => async (dispatch, _) => {
//     try {
//       const res = await Api.post({
//         data: data,
//         url:
//           uniqueId == null
//             ? Urls.Post_Group_Master_Data
//             : Urls.Post_Group_Master_Data + "/" + uniqueId,
//       });
//       if (res?.status !== 201 && res?.status !== 200) {
//         let msgdata = {
//           show: true,
//           icon: "error",
//           buttons: [],
//           type: 1,
//           text: res?.data?.msg,
//         };
//         dispatch(ALERTS(msgdata));
//       } else {
//         cb();
//       }
//     } catch (error) {
//       return;
//     }
//   },

//   // DROPDOWN LIST ENDS HERE

//   // mobile geofenciing start here
//   getManageMobileFencingList:
//     (reset = true, args = "") =>
//     async (dispatch, _) => {
//       try {
//         const res = await Api.get({
//           url: `${Urls.Manage_Mobile_Geofencing_Data}${
//             args != "" ? "?" + args : ""
//           }`,
//         });
//         if (res?.status !== 200) return;
//         let dataAll = res?.data?.data;
//         console.log(dataAll, "sjdbk");
//         dispatch(GET_MANAGE_MOBILE_FENCING_DATA({ dataAll, reset }));
//       } catch (error) {}
//     },
//   postManageMobileFencingList:
//     (reset, data, cb, uniqueId) => async (dispatch, _) => {
//       try {
//         const res = await Api.post({
//           data: data,
//           url:
//             uniqueId == null
//               ? Urls.Manage_Mobile_Geofencing_Data
//               : Urls.Manage_Mobile_Geofencing_Data + "/" + uniqueId,
//         });
//         if (res?.status !== 201 && res?.status !== 200) {
//           let msgdata = {
//             show: true,
//             icon: "error",
//             buttons: [],
//             type: 1,
//             text: res?.data?.msg,
//           };
//           dispatch(ALERTS(msgdata));
//         } else {
//           cb();
//         }
//       } catch (error) {
//         return;
//       }
//     },
//   // mobile geofenciing end here