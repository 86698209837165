import React, { useEffect, useState } from 'react'
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import { useNavigate, useParams } from 'react-router-dom';
import AlertsModal from "../../../../components/DATAPLUS_Components/AlertsModal"
// import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';

const GCForm = ({ setModal }) => {

  const[alerts,setAlerts] = useState(false)
  const navigateLink  =("/DATAPLUS/gc")

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {id} = useParams()
console.log(id,"id")


useEffect(() => {
  dispatch(adminDashboardActions.getGCList())
}, [])

const Data = useSelector((state) => state?.AdminDashboard_reducer?.getGCList)
console.log(Data, "khabasdasdsdjsd")


let item  = Data.filter((itm)=>{

  if(itm.uniqueid === id){
    return itm
  }

})

item =item[0];
console.log(item, "item")

  // market data fetching
  useEffect(() => {
    dispatch(adminDashboardActions.getManageMarketsList())
  }, [])

  const MarketData = useSelector((state) => state?.AdminDashboard_reducer?.getManageMarketsList)

  console.log(MarketData, "kagsdnu")

  const marketOption = MarketData.map((itm) => { return { label: itm.marketName, value: itm.uniqueid } })

  // list user role two data fetching

  useEffect(() => {
    dispatch(adminDashboardActions.getManageListRoleTwoList())
  }, [])

  const listUserRoleTwoData = useSelector((state) => state?.AdminDashboard_reducer?.getManageListRoleTwoList)

  console.log(listUserRoleTwoData, "bytjh")

  // const listUserRoleTwoOption =  listUserRoleTwoData.map((itm)=>{return {label:itm.poc,value:itm.uniqueid}})


  // Crew company data fetching
  useEffect(() => {
    dispatch(adminDashboardActions.getManageCrewCompanyList())
  }, [])

  const crewCompanyData = useSelector((state) => state?.AdminDashboard_reducer?.getManageCrewCompanyList)

  console.log(crewCompanyData, "kagsdnu")

  const crewCompanyOption = crewCompanyData.map((itm) => { return { label: itm.crewCompanyName, value: itm.uniqueid } })


  // account data fetching
  useEffect(() => {
    dispatch(adminDashboardActions.getManageAccountList())
  }, [])

  const accountData = useSelector((state) => state?.AdminDashboard_reducer?.getManageAccountList)

  console.log(accountData, "mjbasnuymd")

  const accountOption = accountData.map((itm) => { return { label: itm.accountName, value: itm.uniqueid } })

  const
    { register,
      handleSubmit,
      setValue
    } = useForm();

  //  if(item.uniqueid)
  //   {
  //     setValue("name",item.name)

  //   }

  const Form = [
    {
      label: "Name",
      value: "",
      name: "name",
      type: "text",
      placeholder: "Enter Your Name",
      required:true
    },
    {
      label: "Mobile Number",
      value: "",
      name: "mobilenumber",
      type: "text",
      //   option:[{label:"Dharmender",value:"Dharmender"},
      //   {label:"Dharmender1",value:"Dharmender1"},
      //   {label:"Dharmender2",value:"Dharmender2"}
      // ],
      placeholder: "Enter your mobile number",
      required:true
    },
    {
      label: " Mobile-App access",
      value: "",
      name: "mobileAppAccess",
      type: "select",
      option: [{ label: "Granted", value: "Granted" },
      { label: "Rejected", value: "Rejected" },
      { label: "Pending", value: "Pending" }
      ],
      placeholder: "Enter Time Zone Name",
      required:true
    },
    {
      label: "Password",
      name: "password",
      type: "text",
      placeholder: "Enter Your Password ",
      required:true
    },
    {
      label: "Email",
      name: "email",
      type: "text",
      placeholder: "Enter Your Email",
      required:true
    },

    {
      label: "Market",
      value: "",
      name: "marketId",
      type: "select",
      option: marketOption,
      placeholder: "",
      required:true
    },
    {
      label: "POC",
      value: "",
      name: "userrole",
      type: "select",
      option: [{ label: "Yes", value: listUserRoleTwoData[0]?.uniqueid },
      { label: "No", value: listUserRoleTwoData[1]?.uniqueid }
      ],
      placeholder: "",
      required:true
    },

    {
      label: "Crew Lead Company",
      value: "",
      name: "crewLeadCompanyId",
      type: "select",
      option: crewCompanyOption,
      placeholder: "",
      required:true
    },
    {
      label: "Account",
      value: "",
      name: "accountId",
      type: "select",
      option: accountOption,
      placeholder: "",
      required:true
    },
    {
      label: "Select Crew Type",
      name: "crewType",
      type: "select",
      option: [{ label: "IX", value: "IX" },
      { label: "CX", value: "CX" },
      ],
      placeholder: "",
      required:true
    },
  
  ]

  const onSubmit = (data) => {
    // Handle form submission here, you can access form data in the 'data' object
    // console.log(data,item.uniqueid, "oashudnm");
    console.log(data,"dxfchjb")
    if (item) {

      console.log("called","kabsd")
      dispatch(adminDashboardActions.postGCList(item.uniqueid, data, () => {
        dispatch(adminDashboardActions.getGCList())
      }))
    }
    else {
      console.log("called","dfcgvh")
      dispatch(adminDashboardActions.postGCList("",data, () => {
        dispatch(adminDashboardActions.getGCList())
      }))
    }
    setAlerts(true)
    
  };

 
  if(item?.uniqueid)
    {
      console.log(Form, item, "xdrcg")
      console.log("called_called")
      Form.forEach((key) => {
        console.log(`sdxghbj`,key.name, item[key.name])
        if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
          setValue(key.name, item[key.name]["$oid"]);
  
        }
        else {
          setValue(key.name, item[key.name]);
        }
  
      })
    }
    // useEffect(() => {
      
      // setValue("userrole", item.roleresult.poc)
  
    // }, [item])
  

  return (

    <>
    {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink}/>}
      {console.log(item, "ghcfbj")}

      <form onSubmit={handleSubmit(onSubmit) }>
        <DATAPLUS_CommonForm Form={Form} register={register} />
        <button  type='submit' className='btn btn-primary'>
          Submit
        </button>
      </form>

    </>

  )
}

export default GCForm