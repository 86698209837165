import React from 'react'

const CheckIn = () => {

  return (
    
<h1>test</h1>
  )
}

export default CheckIn

