import Api from "../../utils/api";
import { Urls } from "../../utils/url";
import {SET_CIQ_SCRIPTING_DASHBOARD} from "../reducers/CIQScripting-reducer"
import { ALERTS } from "../reducers/component-reducer";

const CIQScriptingActions = {
  //  manager account starts here
  // KPI DASHBOARD STARTS HERE

 // CIQ SCRIPTING DASHBOARD STARTS HERE

      getCIQScriptingList:
      (reset = true, args = "") =>
      async (dispatch, _) => {
        try {
          const res = await Api.get({
            url: `${Urls.Manage_Ciq_Sites_Data}${args != "" ? "?" + args : ""}`,
          });
          if (res?.status !== 200) return;
          let dataAll = res?.data?.data;
          console.log(dataAll, "sjdbk");
          dispatch(SET_CIQ_SCRIPTING_DASHBOARD({ dataAll, reset }));
        } catch (error) {}
      },

//       // CIQ SCRIPTING DASHBOARD ENDS HERE


};
export default CIQScriptingActions;
