import React from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable';

const DailyActivityReport = () => {

  let tableHeading = ["Daily Activity Report"];
  // const modalButton ="+";

const marketsTableColumns = [
  {
    Header : 'SCHEDULE DATE.',
    // Footer : 'S No',
    accessor: '',
    Filter: ColumnFilter,
  },
  {
    Header : 'DATE',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'SITE ID',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'PRE CHECK ENGINEER',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'FE NAME',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'FE CONTACT NUMBER',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },

  

  {
    Header : 'FE EMAIL ID.',
    // Footer : 'S No',
    accessor: '',
    Filter: ColumnFilter,
  },
  {
    Header : 'LOGIN TIME',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'NFSD LOGIN ID',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'EIM ',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'PRE NFSD SCREEENSHOT',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'SOW',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },

  {
    Header : 'SOW DETAILS',
    // Footer : 'S No',
    accessor: '',
    Filter: ColumnFilter,
  },
  {
    Header : 'PRE CHECK ALARMS/ISSUES',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'LOGOUT TIME',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'POST CHECK ENGINEER',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'NFSD LOGOUT ID',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  

  {
    Header : 'POST NFSD SCREEENSHOT',
    // Footer : 'S No',
    accessor: '',
    Filter: ColumnFilter,
  },
  {
    Header : 'POST CHECK ALARMS/ISSUES',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'SOW COMPLETED',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
  {
    Header : 'NOTES',
    // Footer : 'account',
    accessor: "",
    Filter: ColumnFilter,
  },
 
  {
    Header : 'Action',
    // Footer : 'account',
    accessor: "action",
    Filter: "",
  },
 
]

 const MARKETS_GROUPED_COLUMNS = [
  {
    Header : 'Id',
    Footer : 'Id',
    accessor: 'id'
  },
  {
    Header : 'Name',
    Footer : 'Name',
    columns: [
      {
        Header : 'First Name',
        Footer : 'First Name',
        accessor: 'first_name'
      },
      {
        Header : 'Last Name',
        Footer : 'Last Name',
        accessor: 'last_name'
      },
    ]
  },
  {
    Header: 'Info',
    Footer: 'Info',
    columns: [
      {
        Header : 'Date of  Birth',
        Footer : 'Date of  Birth',
        accessor: 'date_of_birth'
      },
      {
        Header : 'Country',
        Footer : 'Country',
        accessor: 'country',
      },
      {
        Header : 'Phone',
        Footer : 'Phone',
        accessor: 'phone'
      },
    ]
  },
]


// const Form =[
//   {
//     label:"Account",
//     name:"accountName",
//     type:"text",
//     placeholder:"Enter Account"
//   },
//   {
//     label:"Market",
//     name:"marketName",
//     type:"text",
//     placeholder:"Enter Market"
//   },
//   {
//     label:"Time Zone Name",
//     name:"timeZoneName",
//     type:"text",
//     placeholder:"Enter Time Zone Name"
//   },
//   {
//     label:"Time Zone ",
//     name:"timeZone",
//     type:"text",
//     placeholder:"Enter Time Zone "
//   },
//   {
//     label:"Time Difference",
//     name:"timeDifference",
//     type:"text",
//     placeholder:"Enter Time Difference"
//   },
  
// ]




// const timeZoneTableColumns = [
//   {
//     Header : 'S No.',
//     Footer : 'S No',
//     accessor: 'id'
//   },
//   {
//     Header : 'Account',
//     Footer : 'account',
//     accessor: "first_name"
//   },
//   // {
//   //   Header : 'Last Name',
//   //   Footer : 'Last Name',
//   //   accessor: 'last_name'
//   // },
//   // {
//   //   Header : 'Date of  Birth',
//   //   Footer : 'Date of  Birth',
//   //   accessor: 'date_of_birth'
//   // },
//   // {
//   //   Header : 'Country',
//   //   Footer : 'Country',
//   //   accessor: 'country',
//   // },
//   // {
//   //   Header : 'Phone',
//   //   Footer : 'Phone',
//   //   accessor: 'phone'
//   // },
//   // {
//   //   Header : 'Age',
//   //   Footer : 'Phone',
//   //   accessor: 'age',
//   // },
// ]

// const TIME_ZONE_GROUPED_COLUMNS = [
//   {
//     Header : 'Id',
//     Footer : 'Id',
//     accessor: 'id'
//   },
//   {
//     Header : 'Name',
//     Footer : 'Name',
//     columns: [
//       {
//         Header : 'First Name',
//         Footer : 'First Name',
//         accessor: 'first_name'
//       },
//       {
//         Header : 'Last Name',
//         Footer : 'Last Name',
//         accessor: 'last_name'
//       },
//     ]
//   },
//   {
//     Header: 'Info',
//     Footer: 'Info',
//     columns: [
//       {
//         Header : 'Date of  Birth',
//         Footer : 'Date of  Birth',
//         accessor: 'date_of_birth'
//       },
//       {
//         Header : 'Country',
//         Footer : 'Country',
//         accessor: 'country',
//       },
//       {
//         Header : 'Phone',
//         Footer : 'Phone',
//         accessor: 'phone'
//       },
//     ]
//   },
// ]







return (
  <>

{/* <MPAG_Modal Form={Form} modalButton={modalButton}/> */}

    {/* <div>
      <SortingTable tableColumns={tableColumns} tableData={tableData} tableHeading ={tableHeading[1]}/>
    </div> */}

{/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
    <div className='flex'>
    <div>
      <FilteringTable tableColumns={marketsTableColumns} tableData={["a","b"]} tableHeading ={tableHeading[0]}/>
    </div>



    
    </div>
  </>

  
)

}

export default DailyActivityReport