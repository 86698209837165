import Api from "../../utils/api";
import { Urls } from "../../utils/url";
import { SET_MANAGE_SITE, SET_TT_DASHBOARD,SET_MANAGER_ENGINEER,SET_MANAGE_SITE_ID } from "../reducers/TroubleTicket-reducer";
import { ALERTS } from "../reducers/component-reducer";
// import { ALERTS } from "../reducers/component-reducer";

const troubleTicketActions = {
  //  manager account starts here
 

  getTTDashboardList:
  (reset = true, args = "") =>
  async (dispatch, _) => {
    try {
      const res = await Api.get({
        url: `${Urls.TT_Dashboard_Table_data}${args != "" ? "?" + args : ""}`,
      });
      if (res?.status !== 200) return;
      let dataAll = res?.data?.data;
      console.log(dataAll, "sjdbk");
      dispatch(SET_TT_DASHBOARD({ dataAll, reset }));
    } catch (error) {}
  },
  

  postTTDashboardList: ( uniqueId=null ,data, cb,) => async (dispatch, _) => {
    try {
      const res = await Api.post({
        data: data,
        url:
          uniqueId === null
            ?
            Urls.Post_TT_Dashboard_Data
            : Urls.Post_TT_Dashboard_Data + "/" + uniqueId,
        contentType:"multipart/form-data"
      });
      if (res?.status !== 201 && res?.status !== 200) {
        let msgdata = {
          show: false,
          icon: "",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        console.log(msgdata,"1111")
        dispatch(ALERTS(msgdata));
      } else {
        console.log(res,"resdata")
        let msgdata = {
          show: true,
          icon: "",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        console.log(msgdata,"22222")
        dispatch(ALERTS(msgdata));
        cb();
      }
      
    } catch (error) {
      
      return;
    }
  },


// manage site  starts here
getManageSiteList:
(uniqueId=null,reset = true,cb, args = "") =>
   async (dispatch, _) => {
    try {
      const res = await Api.get({
        url: `${uniqueId === null
          ?
          Urls.Manage_Site_Data
          : Urls.Manage_Site_Data + "/" + uniqueId}${args != "" ? "?" + args : ""}`,
      });
      if (res?.status !== 200) return;
      let dataAll = res?.data?.data;
      console.log(dataAll, "bnuybvnk");
      dispatch(SET_MANAGE_SITE({ dataAll, reset }));
    } catch (error) {}
  },
// manage site  ends here

// manage site id starts here
getManageSiteIdList:
(uniqueId=null,reset = true,cb, args = "") =>
   async (dispatch, _) => {
    try {
      const res = await Api.get({
        url: `${Urls.Manage_Site_Data+"/"+uniqueId}`,
      });
      if (res?.status !== 200) return;
      let dataAll = res?.data?.data;
      console.log(dataAll, "hfcvrby");
      dispatch(SET_MANAGE_SITE_ID({ dataAll, reset }));
    } catch (error) {}
  },
// manage site id ends here

// manage Engineer  starts here
getManageEngineerList:
(uniqueId=null,reset = true,cb, args = "") =>
   async (dispatch, _) => {
    try {
      const res = await Api.get({
        url: `${uniqueId === null
          ?
          Urls.Manage_Engineer_Data
          : Urls.Manage_Engineer_Data + "/" + uniqueId}${args != "" ? "?" + args : ""}`,
      });
      if (res?.status !== 200) return;
      let dataAll = res?.data?.data;
      console.log(dataAll, "bnuybvnk");
      dispatch(SET_MANAGER_ENGINEER({ dataAll, reset }));
    } catch (error) {}
  },
// manage Engineer  ends here


};
export default troubleTicketActions;
