import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom'
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useDispatch, useSelector } from 'react-redux';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import { useStateManager } from 'react-select';
import { useNavigate } from 'react-router-dom';
import AlertsModal from "../../../../components/DATAPLUS_Components/AlertsModal"

const AddCustomerForm = ({ setModal, itm }) => {


  // states
  const [alerts, setAlerts] = useState(false)


  const dispatch = useDispatch();
  const navigate = useNavigate();

  // React hook form
  const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm();



  // accounts data
  useEffect(()=>{
    dispatch(adminDashboardActions.getManageAccountList())
  },[])

  const accountsData  =  useSelector((state)=> state?.AdminDashboard_reducer?.getManageAccountList)
  console.log(accountsData,"______datrattatat")

  const accountOptions  = accountsData.map((itm)=> {return {lable:itm.accountName, value:itm.uniqueid}})

  // customer Dtable data
  const { id } = useParams()
  let navigateLink = "/DATAPLUS/markets";
  const Data = useSelector((state) => state?.AdminDashboard_reducer?.getManageMarketsList)
  let item = Data.filter((itm) => {

    if (itm.uniqueid === id) {
      return itm
    }

  })
  item = item[0];




  // selecgt time zone
  const time = watch('timeZone');
  useEffect(() => {
    if (time === '') {
      setValue('timeZoneName', '')
    }
    else if (time === 'CST') {
      setValue('timeZoneName', 'Central Standard Time')
    }
    else if (time === 'EST') {
      setValue('timeZoneName', 'Eastern Standard Time')
    }
    else if (time === 'PST') {
      setValue('timeZoneName', 'Pacific Standard Time')
    }
    else if (time === 'HAST') {
      setValue('timeZoneName', 'Hawaii-Aleutian Standard Time')
    }
    else if (time === 'AKST') {
      setValue('timeZoneName', 'Alaska Standard Time')
    }
  }, [time, setValue])



  // Form
  const Form = [
    {
      label: "Account",
      value: "",
      name: "accountUniqueId",
      type: "select",
      option:accountOptions,
      required: true,
      disbabled: false
    },
    {
      label: "Market",
      value: "",
      name: "marketName",
      type: "text",
      disabled: false,
      required: true
    },
    {
      label: "Time Zone ",
      value: "",
      name: "timeZone",
      type: "select",
      control: control,
      option: [{ label: "PST", value: "PST" },
      { label: "EST", value: "EST" },
      { label: "CST", value: "CST" },
      { label: "HAST", value: "HAST" },
      { label: "AKST", value: "AKST" }
      ],
      placeholder: "Enter Time Zone Name",
      required: true
    },
    {
      label: "Time Zone Name ",
      value: "",
      name: "timeZoneName",
      type: "text",
      required: true,
      disabled: true
    },

  ]



  // form Submission
  const onSubmit = (data) => {
    console.log(data, "___customerData");

    if (item) {

      console.log("called", "kabsd")
      dispatch(adminDashboardActions.postManageMarketsList( data, () => {
        dispatch(adminDashboardActions.getManageMarketsList())
      },item.uniqueid))
    }
    else {
      console.log("called", "dfcgvh")
      dispatch(adminDashboardActions.postManageMarketsList(data, () => {
        dispatch(adminDashboardActions.getManageMarketsList())
      },""))
    }


    setTimeout(() => {
      setAlerts(true)
    }, 1000)

  };



  // pre fill tehd data
  useEffect(() => {
    if (item?.uniqueid) {
      Form.forEach((key) => {
        if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
          setValue(key.name, item[key.name]["$oid"]);

        }
        else {
          setValue(key.name, item[key.name]);
        }

      })
    }
  }, [item?.uniqueid])






  return (
    <>
    {/* alert */}
      {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink} />}


{/* common form */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <DATAPLUS_CommonForm Form={Form} errors={errors} register={register} />
        <div style={{ width: 'full', display: 'flex', gap: '10px', justifyContent: 'center', marginTop: "20px" }}>
          <button style={{ margintop: "10" }} className='btn btn-info' onClick={() => navigate(-1)}>
            Back
          </button>

          <button style={{ margintop: "10" }} type='submit' className='btn btn-primary'>
            Submit
          </button>

        </div>
      </form>
    </>
  )
}

export default AddCustomerForm