import React,{useState} from 'react'
import FilteringTable from '../../../components/table/FilteringTable/FilteringTable';
import { ColumnFilter } from '../../../components/table/FilteringTable/ColumnFilter';
import FormModal from '../../../components/DATAPLUS_Components/FormModal';
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Modal,
  ProgressBar,
  Button,
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';


const Tickets = () => {

  const navigate  = useNavigate();

    const tableHeading = 'Tickets'
    const[form,setForm]  = useState(false)
    const[actions,setActions]=useState(false)
    const[callDelete,setCallDelete] = useState(false)
    const [itmValue, setItmValue] = useState({})
    const FormComponent ="ksjvajds"
    

    const filterHeading  = "Tickets Filter"
    const modalButton = useState("")
    const showButton  =  "Tickets"
    const AddFormButton = (( <Button style={{}}
      variant="primary"
      className="close"
      onClick={() => navigate("/DATAPLUS/addTicket")}
    >
      New Request
    </Button>))
    const tableColumns = [
        {
          Header: 'S No.',
          // Footer: 'S No',
          accessor: 'sNo',
          Filter: "",
        },
        {
          Header: '#id',
          // Footer: 'account',
          accessor: "accountName",
          Filter: ColumnFilter,
        },
        {
          Header: 'Site ID',
          // Footer: 'S No',
          accessor: 'marketName',
          Filter: ColumnFilter,
        },
        {
          Header: 'Small Cell',
          // Footer: 'account',
          accessor: "mobilenumber",
          Filter: ColumnFilter,
        },
        {
          Header: 'Activity Type',
          // Footer: 'S No',
          accessor: 'name',
          Filter: ColumnFilter,
        },
        {
          Header: 'Mark',
          // Footer: 'account',
          accessor: "crewCompanyName",
          Filter: ColumnFilter,
        },
        {
          Header: 'Owner',
          // Footer: 'S No',
          accessor: 'email',
          Filter: "",
        },
        {
          Header: 'Nest-In',
          // Footer: 'account',
          accessor: "mobileAppAccess",
          Filter: "",
        },
        {
          Header: 'Status',
          // Footer: 'S No',
          accessor: 'password',
          Filter: "",
        },
        {
          Header: 'UnNest',
          // Footer: 'account',
          accessor: "userrole",
          Filter: "",
        },
        {
          Header: 'SOW',
          // Footer: 'S No',
          accessor: 'crewType',
          Filter: "",
        },
        {
          Header: 'Pag Status',
          // Footer: 'account',
          accessor: "",
          Filter: "",
        },
    
    
      ]
    
    
    
      const GROUPED_COLUMNS = [
        {
          Header: 'Id',
          Footer: 'Id',
          accessor: 'id'
        },
        {
          Header: 'Name',
          Footer: 'Name',
          columns: [
            {
              Header: 'First Name',
              Footer: 'First Name',
              accessor: 'first_name'
            },
            {
              Header: 'Last Name',
              Footer: 'Last Name',
              accessor: 'last_name'
            },
          ]
        },
        {
          Header: 'Info',
          Footer: 'Info',
          columns: [
            {
              Header: 'Date of  Birth',
              Footer: 'Date of  Birth',
              accessor: 'date_of_birth'
            },
            {
              Header: 'Country',
              Footer: 'Country',
              accessor: 'country',
            },
            {
              Header: 'Phone',
              Footer: 'Phone',
              accessor: 'phone'
            },
          ]
        },
      ]
  return (
   <>
    


   
     
      {/* {modal && <EditModal modalHeading={modalHeading} setModal={setModal} isOpen={modal} FormComponent={FormComponent} itmValue={itmValue} />} */}

      
       {form && <FormModal modalHeading={modalButton} isOpen={form} setModal={setForm} FormComponent={FormComponent} itmValue={itmValue}/>}

      {/* <MPAG_Modal modalButton={modalButton} FormComponent={FormComponent} /> */}
      {/* <MPAG_Modal tableColumns={tableColumns} tableData={tableData}  /> */}
  {/* <FilterModal FormComponent={Filter} tableColumns={tableColumns} tableData={tableData} modalButton={filterButton}/> */}
  <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4>
      <div>
        <FilteringTable showButton={showButton} AddFormButton={AddFormButton}  tableColumns={tableColumns} tableData={["a","b"]} setForm={setForm} tableHeading={tableHeading} />
      </div>
   </>
  )
}

export default Tickets