import React, { useEffect, useState } from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable'
import { useDispatch, useSelector } from 'react-redux'
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import E911CallTestActions from '../../../../../store/actions/E911CallTest-actions';
import { Row, Col, Card, Button, Dropdown, ButtonGroup, Modal } from 'react-bootstrap'
import CommonActions from '../../../../../store/actions/common-actions';
import { Urls } from '../../../../../utils/url';
import { ALERTS } from '../../../../../store/reducers/component-reducer';
import deleteLogo from "../../../../../images/deleteLogo.png"
import ActionsModal from '../../../../components/DATAPLUS_Components/ActionsModal';

const PSAPDashboard = () => {

 
  const[actions,setActions]=useState(false)
  const[callDelete,setCallDelete] = useState(false)
  const [itmValue, setItmValue] = useState({})


  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(E911CallTestActions.getPSAPDashboardList())
  },[])

  const data  =  useSelector((state)=>state?.E911CallTestReducer?.getPSAPDashboardList)

  console.log(data, "ajsbhdkb")

  let itemUniqueId = ""
  let tableData  = data.map((itm,index)=>{
    itemUniqueId = itm.ranSupportId
    return {
      "sNO":index+1,
      "addDate":itm.addDate,
      "siteUniqueId":itm.siteUniqueId,
      "PACEID":itm.PACEID,
      "Scope":itm.Scope,
      "bandRequested":itm.bandRequested,
      "account":itm.account,
      "market":itm.market,
      "alphaSector":itm.alphaSector,
      "betaSector":itm.betaSector,
      "gammaSector":itm.gammaSector,
      "deltaSector":itm.deltaSector,
      "epsilonSector":itm.epsilonSector,
      "zetaSector":itm.zetaSector,
      'engineerSupportingPSAP':itm.engineerSupportingPSAP,
      "integrationDate":itm.integrationDate,
      "PSPADate":itm.PSPADate,
      "PSAPStatus":itm.PSAPStatus,
      "remarks":itm.remarks,
      'enooid':itm.enooid,
      "mobilenumber":itm.crew_status.mobilenumber,
      "name":itm.crew_status.name,
      "email":itm.crew_status.email,
      "actions":((  <Dropdown>
        <Dropdown.Toggle
          variant="danger"
          className="light sharp i-false"
          onClick={() => {
            setActions(true)
            // dispatch(
            //   CommonActions.deleteApiCaller(
            //     `${Urls.Manage_E911_Dashboard_Data}/${itm.ranSupportId

            //     }`,
            //     () => {
            //       dispatch(E911CallTestActions.getPSAPDashboardList())
            //       dispatch(ALERTS({ show: false }));
            //     }
            //   )
            // );
           
          }}
        >
          <img src={deleteLogo}/>
        </Dropdown.Toggle>
        {/* <Dropdown.Menu>
          <Dropdown.Item>Edit</Dropdown.Item>
          <Dropdown.Item >Delete</Dropdown.Item>
        </Dropdown.Menu> */}
      </Dropdown>))
    }
  })
  
  const tableHeading =  "PSAP Dashboard"
  const tableColumns = [
    {
      Header:"S No.",
      accessor:"sNO"
    },
    {
      Header : 'DATE OF ADD',
      // Footer : 'S No',
      accessor: 'addDate'
    },
    {
      Header : 'SITE ID',
      // Footer : 'account',
      accessor: "siteUniqueId"
    },
    {
      Header : 'PACE ID',
      // Footer : 'account',
      accessor: "PACEID"
    },
    {
      Header : 'SCOPE',
      // Footer : 'account',
      accessor: "Scope"
    },
    {
      Header : 'BAND REQUESTED',
      // Footer : 'account',
      accessor: "bandRequested"
    },
    {
      Header : 'ACCOUNT ',
      // Footer : 'account',
      accessor: "account"
    },
    {
      Header : 'MARKET',
      // Footer : 'account',
      accessor: "market"
    },
    {
      Header : 'ALPHA SECTOR',
      // Footer : 'account',
      accessor: "alphaSector"
    },
    {
      Header : 'BETA SECTOR',
      // Footer : 'account',
      accessor: "betaSector"
    },
    {
      Header : 'GAMMA SECTOR',
      // Footer : 'account',
      accessor: "gammaSector"
    },
    {
      Header : 'DELTA SECTOR',
      // Footer : 'account',
      accessor: "deltaSector"
    },
    {
      Header : 'EPSILON SECTOR ',
      // Footer : 'account',
      accessor: "epsilonSector"
    },
    {
      Header : 'ZETA SECTOR',
      // Footer : 'account',
      accessor: "zetaSector"
    },
    {
      Header : 'REMOTE ENGG',
      // Footer : 'S No',
      accessor: 'engineerSupportingPSAP'
    },

    {
      Header : 'INTEGRATION DATE',
      // Footer : 'S No',
      accessor: 'integrationDate'
    },
    {
      Header : 'PSAP DATE',
      // Footer : 'account',
      accessor: "PSPADate"
    },
    {
      Header : 'PSAP STATUS',
      // Footer : 'account',
      accessor: "PSAPStatus"
    },
    {
      Header : 'REMARKS',
      // Footer : 'account',
      accessor: "remarks"
    },
    {
      Header : 'PSAP NUMBER',
      // Footer : 'account',
      accessor: "enooid"
    },
    {
      Header : 'CREW NUMBER ',
      // Footer : 'account',
      accessor: "mobilenumber"
    },
    {
      Header : 'CREW NAME',
      // Footer : 'account',
      accessor: "name"
    },
    {
      Header : 'PSAP DONE BY : IX CREW LEAD E-MAIL ID',
      // Footer : 'account',
      accessor: "email"
    },
    {
      Header : 'ACTIONS',
      // Footer : 'account',
      accessor: "actions"
    },
  
  ]

if(callDelete){
    dispatch(
      CommonActions.deleteApiCaller(
        `${Urls.Manage_E911_Dashboard_Data}/${itemUniqueId

        }`,
        () => {
          dispatch(E911CallTestActions.getPSAPDashboardList())
          dispatch(ALERTS({ show: false }));
        }
      )
    );
    setCallDelete(false)
  
  }


  return(
    <>
    {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
    {actions && <ActionsModal  actions={actions} setCallDelete={setCallDelete} setActions={setActions} />}
        <div>
      <SortingTable tableColumns={tableColumns} tableData={tableData} tableHeading ={tableHeading}/>
    </div>
    </>
  )

}

export default PSAPDashboard