import { createSlice } from "@reduxjs/toolkit";

const initialState = {

    getGoNoGoSupportList:[]
    
    

};


const GoNoGoSupportData = createSlice({

    name:"GoNoGoSupportData",
    initialState,
    reducers:{

        SET_GONOGO_SUPPORT: (state, { payload }) => {
            if (payload.reset) {
            state.getGoNoGoSupportList = payload.dataAll;
            } else {
            state.getGoNoGoSupportList = [
                ...state.getGoNoGoSupportList,
                ...payload.dataAll,
            ];
            }
        },
     

    }
})

export const {SET_GONOGO_SUPPORT} = GoNoGoSupportData.actions;

export default GoNoGoSupportData.reducer;