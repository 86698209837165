import { createSlice } from "@reduxjs/toolkit";

const initialState = {

    getManageAccountList:[],
    getManageMarketsList:[],
    getTimeZoneList:[],
    getGCList:[],
    getUTRANEngineersList:[],
    getManageCrewCompanyList:[],
    getManageListRoleTwoList:[],
    getOSSList:[],
    getProjectDatabaseList:[],
    getSiteIdList:[],
    getCustomerList:[],
    getEngineerList:[],
    getFilteredMarkets:[],

    
    

};


const AdminDashboardData = createSlice({

    name:"AdminDashboardData",
    initialState,
    reducers:{

        // project Database

        SET_PROJECT_DATABASE: (state, { payload }) => {
            if (payload.reset) {
            state.getProjectDatabaseList = payload.dataAll;
            } else {
            state.getProjectDatabaseList = [
                ...state.getProjectDatabaseList,
                ...payload.dataAll,
            ];
            }
        },
        SET_CUSTOMER: (state, { payload }) => {
            if (payload.reset) {
            state.getCustomerList = payload.dataAll;
            } else {
            state.getCustomerList = [
                ...state.getCustomerList,
                ...payload.dataAll,
            ];
            }
        },
        SET_ENGINEER: (state, { payload }) => {
            if (payload.reset) {
            state.getEngineerList = payload.dataAll;
            } else {
            state.getEngineerList = [
                ...state.getEngineerList,
                ...payload.dataAll,
            ];
            }
        },

         // manage account reducer
        SET_MANAGE_ACCOUNT: (state, { payload }) => {
            if (payload.reset) {
            state.getManageAccountList = payload.dataAll;
            } else {
            state.getManageAccountList = [
                ...state.getManageAccountList,
                ...payload.dataAll,
            ];
            }
        },

        // manage market reducer
        SET_MANAGE_MARKETS: (state, { payload }) => {
            if (payload.reset) {
            state.getManageMarketsList = payload.dataAll;
            } else {
            state.getManageMarketsList = [
                ...state.getManageMarketsList,
                ...payload.dataAll,
            ];
            }
        },

        // manage filtered market
        SET_FILTERED_MARKETS: (state, { payload }) => {
            if (payload.reset) {
            state.getFilteredMarkets = payload.dataAll;
            } else {
            state.getFilteredMarkets = [
                ...state.getFilteredMarkets,
                ...payload.dataAll,
            ];
            }
        },

        // manage TIME ZONE reducer
         
          SET_TIME_ZONE: (state, { payload }) => {
            if (payload.reset) {
            state.getTimeZoneList = payload.dataAll;
            } else {
            state.getTimeZoneList = [
                ...state.getTimeZoneList,
                ...payload.dataAll,
            ];
            }
        },

        // add GC

        SET_GC: (state, { payload }) => {
            if (payload.reset) {
            state.getGCList = payload.dataAll;
            } else {
            state.getGCList = [
                ...state.getGCList,
                ...payload.dataAll,
            ];
            }
        },

        // add oss
        SET_OSS: (state, { payload }) => {
            if (payload.reset) {
            state.getOSSList = payload.dataAll;
            } else {
            state.getOSSList = [
                ...state.getOSSList,
                ...payload.dataAll,
            ];
            }
        },
        SET_SITE_ID: (state, { payload }) => {
            if (payload.reset) {
            state.getSiteIdList = payload.dataAll;
            } else {
            state.getSiteIdList = [
                ...state.getSiteIdList,
                ...payload.dataAll,
            ];
            }
        },

          // add UTRAN Engineers

          SET_UTRAN_ENGINEERS: (state, { payload }) => {
            if (payload.reset) {
            state.getUTRANEngineersList = payload.dataAll;
            } else {
            state.getUTRANEngineersList = [
                ...state.getUTRANEngineersList,
                ...payload.dataAll,
            ];
            }
        },

       

          // add Manage crew company

          SET_MANAGE_CREW_COMPANY: (state, { payload }) => {
            if (payload.reset) {
            state.getManageCrewCompanyList = payload.dataAll;
            } else {
            state.getManageCrewCompanyList = [
                ...state.getManageCrewCompanyList,
                ...payload.dataAll,
            ];
            }
        },

         // add list user role two data

         SET_LIST_USER_ROLE_TWO: (state, { payload }) => {
            if (payload.reset) {
            state.getManageListRoleTwoList = payload.dataAll;
            } else {
            state.getManageListRoleTwoList = [
                ...state.getManageListRoleTwoList,
                ...payload.dataAll,
            ];
            }
        },

        // Manage_CIQ_Sites_KPI data

        

        
    }
})

export const {SET_FILTERED_MARKETS,SET_ENGINEER,SET_CUSTOMER,SET_SITE_ID,SET_PROJECT_DATABASE,SET_MANAGE_ACCOUNT,SET_MANAGE_MARKETS,SET_TIME_ZONE,
    SET_GC,SET_UTRAN_ENGINEERS,
    SET_MANAGE_CREW_COMPANY,SET_LIST_USER_ROLE_TWO,SET_CIQ_SITES_KPI,SET_CIQ_SITES_KPI_ID,SET_OSS} = AdminDashboardData.actions;

export default AdminDashboardData.reducer;