import React, { useEffect, useState } from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable'
import MPAG_Modal from '../../../../components/DATAPLUS_Components/DATAPLUS_Modal'
import TTDashboardForm from './TTDashboardForm'
import { useDispatch, useSelector } from 'react-redux'
import troubleTicketActions from '../../../../../store/actions/TroubleTicket-actions'
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable'
import deleteLogo from "../../../../../images/deleteLogo.png"
import { Row, Col, Card, Button, Dropdown, ButtonGroup, Modal } from 'react-bootstrap'
import { Urls } from '../../../../../utils/url'
import CommonActions from '../../../../../store/actions/common-actions'
import { ALERTS } from '../../../../../store/reducers/component-reducer'
import TTDashboardAddComments from '../TTDashboardAddComments'
import EditModal from '../../../../components/EditModal'
import FormModal from '../../../../components/DATAPLUS_Components/FormModal'

const TTDashboard = () => {

  const[form,setForm]  = useState(false)
  const[actions,setActions]=useState(false)
  const[button,setButton]= useState(false)
  const [modal, setModal] = useState(false)
  const [itmValue, setItmValue] = useState({})
  let buttonOpen = "warning"
  let buttonClose ="danger"
  const dispatch = useDispatch();
  const AddCommentsForm  =  (TTDashboardAddComments)
  const modalHeading  =  "Add Trouble Ticket"
  const commentsModalHeading  = "Add Notes"
  let uniuqeId = ""

  useEffect(()=>{
   dispatch(troubleTicketActions.getTTDashboardList())
  },[])

    const data =  useSelector((state)=> state?.TroubleTicketReducer?.getTTDashboardList)
    console.log(data,"jhfbtvy")

    let tableData  = data.map((itm)=>{
      // uniuqeId = itm.ttUniqueId
      return {
        "ttOpenDate":itm.ttOpenDate,
        "weekNumber":itm.weekNumber,
        "account":itm.account,
        "market":itm.market,
        "siteId":itm.siteId, 
        "ATTID":itm.ATTID,
        "tt_desc":itm.tt_desc,
        "ttStatus":itm.ttStatus,
        "engineerOpeningtt":itm.engineerOpeningtt,
        "ttid":itm.ttid,
        "ttCloseDate":itm.serverttCloseDate,
        "ttNoteComments":((  <Dropdown>
          
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant={!itm.ttNoteComments && 'warning'}
            className="light sharp i-false"
            onClick={() => {
              
              setModal(true)
              setItmValue(itm)
             
            }}
          >
            {itm.ttNoteComments ? itm.ttNoteComments : "Add Comments"}
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>)),
        "actions":((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant= {itm.ttCloseDate ? buttonOpen : buttonClose}
            className="light sharp i-false"
            onClick={() => {
              const data ={
                engineerClosingtt:"",
                ttstatus: itm.ttStatus,
              }
              console.log(data,"gjcasvkbdj")
              setButton((prev)=>!prev)
              dispatch(
                troubleTicketActions.postTTDashboardList(
                  itm.ttUniqueId,data,
                  () => {
                    dispatch(troubleTicketActions.getTTDashboardList())
                    dispatch(ALERTS({ show: false }));
                  }
                )
              );
            }}
            
          >
            {!itm.ttCloseDate ? "Close":"Open"}
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>))
    }
    })

const tableHeading =  "TT Dashboard"
const FormComponent = (TTDashboardForm)
const modalButton = "+ Add Trouble Ticket"
const AddFormButtonName= '+ Ticket'
  const AddFormNavigate ="/DATAPLUS/Add/trouble/Ticket/dashboard"

  const AddFormButton=''
// ( <Button style={{marginLeft:'10px'}}
// variant="primary"
// className="close"
// onClick={() => setForm(true)}
// >
// Add Trouble Ticket
// </Button>)
  const tableColumns = [
    {
      Header : 'TT OPEN DATE',
      // Footer : 'S No',
      accessor: 'ttOpenDate',
      Filter: "",
    },
    {
      Header : 'WEEK#',
      // Footer : 'account',
      accessor: "weekNumber",
      Filter: "",
    },
    {
      Header : 'ACCOUNT',
      // Footer : 'account',
      accessor: "account",
      Filter: "",
    },
    {
      Header : 'Market',
      // Footer : 'account',
      accessor: "market",
      Filter: "",
    },
    {
      Header : 'SITE ID',
      // Footer : 'account',
      accessor: "siteId",
      Filter: ColumnFilter,
    },
    {
      Header : 'ATT ID ',
      // Footer : 'account',
      accessor: "ATTID",
      Filter: ColumnFilter,
    },
    {
      Header : 'TT NOTES',
      // Footer : 'account',
      accessor: "tt_desc",
      Filter: "",
    },
    {
      Header : 'TT STATUS',
      // Footer : 'account',
      accessor: "ttStatus",
      Filter: ColumnFilter,
    },
    {
      Header : 'ENGINEER',
      // Footer : 'account',
      accessor: "engineerOpeningtt",
      Filter: "",
    },
    {
      Header : 'TT NUMBER',
      // Footer : 'account',
      accessor: "ttid",
      Filter: ColumnFilter,
    },
    {
      Header : 'TT CLOSED DATE ',
      // Footer : 'account',
      accessor: "ttCloseDate",
      Filter: "",
    },
    {
      Header : 'TT NOTES ',
      // Footer : 'account',
      accessor: "ttNoteComments",
      Filter: "",
    },
    {
      Header : 'Actions',
      // Footer : 'account',
      accessor: "actions",
      Filter: "",
    },
   
  ]
  console.log(tableColumns,'tableColumns')
console.log(tableData,"yvdtysutbdyas")

  return(
    <>
   
    {form && <FormModal modalHeading={modalHeading}  isOpen={form} setModal={setForm} FormComponent={FormComponent} itmValue={itmValue}/>}

    {/* {modal && <EditModal  modalHeading={modalHeading}  setModal={setModal} isOpen={modal} FormComponent={AddCommentsForm} itmValue={itmValue} />} */}
        {/* <MPAG_Modal  modalButton={modalButton} FormComponent={FormComponent}/> */}
        {modal &&  <FormModal  modalHeading={commentsModalHeading} isOpen={modal} modal ={modal} setModal={setModal} FormComponent={AddCommentsForm} itmValue={itmValue}/>}

        {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
        <div>
      <FilteringTable AddFormNavigate={AddFormNavigate}  AddFormButtonName={AddFormButtonName} AddFormButton={AddFormButton} tableColumns={tableColumns} tableData={tableData} tableHeading ={tableHeading}/>
    </div>
    </>
  )

}

export default TTDashboard