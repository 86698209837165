import React, { useEffect, useState } from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable'
import KPITroubleTicketForm from './KPITroubleTicketForm'
import MPAG_Modal from '../../../../components/DATAPLUS_Components/DATAPLUS_Modal'
import { useDispatch, useSelector } from 'react-redux'
import KPIModuleActions from '../../../../../store/actions/KPIModule-actions'
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable'
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter'
import editIcon from "../../../../../images/editIcon.png"
import { Row, Col, Card, Button, Dropdown, ButtonGroup, Modal } from 'react-bootstrap'
import { Urls } from '../../../../../utils/url'
import CommonActions from '../../../../../store/actions/common-actions'
import { ALERTS } from '../../../../../store/reducers/component-reducer'
import EditModal from '../../../../components/EditModal'
import FormModal from '../../../../components/DATAPLUS_Components/FormModal'
import { Link } from 'react-router-dom'


const KPITroubleTicket = () => {
  const [modal, setModal] = useState(false)
  const [form, setForm] = useState(false)
  const [actions, setActions] = useState(false)
  const [callDelete, setCallDelete] = useState(false)
  const [itmValue, setItmValue] = useState({})

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(KPIModuleActions.getKPITroubleTicketList())

  }, [])


  const data = useSelector((state) => state?.KPIModuleReducer?.getKPITroubleTicketList)
  console.log(data, "hjfbyj")



  let tableData = data.map((itm) => {
    return {
      ...itm,
      "actions": ((<Dropdown>
        <Dropdown.Toggle
          variant="primary"
          className="light sharp i-false"
          onClick={() => {
            setModal(true)
            setItmValue(itm)
          }}
        >
          <Link to={`/DATAPLUS/edit/kpi/trouble/Ticket/dashboard/${itm.ttUId}`}><img src={editIcon} /></Link>
        </Dropdown.Toggle>
        {/* <Dropdown.Menu>
          <Dropdown.Item>Edit</Dropdown.Item>
          <Dropdown.Item >Delete</Dropdown.Item>
        </Dropdown.Menu> */}
      </Dropdown>))
    }
  })

  const tableHeading = "KPI Trouble Ticket"
  const FormComponent = (KPITroubleTicketForm)
  const modalButton = "+ Add Trouble Ticket"
  const modalHeading = "Edit Trouble Ticket"
  let showFilter = false

  const AddFormButtonName = '+ Trouble Ticket'
  const AddFormNavigate = '/DATAPLUS/Add/kpi/trouble/Ticket/dashboard'
  const AddFormButton = ''
  // ( <Button style={{margin:"10px"}}
  // variant="primary"
  // className="close"
  // onClick={() => setForm(true)}
  // >
  // Add Trouble Ticket
  // </Button>)


  const tableColumns = [
    {
      Header: 'KPI TROBLE TICKET ID',
      // Footer: 'S No',
      accessor: 'KPIttid',
      Filter: ColumnFilter,
    },
    {
      Header: 'ACCOUNT',
      // Footer: 'account',
      accessor: "account",
      Filter: ColumnFilter,
    },
    {
      Header: 'MARKET',
      // Footer: 'S No',
      accessor: 'market',
      Filter: ColumnFilter,
    },
    {
      Header: 'SITE ID',
      // Footer: 'account',
      accessor: "siteUniqueId",
      Filter: ColumnFilter,
    },
    {
      Header: 'ATT ID',
      // Footer: 'S No',
      accessor: 'ATTID',
      Filter: ColumnFilter,
    },
    {
      Header: 'USID',
      // Footer: 'account',
      accessor: "usid",
      Filter: ColumnFilter,
    },
    {
      Header: 'CONFIGURATION',
      // Footer: 'S No',
      accessor: 'configuration',
      Filter: ColumnFilter,
    },
    {
      Header: 'LOGS',
      // Footer: 'account',
      accessor: "logs",
      Filter: ColumnFilter,
    },
    {
      Header: 'ISSUE',
      // Footer: 'S No',
      accessor: 'KPITTIssue',
      Filter: ColumnFilter,
    },
    {
      Header: 'PRIORITY',
      // Footer: 'account',
      accessor: "priority",
      Filter: ColumnFilter,
    },
    {
      Header: 'KPI ENGINEERS ASSIGNED',
      // Footer: 'S No',
      accessor: 'KPITTEngineer',
      Filter: ColumnFilter,
    },
    {
      Header: 'OBSERVATION & RECOMMENDATION',
      // Footer: 'account',
      accessor: "observationRecommendation",
      Filter: ColumnFilter,
    },


    {
      Header: 'TICKET STATUS',
      // Footer: 'S No',
      accessor: 'KPITTStatus',
      Filter: ColumnFilter,
    },
    {
      Header: 'KPI TROUBLE TICKET  CLOSURE DATE',
      // Footer: 'account',
      accessor: "serverttCloseDate",
      Filter: ColumnFilter,
    },
    {
      Header: 'ACTIONS',
      // Footer: 'S No',
      accessor: 'actions',
      Filter: "",
    },
  ]

  const GROUPED_COLUMNS = [
    {
      Header: 'Id',
      Footer: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Name',
      Footer: 'Name',
      columns: [
        {
          Header: 'First Name',
          Footer: 'First Name',
          accessor: 'first_name'
        },
        {
          Header: 'Last Name',
          Footer: 'Last Name',
          accessor: 'last_name'
        },
      ]
    },
    {
      Header: 'Info',
      Footer: 'Info',
      columns: [
        {
          Header: 'Date of  Birth',
          Footer: 'Date of  Birth',
          accessor: 'date_of_birth'
        },
        {
          Header: 'Country',
          Footer: 'Country',
          accessor: 'country',
        },
        {
          Header: 'Phone',
          Footer: 'Phone',
          accessor: 'phone'
        },
      ]
    },
  ]
  return (
    <>
      {/* <Button style={{margin:"10px"}}
                        variant="primary"
                        className="close"
                        onClick={() => setForm(true)}
                    >
                        Add Trouble Ticket
                    </Button> */}

      {/* {modal && <FormModal modalHeading={modalHeading} isOpen={modal} setModal={setModal}
        FormComponent={FormComponent} itmValue={itmValue} />} */}
      {/* <MPAG_Modal  modalButton={modalButton} FormComponent={FormComponent}/> */}
      {/* {form && <FormModal modalHeading={modalHeading} isOpen={form} setModal={setForm}
        FormComponent={FormComponent} itmValue={itmValue} />} */}

      {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
      <div>
        <FilteringTable AddFormNavigate={AddFormNavigate}
          AddFormButtonName={AddFormButtonName} AddFormButton={AddFormButton}
          showFilter={showFilter} tableColumns={tableColumns} tableData={tableData} tableHeading={tableHeading} />
      </div>
    </>
  )

}

export default KPITroubleTicket