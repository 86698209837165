import React, { useState } from 'react'
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";



const FilterModal = ({FilteringComponent,modalButton}) => {
    const [largeModal, setLargeModal] = useState(false);


  return (
    <>
    {/* { console.log(tableColumns,"tableadsd1Colums")} */}
     <div className="h-80">
       
        {/* <PageTitle motherMenu={"Bootstrap"} activeMenu={"Modal"} /> */}
        <Row>
           <Col>
           <Button
                          variant="primary"
                          className="filter-modal-button"
                          style={{fontSize:"smaller"}}
                          onClick={() => setLargeModal(true)}
                       >
                          {modalButton}
                       </Button>
                       
                       <Modal
                          className="fade bd-example-modal-lg"
                          show={largeModal}
                          size="lg"
                       >
                          <Modal.Header  style={{backgroundColor:'#470468'}}>
                             {/* <Modal.Title>Modal title</Modal.Title> */}
                             Filter
                             <Button
                                variant=""
                                className="close"
                                onClick={() => setLargeModal(false)}
                             >
                                <span>&times;</span>
                             </Button>
                          </Modal.Header>
                          <Modal.Body style={{backgroundColor:"white"}}>

                          {/* form */}

                          <div className='grid-cols-4 col-lg-12'>
         <div className='card'>
           <div className='card-body'>
             <div className='basic-form'>

             {FilteringComponent}
             
             <div style={{width:"100%",textAlign:"right",backgroundColor:'#FEF9FF'}}>
                           <Button
                           style={{width:"100px", margin:"10px"}}
                           onClick={
                                ()=>{setLargeModal(false)}}>Filter</Button>
                           </div>
                          
             </div>
           </div>
         </div>
       </div>
                          {/* form end */}
                          
                          </Modal.Body>
                          
                       </Modal>

           </Col>
        </Row>
     </div>
     </>
  )
}

export default FilterModal