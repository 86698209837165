import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import {
  loadingToggleAction, loginAction,
} from '../../store/actions/AuthActions';

//
import logo from '../../images/Network_Technology_Logo-removebg-preview.png'
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";
import AuthActions from '../../store/actions/auth-actions';

function Login(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('demo@example.com');
  let errorsObj = { email: '', password: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState('123456');
  const dispatch = useDispatch();







  useEffect(() => {
    // Add class to body when component mounts
    document.body.classList.add('no-scroll');

    // Cleanup function to remove class when component unmounts
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    }
    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));

    //  dispatch(AuthActions.signIn())
    dispatch(loginAction(email, password, navigate));
  }
  const imageUrl = './back.jpg';
  const backgroundStyle = {
    filter: 'none', // Ensure no blur effect
    WebkitFilter: 'none', // For Safari
    MozFilter: 'none', // For Firefox
    MsFilter: 'none', // For IE
    OFilter: 'none', // For Opera
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${imageUrl})`, // Use props to pass imageUrl
    // backgroundSize: '50% 5%',
    backgroundPosition: '50% 5%',
    // backgroundRepeat: 'no-repeat',
    zIndex: 1,
  };

  const containerStyle = {
    filter: 'none', // Ensure no blur effect
    WebkitFilter: 'none', // For Safari
    MozFilter: 'none', // For Firefox
    MsFilter: 'none', // For IE
    OFilter: 'none', // For Opera
    // marginTop: "10px",
    display: 'grid', // Enable grid display
    placeItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  };

  const logoStyle = {
    width: '200px',
    height: '200px',
    zIndex: 1,
    marginBottom:"20px",
    paddingBottom:"40px"
  };

  const formContainerStyle = {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    color: 'black',
  };

  return (
    <div className="login-main-page" style={backgroundStyle}>
      <div style={containerStyle}>
        <div style={formContainerStyle}>
          <img src="./siteLogo.png" alt="Site Logo" style={logoStyle} />
          <div className="auth-form-1">
            <div className="mb-4" style={{ textAlign: "center" }}>
              <h3  style={{ color: "white !important",marginTop:'5px' }}>Sign in to your account</h3>
            </div>
            {props.errorMessage && (
              <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                {props.errorMessage}
              </div>
            )}
            {props.successMessage && (
              <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                {props.successMessage}
              </div>
            )}
            <form onSubmit={onLogin}>
              <div className="form-group" >
                <label className="mb-2" style={{ fontWeight: "600",color:"white"  }}>Username</label>
                <input type="email" className="form-control" style={{ borderRadius: "5px", width: "300px", height: "30px",backgroundColor:"white",color:"black"  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
              </div>
              <div className="form-group">
                <label className="mb-2" style={{ fontWeight: "600",color:"white" }}>Password</label>
                <input type="password" className="form-control" style={{ borderRadius: "5px", width: "300px", height: "30px",backgroundColor:"white",color:"black" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
              </div>
              {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                <div className="form-group"></div>
              </div> */}
              <div className="text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <button type="submit" className="btn btn-primary btn-block" style={{ borderRadius: "5px", width: "100px", height: "30px" }}>
                  Submit
                </button>
              </div>
            </form>
            <div className="new-account mt-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  // console.log
  return {
    errorMessage: state?.auth?.errorMessage,
    successMessage: state?.auth?.successMessage,
    showLoading: state?.auth?.showLoading,
  };
};
export default connect(mapStateToProps)(Login);





