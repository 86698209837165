import React ,{useState} from 'react';
import { Row, Card, Col,Button,Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './DATAPLUS_cards.css'

const DATAPLUS_cards = ({approvedData}) => {


  const [showModal, setShowModal] = useState(false);
  const [currentCard, setCurrentCard] = useState('');
  const [currentLinks, setCurrentLinks] = useState([]);

  const navigate = useNavigate()


  function handleShowModal(cardType, links) {
    setCurrentCard(cardType);
    setCurrentLinks(links);
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
    setCurrentCard(' ');
  }
  function handleReport(){
    // alert('hello')
  }
  return (
    <div className='card-row'>
        {approvedData.map((itm)=>{
          return (<>
            <Col id='mopData' >
          <Card className='card-item'>
            <Col style={{ display: 'flex', alignItems: 'center',marginLeft:'10px' }}>
              {/* <i className="bi bi-arrow-left-right" ></i> */}
              <i class={itm.icon} style={{ fontSize: '24px', marginRight: '5px',color:'black',}}></i>
              <Card.Body>
                <Card.Text>
                  {itm.direct ? <Link to={itm.directLink} className={itm.nameCss} >
                    {itm.name}
                  </Link> :<Link to="#" className={itm.nameCss} onClick={() =>  handleShowModal(itm.handleValue,itm.links)}>
                    {itm.name}
                  </Link>}
                </Card.Text>
              </Card.Body>
            </Col>
          </Card>
        </Col>
        
      

        <Modal backdropClassName='custom-modal-backdrop' show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{currentCard}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {currentLinks.map((bar, idx) => (
            <Button key={idx} variant="light" style={{ marginLeft: '40px', marginBottom: "10px", width: '80%' }} onClick={handleCloseModal}>
              <Link to={bar.link} style={{ color: 'black', textDecoration: 'none' }}>
                {bar.linkName}
              </Link>
            </Button>
          ))}
        </Modal.Body>
      </Modal>
          </>)
      })}
    </div>
  );
};

export default DATAPLUS_cards;
