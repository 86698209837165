import Api from "../../utils/api";
import { Urls } from "../../utils/url";
import {SET_PSAP_DASHBOARD} from "../reducers/E911CallTest-reducer"
import { ALERTS } from "../reducers/component-reducer";

const E911CallTestActions = {
  //  manager account starts here
  // KPI DASHBOARD STARTS HERE

// PSAP dashboard start here
getPSAPDashboardList:
  (reset = true, args = "") =>
  async (dispatch, _) => {
    try {
      const res = await Api.get({
        url: `${Urls.Manage_E911_Dashboard_Data}${args != "" ? "?" + args : ""}`,
      });
      if (res?.status !== 200) return;
      let dataAll = res?.data?.data;
      console.log(dataAll, "sjdbk");
      dispatch(SET_PSAP_DASHBOARD({ dataAll, reset }));
    } catch (error) {}
  },
// // PSAP dashboard ends here


};
export default E911CallTestActions;
