import React, { useEffect, useState } from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable'
import { useDispatch, useSelector } from 'react-redux'
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import IXSupportActions from '../../../../../store/actions/IXSupport-actions';
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Row, Col, Card, Button, Dropdown, ButtonGroup, Modal, Badge } from 'react-bootstrap'
import { Urls } from '../../../../../utils/url'
import CommonActions from '../../../../../store/actions/common-actions'
import { ALERTS } from '../../../../../store/reducers/component-reducer'
import { useNavigate } from 'react-router-dom';
import EditModal from '../../../../components/EditModal';
import AssignedEngineerForm from './AssignedEngineerForm';
import FELoginForm from './FELoginForm';

const IXDashboard = () => {

  const [modal, setModal] = useState(false)
  const[feLogin,setFeLogin]=useState(false)
  const [itmValue, setItmValue] = useState({})
  const [key,setKey] = useState("")

    const dispatch = useDispatch();
    const navigate= useNavigate();

    useEffect(()=>{
      dispatch(IXSupportActions.getIXDashboardList())
    },[])

    const data =  useSelector((state)=> state?.IXSupportReducer?.getIXDashboardList)

    console.log(data,"ajkhbsduj")

    let tableData  = data?.map((itm)=>{

      return {
        "scheduleDate":itm.scheduleDate,
        "serviceAffected":itm.serviceAffected,
        "siteId":itm.actualSiteID,
        "chat":((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="primary"
            className="light sharp i-false"
            onClick={() => {
              
            }}
          >
            Chat
          </Dropdown.Toggle>
          
        </Dropdown>)),
        // "feLogin":((  <Dropdown>
        //   <Dropdown.Toggle
        //     variant="success"
        //     className="light sharp i-false"
        //     onClick={() => {
        //       setFeLogin(true)
        //       setItmValue(itm)
        //       // navigate(`/PAG/ix/module/FLogin/${itm.ranSupportId}`)
        //     }}
        //   >
        //     FE Login
        //   </Dropdown.Toggle>
        // </Dropdown>)),
        "integrationLogin":((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="success"
            className="light sharp i-false"
            onClick={() => {
              navigate(`/DATAPLUS/ix/module/ILogin/${itm.ranSupportId}`)
            }}
          >
            Integration Login
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>)),
       
        "crewAddedRemotely":itm.crewAddedRemotely,
        "assignedEngineer":itm.assignedEngineer?((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant=""
            className="light sharp i-false"
            onClick={() => {
              
              setModal(true)
              setItmValue(itm)
              setKey("oneHourKPIEngineer")
            }}
          >
            {itm.assignedEngineer}
          </Dropdown.Toggle>
      
        </Dropdown>)):((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="success"
            className="light sharp i-false"
            onClick={() => {
              
              setModal(true)
              setItmValue(itm)
              setKey("oneHourKPIEngineer")
            }}
          >
            +
          </Dropdown.Toggle>
         
        </Dropdown>)),
        "nestingTime":itm.nestingTime,
        "preHWLayerConfiguration":itm?.preHWLayerConfiguration?.values.join(", "),
        "postHWLayerConfiguration":itm?.postHWLayerConfiguration?.values.join(", "),
        "crew":((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="info"
            className="light sharp i-false"
            onClick={() => {
              // dispatch(
              //   CommonActions.deleteApiCaller(
              //     `${Urls.Manage_Account_Data}/${itm.uniqueid}`,
              //     () => {
              //       dispatch(
              //         // KPIDashboardActions.getKPIDashboardList()
              //       );
              //       dispatch(ALERTS({ show: false }));
              //     }
              //   )
              // );
            }}
          >
            crew
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>)),
        'sowList':itm?.sowList?.values.join(", "),
        "Shift":itm.Shift,
        "preCheckRequest":itm.preCheckRequest,
        "preCheckDelivered":itm.postCheckRequest,
        "postCheckRequest":itm.postCheckRequest,
        "postCheckDelivered":itm.postCheckDelivered,
        "requestPreCheck":((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="warning"
            className="light sharp i-false"
            onClick={() => {
              // dispatch(
              //   CommonActions.deleteApiCaller(
              //     `${Urls.Manage_Account_Data}/${itm.uniqueid}`,
              //     () => {
              //       dispatch(
              //         // KPIDashboardActions.getKPIDashboardList()
              //       );
              //       dispatch(ALERTS({ show: false }));
              //     }
              //   )
              // );
            }}
          >
            Req Precheck
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>)),
        "preCheck":((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="success"
            className="light sharp i-false"
            onClick={() => {
              // dispatch(
              //   CommonActions.deleteApiCaller(
              //     `${Urls.Manage_Account_Data}/${itm.uniqueid}`,
              //     () => {
              //       dispatch(
              //         // KPIDashboardActions.getKPIDashboardList()
              //       );
              //       dispatch(ALERTS({ show: false }));
              //     }
              //   )
              // );
            }}
          >
            Pre Check Completed
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>)),
        "startCalltest":((  <Dropdown>
          
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="warning"
            className="light sharp i-false"
            onClick={() => {
              // dispatch(
              //   CommonActions.deleteApiCaller(
              //     `${Urls.Manage_Account_Data}/${itm.uniqueid}`,
              //     () => {
              //       dispatch(
              //         // KPIDashboardActions.getKPIDashboardList()
              //       );
              //       dispatch(ALERTS({ show: false }));
              //     }
              //   )
              // );
            }}
          >
            Start Call test
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>)),
        "speedTest":((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="success"
            className="light sharp i-false"
            onClick={() => {
              // dispatch(
              //   CommonActions.deleteApiCaller(
              //     `${Urls.Manage_Account_Data}/${itm.uniqueid}`,
              //     () => {
              //       dispatch(
              //         // KPIDashboardActions.getKPIDashboardList()
              //       );
              //       dispatch(ALERTS({ show: false }));
              //     }
              //   )
              // );
            }}
          >
            Speed Test
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>)),
        "postCheck":((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="success"
            className="light sharp i-false"
            onClick={() => {
              // dispatch(
              //   CommonActions.deleteApiCaller(
              //     `${Urls.Manage_Account_Data}/${itm.uniqueid}`,
              //     () => {
              //       dispatch(
              //         // KPIDashboardActions.getKPIDashboardList()
              //       );
              //       dispatch(ALERTS({ show: false }));
              //     }
              //   )
              // );
            }}
          >
            Post Check Completed
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>)),
        "feLogout":((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="success"
            className="light sharp i-false"
            onClick={() => {
              // dispatch(
              //   CommonActions.deleteApiCaller(
              //     `${Urls.Manage_Account_Data}/${itm.uniqueid}`,
              //     () => {
              //       dispatch(
              //         // KPIDashboardActions.getKPIDashboardList()
              //       );
              //       dispatch(ALERTS({ show: false }));
              //     }
              //   )
              // );
            }}
          >
            FE Logout
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>)),

        "integrationLogout":((  <Dropdown>
          <Dropdown.Toggle
          style={{fontSize:"11px"}}
            variant="success"
            className="light sharp i-false"
            onClick={() => {
              // dispatch(
              //   CommonActions.deleteApiCaller(
              //     `${Urls.Manage_Account_Data}/${itm.uniqueid}`,
              //     () => {
              //       dispatch(
              //         // KPIDashboardActions.getKPIDashboardList()
              //       );
              //       dispatch(ALERTS({ show: false }));
              //     }
              //   )
              // );
            }}
          >
            Integration Logout
          </Dropdown.Toggle>
          {/* <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item >Delete</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>)),
        "progress":(<Badge   onClick={() => {
          // dispatch(
          //   CommonActions.deleteApiCaller(
          //     `${Urls.Manage_Account_Data}/${itm.uniqueid}`,
          //     () => {
          //       dispatch(
          //         // KPIDashboardActions.getKPIDashboardList()
          //       );
          //       dispatch(ALERTS({ show: false }));
          //     }
          //   )
          // );
        }} as="a" href="" bg=" badge-circle" className='badge-outline-success'>
        100%
      </Badge>)
      }
    })

    const modalHeadingFE = "IX-FE LOGIN"
    let addFELogin  = (FELoginForm)

    const modalHeading = "Assign Engineer"
    let AddEngineer  = (AssignedEngineerForm)
 
  // const tableHeading =  "IX Dashboard"
  const tableColumns = [
    {
      Header : 'SCHEDULE DATE',
      // Footer : 'S No',
      accessor: 'scheduleDate',
      Filter: "",
    },
    {
      Header : 'SERVICE AFFECTED',
      // Footer : 'account',
      accessor: "serviceAffected",
      Filter: "",
    },
    {
      Header : 'SITE ID',
      // Footer : 'account',
      accessor: "siteId",
      Filter: "",
    },
    {
      Header : 'CHAT',
      // Footer : 'account',
      accessor: "chat",
      Filter: "",
    },
    {
      Header : 'FE LOGIN',
      // Footer : 'account',
      accessor: "feLogin",
      Filter: "",
    },
    {
      Header : 'INTEGRATION LOGIN',
      // Footer : 'account',
      accessor: "integrationLogin",
      Filter: "",
    },
    {
      Header : 'CREW WORK REMOTE TYPE',
      // Footer : 'account',
      accessor: "crewAddedRemotely",
      Filter: "",
    },
    
    {
      Header : 'INTEGRATION ENGINEER',
      // Footer : 'account',
      accessor: "assignedEngineer",
      Filter: "",
    },
    {
      Header : 'NESTING TIME',
      // Footer : 'account',
      accessor: "nestingTime",
      Filter: "",
    },
    {
      Header : 'PROGRESS',
      // Footer : 'account',
      accessor: "progress",
      Filter: "",
    },

    {
      Header : 'PRE HW/LAYER CONFIGURATION',
      // Footer : 'S No',
      accessor: 'preHWLayerConfiguration',
      Filter: "",
    },
    {
      Header : 'POST HW/LAYER CONFIGURATION',
      // Footer : 'account',
      accessor: "postHWLayerConfiguration",
      Filter: "",
    },
    {
      Header : 'RNC',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'CREW',
      // Footer : 'account',
      accessor: "crew",
      Filter: "",
    },
    {
      Header : 'PLANNED SOW',
      // Footer : 'account',
      accessor: "sowList",
      Filter: "",
    },
    {
      Header : 'DAY/NIGHT MOP',
      // Footer : 'account',
      accessor: "Shift",
      Filter: "",
    },
    {
      Header : 'DAY MOP REASON',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'MANUAL PRE CHECK REQUEST',
      // Footer : 'account',
      accessor: "requestPreCheck",
      Filter: "",
    },
    {
      Header : 'LOGIN TIME PRE CHECK REQUEST (MOBILE)',
      // Footer : 'account',
      accessor: "preCheckRequest",
      Filter: "",
    },
    {
      Header : 'PRE-CHECKS',
      // Footer : 'account',
      accessor: "preCheck",
      Filter: "",
    },
    {
      Header : 'LOGIN TIME PRE-CHECK DELIEVERED ',
      // Footer : 'account',
      accessor: "preCheckDelivered",
      Filter: "",
    },

    {
      Header : 'CALL TEST ACTIVITY',
      // Footer : 'S No',
      accessor: 'startCalltest',
      Filter: "",
    },
    {
      Header : 'SPEED TEST',
      // Footer : 'account',
      accessor: "speedTest",
      Filter: "",
    },
    {
      Header : 'MANUAL POST-CHECK REQUEST',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LOGIN TIME',
      // Footer : 'account',
      accessor: "",
      Filter: "",
    },
    {
      Header : 'LOGOUT TIME POST-CHECK REQUEST',
      // Footer : 'account',
      accessor: "postCheckRequest",
      Filter: "",
    },
    
    {
      Header : 'POST CHECKS',
      // Footer : 'account',
      accessor: "postCheck",
      Filter: "",
    },
    {
      Header : 'LOGOUT TIME POST-CHECK DELIEVERED',
      // Footer : 'account',
      accessor: "postCheckDelivered",
      Filter: "",
    },
    {
      Header : 'FE LOGOUT',
      // Footer : 'account',
      accessor: "feLogout",
      Filter: "",
    },
    {
      Header : 'INTEGRATION LOGOUT',
      // Footer : 'account',
      accessor: "integrationLogout",
      Filter: "",
    },
    {
      Header : 'ACTION',
      // Footer : 'account',
      accessor: "action",
      Filter: "",
    },

    
  ]


  return(
    <>
 { console.log(feLogin,"feLogn")}
      {/* fe login modal */}
    {feLogin && <EditModal  modalHeading={modalHeadingFE} 
      setModal={setFeLogin} isOpen={feLogin} FormComponent={addFELogin} itmValue={itmValue} />}

    {/* KPI engineer Modal */}
    {modal && <EditModal keyValue={key} modalHeading={modalHeading} 
      setModal={setModal} isOpen={modal} FormComponent={AddEngineer} itmValue={itmValue} />}

{/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
        <div>
      <FilteringTable tableColumns={tableColumns} tableData={tableData} />
      {/* tableHeading ={tableHeading} */}
    </div>
    </>
  )

}

export default IXDashboard