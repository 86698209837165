import React, { useEffect, useState } from 'react'
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import IXSupportActions from "../../../../../store/actions/IXSupport-actions"
import { useLoaderData, useNavigate } from 'react-router-dom';
import KPIModuleActions from '../../../../../store/actions/KPIModule-actions';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';


const IXScheduler = () => {

  // const[selectOption,setSelectOption] = useState("")
    // const tableHeading = "IX Scheduler"
    const navigate =  useNavigate()
      const dispatch  =  useDispatch();
      const[alerts,setAlerts] = useState(false)
  const navigateLink  =('/DATAPLUS/ix/calender')

      const { register, control,handleSubmit,watch,setValue,getValues,setError,formState: { errors } } = useForm();

      useEffect(()=>{
        dispatch(IXSupportActions.getIXSchedulerSiteIdList())
    },[])


    const data  =  useSelector((state)=> state?.IXSupportReducer?.getIXSchedulerSiteIdList
    )
    console.log(data,"txfcgh")
      

     const siteIdOptions = data?.map((itm)=>{ return {label:itm._id,value:itm._id}})
     console.log(siteIdOptions,"siteIdOptions")

    const selectedSiteId =watch("actualSiteID")

     let market = "";
     let account="";
     let paceIdOptions=[];
     
     console.log(selectedSiteId,"selectedSiteID")


     useEffect(() => {
      const selectedIxCrew = data.filter(
        (item) => item._id === selectedSiteId
      );
      console.log(selectedIxCrew?.[0], "chadnfdsjfls");
      
      
      setValue(
        "market",
        selectedIxCrew?.[0]?.market
      );
      setValue("account", selectedIxCrew?.[0]?.account);
      // setValue("ixCrewLeadName", selectedIxCrew?.[0]?.name);
      // setValue(
      //   "ixCrewLeadCompany",
      //   selectedIxCrew?.[0]?.crewresult?.[0]?.crewCompanyName
      // );
      // setValue("ixCrewLeadEmailId", selectedIxCrew?.[0]?.email);
    }, [selectedSiteId]);

    if(selectedSiteId !== "Select")
    {
      const SelectedPaceID  = data.filter(
        (itm)=> itm._id === selectedSiteId
      )
      paceIdOptions = SelectedPaceID?.[0]?.siteDetail?.map((itm)=>{return {label:itm.PACEID, value:itm.uId}})
      
    }
    console.log(paceIdOptions,"paceIdData")
 
  
     console.log(market,account,"tjbt")


    //  technology

    useEffect(()=>{
      dispatch(IXSupportActions.getIXSchedulerTechnologyList())
      },[])

      const technologyData =  useSelector((state)=>state?.IXSupportReducer?.getIXSchedulerTechnologyList)
        console.log(technologyData,"getIXSchedulerTechnologyList")

        const technologyOptions = technologyData.map((itm)=>{return {label:itm.technologyName,value:itm.technologyName}})


    // enigneer data
    useEffect(()=>{
      dispatch(IXSupportActions.getIXSchedulerEngineerList())
    },[])

    const engineerData  =  useSelector((state)=>state?.IXSupportReducer?.getIXSchedulerEngineerList)

    console.log(engineerData,"srcdtvfguhn")
    
    const engineerOptions =  engineerData.map((itm)=>{return {labe:itm.name,value:itm.name}})
    console.log(engineerOptions,"engineerOptions")


    // list user data
    useEffect(()=>{
      dispatch(IXSupportActions.getIXSchedulerListUserList())
    },[])

    const listUserData  =  useSelector((state)=>state?.IXSupportReducer?.getIXSchedulerListUserList)

    let userData= [];

    console.log(listUserData,"rdctfgh")

    let SelectOption  = watch("ixSearchBy")

    console.log(SelectOption,"select")
        

   
  
      if(SelectOption === "Contact Number")
    {
      userData = listUserData.map((itm)=>{return {label:itm.mobilenumber,value:itm.uniqueid}})
    }
    
   
   

  
    
      if(SelectOption === "E-Mail")
    {
      userData = listUserData.map((itm)=>{return {label:itm.email,value:itm.uniqueid}})
    }
   
      console.log(SelectOption,"esdfvgybh")
  

  
    
        if(SelectOption === "Lead Name")
      {
        userData = listUserData.map((itm)=>{return {label:itm.name,value:itm.uniqueid }})
      }
     
      console.log(userData," UserData ")

      let selectedIxValue = watch(
        `ixCrewId`
      );
      console.log(selectedIxValue,"selecting")
      
      useEffect(() => {
        const selectedIxCrew = listUserData.filter(
          (item) => item.uniqueid === selectedIxValue
        );
        console.log(selectedIxCrew?.[0], "chadnfdsjfls");
        setValue(
          "ixCrewVendor",
          selectedIxCrew?.[0]?.vendorresult?.[0]?.vendorName
        );
        setValue("ixCrewLeadContactNumber", selectedIxCrew?.[0]?.mobilenumber);
        setValue("ixCrewUniqueId", selectedIxCrew?.[0]?.name);
        setValue(
          "ixCrewLeadCompany",
          selectedIxCrew?.[0]?.crewresult?.[0]?.crewCompanyName
        );
        setValue("ixCrewLeadEmailId", selectedIxCrew?.[0]?.email);
      }, [selectedIxValue]);


      
      
      
      
      
      
      const showForm  = watch("crewAddedRemotely")
      const showCancel = watch("postActivityStatusSiteLevel")

      let secondForm  = []
      let thirdForm=[]
      
    if(showForm !== "Yes")
    {
        secondForm=[{
          label: "IX Search By----",
          value: "",
          name: "ixSearchBy",
          type: "radio",
          required: true,
          option: [
            { label: "Contact Number", value: "Contact Number" },
            { label: "E-Mail", value: "E-Mail" },
            { label: "Lead Name", value: "Lead Name" },
          ],
          classes: "col-span-2",
        },
        {
          label: `Enter ${SelectOption ?  SelectOption :  "Contact Number"}`,
          value: "",
          name: `ixCrewId`,
          type: "select",
          required: true,
          option:userData,
          classes: "col-span-2",
        },
        {
        label: "FE : IX Crew Vendor",
        value: "",
        name: "ixCrewVendor",
        type: "text",
        required: true,
        disabled:true
        // classes: "col-span-1",
      },
      {
        label: "IX Crew Lead Contact Number",
        value: "",
        name: "ixCrewLeadContactNumber",
        type: "text",
        required: true,
        disabled:true
        // classes: "col-span-1",
      },
      {
        label: "FE : IX Crew Lead Name",
        value: "",
        name: "ixCrewUniqueId",
        type: "text",
        required: true,
        disabled:true
        // classes: "col-span-1",
      },
      {
        label: "FE : IX Crew Lead Company",
        value: "",
        name: "ixCrewLeadCompany",
        type: "text",
        required: true,
        disabled:true
        // classes: "col-span-1",
      },
      {
        label: "FE : IX Crew Lead E-mail ID",
        value: "",
        name: "ixCrewLeadEmailId",
        type: "text",
        required: true,
        disabled:true
        // classes: "col-span-1",
      }, ]
    }
      
    if(showCancel === ("Rollback" ) || showCancel === "Cancel")
      {
        thirdForm=[{
          label: `${showCancel} Reason`,
          value: "",
          name: "cancel",
          type: "text",
          required: true,
         
          classes: "col-span-2",
        },
       ]
      }

  const Form =[
    {
      label:"Site ID",
      value:"",
      name:"actualSiteID",
      type:"select",
      option:siteIdOptions,
      required: true,
      setError:setError,
      getValues:getValues,
      setValue:setValue,
      // placeholder:"Enter Your Name",
      isJson:true
    },
    {
      label:"PACE ID",
      // value:marketAccountOption?.account,
      name:"paceId",
      type:"multiSelect",
      required: true,
      option:paceIdOptions,
      setError:setError,
      getValues:getValues,
      setValue:setValue,
      control:control,
    //   option:[{label:"Dharmender",value:"Dharmender"},
    //   {label:"Dharmender1",value:"Dharmender1"},
    //   {label:"Dharmender2",value:"Dharmender2"}
    // ],
      // placeholder:"Enter your mobile number"
    },
    {
      label:"Account",
      value:account,
      name:"account",
      type:"text",
      required: true,
      setError:setError,
      getValues:getValues,
      setValue:setValue,
      disabled:true,
    //   option:[{label:"Dharmender",value:"Dharmender"},
    //   {label:"Dharmender1",value:"Dharmender1"},
    //   {label:"Dharmender2",value:"Dharmender2"}
    // ],
      // placeholder:"Enter your mobile number"
    },
    {
      label:"Market",
      value:market,
      name:"market",
      required: true,
      type:"text",
      setError:setError,
      getValues:getValues,
      setValue:setValue,
      disabled:true,
 //      option:[{label:"Granted",value:"Granted"},
 //      {label:"Rejected",value:"Rejected"},
 //      {label:"Pending",value:"Pending"}
 //    ],
      // placeholder:"Enter Time Zone Name"
    },
    {
      label:"Activity Date",
      name:"scheduleDate",
      type:"DateTime",
      // option:[{label:"Open",value:"Open"}],
      required: true,
      setError:setError,
      getValues:getValues,
      setValue:setValue,
      // placeholder:"Enter Your Password "
    },
    {
      label:"SOW",
      name:"sowList",
      type:"multiSelect",
      option:[{label:"IX",value:"IX"},
      {label:"E911-Call Test",value:"E911-Call Test"},
      {label:"Troubleshooting",value:"Troubleshooting"},
      {label:"Call Test(PSAP/Speed Test)",value:"Call Test(PSAP/Speed test)"}],
      setError:setError,
      getValues:getValues,
      setValue:setValue,
      control:control,
      required: true,
      // placeholder:"Enter Your Email"
    },
   {
        label:"Service Affected",
        value:"",
        name:"serviceAffected",
        type:"select",
        option:[{label:"Yes",value:"Yes"},
        {label:"No",value:"No"}],
        setError:setError,
        getValues:getValues,
        setValue:setValue,
        required: true,
     //    option:marketOption,
        placeholder:""
      },
      {
        label:"Pre HW/Layer Configuration",
        value:"",
        name:"preHWLayerConfiguration",
        type:"multiSelect",
        option:technologyOptions,
        setError:setError,
        getValues:getValues,
        setValue:setValue,
        control:control,
        required: true,
       
        placeholder:""
      },
      
      {
        label:"Post HW/Layer Configuration",
        value:"",
        name:"postHWLayerConfiguration",
        type:"multiSelect",
        option:technologyOptions,
        setError:setError,
        getValues:getValues,
        setValue:setValue,
        control:control,
        required: true,
     //    option:crewCompanyOption,
        placeholder:""
      },
      {
        label:"Shift",
        value:"",
        name:"Shift",
        type:"select",
        option:[{label:"Day Time",value:"Day Time"},
        {label:"Maintainance Window",value:"Maintainance Window"}],
        setError:setError,
        getValues:getValues,
        setValue:setValue,
        required: true,
     //    option:crewCompanyOption,
        placeholder:""
      },
      {
        label:"Scheduler Note",
        name:"SchedulerNote",
        type:"text",
        setError:setError,
        getValues:getValues,
        setValue:setValue,
        required: true,
        // placeholder:"Enter Your Email"
      },

      {
        label:"IX/RAN Assisgned Engg",
        value:"",
        name:"SchedulerAssignedIntegrationEngineer",
        type:"select",
        option:engineerOptions,
        setError:setError,
        getValues:getValues,
        setValue:setValue,
        required: true,
     //    option:crewCompanyOption,
        placeholder:""
      },
      {
        label:"Status",
        value:"",
        name:"postActivityStatusSiteLevel",
        type:"select",
        option:[{label:"Scheduled",value:"Scheduled"},
        {label:"Ongoing",value:"Ongoing"},
        {label:"Rollback",value:"Rollback"},
        {label:"On-Hold",value:"On-Hold"},{label:"Cancel",value:"Cancel"},
        {label:"Completed",value:"Completed"}],
        setError:setError,
        getValues:getValues,
        setValue:setValue,
        required: true,
     //    option:crewCompanyOption,
        placeholder:""
      },
      ...thirdForm.map((itm)=>{return itm}),
      
      {
        label:"Add crew Remotely",
        value:"",
        name:"crewAddedRemotely",
        type:"select",
        option:[{label:"Yes",value:"Yes"},
      {label:"No",value:"No"}],
        setError:setError,
        getValues:getValues,
        setValue:setValue,
        required: true,
     //    option:crewCompanyOption,
        placeholder:""
      },

      

      ...secondForm.map((itm)=> {return itm})
      
  ]

  

  console.log(Form,"Form")


    
  let values=[];
  let preHWValues=[]
  let postHWValues=[]
  let paceIdValues=[]
    const onSubmit = (data) => {
        // Handle form submission here, you can access form data in the 'data' object
        console.log(data,"dataasfdgvsgdv")
        values = data.sowList.map((bar)=>{return bar.value})
        preHWValues =data.preHWLayerConfiguration.map((bar)=>{return bar.value})
        postHWValues =data.postHWLayerConfiguration.map((bar)=>{return bar.value})
          paceIdValues=data.paceId.map((bar)=>{return bar.value})
          console.log(paceIdValues,"paceIdValues")
          paceIdValues=paceIdValues[0];
        data={...data,sowList:{values:values},cxCrewId:"",preHWLayerConfiguration:{values:preHWValues},postHWLayerConfiguration:{values:postHWValues},paceId:paceIdValues,ixCrewUniqueId:data.ixCrewId}
        delete data.market
        delete data.account
        if(showForm ==="Yes")
          {
            delete data.ixCrewLeadCompany
            delete data.ixCrewLeadContactNumber
            delete data.ixCrewLeadEmailId
            delete data.ixCrewUniqueId
            delete data.ixCrewVendor
            delete data.ixSearchBy
            delete data.ixCrewId

          }

            delete data.ixCrewLeadCompany
            delete data.ixCrewLeadContactNumber
            delete data.ixCrewLeadEmailId
            delete data.ixCrewVendor
            delete data.ixSearchBy
        console.log(data,"jnbhvgecsfgvrh");

        const uniqueId = data.siteid
            console.log(uniqueId,"kjjasbdyn")
      
            
          // dispatch(IXSupportActions.postIXScheduler(data,()=>{
          //     dispatch(IXSupportActions.getIXCalendarDataList())
          // }))
          // navigate('/DATAPLUS/ix/calender')
        setAlerts(true)
  
      };

  return (
    <>
    {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
    {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink}/>}
      <div className=' '>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DATAPLUS_CommonForm Form={Form} setValue={setValue} getValues={getValues} errors={errors}  register={register}/>
        <button type='submit' className='btn btn-primary'>
          Submit
        </button>
      </form>
      </div>
      
      
    </>
  )
}

export default IXScheduler;