import Api from "../../utils/api";
import { Urls } from "../../utils/url";
import {SET_UTRAN_DASHBOARD} from "../reducers/UTRANSupport-reducer"
import { ALERTS } from "../reducers/component-reducer";

const UTRANSupportActions = {
  //  manager account starts here
  // KPI DASHBOARD STARTS HERE

        getUTRANDashboardList:
        (reset = true, args = "") =>
        async (dispatch, _) => {
          try {
            const res = await Api.get({
              url: `${Urls.UTRAN_Dashboard_Data}${args != "" ? "?" + args : ""}`,
            });
            if (res?.status !== 200) return;
            let dataAll = res?.data?.data;
            console.log(dataAll, "tbvjn");
            dispatch(SET_UTRAN_DASHBOARD({ dataAll, reset }));
          } catch (error) {}
        },

        postUTRANEngineer: ( data, cb, uniqueId=null,args="") => async (dispatch, _) => {
            try {
              const res = await Api.post({
                data: data,
                url:
                  uniqueId === null
                    ?
                    Urls.Post_KPI_Engineer_Data
                    : `${Urls.Post_KPI_Engineer_Data + "/" + uniqueId }${args != "" ? "?" + args : ""}`,
              });
              if (res?.status !== 201 && res?.status !== 200) {
                let msgdata = {
                  show: true,
                  icon: "error",
                  buttons: [],
                  type: 1,
                  text: res?.data?.msg,
                };
                // dispatch(ALERTS(msgdata));
              } else {
                cb();
              }
            } catch (error) {
              return;
            }
          },

       




};
export default UTRANSupportActions;
