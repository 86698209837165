import React, { useState } from 'react'
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";

const FormModal = ({type={},FormComponent,itmValue="",Heading={},setModal,isOpen,modalHeading="",keyValue=""}) => {
  
    console.log(keyValue,"keyValue")
    return (
        <>
        
            {/* {console.log(Heading, "ghcbjk")} */}
            <Modal
            // style={{backgroundColor:"white"}}
                className="fade bd-example-modal-sm"
                show={isOpen}
                size="lg"
            >
                <Modal.Header  style={{backgroundColor:"#470468", color:'white'}}>
                    {/* {modalHeading} */}
                    {/* <Modal.Title>Modal title</Modal.Title> */}
                 {   console.log(modalHeading,"modalHeading")}
                    {Heading.heading  ? `${Heading.heading} Report` : modalHeading}
                    <Button
                    style={{color:'white'}}
                        variant=""
                        className="close"
                        onClick={() => setModal(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:"white"}}>
                    {/* form */}
                    <FormComponent type={type} modalHeading={modalHeading} item ={itmValue} keyValue={keyValue} Heading={Heading} setModal={setModal} /> 
                    {/* form end */}
                </Modal.Body>

            </Modal>
        </>
    )

}



export default FormModal