import { createSlice } from "@reduxjs/toolkit";

const initialState = {

    getCXSupportList:[]
    
    

};


const CXSupportData = createSlice({

    name:"CXSupportData",
    initialState,
    reducers:{

        SET_CX_SUPPORT: (state, { payload }) => {
            if (payload.reset) {
            state.getCXSupportList = payload.dataAll;
            } else {
            state.getCXSupportList = [
                ...state.getCXSupportList,
                ...payload.dataAll,
            ];
            }
        },
     

    }
})

export const {SET_CX_SUPPORT} = CXSupportData.actions;

export default CXSupportData.reducer;