import React, {useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions'
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable'
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter'
import CommonActions from '../../../../../store/actions/common-actions'
import { Urls } from '../../../../../utils/url'
import ActionsModal from '../../../../components/DATAPLUS_Components/ActionsModal'
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal'
import CommentModal from '../../../../components/DATAPLUS_Components/CommentModal'

const UTRANEngineers = () => {


  const navigate = useNavigate()
  const [modal, setModal] = useState(false)

  const [itmValue, setItmValue] = useState({})

  const[alerts,setAlerts] = useState(false)
  const [actions, setActions] = useState(false)
  const [callDelete, setCallDelete] = useState(false)
  const [commentHeading, setCommentHeading] = useState("")
  const[deleteIndex,setDeleteIndex] = useState(null)
  


  const AddFormButtonName= '+ Engineers'
  const AddFormNavigate ="/DATAPLUS/Add/Engineers"

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(adminDashboardActions.getEngineerList())
  },[])


  const data  = useSelector((state)=>state?.AdminDashboard_reducer?.getEngineerList)




  console.log(data,"habsjavbsd ")


    //  engineers

    useEffect(()=>{
      dispatch(adminDashboardActions.getManageMarketsList())
    },[])
  
    const marketsData  =  useSelector((state)=> state?.AdminDashboard_reducer?.getManageMarketsList)
  
    console.log(marketsData,"yhviyubv6f7")

  
    let itemUniqueId =[]
  let tableData  =  data.map((itm,index)=>{
    itemUniqueId[index] = itm.uniqueid
    


    console.log(itm?.department,"___itm")
    console.log(itm.password,"____password")
    return {
      "name":itm.name,
      'email':itm.email,
      "companyName":itm.companyName,
      "email":itm.email,
      'password':itm.password,
      'poc':itm.poc,
      'mobileAppAccess':itm.mobileAppAccess,
      "mobilenumber":itm.mobilenumber,
      "markets":itm?.markets?.join(", "),
      "account":itm?.account.length === 1  ? itm?.account[0] :itm?.account.length === 2  && itm?.account[0] === null ?itm.account[1]: itm?.account?.join(", "),
      "department":<div
     
      onClick={() => {
        setModal(true);
        setItmValue(itm?.department.length === 1  ? itm?.department[0] :itm?.department.length === 2  && itm?.department[0] === null ?itm.department[1]: itm?.department? itm?.department?.join(", ") : "");
        setCommentHeading('Departments')
      }}
      style={{
        padding:'5px', 
        borderRadius: '5px',
        alignContent:'center',
        borderWidth: '2px',
        borderColor: 'black',
        height: "40px",
        maxWidth: '200px',
        minWidth: '200px',
        width: "100px",
        overflow: 'hidden',
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap'
      }}
    >
      {itm?.department
        ? itm?.department.length > 15
          ? itm?.department?.join(", ")?.substring(0, 50) + '...'
          : itm?.department?.join(", ")
        : ""}
    </div>,
      "actions": (<div style={{display:'flex', gap:"5px",cursor:'pointer',}}>
        <div  onClick={() => {
            navigate(`/DATAPLUS/Edit/Engineers/${itm.uniqueid}`)

          }}><img src='/editIcon2.png' style={{width:"25px",width:"25px"}}/></div>


          <div  onClick={() => {
            setActions(true)
            setDeleteIndex(index)


          }}><img src='/delete2.png' style={{width:"25px",width:"25px",cursor:'pointer'}}/></div>
      </div>)
    }
  })

 
  const tableColumns = [
    {
      Header: 'ENGG. NAME',
      accessor: "name",
      Filter: ColumnFilter,
    },
    {
      Header: 'COMPANY',
      accessor: "companyName",
      Filter: ColumnFilter,
    },
    {
      Header: 'MOBILE NUMBER',
          accessor: 'mobilenumber',
      Filter: ColumnFilter,
    },
    {
      Header: 'EMAIL ID',
      
      accessor: "email",
      Filter: ColumnFilter,
    },

    {
      Header: 'ACCOUNT ',
      
      accessor: "account",
      Filter: ColumnFilter,
    },
    {
      Header: 'MARKET ',
      
      accessor: "markets",
      Filter: ColumnFilter,
    },
    {
      Header: 'DEPARTMENT',
      
      accessor: "department",
      Filter: ColumnFilter,
    },
    {
      Header: 'Mobile App Access',
      
      accessor: "mobileAppAccess",
      Filter: ColumnFilter,
    },
    {
      Header: 'Password',
      
      accessor: "password",
      Filter: ColumnFilter,
    },
    {
      Header: 'POC',
      
      accessor: "poc",
      Filter: ColumnFilter,
    },
    {
      Header: 'ACTIONS',
      
      accessor: "actions",
      Filter: "",
    },
    
    // {
    //   Header : 'Date of  Birth',
    // Birth',
    //   accessor: 'date_of_birth'
    // },
    // {
    //   Header : 'Country',
    //,
    //   accessor: 'country',
    // },
    // {
    //   Header : 'Phone',
    //    //   accessor: 'phone'
    // },
    // {
    //   Header : 'Age',
    //    //   accessor: 'age',
    // },
  ]

  if (callDelete) {
    dispatch(
      CommonActions.deleteApiCaller(
        `${Urls.Engineer_data}/${itemUniqueId[deleteIndex]}`,
        () => {

          console.log(itemUniqueId, "dgcfk")
          dispatch(
            adminDashboardActions.getEngineerList()
          );
          // dispatch(ALERTS({ show: false }));
        }
      )
    );
    setCallDelete(false)

  }

  const GROUPED_COLUMNS = [
    {
      Header: 'Id',
      Footer: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Name',
      Footer: 'Name',
      columns: [
        {
          Header: 'First Name',
          Footer: 'First Name',
          accessor: 'first_name'
        },
        {
          Header: 'Last Name',
          Footer: 'Last Name',
          accessor: 'last_name'
        },
      ]
    },
    {
      Header: 'Info',
      Footer: 'Info',
      columns: [
        {
          Header: 'Date of  Birth',
          Footer: 'Date of  Birth',
          accessor: 'date_of_birth'
        },
        {
          Header: 'Country',
          Footer: 'Country',
          accessor: 'country',
        },
        {
          Header: 'Phone',
          Footer: 'Phone',
          accessor: 'phone'
        },
      ]
    },
  ]
  return (
 
     <div>
      
       
      {modal && <CommentModal commentHeading={commentHeading} modal={modal} setModal={setModal} itmValue={itmValue} />}
      {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} />}
      {actions && <ActionsModal setAlerts={setAlerts} actions={actions} setCallDelete={setCallDelete} setActions={setActions} />}


        <div>
        <FilteringTable 
        AddFormNavigate={AddFormNavigate}
        AddFormButtonName={AddFormButtonName} 
        tableColumns={tableColumns} 
        tableData={tableData} 
        />
      </div>

     </div>
  )
}

export default UTRANEngineers