import React, { useEffect, useState } from 'react'
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';

const AccountsForm = () => {
    
    // states
  const [alerts, setAlerts] = useState(false)


  const dispatch = useDispatch();
  const navigate = useNavigate();

  // React hook form
  const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm();



  // customer Dtable data
  const { id } = useParams()
  let navigateLink = "/DATAPLUS/accounts";
  const Data = useSelector((state) => state?.AdminDashboard_reducer?.getManageAccountList)
  console.log(Data, "_Datattaaa")
  let item = Data.filter((itm) => {

    if (itm.uniqueid === id) {
      return itm
    }

  })
  item = item[0];

console.log(item,"____datatatat")


  // selecgt time zone
//   const time = watch('timeZone');
//   useEffect(() => {
//     if (time === '') {
//       setValue('timeZoneName', '')
//     }
//     else if (time === 'CST') {
//       setValue('timeZoneName', 'Central Standard Time')
//     }
//     else if (time === 'EST') {
//       setValue('timeZoneName', 'Eastern Standard Time')
//     }
//     else if (time === 'PST') {
//       setValue('timeZoneName', 'Pacific Standard Time')
//     }
//     else if (time === 'HAST') {
//       setValue('timeZoneName', 'Hawaii-Aleutian Standard Time')
//     }
//     else if (time === 'AKST') {
//       setValue('timeZoneName', 'Alaska Standard Time')
//     }
//   }, [time, setValue])



  // Form
  const Form = [
    {
      label: "Account",
      value: "",
      name: "accountName",
      type: "text",
      required: true,
      disbabled: false
    },
  ]



  // form Submission
  const onSubmit = (data) => {
    console.log(data, "___customerData");

    if (item) {

      console.log("called", "kabsd")
      dispatch(adminDashboardActions.postManageAccountList( data, () => {
        dispatch(adminDashboardActions.getManageAccountList())
      },item.uniqueid))
    }
    else {
      console.log("called", "dfcgvh")
      dispatch(adminDashboardActions.postManageAccountList( data, () => {
        dispatch(adminDashboardActions.getManageAccountList())
      },""))
    }


    setTimeout(() => {
      setAlerts(true)
    }, 1000)

  };



  // pre fill tehd data
  useEffect(() => {
    if (item?.uniqueid) {
      Form.forEach((key) => {
        if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
          setValue(key.name, item[key.name]["$oid"]);

        }
        else {
          setValue(key.name, item[key.name]);
        }

      })
    }
  }, [item?.uniqueid])






  return (
    <>
    {/* alert */}
      {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink} />}


{/* common form */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <DATAPLUS_CommonForm Form={Form} errors={errors} register={register} />
        <div style={{ width: 'full', display: 'flex', gap: '10px', justifyContent: 'center', marginTop: "20px" }}>
          <button style={{ margintop: "10" }} className='btn btn-info' onClick={() => navigate(-1)}>
            Back
          </button>

          <button style={{ margintop: "10" }} type='submit' className='btn btn-primary'>
            Submit
          </button>

        </div>
      </form>
    </>
  )
}

export default AccountsForm