import React, { useEffect } from 'react'
import MPAG_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import troubleTicketActions from '../../../../../store/actions/TroubleTicket-actions';
import KPIModuleActions from '../../../../../store/actions/KPIModule-actions';
import { ALERTS } from '../../../../../store/reducers/component-reducer';
import moment from "moment"
import UTRANSupportActions from '../../../../../store/actions/UTRANSupport-actions';

const KPIAddEngineer = ({item,keyValue,setModal}) => {


    console.log(item,keyValue,"ujbfksbhjkd")
    const dispatch = useDispatch()

console.log(item,keyValue,"item")
    useEffect(()=>{
        dispatch(troubleTicketActions.getManageEngineerList())
    },[])

    const engineerData  = useSelector((state)=>state?.TroubleTicketReducer?.getManageEngineerList)
    console.log(engineerData,"jhabsdu")

    const engineerOption = engineerData.map((itm)=>{return {label:itm.name, value:itm.name}})

    const
    { register,
      handleSubmit,
      setError,
      watch,
      getValues,
      setValue,
      } = useForm();
   

      const Form =[
        {
            label:"Assign Engineer",
            type:"select",
            name:keyValue,
            option:engineerOption,
            required:true,
            disabled:false,

        }
      ]
    
    const onSubmit = (data) => {
        // Handle form submission here, you can access form data in the 'data' object
      
            console.log(data,item.ranSupportId,"gcjvhbkl")
        
            dispatch(
                UTRANSupportActions.postUTRANEngineer(
                    data,
                  () => {
                    // dispatch(KPIModuleActions.getKPICIQSitesList())
                    dispatch(UTRANSupportActions.getUTRANDashboardList())
                    dispatch(ALERTS({ show: false }));
                  },item.ranSupportId,"bandV=True"
                )
              );
              setModal(false)
      };

      useEffect(()=>{
        Form.forEach((key) => {
          console.log(`sdxghbj`,key.name, item[key.name])
          if (["endAt", "startAt"].indexOf(key.name) != -1) {
            console.log("date formValuekey", key.name, item[key.name])
            const momentObj = moment(item[key.name]);
            setValue(key.name, momentObj.toDate());
            // if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
            //   setValue(key.name, item[key.name]["$oid"]);
      
            // }
            // else

        }
          else {
            setValue(key.name, item[key.name]);
          }
    
        })
      },[item?.ranSupportId])

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
 <MPAG_CommonForm Form={Form} register={register}/>
 <button type='submit' className='btn btn-primary'>
   Submit
 </button>
</form>
</>
  )
}

export default KPIAddEngineer