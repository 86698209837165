import {createSlice} from "@reduxjs/toolkit"

const initialState={
    getCIQScriptingList:[],
}

const CIQScriptingData = createSlice({
    name:"CIQScriptingData",
    initialState,
    reducers:{

       // add PSAP Dashboard

     // add CIQ SCripting Dashboard

     SET_CIQ_SCRIPTING_DASHBOARD: (state, { payload }) => {
        if (payload.reset) {
        state.getCIQScriptingList = payload.dataAll;
        } else {
        state.getCIQScriptingList = [
            ...state.getCIQScriptingList,
            ...payload.dataAll,
        ];
        }
    },
    }
})

export const {SET_CIQ_SCRIPTING_DASHBOARD,}= CIQScriptingData.actions;
export default CIQScriptingData.reducer;