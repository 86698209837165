import React, { Fragment, useContext, useEffect } from 'react';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Link } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import troubleTicketActions from '../../../store/actions/TroubleTicket-actions';
import DropdownBlog from './../Karciz/Dropdown/DropdownBlog';
import { ThemeContext } from '../../../context/ThemeContext';

const SalesChart = loadable(() =>
    pMinDelay(import("./../Karciz/Dashboard/SalesChart"), 1000)
);
const IncreaseChart = loadable(() =>
    pMinDelay(import("./../Karciz/Dashboard/IncreaseChart"), 1000)
);
const IncomeChart = loadable(() =>
    pMinDelay(import("./../Karciz/Dashboard/IncomeChart"), 1000)
);
const Doughnutchart = loadable(() =>
    pMinDelay(import("./../Karciz/Dashboard/Doughnutchart"), 1000)
);
const SellingApexChart = loadable(() =>
    pMinDelay(import("./../Karciz/EventPage/SellingApexChart"), 1000)
);
const HomeSalesRevenueChart = loadable(() =>
    pMinDelay(import("./../Karciz/Dashboard/HomeSalesRevenueChart"), 1000)
);

function Home() {
    const dispatch = useDispatch();
    // const { changeBackground } = useContext(ThemeContext);

    useEffect(() => {
        dispatch(troubleTicketActions.getTTDashboardList());
        // changeBackground({ value: "dark", label: "Dark" });
    }, [dispatch]);

    const data = useSelector((state) => state?.TroubleTicketReducer?.getTTDashboardList) || [];
    let openCount = 0;
    let closeCount = 0;

    data.forEach(item => {
        if (item.ttStatus === "Open") {
            openCount++;
        } else if (item.ttStatus === "Close") {
            closeCount++;
        }
    });

    return (
        <Fragment>
            <div className="row">
                <div className="col-xl-6 col-xxl-5 col-lg-6">
                    <div className="card ticket-bx" >
                        <div className="card-body">
                            <div className="d-sm-flex d-block pb-sm-3 align-items-end">
                                <div style={{ display: "flex", fontSize: "smaller" }} className="me-auto pr-3 mb-2 mb-sm-0 col-sm-12">
                                    <p>Total Sites</p>
                                    <p>Sites Plan Today</p>
                                    <p>Sites Login Today</p>
                                    <p>Total IX Done</p>
                                    <p>IX Planned Today</p>
                                    <p>TIX Done Today</p>
                                </div>
                            </div>
                            <div className="progress mt-3 mb-4" style={{ height: "15px" }}>
                                <div className="progress-bar-striped progress-bar-animated" style={{ width: "86%", height: "15px" }} role="progressbar">
                                    <span className="sr-only">86% Complete</span>
                                </div>
                            </div>
                            <Link to={"#"} className="text-white">View detail<i className="las la-long-arrow-alt-right scale5 ms-3"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-7 col-lg-6">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card overflow-hidden" style={{ maxHeight: '175px' }}>
                                <div className="card-header align-items-start pb-0 border-0">
                                    <div>
                                        <h4 className="fs-16 mb-0">E911 Status</h4>
                                    </div>
                                    <Dropdown className="">
                                        <Dropdown.Toggle as="div" className="cursor-pointer fs-12">This Week</Dropdown.Toggle>
                                        <Dropdown.Menu alignRight={true} className="dropdown-menu-right">
                                            <Dropdown.Item>Daily</Dropdown.Item>
                                            <Dropdown.Item>Weekly</Dropdown.Item>
                                            <Dropdown.Item>Monthly</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="card-body p-0">
                                    <SalesChart className="me-3" />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card overflow-hidden" style={{ maxHeight: '175px' }}>
                                <div className="card-header align-items-start pb-0 border-0">
                                    <div>
                                        <h4 className="fs-16 mb-0">Comparison of Login</h4>
                                        <span className="fs-12">IX Sites Weekwise</span>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <IncreaseChart />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card overflow-hidden" style={{ maxHeight: '175px' }}>
                                <div className="card-header align-items-start pb-0 border-0">
                                    <p> Trouble Tickets</p>
                                </div>
                                <div className="card-body pt-2">
                                    <div className="index-chart-point">
                                        <div className="check-point-area overflow-hidden rounded me-2">
                                            <Doughnutchart />
                                        </div>
                                        <ul className="index-chart-point-list">
                                            <li><i className="fa fa-stop text-danger"></i>Close - {openCount}</li>
                                            <li><i className="fa fa-stop text-success"></i> Open - {closeCount}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card overflow-hidden" style={{ maxHeight: '175px' }}>
                                <div className="card-header align-items-start pb-0 border-0">
                                    <div>
                                        <h4 className="fs-16 mb-0">Ticket Closure rate</h4>
                                    </div>
                                    <Dropdown className="ms-auto">
                                        <Dropdown.Toggle as="div" className="cursor-pointer fs-12">Monthly</Dropdown.Toggle>
                                        <Dropdown.Menu alignRight={true} className="dropdown-menu-right">
                                            <Dropdown.Item>Daily</Dropdown.Item>
                                            <Dropdown.Item>Weekly</Dropdown.Item>
                                            <Dropdown.Item>Monthly</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="card-body p-0">
                                    <IncomeChart />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-9 col-xxl-8">
                    <div className="card" id="sales_revenue" style={{ maxHeight: '500px' }}>
                        <div className="card-header border-0 pb-0 d-sm-flex d-block">
                            <div style={{ display: "flex", gap: '10px' }}>
                                <Dropdown className="ms-auto">
                                    <Dropdown.Toggle as="div" className="cursor-pointer fs-12">Monthly</Dropdown.Toggle>
                                    <Dropdown.Menu alignRight={true} className="dropdown-menu-right">
                                        <Dropdown.Item>Daily</Dropdown.Item>
                                        <Dropdown.Item>Weekly</Dropdown.Item>
                                        <Dropdown.Item>Monthly</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <p style={{ marginBottom: '20px' }}> Graph of Login Sites and IX Done Sites</p>
                            </div>
                        </div>
                        <div className="card-body custome-tooltip">
                            <HomeSalesRevenueChart />
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-xxl-4 col-md-6">
                    <div className="card" style={{ maxHeight: '500px' }}>
                        <div className="card-header border-0 pb-0">
                            <Dropdown>
                                <Dropdown.Toggle as="div" className="cursor-pointer fs-12">This Week</Dropdown.Toggle>
                                <Dropdown.Menu alignRight={true} className="dropdown-menu-right">
                                    <Dropdown.Item>Daily</Dropdown.Item>
                                    <Dropdown.Item>Weekly</Dropdown.Item>
                                    <Dropdown.Item>Monthly</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center selling p-3 rounded">
                                <span className="fs-14">Tuesday</span>
                                <span className="fs-14">21 Sites</span>
                            </div>
                            <SellingApexChart />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Home;
