import {createSlice} from "@reduxjs/toolkit"

const initialState={
    getPSAPDashboardList:[],
}

const E911CallTestData = createSlice({
    name:"E911CallTestData",
    initialState,
    reducers:{

       // add PSAP Dashboard

       SET_PSAP_DASHBOARD: (state, { payload }) => {
        if (payload.reset) {
        state.getPSAPDashboardList = payload.dataAll;
        } else {
        state.getPSAPDashboardList = [
            ...state.getPSAPDashboardList,
            ...payload.dataAll,
        ];
        }
    },
    }
})

export const {SET_PSAP_DASHBOARD,}= E911CallTestData.actions;
export default E911CallTestData.reducer;