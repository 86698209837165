import {createSlice} from "@reduxjs/toolkit"

const initialState={
    getUTRANDashboardList:[],
    
}

const KPIModuleData = createSlice({
    name:"KPIModuleData",
    initialState,
    reducers:{

        SET_UTRAN_DASHBOARD: (state, { payload }) => {
            if (payload.reset) {
            state.getUTRANDashboardList = payload.dataAll;
            } else {
            state.getUTRANDashboardList = [
                ...state.getUTRANDashboardList,
                ...payload.dataAll,
            ];
            }
        },


      
    }
})

export const {SET_UTRAN_DASHBOARD} = KPIModuleData.actions;
export default KPIModuleData.reducer;