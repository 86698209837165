import React, { useEffect, useState } from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable'
import deleteLogo from "../../../../../images/deleteLogo.png"
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable'
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter'
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Modal,
  ProgressBar,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import UTRANSupportActions from '../../../../../store/actions/UTRANSupport-actions';
import FormModal from '../../../../components/DATAPLUS_Components/FormModal';
import RANAddEngineer from './RANAddEngineer';
import DataBar from '../../../../components/DATAPLUS_Components/DataBar'
import RunPreCheckForm from './RunPreCheckForm'


const RANDashboard = () => {
  const [preCheck, setPreCheck] = useState(false)
  const [form, setForm] = useState(false)
  const [modal, setModal] = useState(false)
  const [itmValue, setItmValue] = useState({})
  const [key, setKey] = useState("")
  const [actions, setActions] = useState(false)
  const [callDelete, setCallDelete] = useState(false)
  const [kpiAgeModal, setKpiAgeModal] = useState(false)
  const [heading, setHeading] = useState({})
  const navigate = useNavigate();
  const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(UTRANSupportActions.getUTRANDashboardList())
  // }, [])

  // const data = useSelector((state) => state?.UTRANSupportReducer?.getUTRANDashboardList)
  // console.log(data, "dfcgj")

  const showMarket = "Market";
  const modalHeading = "Add Engineer"
  const modalButton = "Pre Check"
  let AddEngineer = (RANAddEngineer)
  const RunPreCheck = (RunPreCheckForm)
  // let tableData = data.map((itm) => {

  //   return {
  //     ...itm,
  //     // "sowList":itm.sowList.values.join(", "),
  //     "chat": ((<Dropdown>
  //       <Dropdown.Toggle
  //         variant="primary"
  //         className="light sharp i-false"
  //         onClick={() => {

  //         }}
  //       >
  //         Chat
  //       </Dropdown.Toggle>

  //     </Dropdown>)),
  //     "runPreCheck": ((<Dropdown>
  //       <Dropdown.Toggle
  //         variant='warning'
  //         className="light sharp i-false"
  //         onClick={() => {

  //           // navigate(`/DATAPLUS/cx/Pre-Check/dashboard/${itm.ranSupportId}`)
  //           // setKey("fourthHourKPIEngineer")
  //         }}
  //       >
  //         Run Pre-Check
  //       </Dropdown.Toggle>

  //     </Dropdown>))

  //     ,
  //     "crew": ((<Dropdown>
  //       <Dropdown.Toggle
  //         variant="success"
  //         className="light sharp i-false"
  //         onClick={() => {

  //         }}
  //       >
  //         Crew
  //       </Dropdown.Toggle>

  //     </Dropdown>)),
  //     "progress": (<Badge onClick={() => {

  //       // setKpiAgeModal(true)
  //       // setItmValue(itm)
  //       // setHeading({heading:"4th Hour",key:"fourthHour"})
  //       // setKey("oneHourKPIEngineer")
  //     }} bg=" badge-circle" className='badge-outline-danger'>
  //       100%
  //     </Badge>),
  //     "assignedEngineer": itm.assignedEngineer ? ((<Dropdown>
  //       <Dropdown.Toggle
  //         variant={!itm.assignedEngineer && "success"}
  //         className="light sharp i-false"
  //         onClick={() => {

  //           setModal(true)
  //           setItmValue(itm)
  //           setKey("assignedEngineer")
  //         }}
  //       >
  //         {itm.assignedEngineer}
  //       </Dropdown.Toggle>

  //     </Dropdown>)) : ((<Dropdown>
  //       <Dropdown.Toggle
  //         variant="success"
  //         className="light sharp i-false"
  //         onClick={() => {

  //           setModal(true)
  //           setItmValue(itm)
  //           setKey("assignedEngineer")
  //         }}
  //       >
  //         +
  //       </Dropdown.Toggle>

  //     </Dropdown>)),

  //     "delete": ((<Dropdown>
  //       <Dropdown.Toggle
  //         variant="danger"
  //         className="light sharp i-false"
  //         onClick={() => {
  //           setActions(true)
  //           // setItmValue(itm)
  //           console.log(callDelete, "callDelete")

  //         }}
  //       >
  //         <img src={deleteLogo} />
  //       </Dropdown.Toggle>
  //       {/* <Dropdown.Menu>
  //         <Dropdown.Item>Edit</Dropdown.Item>
  //         <Dropdown.Item >Delete</Dropdown.Item>
  //       </Dropdown.Menu> */}
  //     </Dropdown>))
  //   }
  // })




  // const tableHeading  = "UTRAN Dashboard"
  const showButton = "CX"
  const AddFormButtonName= 'New Request'
  const AddFormNavigate ="/DATAPLUS/add/cx/support"
  const AddFormButton = ''
  // ((<Button style={{ marginLeft: '9px' }}
  //   variant="primary"
  //   className="close"
  //   onClick={()=>{setForm(true)}}
  // >
  //   New Request
  // </Button>))

  // const FormComponent=(RunPreCheckForm)

  const tableColumns = [

    // {
    //   Header: "GC Company",
    //   accessor: "scheduleDate",
    //   Filter: "",

    // },

    // //nhi pta api mai kha h
    // // {
    // //   Header: "SITE STATUS",
    // //   accessor: "siteStatus",
    // //   Filter: "",
    // // },
    // // {
    // //   Header: "SERVICE AFFECTED",
    // //   accessor: "serviceAffected",
    // //   Filter: "",
    // // },
    // // {
    // //   Header: "OUTAGE APPROVAL",
    // //   accessor: "tmoOutageApproval",
    // //   Filter: "",
    // // },
    // {
    //   Header: "GC Name",
    //   accessor: "sitesearchId",
    //   Filter: "",
    // },
    // {
    //   Header: "GC Email",
    //   accessor: "chat",
    //   Filter: "",
    // },
    // {
    //   Header: "GC Contact",
    //   accessor: "assignedEngineer",
    //   Filter: "",
    // },
    // // {
    // //   Header: "OUTAGE APPROVED MW TIME",
    // //   accessor: "tmoOutageApprovedMwTime",
    // //   Filter: "",
    // // },
    // {
    //   Header: "Activity Start Time",
    //   accessor: "nestingTime",
    //   Filter: "",
    // },
    // {
    //   Header: "Activity End Time",
    //   accessor: "progress",
    //   Filter: "",
    // },
    // {
    //   Header: "Activity Hours",
    //   accessor: "crew",
    //   Filter: "",
    // },
    // {
    //   Header: "SA/NSA",
    //   accessor: "",
    //   Filter: "",
    // },
    // {
    //   Header: "NFSD Login",
    //   accessor: "",
    //   Filter: "",
    // },
    // {
    //   Header: "NFSD Snapshot",
    //   accessor: "",
    //   Filter: "",
    // },
    // {
    //   Header: "EIM Login",
    //   accessor: "",
    //   Filter: "",
    // },
    // {
    //   Header: "Request Source",
    //   accessor: "",
    //   Filter: "",
    // },
    // {
    //   Header: "GO/NOGO Approval",
    //   accessor: "",
    //   Filter: "",
    // },
     // {
    //   Header: "File Upload",
    //   accessor: "",
    //   Filter: "",
    // },
     // {
    //   Header: "Notes",
    //   accessor: "",
    //   Filter: "",
    // },


    {
      Header: "Ticket ID",
      accessor: "scheduleDate",
      Filter: "",

    },

    //nhi pta api mai kha h
    // {
    //   Header: "SITE STATUS",
    //   accessor: "siteStatus",
    //   Filter: "",
    // },
    // {
    //   Header: "SERVICE AFFECTED",
    //   accessor: "serviceAffected",
    //   Filter: "",
    // },
    // {
    //   Header: "OUTAGE APPROVAL",
    //   accessor: "tmoOutageApproval",
    //   Filter: "",
    // },
    {
      Header: "Pace ID",
      accessor: "sitesearchId",
      Filter: "",
    },
    {
      Header: "Site ID",
      accessor: "chat",
      Filter: "",
    },
    {
      Header: "SOW",
      accessor: "assignedEngineer",
      Filter: "",
    },
    // {
    //   Header: "OUTAGE APPROVED MW TIME",
    //   accessor: "tmoOutageApprovedMwTime",
    //   Filter: "",
    // },
    {
      Header: "Sector",
      accessor: "nestingTime",
      Filter: "",
    },
    {
      Header: "Technology Affected",
      accessor: "progress",
      Filter: "",
    },
    {
      Header: "Status",
      accessor: "crew",
      Filter: "",
    },
    // {
    //   Header: "SCHEDULE SOURCE",
    //   accessor: "scheduleSource",
    //   Filter: "",
    // },
    // {
    //   Header: "PROJECT CODE",
    //   accessor: "projectCode",
    //   Filter: "",
    // },
    // {
    //   Header: "TECHNOLOGY",
    //   accessor: "technology",
    //   Filter: "",
    // },
    // {
    //   Header: "CREW",
    //   accessor: "crew",
    //   Filter: "",
    // },
    // {
    //   Header: "MPULSE TT_ID",
    //   accessor: "mpulseTtId",
    //   Filter: "",
    // },
    // {
    //   Header: "PIER TT ID",
    //   accessor: "pierTtId",
    //   Filter: "", 
    // },

    // {
    //   Header: "CR NUMBER",
    //   accessor: "crNumber",
    //   Filter: "",
    // },
    // {
    //   Header: "CR STATUS",
    //   accessor: "crStatus",
    //   Filter: "",
    // },

    // {
    //   Header: "PLANNED SOW",
    //   accessor: "sowList ",
    //   Filter: "",
    // },
    // {
    //   Header: "SOW DETAILS",
    //   accessor: "sowComments",
    //   Filter: "",
    // },
    // // {
    // //   Header: "DAY/NIGHT MOP",
    // //   accessor: "dayNightMop",
    // //   Filter: "",
    // // },
    // // {
    // //   Header: "DAY MOP REASON",
    // //   accessor: "dayMopReason",
    // //   Filter: "",
    // // },

    // {
    //   Header: "LOGIN TIME PRECHECK REQUEST(MOBILE)",
    //   accessor: "preCheckRequest",
    //   Filter: "",
    // },

    // {
    //   Header: "PRE-CHECKS",
    //   accessor: "runPreCheck",
    //   Filter: "",
    // },
    // {
    //   Header: "LOGIN TIME PRECHECK DELIVERED",
    //   // accessor: "loginTimePrecheckDelivered",
    //   Filter: "",
    // },
    ,
    // {
    //   Header: "MANUAL PRECHECK REQUEST",
    //   accessor: "manualPrecheckRequest",
    //   Filter: "",
    // },

    // {
    //   Header: "RIOT",
    //   value: "riot",
    //   Filter: "",
    // },
    // {
    //   Header: "CALL TEST ACTIVITY",
    //   value: "callTestActivity",
    //   Filter: "",
    // },
    // {
    //   Header: "CBN VALIDATION",
    //   value: "cbnValidation",
    //   Filter: "",
    // },
    // {
    //   Header: "LOCK/UNLOCK REQUEST",
    //   value: "lockUnlockRequest",
    //   Filter: "",
    // },
    // {
    //   Header: "MANUAL POSTCHECK REQUEST",
    //   // value: "manualPostcheckRequest",
    //   Filter: "",
    // },
    // {
    //   Header: "LOGOUT TIME POSTCHECK REQUEST",
    //   // value: "logoutTimePostcheckDelivered",
    //   Filter: "",
    // },
    // {
    //   Header: "POST CHECKS",
    //   // accessor: "manualPrecheckRequest",
    //   Filter: "",
    // },
    // {
    //   Header: "LOGOUT TIME POSTCHECK DELIVERED",
    //   // accessor: "logoutTimePostcheckDelivered",
    //   Filter: "",
    // },
    // {
    //   Header: "PIER UPDATED",
    //   accessor: "pierUpdatedś",
    //   Filter: "",
    // },
    // {
    //   Header: "SITE TYPE",
    //   accessor: "siteType",
    //   Filter: "",
    // },
    // {
    //   Header: "DELETE",
    //   accessor: "delete",
    //   Filter: "",
    // },

  ]

  const GROUPED_COLUMNS = [
    {
      Header: 'Id',
      Footer: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Name',
      Footer: 'Name',
      columns: [
        {
          Header: 'First Name',
          Footer: 'First Name',
          accessor: 'first_name'
        },
        {
          Header: 'Last Name',
          Footer: 'Last Name',
          accessor: 'last_name'
        },
      ]
    },
    {
      Header: 'Info',
      Footer: 'Info',
      columns: [
        {
          Header: 'Date of  Birth',
          Footer: 'Date of  Birth',
          accessor: 'date_of_birth'
        },
        {
          Header: 'Country',
          Footer: 'Country',
          accessor: 'country',
        },
        {
          Header: 'Phone',
          Footer: 'Phone',
          accessor: 'phone'
        },
      ]
    },
  ]

  let DataBarNew = [
    //new
    {
      head: {
        heading: "New Request",
        heading_color: "text-blue-600",
        headingbar_color: "white",
      },

      sub: [
        {
          sub_heading: "Login-In Req",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "21",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "Rejected Log-In ",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "No Show ",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "Activity Cancelled ",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
      ],
    },

    {
      head: {
        heading: "Log-In",
        heading_color: "text-blue-600",
        headingbar_color: "white",
      },

      sub: [
        {
          sub_heading: "Log-In Approval",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "21",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "Pre-Check Request",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "Pre-Check Submitted",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
      ],
    },

    {
      head: {
        heading: "GC Progress",
        heading_color: "text-blue-600",
        headingbar_color: "white",
      },

      sub: [
        {
          sub_heading: "NFSD Log-In",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "21",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "Log-Out Rejected",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "NFSD Extend",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
      ],
    },

    {
      head: {
        heading: "Validation",
        heading_color: "text-blue-600",
        headingbar_color: "white",
      },

      sub: [
        {
          sub_heading: "Lock Request",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "21",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "Unlock Request",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "RSSI",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },

      ],
    },

    {
      head: {
        heading: "Progress",
        heading_color: "text-blue-600",
        headingbar_color: "white",
      },

      sub: [
        {
          sub_heading: "Post Check Request",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "21",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "Post Check Submitted",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
        {
          sub_heading: "Post Check No Show",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "30",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },
      ],
    },

    {
      head: {
        heading: "Closed",
        heading_color: "text-blue-600",
        headingbar_color: "white",
      },

      sub: [
        {
          sub_heading: "Log-Out",
          sub_heading_color: "text-black",
          databar_color: "bg-gray-200",
          // subdatabar_color: "bg-red-700",
          subdatabar_count: "21",
          subdatabar_count_bgcolor: "white",
          borderRadius: "50%"
        },

      ],
    },
    // {
    //   head: {
    //     heading: "MECHANISM",
    //     heading_color: "text-orange-600",
    //     headingbar_color: "bg-black",
    //   },

    //   sub: [
    //     {
    //       sub_heading: "Site integration",
    //       sub_heading_color: "text-black",
    //       databar_color: "bg-gray-200",
    //       subdatabar_color: "bg-red-700",
    //       subdatabar_count: "90",
    //       subdatabar_count_bgcolor: "bg-orange-600",
    //     },
    //   ],
    // },
    // {
    //   head: {
    //     heading: "PROCESSING",
    //     heading_color: "text-yellow-900",
    //     headingbar_color: "bg-black",
    //   },

    //   sub: [
    //     {
    //       sub_heading: "Processing",
    //       sub_heading_color: "text-black",
    //       databar_color: "bg-gray-200",
    //       subdatabar_color: "bg-red-700",
    //       subdatabar_count: "5",
    //       subdatabar_count_bgcolor: "bg-yellow-900",
    //     },
    //   ],
    // },
    // {
    //   head: {
    //     heading: "RESULT",
    //     heading_color: "text-green-400",
    //     headingbar_color: "bg-black",
    //   },

    //   sub: [
    //     {
    //       sub_heading: "Completed",
    //       sub_heading_color: "text-black",
    //       databar_color: "bg-gray-200",
    //       subdatabar_color: "bg-red-700",
    //       subdatabar_count: "33",
    //       subdatabar_count_bgcolor: "bg-green-400",
    //     },
    //     {
    //       sub_heading: "Failed",
    //       sub_heading_color: "text-black",
    //       databar_color: "bg-gray-200",
    //       subdatabar_color: "bg-red-700",
    //       subdatabar_count: "5",
    //       subdatabar_count_bgcolor: "bg-orange-600",
    //     },
    //   ],
    // },

    // check in
    //
  ];



  return (
    <>
      {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
      {/* <div>
        <FilteringTable tableColumns={tableColumns} tableData={["a","b"]} tableHeading={tableHeading}/>
      </div> */}
      {modal && <FormModal keyValue={key} modalHeading={modalHeading} isOpen={modal} setModal={setModal}
        FormComponent={AddEngineer} itmValue={itmValue} />}

      {form && <FormModal modalHeading={modalButton}
        isOpen={form} setModal={setForm} FormComponent={RunPreCheck} itmValue={itmValue} />}

      <div className='bar-data' >
        <DataBar approvedData={DataBarNew} />
      </div>

      <div>
        <FilteringTable AddFormNavigate={AddFormNavigate}
         AddFormButtonName={AddFormButtonName}  showMarket={showMarket} showButton={showButton} AddFormButton={AddFormButton} tableColumns={tableColumns} tableData={['a','b']}  />
        {/* tableHeading={tableHeading} */}
      </div>
    </>
  )

}

export default RANDashboard