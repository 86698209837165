import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import MPAG_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useDispatch } from 'react-redux';
import IXSupportActions from '../../../../../store/actions/IXSupport-actions';
import moment from "moment"

const FELoginForm = ({item}) => {

  const dispatch = useDispatch()

  const { register, control,handleSubmit,watch,setValue,getValues,setError } = useForm();

  useEffect(()=>{
    dispatch(IXSupportActions.getIXDashboardIDList(item.ranSupportId))
  },[])
 
  const Form =[
    {
      label:"Scheduled Date",
      value:"",
      name:"scheduledDate",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"Site ID",
      value:"",
      name:"actualSiteID",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"PACE_IDs",
      value:"",
      name:"actualSiteID",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"Account",
      value:"",
      name:"actualSiteID",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"Market",
      value:"",
      name:"actualSiteID",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"Activity Date",
      name:"scheduleDate",
      type:"dateTime",
      // option:[{label:"Open",value:"Open"}],
      required: true,
      setError:setError,
      getValues:getValues,
      setValue:setValue,
      placeholder:"Enter Your Password "
    },
    {
      label:"Shift",
      value:"",
      name:"Shift",
      type:"select",
      option:[{label:"Day Time",value:"Day Time"},
      {label:"Maintainance Window",value:"Maintainance Window"}
      ],
      placeholder:"Enter Your Name",
      // disabled:true
    },
    {
      label:"SOW",
      value:"",
      name:"dateTime",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"Service Affecting",
      value:"",
      name:"dateTime",
      type:"select",
      option:[{label:"Yes",value:"Yes"},
      {label:"No",value:"No"}
      ],
      placeholder:"Enter Your Name",
      // disabled:true
    },
    {
      label:"Pre HW Configuration",
      value:"",
      name:"dateTime",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"FE Name",
      value:"",
      name:"dateTime",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"FE Contact",
      value:"",
      name:"dateTime",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"FE E-mail ID",
      value:"",
      name:"dateTime",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"FWO/CR Number",
      value:"",
      name:"dateTime",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"EIM Login",
      value:"",
      name:"dateTime",
      type:"text",
      // option:[{label:"Yes",value:"Yes"},
      // {label:"No",value:"No"},
      // {label:"Awaited",value:"Awaited"}
      // ],
      placeholder:"Enter Your Name",
      // disabled:true
    },
    {
      label:"NFSD Login",
      value:"",
      name:"dateTime",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      disabled:true
    },
    {
      label:"EIM Login Snap",
      value:"",
      name:"dateTime",
      type:"select",
      option:[{label:"Yes",value:"Yes"},
      {label:"No",value:"No"},
      {label:"Awaited",value:"Awaited"}
      ],
      placeholder:"Enter Your Name",
      disabled:true
    },
    
    {
      label:"NFSD Login Snap Reason",
      value:"",
      name:"dateTime",
      type:"text",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      // disabled:true
    },

    {
      label:"NFSD Login Snap Upload",
      value:"",
      name:"files",
      type:"file",
      // option:siteIdOptions,
      placeholder:"Enter Your Name",
      // disabled:true,
      control:control
    },
    
      
  ]

  

  console.log(Form,"Form")


    
  // let values=[];
  // let preHWValues=[]
  // let postHWValues=[]
  // let paceIdValues=[]
    const onSubmit = (data) => {
        // Handle form submission here, you can access form data in the 'data' object
        // values = data.sowList.map((bar)=>{return bar.value})
        // preHWValues =data.preHWLayerConfiguration.map((bar)=>{return bar.value})
        // postHWValues =data.postHWLayerConfiguration.map((bar)=>{return bar.value})
        //   paceIdValues=data.paceId.map((bar)=>{return bar.value})
        // data={...data,sowList:{values:values},preHWLayerConfiguration:{values:preHWValues},postHWLayerConfiguration:{values:postHWValues},paceId:{values:paceIdValues}}
        console.log(data,"jnbhvgecsfgvrh");

        // const uniqueId = data.siteid
        //     console.log(uniqueId,"kjjasbdyn")
      
        //   dispatch(KPIModuleActions.getManageCIQSitesKPIIdlist(uniqueId,true,()=>{
        //       dispatch(KPIModuleActions.getKPIDashboardList())
        //   }))

        
  
      };

      useEffect(()=>{
        Form.forEach((key) => {
          console.log(`sdxghbj`,key.name, item[key.name])
          if (["endAt", "startAt"].indexOf(key.name) != -1) {
            console.log("date formValuekey", key.name, item[key.name])
            const momentObj = moment(item[key.name]);
            setValue(key.name, momentObj.toDate());
            // if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
            //   setValue(key.name, item[key.name]["$oid"]);
      
            // }
            // else

        }
          else {
            setValue(key.name, item[key.name]);
          }
    
        })
      },[item?.ranSupportId])

  return (
    <>
      <div className=' '>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MPAG_CommonForm Form={Form} setValue={setValue} getValues={getValues}  register={register}/>
        <button type='submit' className='btn btn-primary'>
          Submit
        </button>
      </form>
      </div>
      
      
    </>
  )

}

export default FELoginForm