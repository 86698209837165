import React, { useEffect } from 'react'
import DATAPLUS_cards from "../../../../components/DATAPLUS_Components/DATAPLUS_cards"
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

const MyProjects = () => {

  const dispatch = useDispatch()
  useEffect(()=>{
  	dispatch(adminDashboardActions.getManageMarketsList())
    },[])
  
    const marketsData  =  useSelector((state)=> state?.AdminDashboard_reducer?.getManageMarketsList)
  
    console.log(marketsData,"yhviyubv6f7")
  
    let marketOptions = marketsData?.map((itm, index) => {
  
  	return {
    
  	  label:itm.marketName,value:itm.marketName
      
  
  	}
  
    })
  
  	const{register, handleSubmit}= useForm()
  
  const 	Form=[{
  		label: " Select Market :",
  		value: "",
  		name: "markets",
  		type: "select",
  		option: marketOptions,
  		placeholder: "",
  		// control:control
  	  },]
  // form market end

  const approvedData = [
    {
      name: "GO/NO GO Status",
      nameCss: 'name-css',
      icon: "bi bi-diamond-fill",
      iconCss: { fontSize: '24px', marginRight: '5px' },
      cardCss: { width: '18rem', display: 'flex', alignItems: 'center', padding: '10px' },
      columnCss: { display: 'flex', alignItems: 'center' },
      handleValue: 'MOP',
      directLink:'/DATAPLUS/GO-NOGO/dashboard',
      direct:true,
      links: [
        {
          linkName: "GO/NO GO Status",
          link: '/DATAPLUS/GO-NOGO/dashboard',

        },

      ]
    },
    {
      name: "CX Support",
      nameCss: 'name-css',
      icon: "bi bi-person-workspace",
      iconCss: { fontSize: '24px', marginRight: '5px' },
      cardCss: { width: '18rem', display: 'flex', alignItems: 'center', padding: '10px' },
      columnCss: { display: 'flex', alignItems: 'center' },
      handleValue: 'CX',
      directLink:'',
      direct:false,
      links: [
        {
          linkName: 'CX Dashboard',
          link: "/DATAPLUS/cx/support/dashboard",

        },
      ]
    },
    {
      name: "IX Support",
      nameCss: 'name-css',
      // nameCss:{ color: '#45CAFC', fontWeight: 'bold' },
      icon: "bi bi-collection",
      iconCss: { fontSize: '24px', marginRight: '5px' },
      cardCss: { width: '18rem', display: 'flex', alignItems: 'center', padding: '10px' },
      columnCss: { display: 'flex', alignItems: 'center' },
      handleValue: 'IX',
      directLink:'',
      direct:false,
      links: [
        {
          linkName: 'IX Scheduler',
          link: '/DATAPLUS/ix/scheduler',

        },
        {
          linkName: 'IX Calendar',
          link: '/DATAPLUS/ix/calender',

        },
        {
          linkName: 'IX Dashboard',
          link: '/DATAPLUS/ix/dashboard',
        }
      ]
    },
    {
      name: "Emergency Call Support",
      nameCss: 'name-css',
      // nameCss:{ color: '#45CAFC', fontWeight: 'bold' },
      icon: "bi bi-arrow-repeat",
      iconCss: { fontSize: '24px', marginRight: '5px' },
      cardCss: { width: '18rem', display: 'flex', alignItems: 'center', padding: '10px' },
      columnCss: { display: 'flex', alignItems: 'center' },
      handleValue: 'CALLS',
      directLink:'',
      direct:false,
      links: [
        {
          linkName: 'E911 Dashboard',
          link: "/DATAPLUS/e911/dashboard",

        },
        {
          linkName: 'PSAP Dashboard',
          link: '/DATAPLUS/psap/dashboard',

        },

      ]
    },
    {
      name: "KPI Monitoring ",
      nameCss: 'name-css',
      // nameCss:{ color: '#45CAFC', fontWeight: 'bold' },
      icon: "bi bi-view-stacked",
      iconCss: { fontSize: '24px', marginRight: '5px' },
      cardCss: { width: '18rem', display: 'flex', alignItems: 'center', padding: '10px' },
      
      handleValue: 'KPI',
      directLink:'',
      direct:false,
      links: [
        {
          linkName: 'KPI Dashboard',
          link: '/DATAPLUS/kpi/dashboard',

        },
        {
          linkName: 'KPI Tracker',
          link: '/DATAPLUS/kpi/tracker',

        },
        {
          linkName: 'KPI Trouble Ticket',
          link: '/DATAPLUS/kpi/trouble/Ticket/dashboard',

        },



      ],
    },
    {
      name: "Reports",
      nameCss: 'name-css',
      // nameCss:{ color: '#45CAFC', fontWeight: 'bold' },
      icon: "bi bi-file-earmark-ruled",
      iconCss: { fontSize: '24px', marginRight: '5px' },
      cardCss: { width: '18rem', display: 'flex', alignItems: 'center', padding: '10px' },
      columnCss: { display: 'flex', alignItems: 'center' },
      handleValue: 'REPORTS',
      directLink:'',
      direct:false,
      links: [
        {
          linkName: 'Daily Activity Report',
          link: '/DATAPLUS/daily/activity/Report',

        },
        {
          linkName: 'SLA Report',
          link: '/DATAPLUS/sla/report',

        },
      ]
    },
    {
      name: "Tickets",
      nameCss: 'name-css',
      // nameCss:{ color: '#45CAFC', fontWeight: 'bold' },
      icon: "bi bi-ticket",
      iconCss: { fontSize: '24px', marginRight: '5px' },
      cardCss: { width: '18rem', display: 'flex', alignItems: 'center', padding: '10px' },
      columnCss: { display: 'flex', alignItems: 'center' },
      handleValue: 'TICKET',
      directLink:'',
      direct:false,
      links: [
        {
          linkName: 'View Tickets',
          link: "/DATAPLUS/utran/support/dashboard",

        },

      ]
    }


  ]

  return (
    <>
      <div style={{margin:"20px"}}>
      {/* market Form start */}
					{Form.map((itm)=>{
						return (
								<div style={{display:'grid',marginBottom:"20px",justifyContent:'center'}}>
								<label style={{fontSize:"15px",color:"#000",paddingLeft:"10px",fontWeight:"normal"}}>{itm.label}</label>
                                <select style={{width:'200px', marginRight:"10px",height:"35px",borderRadius:"7px"}}
                                    id='selectOption'
                                    className='form-control'
                                    disabled={itm.disabled}
                                    {...register(itm.name)}
                                >
                                    <option selected value={""} disabled >Select</option>
                                    {itm?.option?.map((bar) => {
                                        return (

                                            <option style={{color:'black'}} value={bar.value}>{bar.label}</option>
                                        )
                                    })}


                                </select>
								</div>
                            )
					})}
					{/* market Form end */}
</div>

<DATAPLUS_cards approvedData={approvedData} />
    </>
  )
}

export default MyProjects

