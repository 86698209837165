import {createSlice} from "@reduxjs/toolkit"

const initialState={
    getTTDashboardList:[],
    getManageSiteList:[],
    getManageSiteIdList:[],
    getManageEngineerList:[],
    
}

const TroubleTicketData = createSlice({
    name:"TroubleTicketData",
    initialState,
    reducers:{

         // add IX Dashboard

      SET_TT_DASHBOARD: (state, { payload }) => {
        if (payload.reset) {
        state.getTTDashboardList = payload.dataAll;
        } else {
        state.getTTDashboardList = [
            ...state.getTTDashboardList,
            ...payload.dataAll,
        ];
        }
    },

      // add manage site

      SET_MANAGE_SITE: (state, { payload }) => {
        if (payload.reset) {
        state.getManageSiteList = payload.dataAll;
        } else {
        state.getManageSiteList = [
            ...state.getManageSiteList,
            ...payload.dataAll,
        ];
        }
    },

    // add manage site id

    SET_MANAGE_SITE_ID: (state, { payload }) => {
        if (payload.reset) {
        state.getManageSiteIdList = payload.dataAll;
        } else {
        state.getManageSiteIdList = [
            ...state.getManageSiteIdList,
            ...payload.dataAll,
        ];
        }
    },

    
    SET_MANAGER_ENGINEER: (state, { payload }) => {
        if (payload.reset) {
        state.getManageEngineerList = payload.dataAll;
        } else {
        state.getManageEngineerList = [
            ...state.getManageEngineerList,
            ...payload.dataAll,
        ];
        }
    },

    }
})

export const {SET_TT_DASHBOARD,SET_MANAGE_SITE,SET_MANAGER_ENGINEER,SET_MANAGE_SITE_ID} = TroubleTicketData.actions;
export default TroubleTicketData.reducer;