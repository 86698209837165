import React, { useEffect, useState } from 'react'
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import troubleTicketActions from '../../../../../store/actions/TroubleTicket-actions';
import { json, useNavigate, useParams } from 'react-router-dom';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';
import moment from 'moment'; // Import moment

const TTDashboardForm = ({setModal}) => {
  


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams()
    console.log(id,"id")

    const[alerts,setAlerts] = useState(false)
  const navigateLink  =("/DATAPLUS/trouble/Ticket/dashboard")



    const
    { register,
      handleSubmit,
      setError,
      watch,
      getValues,
      setValue,
      } = useForm();
   
      
      
      // item =item[0];
      // console.log(item, "item")


    useEffect(()=>{
      dispatch(troubleTicketActions.getManageSiteList())
    },[])


    const siteIDData = useSelector((state)=>state?.TroubleTicketReducer?.getManageSiteList)
    console.log(siteIDData,"jhbguyngu")

    


    const siteIDOption  = siteIDData.map((itm)=>{return {label:itm.siteId, value:itm.siteId}})



    const selectedSiteId = watch("siterId");
    console.log(selectedSiteId,"dgfcgvhbj")


    useEffect(() => {
      if (selectedSiteId !== "Select") {
        
        dispatch(troubleTicketActions?.getManageSiteIdList(selectedSiteId,true));
      }
    }, [selectedSiteId]);

   
   let marketAccountList = useSelector(
      (state) => state?.TroubleTicketReducer?.getManageSiteIdList
    );
    console.log(marketAccountList,selectedSiteId,"kyabskgd")


    console.log(marketAccountList,"tdfhvtbj")
    
    let marketAccountOption={};
    
    
    for(let i = 0; i<marketAccountList.length;i++)
    {
      marketAccountOption={
        market:marketAccountList[i].market,
        account:marketAccountList[i].account
      }
      setValue("market",marketAccountOption.market)
      setValue("account",marketAccountOption.account)
    }
    
     

    

    console.log(marketAccountOption,"ybv")
    

    // manager engineer option

    useEffect(()=>{
        dispatch(troubleTicketActions.getManageEngineerList())
    },[])

    const engineerData  = useSelector((state)=>state?.TroubleTicketReducer?.getManageEngineerList)
    console.log(engineerData,"jhabsdu")

    const engineerOption = engineerData.map((itm)=>{return {label:itm.name, value:itm.name}})
    

   

   const Form =[
       {
         label:"Site ID",
         value:"",
         name:"siterId",
         type:"select",
         option:siteIDOption,
         setError:setError,
         getValues:getValues,
         setValue:setValue,
         placeholder:"Enter Your Name",
         isJson:false,
         required:true
         
       },
       {
         label:"Account",
         
         name:"account",
         type:"text",
         setError:setError,
         getValues:getValues,
         setValue:setValue,
       //   option:[{label:"Dharmender",value:"Dharmender"},
       //   {label:"Dharmender1",value:"Dharmender1"},
       //   {label:"Dharmender2",value:"Dharmender2"}
       // ],
        //  placeholder:"Enter your mobile number",
         disabled:true,
         required:true
       },
       {
         label:"Market",
         
         name:"market",
         type:"text",
         setError:setError,
         getValues:getValues,
         setValue:setValue,
    //      option:[{label:"Granted",value:"Granted"},
    //      {label:"Rejected",value:"Rejected"},
    //      {label:"Pending",value:"Pending"}
    //    ],
        //  placeholder:"Enter Time Zone Name",
         disabled:true,
         required:true
       },
       {
         label:"TT Status",
         name:"ttStatus",
         type:"select",
         option:[{label:"Open",value:"Open"}],
         setError:setError,
         getValues:getValues,
         setValue:setValue,
         required:true
        //  placeholder:"Enter Your Password "
       },
       {
         label:"ATT ID",
         name:"ATTID",
         type:"text",
         setError:setError,
         getValues:getValues,
         setValue:setValue,
         required:true
        //  placeholder:"Enter Your Email"
       },
       
       {
           label:"Engineer",
           value:"",
           name:"engineerOpeningtt",
           type:"select",
           option:engineerOption,
           setError:setError,
           getValues:getValues,
           setValue:setValue,
        //    option:marketOption,
           placeholder:"",
           required:true
         },
         {
           label:"Date",
           value:"",
           name:"ttOpenDate",
           type:"dateTime",
           setError:setError,
           getValues:getValues,
           setValue:setValue,
          
           placeholder:"",
           required:true
         },
         
         {
           label:"TT Notes",
           value:"",
           name:"tt_desc",
           type:"textArea",
           setError:setError,
           getValues:getValues,
           setValue:setValue,
        //    option:crewCompanyOption,
           placeholder:"",
           required:true
         },
    //      
         
     ]


    //  console.log(Form[2].value,"aybsndy")

     let uniqueId = "";

     for(let i=0; i <siteIDData.length;i++)
     {
      uniqueId =  siteIDData[i].uniqueSiteId
     }

     let userId  = JSON.parse(localStorage.getItem("userDetails"))
     console.log(userId,"jyfvyj")

     
     userId=userId.uniqueid
     console.log(userId,"habgsdj")
     

     const onSubmit = (data) => {
       // Handle form submission here, you can access form data in the 'data' object
       data ={...data,siterId:uniqueId}

       data={...data,userId:userId,pageFromData:"Trouble Ticket",account:marketAccountOption?.account,market:marketAccountOption?.market}
       console.log(data,"rtxctt");

        // let fullString = data.ttOpenDate
        const dateTimeString = data.ttOpenDate;

        const formattedDateTime = moment(dateTimeString).format('YYYY/MM/DD HH:mm');

        console.log(formattedDateTime,"formattedDateTime"); // Output: 2024/06/26 5:30

       
      console.log(dateTimeString,"datedate12")
      
     
        //  dispatch(troubleTicketActions.postTTDashboardList(null,data,()=>{
        //      dispatch(troubleTicketActions.getTTDashboardList())
        //  }))
        // setModal(false)
      //  navigate("/DATAPLUS/trouble/Ticket/dashboard")
      setAlerts(true)
 
     };

 return (
   
   <>
   {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink}/>}
   <form onSubmit={handleSubmit(onSubmit)}>
     <DATAPLUS_CommonForm Form={Form} register={register}/>
     <button type='submit' className='btn btn-primary'>
       Submit
     </button>
   </form>

   </>

 )

}

export default TTDashboardForm