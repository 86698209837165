import React from 'react'
import DATAPLUS_CommonForm from '../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useForm } from 'react-hook-form';

const CheckInRequestForm = () => {
  
  const
  { register,
    handleSubmit,
    setValue
  } = useForm();

//  if(item.uniqueid)
//   {
//     setValue("name",item.name)

//   }

const Form = [
  {
    label: "Site ID",
    value: "",
    name: "name",
    type: "text",
    placeholder: "Enter Your Name"
  },
  {
    label: "Small Cell Suffix",
    value: "",
    name: "mobilenumber",
    type: "text",
    //   option:[{label:"Dharmender",value:"Dharmender"},
    //   {label:"Dharmender1",value:"Dharmender1"},
    //   {label:"Dharmender2",value:"Dharmender2"}
    // ],
    placeholder: "Enter your mobile number"
  },
  {
    label: "Development or Operations Work",
    value: "",
    name: "mobileAppAccess",
    type: "select",
    option: [{ label: "Granted", value: "Granted" },
    { label: "Rejected", value: "Rejected" },
    { label: "Pending", value: "Pending" }
    ],
    placeholder: "Enter Time Zone Name"
  },
  {
    label: "Check Project",
    name: "password",
    type: "text",
    placeholder: "Enter Your Password "
  },
  {
    label: "Equivalent Vendor",
    name: "email",
    type: "text",
    placeholder: "Enter Your Email"
  },

  {
    label: "Vendor",
    value: "",
    name: "marketId",
    type: "select",
    // option: marketOption,
    placeholder: ""
  },
  {
    label: "SA/nSA",
    value: "",
    name: "userrole",
    type: "select",
    // option: [{ label: "Yes", value: listUserRoleTwoData[0]?.uniqueid },
    // { label: "No", value: listUserRoleTwoData[1]?.uniqueid }
    // ],
    placeholder: ""
  },

  {
    label: "SOW",
    value: "",
    name: "crewLeadCompanyId",
    type: "select",
    // option: crewCompanyOption,
    placeholder: ""
  },
  {
    label: "SOW others",
    value: "",
    name: "accountId",
    type: "select",
    // option: accountOption,
    placeholder: ""
  },
  {
    label: "",
    name: "crewType",
    type: "select",
    option: [{ label: "SA", value: "SA" },
    { label: "nSA", value: "nSA" },
    ],
    placeholder: ""
  },
  {
    label: "TT/CR",
    value: "",
    name: "h",
    type: "select",
    // option: accountOption,
    placeholder: ""
  },
  {
    label: "Updated information from GC after rejection",
    value: "",
    name: "i",
    type: "select",
    // option: accountOption,
    placeholder: ""
  },

]

const onSubmit = (data) => {
  // Handle form submission here, you can access form data in the 'data' object
  // console.log(data,item.uniqueid, "oashudnm");
  console.log(data,"dxfchjb")
  // if (item) {

  //   console.log("called","kabsd")
  //   dispatch(adminDashboardActions.postGCList(item.uniqueid, data, () => {
  //     dispatch(adminDashboardActions.getGCList())
  //   }))
  // }
  // else {
  //   console.log("called","dfcgvh")
  //   dispatch(adminDashboardActions.postGCList("",data, () => {
  //     dispatch(adminDashboardActions.getGCList())
  //   }))
  // }
  // setModal(false)
};


// if(item?.uniqueid)
//   {
//     console.log(Form, item, "xdrcg")
//     console.log("called_called")
//     Form.forEach((key) => {
//       console.log(`sdxghbj`,key.name, item[key.name])
//       if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
//         setValue(key.name, item[key.name]["$oid"]);

//       }
//       else {
//         setValue(key.name, item[key.name]);
//       }

//     })
//   }
  // useEffect(() => {
    
    // setValue("userrole", item.roleresult.poc)

  // }, [item])


return (

  <>
    {/* {console.log(item, "ghcfbj")} */}

    <form onSubmit={handleSubmit(onSubmit) }>
      <DATAPLUS_CommonForm Form={Form} register={register} />
      <button  type='submit' className='btn btn-primary'>
        Submit
      </button>
    </form>

  </>

)

}

export default CheckInRequestForm