import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import KPIModuleActions from '../../../../../store/actions/KPIModule-actions';
import { useNavigate, useParams } from 'react-router-dom';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';
// import troubleTicketActions from '../../../../../store/actions/TroubleTicket-actions';

const KPITroubleTicketForm = () => {

    // console.log(item,"item")
    const dispatch = useDispatch();
  const navigate=useNavigate()
  const {id} = useParams()
console.log(id,"id")

const[alerts,setAlerts] = useState(false)
  const navigateLink  =("/DATAPLUS/kpi/trouble/Ticket/dashboard")

const data = useSelector((state) => state?.KPIModuleReducer?.getKPITroubleTicketList)
console.log(data, "hjfbyj")

let item  = data.filter((itm)=>{

  if(id && itm?.ttUId === id){
    return itm
  }

})

if(id)item =item[0];
console.log(item, "item")

//   useEffect(() => {
//     dispatch(KPIModuleActions.getKPITroubleTicketList())

//   }, [])


//   const data = useSelector((state) => state?.KPIModuleReducer?.getKPITroubleTicketList)
//   console.log(data, "hjfbyj")

  
// let item  = data.filter((itm)=>{

//   if(itm.ttUId === id){
//     return itm
//   }

// })

// item =item[0];
// console.log(item, "item")

    const
    { register,
      handleSubmit,
      setError,
      watch,
      getValues,
      setValue,
      } = useForm();


    useEffect(()=>{
      console.log("Fetching CIQ sites data...");
        dispatch(KPIModuleActions?.getKPICIQSitesList())
    },[])

    const CIQSitesData = useSelector((state)=>state?.KPIModuleReducer?.getKPICIQSitesList)

    console.log(CIQSitesData,"jyvfybunk")

    const siteIdOption  = CIQSitesData?.map((itm)=>{return {label:itm._id,value:itm._id}})
    

    const selectedSiteId =  watch("siteUniqueId")
    console.log(selectedSiteId,"jybyk")


    // let dependingValues=[];
    let market = "";
    let account="";
    let paceId="";

   if(selectedSiteId !== "Select")
   {
        for(let i = 0; i<CIQSitesData.length;i++)
        {
            if(selectedSiteId === CIQSitesData[i]._id)
            {
                market  = CIQSitesData[i].market;
                account = CIQSitesData[i].account;
                paceId = CIQSitesData[i].siteDetail[0].uId;
                setValue("market",market)
                setValue("account",account)
                
                
            }
            else{
                continue;
            }
        }
   }

    console.log(market,account,"tjbt")


    useEffect(()=>{
      dispatch(KPIModuleActions.getKPIEngineerList())
    },[])

    const engineerData = useSelector((state)=>state?.KPIModuleReducer?.getKPIEngineerList)
console.log(engineerData,"egnineerData")

    const engineerOptions =engineerData.map((itm)=>{return {label:itm.name,value:itm.name}})

    console.log(engineerOptions,"enginerr")
    

      const Form =[
        {
          label:"Site ID",
          value:"",
          name:"siteUniqueId",
          type:"select",
          option:siteIdOption ? siteIdOption :[{}],
          setError:setError,
          getValues:getValues,
          setValue:setValue,
          required:true
          // placeholder:"Enter Your Name",
          // disabled: item?.ttUId ? true : false
          
        },
        {
          label:"Account",
          
          name:"account",
          type:"text",
          setError:setError,
          getValues:getValues,
          setValue:setValue,
        //   option:[{label:"Dharmender",value:"Dharmender"},
        //   {label:"Dharmender1",value:"Dharmender1"},
        //   {label:"Dharmender2",value:"Dharmender2"}
        // ],
          // placeholder:"Enter your mobile number",
          disabled:true,
          required:true
        },
        {
          label:"Market",
        
          name:"market",
          type:"text",
          setError:setError,
          getValues:getValues,
          setValue:setValue,
          disabled:true,
          required:true
     //      option:[{label:"Granted",value:"Granted"},
     //      {label:"Rejected",value:"Rejected"},
     //      {label:"Pending",value:"Pending"}
     //    ],
          // placeholder:"Enter Time Zone Name"
        },
        
        {
          label:"ATT ID",
          name:"ATTID",
          type:"text",
          setError:setError,
          getValues:getValues,
          setValue:setValue,
          required:true
          // placeholder:"Enter Your Email"
        },
        {
            label:"KPI Engineer Assigned",
            name:"KPITTEngineer",
            type:"select",
            option:engineerOptions,
            setError:setError,
            getValues:getValues,
            setValue:setValue,
            placeholder:"Enter Your Password ",
            required:true
          },
        
        {
            label:"Priority",
            value:"",
            name:"priority",
            type:"select",
            option:[{label:"Critical",value:"Critical"},
            {label:"Major",value:"Major"},
            {label:"Minor",value:"Minor"}
        ],
            setError:setError,
            getValues:getValues,
            setValue:setValue,
         //    option:marketOption,
            placeholder:"",
            required:true
          },
          {
            label:"voLTE Accessibility",
            value:"",
            name:"VoLTEAccessibility",
            type:"select",
            option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
            setError:setError,
            getValues:getValues,
            setValue:setValue,
           
            placeholder:"",
            required:true
          },
          
          {
            label:"voLTE Retainability",
            value:"",
            name:"VoLTERetainablity",
            type:"select",
            option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
            setError:setError,
            getValues:getValues,
            setValue:setValue,
         //    option:crewCompanyOption,
            placeholder:"",
            required:true
          },



          {
            label:"LTE Accessibility",
            value:"",
            name:"LTEAccessibility",
            type:"select",
            option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
            setError:setError,
            getValues:getValues,
            setValue:setValue,
            placeholder:"Enter Your Name",
            required:true
          },
          {
            label:"LTE Retainability",
          //   value:marketAccountOption?.account,
            name:"LTERetainablity",
            type:"select",
            option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
            setError:setError,
            getValues:getValues,
            setValue:setValue,
          //   option:[{label:"Dharmender",value:"Dharmender"},
          //   {label:"Dharmender1",value:"Dharmender1"},
          //   {label:"Dharmender2",value:"Dharmender2"}
          // ],
            placeholder:"Enter your mobile number",
            required:true
          },
          {
            label:"Traffice/Throughput",
          //   value:marketAccountOption?.market,
            name:"TrafficThroughput",
            type:"select",
            option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
            setError:setError,
            getValues:getValues,
            setValue:setValue,
       //      option:[{label:"Granted",value:"Granted"},
       //      {label:"Rejected",value:"Rejected"},
       //      {label:"Pending",value:"Pending"}
       //    ],
            placeholder:"Enter Time Zone Name",
            required:true
          },
          
          {
            label:"Mobility",
            name:"Mobility",
            type:"select",
            option:[{label:"OK",value:"OK"},
            {label:"Critical",value:"Critical"},
            {label:"Major Degradation",value:"Major Degradation"},
            {label:"Minor Degradation",value:"Minor Degradation"}
        ],
            setError:setError,
            getValues:getValues,
            setValue:setValue,
            placeholder:"Enter Your Email",
            required:true
          },
          {
              label:"Ticket Status",
              name:"KPITTStatus",
              type:"select",
              option:[{label:"Open",value:"Open"},
              {label:"Close",value:"Close"},
              {label:"In progress",value:"In progress"}],
              setError:setError,
              getValues:getValues,
              setValue:setValue,
              placeholder:"Enter Your Password ",
              required:true
            },
          
            {
                label:"Issue",
                name:"KPITTIssue",
                type:"text",
                // option:[{label:"Open",value:"Open"}],
                setError:setError,
                getValues:getValues,
                setValue:setValue,
                required:true
                // placeholder:"Enter Your Password "
              },
             
          
      ]

      const onSubmit = (data) => {
        // Handle form submission here, you can access form data in the 'data' object
        // data ={...data,siterId:uniqueId}
        console.log("sunmitjhvasd","submit")
        console.log(data,"fxgchjbklm")
        if (item) {

          console.log("called","kabsd")
          data={...data,sitePACEUniqueId:paceId,formStatus:1 }
          console.log(data,"dtfcgvbhjk")
          dispatch(KPIModuleActions.postKPITroubleTicket( data, () => {
            dispatch(KPIModuleActions.getKPITroubleTicketList())
          },item.ttUId))
        }
        else {
          console.log("called","dfcgvh")
          data={...data,sitePACEUniqueId:paceId,formStatus:1 }
          console.log(data,"ybtnvuy");
          dispatch(KPIModuleActions.postKPITroubleTicket(data,()=>{
              dispatch(KPIModuleActions.getKPITroubleTicketList())
          }))

          
        }
 
        // setModal(false)
          // navigate('/DATAPLUS/kpi/trouble/Ticket/dashboard')
          setAlerts(true)
      
          
        
  
      };

        useEffect(()=>{
          Form.forEach((key) => {
            // console.log(`sdxghbj`,key.name, item[key.name])
            if (["endAt", "startAt"].indexOf(key.name) != -1) {
              console.log("date formValuekey", key.name, item[key.name])
              const momentObj = moment(item[key.name]);
              setValue(key.name, momentObj.toDate());
              // if (typeof (item[key.name]) !== typeof ("") && "$oid" in item[key.name]) {
              //   setValue(key.name, item[key.name]["$oid"]);
        
              // }
              // else

          }
            else {
              setValue(key.name, item[key.name]);
            }
      
          })
        },[item?.ttUId])

       
        
 

  return (
    <>
       {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink}/>}
    <form onSubmit={handleSubmit(onSubmit)}>
     <DATAPLUS_CommonForm Form={Form} setValue={setValue} getValues={getValues} register={register}/>
     <button type='submit' className='btn btn-primary'>
       Submit
     </button>
   </form>
    </>
  )
}

export default KPITroubleTicketForm



// const KPITroubleTicketForm = () => {

//     const dispatch = useDispatch();

//     const
//     { register,
//       handleSubmit,
//       setError,
//       watch,
//       getValues,
//       setValue,
//       } = useForm();


//     useEffect(()=>{
//         dispatch(KPIModuleActions.getKPICIQSitesList())
//     },[])

//     const CIQSitesData = useSelector((state)=>state?.KPIModuleReducer?.getKPICIQSitesList)

//     console.log(CIQSitesData,"jyvfybunk")

//     const siteIdOption  = CIQSitesData.map((itm)=>{return {label:itm._id,value:itm._id, ...itm}})
    

//     const selectedSiteId = watch("siteId") 
   
//     if(selectedSiteId){
//         try {
//             const parsedData = JSON.parse(selectedSiteId)
//            console.log("parsedData",parsedData)
//             ['account','market','siteId'].forEach(key => {
//                 // if(key === 'siteId')  setValue(key,parsedData['value'])
//                  setValue("account",parsedData['account'])
//             })
//         } catch (error) {
            
//         }
//     }
//     console.log( getValues('market'),getValues('account'),"2222222___22222222")


//     // let dependingValues=[];
//     let market = "";
//     let account="";

//    if(selectedSiteId !== "Select")
//    {
//         for(let i = 0; i<CIQSitesData.length;i++)
//         {
//             if(selectedSiteId === CIQSitesData[i]._id)
//             {
//                 market  = CIQSitesData[i].market;
//                 account = CIQSitesData[i].account;
//             }
//             else{
//                 continue;
//             }
//         }
//    }

//     console.log(market,account,"tjbt")

//     // setValue("account","aslkjdfwsij")

//       const Form =[
//         {
//           label:"Site ID",
//           value:"",
//           name:"siteId",
//           type:"select",
//           option:siteIdOption,
//           setError:setError,
//           getValues:getValues,
//           setValue:setValue,
//           placeholder:"Enter Your Name",
//           isJson : true
//         },
//         {
//           label:"Account",
//           value:account,
//           name:"account",
//           type:"text",
//           setError:setError,
//           getValues:getValues,
//           setValue:setValue,
//         //   option:[{label:"Dharmender",value:"Dharmender"},
//         //   {label:"Dharmender1",value:"Dharmender1"},
//         //   {label:"Dharmender2",value:"Dharmender2"}
//         // ],
//           placeholder:"Enter your mobile number"
//         },
//         {
//           label:"Market",
//           value:market,
//           name:"market",
//           type:"text",
//           setError:setError,
//           getValues:getValues,
//           setValue:setValue,
//      //      option:[{label:"Granted",value:"Granted"},
//      //      {label:"Rejected",value:"Rejected"},
//      //      {label:"Pending",value:"Pending"}
//      //    ],
//           placeholder:"Enter Time Zone Name"
//         },
        
//         {
//           label:"ATT ID",
//           name:"attId",
//           type:"text",
//           setError:setError,
//           getValues:getValues,
//           setValue:setValue,
//           placeholder:"Enter Your Email"
//         },
//         {
//             label:"KPI Engineer Assigned",
//             name:"a",
//             type:"text",
//             // option:[{label:"Open",value:"Open"}],
//             setError:setError,
//             getValues:getValues,
//             setValue:setValue,
//             placeholder:"Enter Your Password "
//           },
        
//         {
//             label:"Priority",
//             value:"",
//             name:"b",
//             type:"select",
//             option:[{label:"Critical",value:"Critical"},
//             {label:"Major",value:"Major"},
//             {label:"Minor",value:"Minor"}
//         ],
//             setError:setError,
//             getValues:getValues,
//             setValue:setValue,
//          //    option:marketOption,
//             placeholder:""
//           },
//           {
//             label:"voLTE Accessibility",
//             value:"",
//             name:"c",
//             type:"select",
//             option:[{label:"OK",value:"OK"},
//             {label:"Critical",value:"Critical"},
//             {label:"Major Degradation",value:"Major Degradation"},
//             {label:"Minor Degradation",value:"Minor Degradation"}
//         ],
//             setError:setError,
//             getValues:getValues,
//             setValue:setValue,
           
//             placeholder:""
//           },
          
//           {
//             label:"voLTE Retainability",
//             value:"",
//             name:"d",
//             type:"select",
//             option:[{label:"OK",value:"OK"},
//             {label:"Critical",value:"Critical"},
//             {label:"Major Degradation",value:"Major Degradation"},
//             {label:"Minor Degradation",value:"Minor Degradation"}
//         ],
//             setError:setError,
//             getValues:getValues,
//             setValue:setValue,
//          //    option:crewCompanyOption,
//             placeholder:""
//           },



//           {
//             label:"LTE Accessibility",
//             value:"",
//             name:"e",
//             type:"select",
//             option:[{label:"OK",value:"OK"},
//             {label:"Critical",value:"Critical"},
//             {label:"Major Degradation",value:"Major Degradation"},
//             {label:"Minor Degradation",value:"Minor Degradation"}
//         ],
//             setError:setError,
//             getValues:getValues,
//             setValue:setValue,
//             placeholder:"Enter Your Name"
//           },
//           {
//             label:"LTE Retainability",
//           //   value:marketAccountOption?.account,
//             name:"f",
//             type:"select",
//             option:[{label:"OK",value:"OK"},
//             {label:"Critical",value:"Critical"},
//             {label:"Major Degradation",value:"Major Degradation"},
//             {label:"Minor Degradation",value:"Minor Degradation"}
//         ],
//             setError:setError,
//             getValues:getValues,
//             setValue:setValue,
//           //   option:[{label:"Dharmender",value:"Dharmender"},
//           //   {label:"Dharmender1",value:"Dharmender1"},
//           //   {label:"Dharmender2",value:"Dharmender2"}
//           // ],
//             placeholder:"Enter your mobile number"
//           },
//           {
//             label:"Traffice/Throughput",
//           //   value:marketAccountOption?.market,
//             name:"g",
//             type:"select",
//             option:[{label:"OK",value:"OK"},
//             {label:"Critical",value:"Critical"},
//             {label:"Major Degradation",value:"Major Degradation"},
//             {label:"Minor Degradation",value:"Minor Degradation"}
//         ],
//             setError:setError,
//             getValues:getValues,
//             setValue:setValue,
//        //      option:[{label:"Granted",value:"Granted"},
//        //      {label:"Rejected",value:"Rejected"},
//        //      {label:"Pending",value:"Pending"}
//        //    ],
//             placeholder:"Enter Time Zone Name"
//           },
          
//           {
//             label:"Mobility",
//             name:"h",
//             type:"select",
//             option:[{label:"OK",value:"OK"},
//             {label:"Critical",value:"Critical"},
//             {label:"Major Degradation",value:"Major Degradation"},
//             {label:"Minor Degradation",value:"Minor Degradation"}
//         ],
//             setError:setError,
//             getValues:getValues,
//             setValue:setValue,
//             placeholder:"Enter Your Email"
//           },
//           {
//               label:"Ticket Status",
//               name:"ticketStatus",
//               type:"select",
//               option:[{label:"Open",value:"Open"},
//               {label:"Close",value:"Close"},
//               {label:"In progress",value:"In progress"}],
//               setError:setError,
//               getValues:getValues,
//               setValue:setValue,
//               placeholder:"Enter Your Password "
//             },
          
//           {
//               label:"Issue",
//               value:"",
//               name:"issue",
//               type:"text",
//               // option:engineerOption,
//               setError:setError,
//               getValues:getValues,
//               setValue:setValue,
//            //    option:marketOption,
//               placeholder:""
//             },
             
          
//       ]

//       const onSubmit = (data) => {
//         // Handle form submission here, you can access form data in the 'data' object
//         // data ={...data,siterId:uniqueId}
 
//         // data={...data,userId:userId,pageFromData:"Trouble Ticket"}
//         console.log(data,"khnuy");
  
      
//         //   dispatch(troubleTicketActions.postTTDashboardList(data,()=>{
//         //       dispatch(troubleTicketActions.getTTDashboardList())
//         //   }))
        
  
//       };
 

//   return (
//     <>
//     <form onSubmit={handleSubmit(onSubmit)}>
//      <MPAG_CommonForm Form={Form} register={register}/>
//      <button type='submit' className='btn btn-primary'>
//        Submit
//      </button>
//    </form>
//     </>
//   )
// }