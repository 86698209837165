import React, { useEffect, useState } from 'react'
import MPAG_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import { useNavigate, useParams } from 'react-router-dom';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';

const UTRANEngineersForm = () => {

  const [alerts, setAlerts] = useState(false)
  const { id } = useParams()
  console.log(id, "id")
  const dispatch = useDispatch(); 
  const navigate = useNavigate()
  const navigateLink  =("/DATAPLUS/Engineers")

  useEffect(() => {
    dispatch(adminDashboardActions.getUTRANEngineersList())
  }, [])


  const data = useSelector((state) => state?.AdminDashboard_reducer?.getEngineerList)

  console.log(data, "habsjavbsd ")
  let item = data.filter((itm) => {

    if (itm?.uniqueid === id) {
      return itm
    }

  })

  item = item[0];
  console.log(item?.uniqueid,id,"P____________id")

  const
    { register,
      handleSubmit,
      setValue,
      control,formState: { errors }
    } = useForm();


  
        // ACCOUNT Options
        useEffect(()=>{
          dispatch(adminDashboardActions.getCustomerList())
        },[])
      
        const customerData  =  useSelector((state)=> state?.AdminDashboard_reducer?.getCustomerList)
    
        const accountOptions =customerData.map((itm)=>{
          return {
            label:itm.accountName,
            value:itm.accountName
          }
        })
    
        // marketoptions
        const marketOptions =customerData.map((itm)=>{
          return {
            label:itm.marketName,
            value:itm.marketName
          }



        })


        

  const Form = [
    {
      label: "Name",
      value: "",
      name: "name",
      type: "text",
      required:true
    },
    {
      label: "Company Name",
      name: "companyName",
      type: "text",
      required:true
    },
    {
      label: "Mobile Number",
      value: "",
      name: "mobilenumber",
      type: "text",
      required:true,
      validation:  {
              value: /^[0-9]{10}$/,
              message: 'please enter valid 10 digits Mobile Number'
            }
    },


    {
      label: "Email",
      name: "email",
      type: "text",
      placeholder: "Enter Your Email",
      required:true,
       validation:  {
              value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'please enter valid Email ID'
            }
    
    },
    {
      label: "Department",
      name: "department",
      type: "multiSelect",
      option:[{label:"CX SUPPORT",value:"CX SUPPORT"},
        {label:"IX SUPPORT",value:"IX SUPPORT"},
        {label:"KPI SUPPORT",value:"KPI SUPPORT"},
        {label:"E911 SUPPORT",value:"E911 SUPPORT"},
        {label:"ADMIN",value:"ADMIN"},
        {label:"CIQ & SCRIPTING",value:"CX SUPPORT"},
        {label:"TROUBLE TICKET",value:"TROUBLE TICKET"},
      ],
      // placeholder: "Enter Your Email",
      control: control,
      required:true
    },

    {
      label: "Account",
      value: "",
      name: "account",
      type: "multiSelect",
        option: accountOptions,
      placeholder: "",
      control: control,
      required:true
    },

    {
      label: "Market",
      value: "",
      name: "markets",
      type: "multiSelect",
        option: marketOptions,
      placeholder: "",
      control: control,
      required:true
    },

    {
      label: "Mobile App Access",
      value: "",
      name: "mobileAppAccess",
      type: "select",
        option: [{label:"Yes",value:"Yes"},
          {label:"No",value:"No"},
        ],
      placeholder: "",
      control: control,
      required:false
    },

    {
      label: "Password",
      value: "",
      name: "password",
      type: "text",
      placeholder: "",
      required:true
    },

    {
      label: "POC",
      value: "",
      name: "poc",
      type: "select",
        option: [{label:"Yes",value:"Yes"},
          {label:"No",value:"No"},
        ],
      placeholder: "",
      control: control,
      required:false
    },


  ]

  const onSubmit = (data) => {

    let accountData =data['account'].map((itm)=>{return itm.value})
    let departmentData =data['department'].map((itm)=>{return itm.value})
    let marketData =data['markets'].map((itm)=>{return itm.value})

    console.log(data,"___data")
   
      data ={...data,account:accountData, markets:marketData,department:departmentData}
    if (item) {

      console.log("called", "kabsd")
      dispatch(adminDashboardActions.postEngineerList(item.uniqueid, data, () => {
        dispatch(adminDashboardActions.getEngineerList())
      }))
    }
    else {
      console.log("called", "dfcgvh")
      dispatch(adminDashboardActions.postEngineerList("", data, 
        () => {
        dispatch(adminDashboardActions.getEngineerList())
      }

      ))
    }
    setTimeout(()=>{
      setAlerts(true)
    },1000)
   
  }
    ;


    if (item?.uniqueid) {
      console.log(Form, item, "xdrcg");
      console.log("called_called");
    
      Form.forEach((key) => {
        console.log(`sdxghbj`, key.name, item[key.name]);
    
        if (["endAt", "startAt"].indexOf(key.name) !== -1) {
          console.log("date formValuekey", key.name, item[key.name]);
          const momentObj = moment(item[key.name]);
          setValue(key.name, momentObj.toDate());
        } else {
          let value = item[key.name];
          if (Array.isArray(value)) {
            value = value.map(val => ({ label: val, value: val })); 
          }
          console.log(key.name, value, "___engineer");
          setValue(key.name, value);
        }
      });
    }


  return (

    <>
      {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} navigateLink={navigateLink}/>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <DATAPLUS_CommonForm Form={Form} register={register} errors={errors}  />
        <div style={{ width: 'full', display: 'flex', gap:'10px',justifyContent: 'center', marginTop: "20px" }}>
                <button style={{ margintop: "10" }}  className='btn btn-info'  onClick={()=>navigate(-1)}>
                    Back
                </button>
                    
                    <button style={{ margintop: "10" }} type='submit' className='btn btn-primary'>
                    Submit
                </button>
                
                </div>
      </form>

    </>

  )
}

export default UTRANEngineersForm