import React, { useEffect, useState } from 'react'
import SortingTable from '../../../../components/table/SortingTable/SortingTable'
import { useDispatch, useSelector } from 'react-redux'
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import MPAG_Modal from '../../../../components/DATAPLUS_Components/DATAPLUS_Modal';
import KPIDashboardForm from './KPIDashboardForm';
import KPIModuleActions from '../../../../../store/actions/KPIModule-actions';
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Badge, Button, Dropdown } from 'react-bootstrap';
import EditModal from '../../../../components/EditModal';
import KPIAddEngineer from './KPIAddEngineer';
import KPIAgeForm from './KPIAgeForm';
import FormModal from '../../../../components/DATAPLUS_Components/FormModal';

const KPIDashboard = () => {
  const [modal, setModal] = useState(false)
  const [itmValue, setItmValue] = useState({})
  const [key,setKey] = useState("")
  const[kpiAgeModal,setKpiAgeModal] = useState(false)
  const[heading,setHeading] = useState({})

  
  const[form,setForm]  = useState(false)
  const[actions,setActions]=useState(false)
  const[callDelete,setCallDelete] = useState(false)
  // const [itmValue, setItmValue] = useState({})

  

  const dispatch =  useDispatch();

  useEffect(()=>{
    dispatch(KPIModuleActions.getKPIDashboardList())
  },[])

  const data = useSelector((state)=> state?.KPIModuleReducer?.getKPIDashboardList)
  console.log(data,"uaksbudn")

// const timeArray = ["oneHour","twoHour","threeHour","fourHour","eightHour","oneDay","twoDay","threeDay","fourDay","fiveDay",
//   ,"sixDay","sevenDay"
// ]
 

// Modal Variables
// const tableHeading  = "KPI Dashboard"

const filterHeading  =  "KPI Dashboard Filter"
 
// 
const modalHeading = "Add Site"
const modalButton ="+ ";
let formComponent  =  (KPIDashboardForm)
// 

// 
let AddEngineer  = (KPIAddEngineer)
const KpiAgeForm  = (KPIAgeForm)
// 
    const AddFormButton  = (<Button style={{marginLeft:"10px"}}
    variant="primary"
    className="close"
    onClick={() => setForm(true)}
>
    + Add
</Button>)
 

  let tableData = data?.tableData?.map((itm)=>{
    return {
      "ILogout_timeStamp":itm.ILogout_timeStamp,
      "account":itm.account,
      "market":itm.market,
      "actualSiteId":itm?.actualSiteID,
      "usID":itm?.ndResult?.map((bar)=>{return bar.USID}),
      "kpiAge":`${itm.kpiAge}Hrs`,
      "KPIttid":itm?.ttReport?.KPIttid,
      "oneHourReport":(<Badge   onClick={() => {
              
        setKpiAgeModal(true)
        setItmValue(itm)
        setHeading({heading:"1st Hour",key:"oneHour"})
        // setKey("oneHourKPIEngineer")
      }}  bg=" badge-circle" className='badge-outline-danger'>
      {itm.kpiAge}
    </Badge>),
    "oneHourKPIEngineer":itm.oneHourKPIEngineer?((  <Dropdown>
      <Dropdown.Toggle
      style={{color:"white"}}
        variant={!itm.oneHourKPIEngineer && "success"}
        className="light sharp i-false"
        onClick={() => {
              
          setModal(true)
          setItmValue(itm)
          setKey("oneHourKPIEngineer")
        }}
      >
        {itm.oneHourKPIEngineer}
      </Dropdown.Toggle>
      
    </Dropdown>)):((  <Dropdown>
      <Dropdown.Toggle
        variant="success"
        className="light sharp i-false"
        onClick={() => {
              
          setModal(true)
          setItmValue(itm)
          setKey("oneHourKPIEngineer")
        }}
      >
        +
      </Dropdown.Toggle>
      
    </Dropdown>)),

"twoHourReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"2nd Hour",key:"secondHour"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),
"secondHourKPIEngineer":itm.secondHourKPIEngineer?((  <Dropdown>
  <Dropdown.Toggle
  style={{color:"white"}}
    variant={!itm.secondHourKPIEngineer && "success"}
    className="light sharp i-false"
    onClick={() => {
          
      setModal(true)
      setItmValue(itm)
      setKey("secondHourKPIEngineer")
    }}
  >
    {itm.secondHourKPIEngineer}
  </Dropdown.Toggle>
  
</Dropdown>)):((  <Dropdown>
  <Dropdown.Toggle
    variant="success"
    className="light sharp i-false"
    onClick={() => {
          
      setModal(true)
      setItmValue(itm)
      setKey("secondHourKPIEngineer")
    }}
  >
    +
  </Dropdown.Toggle>
  
</Dropdown>)),

"threeHourReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"3rd Hour",key:"threeHour"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),
"threeHourKPIEngineer":itm.threeHourKPIEngineer?((  <Dropdown>
  <Dropdown.Toggle
  style={{color:"white"}}
    variant={!itm.threeHourKPIEngineer && "success"}
    className="light sharp i-false"
    onClick={() => {
          
      setModal(true)
      setItmValue(itm)
      setKey("threeHourKPIEngineer")
    }}
  >
    {itm.threeHourKPIEngineer}
  </Dropdown.Toggle>
  
</Dropdown>)):((  <Dropdown>
  <Dropdown.Toggle
    variant="success"
    className="light sharp i-false"
    onClick={() => {
          
      setModal(true)
      setItmValue(itm)
      setKey("threeHourKPIEngineer")
    }}
  >
    +
  </Dropdown.Toggle>
  
</Dropdown>)),

"fourHourReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"4th Hour",key:"fourthHour"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),
"fourthHourKPIEngineer":itm.fourthHourKPIEngineer?((  <Dropdown>
  <Dropdown.Toggle
  style={{color:"white"}}
    variant={!itm.fourthHourKPIEngineer && "success"}
    className="light sharp i-false"
    onClick={() => {
          
      setModal(true)
      setItmValue(itm)
      setKey("fourthHourKPIEngineer")
    }}
  >
    {itm.fourthHourKPIEngineer}
  </Dropdown.Toggle>
  
</Dropdown>)):((  <Dropdown>
  <Dropdown.Toggle
    variant="success"
    className="light sharp i-false"
    onClick={() => {
          
      setModal(true)
      setItmValue(itm)
      setKey("fourthHourKPIEngineer")
    }}
  >
    +
  </Dropdown.Toggle>
  
</Dropdown>)),

"eightHourReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"8th Hour",key:"eightHour"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),


"eightHourKPIEngineer":itm.eightHourKPIEngineer?((  <Dropdown>
  <Dropdown.Toggle
  style={{color:"white"}}
    variant={!itm.eightHourKPIEngineer && "success"}
    className="light sharp i-false"
    onClick={() => {
          
      setModal(true)
      setItmValue(itm)
      setKey("eightHourKPIEngineer")
    }}
  >
    {itm.eightHourKPIEngineer}
  </Dropdown.Toggle>
  
</Dropdown>)):((  <Dropdown>
  <Dropdown.Toggle
    variant="success"
    className="light sharp i-false"
    onClick={() => {
          
      setModal(true)
      setItmValue(itm)
      setKey("eightHourKPIEngineer")
    }}
  >
    +
  </Dropdown.Toggle>
  
</Dropdown>)),

"oneDayReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"1st Day",key:"oneDay"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),

  "oneDayKPIEngineer":itm.oneDayKPIEngineer?((  <Dropdown>
    <Dropdown.Toggle
    style={{color:"white"}}
      variant={!itm.oneDayKPIEngineer && "success"}
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("oneDayKPIEngineer")
      }}
    >
      {itm.oneDayKPIEngineer}
    </Dropdown.Toggle>
    
  </Dropdown>)):((  <Dropdown>
    <Dropdown.Toggle
      variant="success"
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("oneDayKPIEngineer")
      }}
    >
      +
    </Dropdown.Toggle>
    
  </Dropdown>)),

"twoDayReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"2nd Day",key:"secondDay"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),

  "secondDayKPIEngineer":itm.secondDayKPIEngineer?((  <Dropdown>
    <Dropdown.Toggle
    style={{color:"white"}}
      variant={!itm.secondDayKPIEngineer && "success"}
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("secondDayKPIEngineer")
      }}
    >
      {itm.secondDayKPIEngineer}
    </Dropdown.Toggle>
    
  </Dropdown>)):((  <Dropdown>
    <Dropdown.Toggle
      variant="success"
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("secondDayKPIEngineer")
      }}
    >
      +
    </Dropdown.Toggle>
    
  </Dropdown>)),


"threeDayReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"3rd Day",key:"threeDay"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),

  "threeDayKPIEngineer":itm.threeDayKPIEngineer?((  <Dropdown>
    <Dropdown.Toggle
    style={{color:"white"}}
      variant={!itm.threeDayKPIEngineer && "success"}
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("threeDayKPIEngineer")
      }}
    >
      {itm.threeDayKPIEngineer}
    </Dropdown.Toggle>
    
  </Dropdown>)):((  <Dropdown>
    <Dropdown.Toggle
      variant="success"
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("threeDayKPIEngineer")
      }}
    >
      +
    </Dropdown.Toggle>
    
  </Dropdown>)),

"fourDayReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"4th Day",key:"fourthDay"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),

  "fourthDayKPIEngineer":itm.fourthDayKPIEngineer?((  <Dropdown>
    <Dropdown.Toggle
    style={{color:"white"}}
      variant={!itm.fourthDayKPIEngineer && "success"}
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("fourthDayKPIEngineer")
      }}
    >
      {itm.fourthDayKPIEngineer}
    </Dropdown.Toggle>
    
  </Dropdown>)):((  <Dropdown>
    <Dropdown.Toggle
      variant="success"
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("fourthDayKPIEngineer")
      }}
    >
      +
    </Dropdown.Toggle>
    
  </Dropdown>)),

"fiveDayReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"5th Day",key:"fiveDay"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),

  "fiveDayKPIEngineer":itm.fiveDayKPIEngineer?((  <Dropdown>
    <Dropdown.Toggle
    style={{color:"white"}}
      variant={!itm.fiveDayKPIEngineer && "success"}
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("fiveDayKPIEngineer")
      }}
    >
      {itm.fiveDayKPIEngineer}
    </Dropdown.Toggle>
    
  </Dropdown>)):((  <Dropdown>
    <Dropdown.Toggle
      variant="success"
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("fiveDayKPIEngineer")
      }}
    >
      +
    </Dropdown.Toggle>
    
  </Dropdown>)),

"sixDayReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"6th Day",key:"sixDay"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),

  "sixDayKPIEngineer":itm.sixDayKPIEngineer?((  <Dropdown>
    <Dropdown.Toggle
    style={{color:"white"}}
      variant={!itm.sixDayKPIEngineer && "success"}
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("sixDayKPIEngineer")
      }}
    >
      {itm.sixDayKPIEngineer}
    </Dropdown.Toggle>
    
  </Dropdown>)):((  <Dropdown>
    <Dropdown.Toggle
      variant="success"
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("sixDayKPIEngineer")
      }}
    >
      +
    </Dropdown.Toggle>
    
  </Dropdown>)),

"sevenDayReport":(<Badge   onClick={() => {
              
  setKpiAgeModal(true)
  setItmValue(itm)
  setHeading({heading:"7th Day",key:"sevenDay"})
  // setKey("oneHourKPIEngineer")
}}  bg=" badge-circle" className='badge-outline-danger'>
{itm.kpiAge}
</Badge>),

  "sevenDayKPIEngineer":itm.sevenDayKPIEngineer?((  <Dropdown>
    <Dropdown.Toggle
    style={{color:"white"}}
      variant={!itm.sevenDayKPIEngineer && "success"}
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("sevenDayKPIEngineer")
      }}
    >
      {itm.sevenDayKPIEngineer}
    </Dropdown.Toggle>
    
  </Dropdown>)):((  <Dropdown>
    <Dropdown.Toggle
      variant="success"
      className="light sharp i-false"
      onClick={() => {
            
        setModal(true)
        setItmValue(itm)
        setKey("sevenDayKPIEngineer")
      }}
    >
      +
    </Dropdown.Toggle>
    
  </Dropdown>)),



    }
  })

  console.log(tableData,"tableData")
  

  const tableColumns = [
    {
      Header: "REQUESTED DATE",
      accessor: "ILogout_timeStamp",
      Filter: "",
      
    },
    {
      Header: "ACCOUNT",
      accessor: "account",
      Filter: ColumnFilter,
      
    },
    {
      Header: "MARKET",
      accessor: "market",
      Filter: ColumnFilter,
      
    },
    //nhi pta api mai kha h
    {
      Header: "SITE ID",
      accessor: "actualSiteId",
      Filter: ColumnFilter,
    },
    {
      Header: "USID",
      accessor: "usID",
      Filter: "",
      
    },
    {
      Header: "AGING",
      accessor: "kpiAge",
      Filter: "",
      
    },
    {
      Header: "KPI TT",
      accessor: "KPIttid",
      Filter: "",
      
    },
    {
      Header: "1ST HOUR REPORT",
      accessor: "oneHourReport",
      Filter: "",
      
    },
    {
      Header: "1ST HOUR KPI ENGINEER",
      accessor: "oneHourKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "2ND HOUR REPORT",
      accessor: "twoHourReport",
      Filter: "",
      
    },
    {
      Header: "2ND HOUR KPI ENGINEER",
      accessor: "secondHourKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "3RD HOUR REPORT",
      accessor: "threeHourReport",
      Filter: "",
      
    },
    {
      Header: "3RD HOUR KPI ENGINEER",
      accessor: "threeHourKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "4TH HOUR REPORT",
      accessor: "fourHourReport",
      Filter: "",
      
    },
    {
      Header: "4TH HOUR KPI ENGINEER",
      accessor: "fourthHourKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "8TH HOUR REPORT",
      accessor: "eightHourReport",
      Filter: "",
      
    },
    {
      Header: "8TH HOUR KPI ENGINEER",
      accessor: "eightHourKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "1ST DAY REPORT",
      accessor: "oneDayReport",
      Filter: "",
      
    },

    {
      Header: "1ST DAY KPI ENGINEER",
      accessor: "oneDayKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "2ND DAY REPORT",
      accessor: "twoDayReport",
      Filter: "",
      
    },

    {
      Header: "2ND DAY KPI ENGINEER",
      accessor: "secondDayKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "3RD DAY REPORT",
      accessor: "threeDayReport",
      Filter: "",
      
    },
    {
      Header: "3RD DAY KPI ENGINEER",
      accessor: "threeDayKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "4TH DAY REPORT",
      accessor: "fourDayReport",
      Filter: "",
      
    },
    {
      Header: "4TH DAY KPI ENGINEER",
      accessor: "fourthDayKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "5TH DAY REPORT",
      accessor: "fiveDayReport",
      Filter: "",
      
    },

    {
      Header: "5TH DAY KPI ENGINEER",
      accessor: "fiveDayKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "6TH DAY REPORT",
      accessor: "sixDayReport",
      Filter: "",
      
    },

    {
      Header: "6TH DAY KPI ENGINEER",
      accessor: "sixDayKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "FINAL KPI 7TH DAY REPORT",
      accessor: "sevenDayReport",
      Filter: "",
      
    },
    {
      Header: "FINAL DAY KPI ENGINEER",
      accessor: "sevenDayKPIEngineer",
      Filter: "",
      
    },
    {
      Header: "ACTIONS",
      accessor: "actions",
      Filter: "",
      
    },
    
    
  ]

  const GROUPED_COLUMNS = [
    {
      Header : 'Id',
      Footer : 'Id',
      accessor: 'id'
    },
    {
      Header : 'Name',
      Footer : 'Name',
      columns: [
        {
          Header : 'First Name',
          Footer : 'First Name',
          accessor: 'first_name'
        },
        {
          Header : 'Last Name',
          Footer : 'Last Name',
          accessor: 'last_name'
        },
      ]
    },
    {
      Header: 'Info',
      Footer: 'Info',
      columns: [
        {
          Header : 'Date of  Birth',
          Footer : 'Date of  Birth',
          accessor: 'date_of_birth'
        },
        {
          Header : 'Country',
          Footer : 'Country',
          accessor: 'country',
        },
        {
          Header : 'Phone',
          Footer : 'Phone',
          accessor: 'phone'
        },
      ]
    },
  ]

  return (
    <>
  

{form && <FormModal modalHeading={modalHeading} isOpen={form} setModal={setForm} FormComponent={formComponent} itmValue={itmValue}/>}
    {console.log(modal,kpiAgeModal,"kpiAgeModal")}
    {/* {console.log(key,"key")} */}
    {/* {kpiAgeModal && <EditModal  setModal={setKpiAgeModal} isOpen={kpiAgeModal} 
    FormComponent={KpiAgeForm} itmValue={itmValue} Heading={heading}/>} */}

{kpiAgeModal && <FormModal modalHeading={modalHeading} isOpen={kpiAgeModal} setModal={setKpiAgeModal}
 FormComponent={KpiAgeForm} itmValue={itmValue} Heading={heading}/>}
    {/* add Engineer Modal */}
   {/* {modal && <EditModal keyValue={key} modalHeading={modalHeading} 
    setModal={setModal} isOpen={modal} FormComponent={AddEngineer} itmValue={itmValue} />} */}
   
   {modal && <FormModal keyValue={key} modalHeading={modalHeading} isOpen={modal} setModal={setModal}
 FormComponent={AddEngineer} itmValue={itmValue} />}
      {/* <MPAG_Modal  modalButton={modalButton} FormComponent={formComponent}/> */}

      {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
    <div>
        <FilteringTable AddFormButton={AddFormButton} filterHeading={filterHeading} tableColumns={tableColumns} tableData={tableData} />
        {/* tableHeading={tableHeading} */}
      </div>
    </>
  )

}

export default KPIDashboard