import React, { useState } from 'react'
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";

const EditModal = ({ setActions,FormComponent,setModal,isOpen,itmValue="",modalHeading,keyValue="", Heading={}}) => {
    // const [largeModal, setLargeModal] = useState(true)
        console.log(keyValue,"keyValue")
    return (
        <>
        
            {console.log(Heading, "ghcbjk")}
            <Modal
                className="fade bd-example-modal-lg"
                show={isOpen}
                size="lg"
            >
                <Modal.Header>
                    {/* {modalHeading} */}
                    {/* <Modal.Title>Modal title</Modal.Title> */}
                    {Heading.heading ? `${Heading.heading} Report` : modalHeading}
                    <Button
                        variant=""
                        className="close"
                        onClick={() => setModal(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>

                    {/* form */}

                    <div className='grid-cols-4 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='basic-form'>

                                    <FormComponent setActions={setModal} item ={itmValue} keyValue={keyValue} Heading={Heading} />

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* form end */}

                </Modal.Body>

            </Modal>
        </>
    )
}

export default EditModal
