export const MenuList = [
    //Dashboard
    
    {
        title: 'Dashboard',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="flaticon-layout" />,
        content: [
           
            {
                title: 'Dashboard Light',
                to: 'dashboard-light',
            },
            {
                title: 'GC Dashboard',
                to: '/DATAPLUS/vendor/dashboard',					
            },
            {
                title: 'Agent Login Dashboard',
                to: '/DATAPLUS/aws/dashboard',					
            },
            // {
            //     title: 'Event List',
            //     to: 'event-list',
            // },
            // {
            //     title: 'Event',
            //     to: 'event',
            // },
            // {
            //     title: 'Customer',
            //     to: 'customers',                
            // },
            // {
            //     title: 'Analytics',
            //     to: 'analytics',
                
            // },
			// {
            //     title: 'Review' ,
            //     to: 'reviews',                
            // },
			
			// {
            //     title: 'Task',
            //     to: 'task',                
            // },    
        ],
    },
    // {
    //     title: 'Dashboards',	
    //     classsChange: 'mm-collapse',		
    //     iconStyle: <i className="flaticon-layout" />,
    //     content: [
    //         {
    //             title: 'Dashboard',
    //             to: 'dashboard',					
    //         },
    //         // {
    //         //     title: ' Dashboard',
    //         //     to: '/DATAPLUS/customer/dashboard',					
    //         // },
    //         {
    //             title: 'GC Dashboard',
    //             to: '/DATAPLUS/vendor/dashboard',					
    //         },
    //         {
    //             title: 'Agent Login Dashboard',
    //             to: '/DATAPLUS/aws/dashboard',					
    //         },
    //         // {
    //         //     title: 'Example Page',
    //         //     to: '/Example',					
    //         // },
    //         // {
    //         //     title: 'Dashboard Light',
    //         //     to: 'dashboard-light',
    //         // },
    //         // {
    //         //     title: 'Event List',
    //         //     to: 'event-list',
    //         // },
    //         // {
    //         //     title: 'Event',
    //         //     to: 'event',
    //         // },
    //         // {
    //         //     title: 'Customer',
    //         //     to: 'customers',                
    //         // },
    //         // {
    //         //     title: 'Analytics',
    //         //     to: 'analytics',
                
    //         // },
	// 		// {
    //         //     title: 'Review' ,
    //         //     to: 'reviews',                
    //         // },
			
	// 		// {
    //         //     title: 'Task',
    //         //     to: 'task',                
    //         // },    
    //     ],
    // },

    {
        title : "Projects",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="flaticon-381-diploma" />,
        content:[
            {
                title:'Project',
                to:'/DATAPLUS/projects'
            },
            {
                title:'Project Progress',
                to:'/DATAPLUS/Project/Progress'
            },
            {
                title:'GO/NO GO Support',
                to:'/DATAPLUS/GO-NOGO/dashboard'
            },

            {
                title : "CX SUPPORT",
                classsChange: 'mm-collapse',
                update:"New",
                content:[
                    {
                        title:'CX Dashboard',
                        to:'/DATAPLUS/cx/support/dashboard'
                    },
                   
        ],},{
                title : "IX SUPPORT",
                classsChange: 'mm-collapse',
                update:"New",
                content:[
                    {
                        title:'IX Scheduler',
                        to:'/DATAPLUS/ix/scheduler'
                    },
                    {
                        title:'IX Calender',
                        to:'/DATAPLUS/ix/calender'
                    },
                    {
                        title:'IX Dashboard',
                        to:'/DATAPLUS/ix/dashboard'
                    },
        ],},{
                title : "KPI SUPPORT",
                classsChange: 'mm-collapse',
                update:"New",
                content:[
                    {
                        title:'KPI Dashboard',
                        to:'/DATAPLUS/kpi/dashboard'
                    },
                   
                 
        ],},{
            title : "CALL SUPPORT",
            classsChange: 'mm-collapse',
            update:"New",
            content:[
                {
                    title:'E911 Dashboard',
                    to:'/DATAPLUS/e911/dashboard'
                },{
                    title:'PSAP Dashboard',
                    to:'/DATAPLUS/psap/dashboard'
                },
    ],},{
        title : "REPORTS",
        classsChange: 'mm-collapse',
        update:"New",
        content:[
             {
                title:'Daily Activity Report',
                to:'/DATAPLUS/daily/activity/Report'
            },
             {
                title:'SLA Report',
                to:'/DATAPLUS/sla/report'
            }, {
                title:'CX Tracker',
                to:'/DATAPLUS/cx/support/tracker'
            },
            {
                title:'KPI Tracker',
                to:'/DATAPLUS/kpi/tracker'
            },
],},
            // {
            //     title:'RAN Dashboard',
            //     to:'/DATAPLUS/ran/support/dashboard'
            // },
            // {
            //     title:'RAN Tracker',
            //     to:'/DATAPLUS/ran/support/tracker'
            // },
            // {
            //     title:'Daily Activity Report',
            //     to:'/DATAPLUS/ran/support/dailyReport'
            // },
            // {
            //     title:'SLA Report',
            //     to:'/DATAPLUS/ran/sla/report'
            // },

            // IX SUpport
            // {
            //     title:'IX Scheduler',
            //     to:'/DATAPLUS/ix/module'
            // },
            // {
            //     title:'IX Calender',
            //     to:'/DATAPLUS/ix/calender'
            // },
            // {
            //     title:'IX Dashboard',
            //     to:'/DATAPLUS/ix/dashboard'
            // },
             // KPI DASHBOARD
            //  {
            //     title:'KPI Dashboard',
            //     to:'/DATAPLUS/kpi/dashboard'
            // },
            // {
            //     title:'KPI Tracker',
            //     to:'/DATAPLUS/kpi/tracker'
            // },
            // {
            //     title:'KPI Trouble Ticket',
            //     to:'/DATAPLUS/kpi/troubleTicket'
            // },

            // E911 Call test
            // {
            //     title:'E911 Dashboard',
            //     to:'/DATAPLUS/e911/dashboard'
            // },
            // {
            //     title:'PSAP Dashboard',
            //     to:'/DATAPLUS/psap/dashboard'
            // },
        ],
    },
    
    // IX Support
    // {
    //     title : "IX Support",
    //     classsChange: 'mm-collapse',
    //     update:"New",
    //     iconStyle: <i className="flaticon-381-diploma" />,
    //     content:[
    //         {
    //             title:'IX Scheduler',
    //             to:'/DATAPLUS/ix/module'
    //         },
    //         {
    //             title:'IX Calender',
    //             to:'/DATAPLUS/ix/calender'
    //         },
    //         {
    //             title:'IX Dashboard',
    //             to:'/DATAPLUS/ix/dashboard'
    //         },
           
            
    //     ],
    // },

    // {
    //     title : "KPI Module",
    //     classsChange: 'mm-collapse',
    //     update:"New",
    //     iconStyle: <i className="flaticon-381-id-card" />,
    //     content:[
    //         {
    //             title:'KPI Dashboard',
    //             to:'/DATAPLUS/kpi/dashboard'
    //         },
    //         {
    //             title:'KPI Tracker',
    //             to:'/DATAPLUS/kpi/tracker'
    //         },
    //         {
    //             title:'KPI Trouble Ticket',
    //             to:'/DATAPLUS/kpi/troubleTicket'
    //         },
    //     ],
    // },
    // {
    //     title:'Check In Request',
    //     to:"/DATAPLUScheckIn/dashboard",
    //     iconStyle: <i className="flaticon-381-id-card-4" />,
    // },
    {
        title : "Trouble Tickets",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="flaticon-381-id-card-4" />,
        content:[
            {
                title:'TT Dashboard',
                to:'/DATAPLUS/trouble/Ticket/dashboard'
            },   {
                title:'KPI Trouble Ticket',
                to:'/DATAPLUS/kpi/trouble/Ticket/dashboard'
            },

            // {
            //     title:'Chat',
            //     to:'chat'
            // },
        ],
    },
    {
        title : "CIQ & Scripting",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="flaticon-381-id-card-4" />,
        content:[
            {
                title:'CIQ & Scripting Dashboard',
                to:'/DATAPLUS/CIQ/&/Scripting/dashboard'
            },
            
        ],
    },
    
    //Apps
    // {
    //     title: 'CIQ & Scripting',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-monitor" />,
    //     content: [
    //         {
    //             title: 'CIQ & Scripting',
    //             to: 'app-profile'
    //         },
    //         // {
    //         //     title: 'Edit Profile',
    //         //     to: 'edit-profile'
    //         // },
    //         // {
    //         //     title: 'Post Details',
    //         //     to: 'post-details'
    //         // },
    //         // {
    //         //     title: 'Email',
    //         //     //to: './',
    //         //     hasMenu : true,
    //         //     content: [
    //         //         {
    //         //             title: 'Compose',
    //         //             to: 'email-compose',
    //         //         },
    //         //         {
    //         //             title: 'Index',
    //         //             to: 'email-inbox',
    //         //         },
    //         //         {
    //         //             title: 'Read',
    //         //             to: 'email-read',
    //         //         }
    //         //     ],
    //         // },
    //         // {
    //         //     title:'Calendar',
    //         //     to: 'app-calender'
    //         // },
    //         // {
    //         //     title: 'Shop',
    //         //     //to: './',
    //         //     hasMenu : true,
    //         //     content: [
    //         //         {
    //         //             title: 'Product Grid',
    //         //             to: 'ecom-product-grid',
    //         //         },
    //         //         {
    //         //             title: 'Product List',
    //         //             to: 'ecom-product-list',
    //         //         },
    //         //         {
    //         //             title: 'Product Details',
    //         //             to: 'ecom-product-detail',
    //         //         },
    //         //         {
    //         //             title: 'Order',
    //         //             to: 'ecom-product-order',
    //         //         },
    //         //         {
    //         //             title: 'Checkout',
    //         //             to: 'ecom-checkout',
    //         //         },
    //         //         {
    //         //             title: 'Invoice',
    //         //             to: 'ecom-invoice',
    //         //         },
    //         //         {
    //         //             title: 'Customers',
    //         //             to: 'ecom-customers',
    //         //         },
    //         //     ],
    //         // },
    //     ],
    // },

    // {
    //     title : "E911 Call Test",
    //     classsChange: 'mm-collapse',
    //     update:"New",
    //     iconStyle: <i className="flaticon-381-id-card-4" />,
    //     content:[
    //         {
    //             title:'E911 Dashboard',
    //             to:'/DATAPLUS/e911/dashboard'
    //         },
    //         {
    //             title:'PSAP Dashboard',
    //             to:'/DATAPLUS/psap/dashboard'
    //         },
    //     ],
    // },

    {
        title : "Admin Dashboard",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="flaticon-381-id-card-4" />,
        content:[

            
            // {
            //     title:'table',
            //     to:'/DATAPLUS/table'
            // },
            {
                title:'Accounts',
                to:'/DATAPLUS/accounts'
            },
            {
                title:'Markets',
                to:'/DATAPLUS/markets'
            },

            
            // {
            //     title:'GC',
            //     to:'/DATAPLUS/gc'
            // },
           
            {
                title:'Engineers',
                to:'/DATAPLUS/Engineers'
            },
            // {
            //     title:'Add Markets',
            //     to:'/DATAPLUS/manage/market'
            // },
            {
                title:'ENM',
                to:'/DATAPLUS/enm'
            },

            {
                title:'Site Config',
                to:'/DATAPLUS/site/config'
            },
            

            // {
            //     title:' Site',
            //     to:'/DATAPLUS/manageSites'
            // },
            
            // {
            //     title:'Manage Utilization',
            //     to:'/DATAPLUS/manage/utilization'
            // },

            // {
            //     title:'Upload Master',
            //     to:'/DATAPLUS/form-redux-wizard'
            // },
            // {
            //     title:'Manage Dropdown List',
            //     to:'/DATAPLUS/manage/dropDown'
            // },
        ],
    },

  


    // {
    //     title : "CMS",
    //     classsChange: 'mm-collapse',
    //     update:"New",
    //     iconStyle: <i className="flaticon-381-diploma" />,
    //     content:[
    //         {
    //             title:'Content',
    //             to:'content'
    //         },
    //         {
    //             title:'Menu',
    //             to:'menu-1'
    //         },
    //         {
    //             title:'Email Template',
    //             to:'email-template'
    //         },
    //         {
    //             title:'Blog',
    //             to:'blog'
    //         },
    //     ],
    // },
    // {
    //     title : "Ticket",
    //     classsChange: 'mm-collapse',
    //     update:"New",
    //     iconStyle: <i className="flaticon-381-id-card" />,
    //     content:[
    //         {
    //             title:'Create Ticket',
    //             to:'create-ticket'
    //         },
    //         {
    //             title:'All Ticket',
    //             to:'all-ticket'
    //         },
    //     ],
    // },
    // {
    //     title : "Customers",
    //     classsChange: 'mm-collapse',
    //     update:"New",
    //     iconStyle: <i className="flaticon-381-id-card-4" />,
    //     content:[
    //         {
    //             title:'Customers List',
    //             to:'customers-list'
    //         },
    //         {
    //             title:'Chat',
    //             to:'chat'
    //         },
    //     ],
    // },
    
    // //Apps
    // {
    //     title: 'Apps',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-monitor" />,
    //     content: [
    //         {
    //             title: 'Profile',
    //             to: 'app-profile'
    //         },
    //         {
    //             title: 'Edit Profile',
    //             to: 'edit-profile'
    //         },
    //         {
    //             title: 'Post Details',
    //             to: 'post-details'
    //         },
    //         {
    //             title: 'Email',
    //             //to: './',
    //             hasMenu : true,
    //             content: [
    //                 {
    //                     title: 'Compose',
    //                     to: 'email-compose',
    //                 },
    //                 {
    //                     title: 'Index',
    //                     to: 'email-inbox',
    //                 },
    //                 {
    //                     title: 'Read',
    //                     to: 'email-read',
    //                 }
    //             ],
    //         },
    //         {
    //             title:'Calendar',
    //             to: 'app-calender'
    //         },
    //         {
    //             title: 'Shop',
    //             //to: './',
    //             hasMenu : true,
    //             content: [
    //                 {
    //                     title: 'Product Grid',
    //                     to: 'ecom-product-grid',
    //                 },
    //                 {
    //                     title: 'Product List',
    //                     to: 'ecom-product-list',
    //                 },
    //                 {
    //                     title: 'Product Details',
    //                     to: 'ecom-product-detail',
    //                 },
    //                 {
    //                     title: 'Order',
    //                     to: 'ecom-product-order',
    //                 },
    //                 {
    //                     title: 'Checkout',
    //                     to: 'ecom-checkout',
    //                 },
    //                 {
    //                     title: 'Invoice',
    //                     to: 'ecom-invoice',
    //                 },
    //                 {
    //                     title: 'Customers',
    //                     to: 'ecom-customers',
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // //Charts
    // {
    //     title: 'Charts',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-bar-chart-1" />,
    //     content: [
            
    //         {
    //             title: 'RechartJs',
    //             to: 'chart-rechart',					
    //         },
    //         {
    //             title: 'Chartjs',
    //             to: 'chart-chartjs',					
    //         },
    //         {
    //             title: 'Sparkline',
    //             to: 'chart-sparkline',					
    //         },
    //         {
    //             title: 'Apexchart',
    //             to: 'chart-apexchart',					
    //         },
    //     ]
    // },
    // // //Boosttrap
    // {
    //     title: 'Bootstrap',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-web" />,	
    //     content: [
    //         {
    //             title: 'Accordion',
    //             to: 'ui-accordion',					
    //         },
    //         {
    //             title: 'Alert',
    //             to: 'ui-alert',					
    //         },
    //         {
    //             title: 'Badge',
    //             to: 'ui-badge',					
    //         },
    //         {
    //             title: 'Button',
    //             to: 'ui-button',					
    //         },
    //         {
    //             title: 'Modal',
    //             to: 'ui-modal',					
    //         },
    //         {
    //             title: 'Button Group',
    //             to: 'ui-button-group',					
    //         },
    //         {
    //             title: 'List Group',
    //             to: 'ui-list-group',					
    //         },
    //         {
    //             title: 'Cards',
    //             to: 'ui-card',					
    //         },
    //         {
    //             title: 'Carousel',
    //             to: 'ui-carousel',					
    //         },
    //         {
    //             title: 'Dropdown',
    //             to: 'ui-dropdown',					
    //         },
    //         {
    //             title: 'Popover',
    //             to: 'ui-popover',					
    //         },
    //         {
    //             title: 'Progressbar',
    //             to: 'ui-progressbar',					
    //         },
    //         {
    //             title: 'Tab',
    //             to: 'ui-tab',					
    //         },
    //         {
    //             title: 'Typography',
    //             to: 'ui-typography',					
    //         },
    //         {
    //             title: 'Pagination',
    //             to: 'ui-pagination',					
    //         },
    //         {
    //             title: 'Grid',
    //             to: 'ui-grid',					
    //         },
    //     ]
    // },
    // //plugins
    // {
    //     title:'Plugins',
    //     classsChange: 'mm-collapse',
    //     iconStyle : <i className="flaticon-plugin" />,
    //     content : [
    //         {
    //             title:'Select 2',
    //             to: 'uc-select2',
    //         },
    //         // {
    //         //     title:'Noui Slider',
    //         //     to: 'uc-noui-slider',
    //         // },
    //         {
    //             title:'Sweet Alert',
    //             to: 'uc-sweetalert',
    //         },
    //         {
    //             title:'Toastr',
    //             to: 'uc-toastr',
    //         },
    //         {
    //             title:'Jqv Map',
    //             to: 'map-jqvmap',
    //         },
    //         {
    //             title:'Light Gallery',
    //             to: 'uc-lightgallery',
    //         },
    //     ]
    // },
    // //Widget
    // {   
    //     title:'Widget',
    //     //classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-admin"></i>,
    //     to: 'widget-basic',
    // },
    
    // //Forms
    // {
    //     title:'Forms',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-contract"></i>,
    //     content : [
    //         {
    //             title:'Form Elements',
    //             to: 'form-element',
    //         },
    //         {
    //             title:'Wizard',
    //             to: 'form-wizard',
    //         },
    //         {
    //             title:'CkEditor',
    //             to: 'form-ckeditor',
    //         },
    //         {
    //             title:'Pickers',
    //             to: 'form-pickers',
    //         },
    //         {
    //             title:'Form Validate',
    //             to: 'form-validation',
    //         },

    //     ]
    // },
    // // //Table
    // {
    //     title:'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-table"></i>,
    //     content : [
    //         {
    //             title:'Table Filtering',
    //             to: 'table-filtering',
    //         },
    //         {
    //             title:'Table Sorting',
    //             to: 'table-sorting',
    //         },
    //         {
    //             title:'Bootstrap',
    //             to: 'table-bootstrap-basic',
    //         },
           

    //     ]
    // },
    // {
    //     title:'Report',
    //     to: 'reports',
    //     update:"New",
    //     iconStyle : <i className="flaticon-381-list" />,

    // },
    //Pages
    // {
    //     title:'Pages',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-newsletter"></i>,
    //     content : [
    //         {
    //             title:'Error',
    //             hasMenu : true,
    //             content : [
    //                 {
    //                     title: 'Error 400',
    //                     to : 'page-error-400',
    //                 },
    //                 {
    //                     title: 'Error 403',
    //                     to : 'page-error-403',
    //                 },
    //                 {
    //                     title: 'Error 404',
    //                     to : 'page-error-404',
    //                 },
    //                 {
    //                     title: 'Error 500',
    //                     to : 'page-error-500',
    //                 },
    //                 {
    //                     title: 'Error 503',
    //                     to : 'page-error-503',
    //                 },
    //                 {
    //                     title: 'Example',
    //                     to : 'page-error-5000000000',
    //                 },
    //             ],
    //         },
    //         {
    //             title:'Lock Screen',
    //             to: 'page-lock-screen',
    //         },

    //     ]
    // },
    
]