import React, { useEffect } from 'react'
import MPAG_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm'
import { useDispatch, useSelector } from 'react-redux'
import troubleTicketActions from '../../../../../store/actions/TroubleTicket-actions'
import { useForm } from 'react-hook-form'
import KPIModuleActions from '../../../../../store/actions/KPIModule-actions'
import IXSupportActions from '../../../../../store/actions/IXSupport-actions'
import { ALERTS } from '../../../../../store/reducers/component-reducer'

const AssignedEngineerForm = ({item,keyValue}) => {
  
    console.log(item,keyValue,"ujbfksbhjkd")
    const dispatch = useDispatch()

console.log(item,keyValue,"item")
    useEffect(()=>{
        dispatch(troubleTicketActions.getManageEngineerList())
    },[])

    const engineerData  = useSelector((state)=>state?.TroubleTicketReducer?.getManageEngineerList)
    console.log(engineerData,"jhabsdu")

    const engineerOption = engineerData.map((itm)=>{return {label:itm.name, value:itm.name}})

    const
    { register,
      handleSubmit,
      setError,
      watch,
      getValues,
      setValue,
      } = useForm();
   

      const Form =[
        {
            label:"Assign Engineer",
            type:"select",
            name:"assignedEngineer",
            option:engineerOption,
            required:true,
            disabled:false,

        }
      ]
    
    const onSubmit = (data) => {
        // Handle form submission here, you can access form data in the 'data' object
      
            console.log(data,item.ranSupportId,"gcjvhbkl")
        
            dispatch(
                IXSupportActions.postIXDashboardEngineer(
                    data,
                  () => {
                    // dispatch(KPIModuleActions.getKPICIQSitesList())
                    dispatch(IXSupportActions.getIXDashboardList())
                    dispatch(ALERTS({ show: false }));
                  },item.ranSupportId,"bandV=True"
                )
              );
      };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
 <MPAG_CommonForm Form={Form} register={register}/>
 <button type='submit' className='btn btn-primary'>
   Submit
 </button>
</form>
</>
  )
}

export default AssignedEngineerForm