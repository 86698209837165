import { useNavigate } from "react-router-dom";
import Api from "../../utils/api";
import { Urls } from "../../utils/url";
import {
  
    SET_CX_SUPPORT
} from "../reducers/CXSupport-reducer";
import { ALERTS } from "../reducers/component-reducer";
// import { ALERTS } from "../reducers/component-reducer";

const CXSupportActions = {

    getCXSupportList:
    (reset = true, args = "") =>
    async (dispatch, _) => {
      try {
        const res = await Api.get({
          url: `${Urls.CXSupport_Data}${args != "" ? "?" + args : ""}`,
        });
        console.log(res,"result")
        if (res?.status !== 200) return ;
        let dataAll = res?.data?.data;
        console.log(dataAll,"casdasd")

        dispatch(SET_CX_SUPPORT({ dataAll, reset }));
      } catch (error) {
        console.log(error,"error")
      }
    },
  //  manager account starts here
 
  postCXSupportList: (uniqueId=null,data, cb) => async (dispatch, _) => {
    try {
      const res = await Api.post({
        data: data,
        url:
          uniqueId == null
            ? Urls.CXSupport_Data
            : Urls.CXSupport_Data + "/" + uniqueId,
      });
      if (res?.status !== 201 && res?.status !== 200) {
        let msgdata = {
          show: true,
          icon: "error",
          buttons: [],
          type: 1,
          text: res?.data?.msg,
        };
        dispatch(ALERTS(msgdata));
      } else {
        cb();
      }
    } catch (error) {
      return;
    }
  },




};
export default CXSupportActions;



