import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import { configureStore } from '@reduxjs/toolkit';
// import AdminDashboardDataReducer from "./reducers/AdminDashboard_reducer"
import AdminDashboard_reducer from './reducers/AdminDashboard_reducer';
import KPIModuleReducer from './reducers/KPIModule-reducer';
import E911CallTestReducer from './reducers/E911CallTest-reducer';
import CIQScriptingReducer from './reducers/CIQScripting-reducer';

import TroubleTicketReducer from './reducers/TroubleTicket-reducer';
import IXSupportReducer from './reducers/IXSupport-reducer';
import UTRANSupportReducer from './reducers/UTRANSupport-reducer';
import authReducer from './reducers/auth-reducer';
import GoNoGoSupportReducer from './reducers/GoNoGoSupport-reducer';
import CXSupportReducer from './reducers/CXSupport-reducer';
import componentReducer from './reducers/component-reducer';
//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const reducers = combineReducers({
//     posts: PostsReducer,
//     auth: AuthReducer,
// 		todoReducers,
// 	//form: reduxFormReducer,	
	
// });
const store = configureStore  ({
  reducer: {
    
    AdminDashboard_reducer,
      PostsReducer,
      AuthReducer,
      todoReducers,
      KPIModuleReducer,
      E911CallTestReducer,
      CIQScriptingReducer,
      IXSupportReducer,
      TroubleTicketReducer,
      UTRANSupportReducer,
      authReducer,
      GoNoGoSupportReducer,
      CXSupportReducer,
      componentReducer
      // ranSupportReducer,
      // hours72alarmReducer,
      // KPIDashboardReducer
  },
  devTools: true
})
export default store

//const store = createStore(rootReducers);

// export const store = createStore(reducers,  composeEnhancers(middleware));
