import React, { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';
import ActionsModal from '../../../../components/DATAPLUS_Components/ActionsModal'; 
// import AddCustomerForm from './AddCustomerForm'
import FilteringTable from '../../../../components/table/FilteringTable/FilteringTable';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Button,
} from "react-bootstrap";
import { Urls } from '../../../../../utils/url';
import CommonActions from '../../../../../store/actions/common-actions';
import FormModal from '../../../../components/DATAPLUS_Components/FormModal';
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';
import AccountsForm from './AccountsForm';


const Accounts = () => {
 

    const[form,setForm]  = useState(false)
    const [itmValue, setItmValue] = useState({})
    const[alerts,setAlerts] = useState(false)
    const [actions, setActions] = useState(false)
    const [callDelete, setCallDelete] = useState(false)
    const[deleteIndex,setDeleteIndex] = useState(null)
  
    
  
    const dispatch = useDispatch();
    const navigate = useNavigate()
  
    const modalHeading = "+ Account"
    let formComponent  =  (AccountsForm)
    const AddFormButtonName= '+ Account'
    const AddFormNavigate ="/DATAPLUS/Add/account"
  
  
  
    useEffect(()=>{
      dispatch(adminDashboardActions.getManageAccountList())
    },[])
  
    const accountsData  =  useSelector((state)=> state?.AdminDashboard_reducer?.getManageAccountList)
  
    console.log(accountsData,"yhviyubv6f7")
  
     let itemUniqueId = [];
    let accountTableData = accountsData?.map((itm, index) => {
      itemUniqueId[index] = itm.uniqueid
      return {
        "id": index + 1,
        "accountName":itm.accountName,
        "actions": (<div style={{display:'flex', gap:"5px",cursor:'pointer'}}>
          <div  onClick={() => {
              navigate(`/DATAPLUS/Edit/account/${itm.uniqueid}`)
  
            }}><img src='/editIcon2.png' style={{width:"25px",width:"25px"}}/></div>
  
  
            <div  onClick={() => {
              setActions(true)
              setDeleteIndex(index)
  
            }}><img src='/delete2.png' style={{width:"25px",width:"25px",cursor:'pointer'}}/></div>
        </div>)
        
      }
  
    })
     
  
    const marketsTableColumns = [
      {
        Header : 'S No.',
        // Footer : 'S No',
        accessor: 'id',
        Filter: ColumnFilter,
      },
      {
        Header : 'Account',
        // Footer : 'account',
        accessor: "accountName",
        Filter: ColumnFilter,
      },
      {
        Header : 'Action',
        // Footer : 'account',
        accessor: "actions",
        Filter: "",
      },
    ]
    
     const MARKETS_GROUPED_COLUMNS = [
      {
        Header : 'Id',
        Footer : 'Id',
        accessor: 'id'
      },
      {
        Header : 'Name',
        Footer : 'Name',
        columns: [
          {
            Header : 'First Name',
            Footer : 'First Name',
            accessor: 'first_name'
          },
          {
            Header : 'Last Name',
            Footer : 'Last Name',
            accessor: 'last_name'
          },
        ]
      },
      {
        Header: 'Info',
        Footer: 'Info',
        columns: [
          {
            Header : 'Date of  Birth',
            Footer : 'Date of  Birth',
            accessor: 'date_of_birth'
          },
          {
            Header : 'Country',
            Footer : 'Country',
            accessor: 'country',
          },
          {
            Header : 'Phone',
            Footer : 'Phone',
            accessor: 'phone'
          },
        ]
      },
    ]
  
  
    
  
    if (callDelete) {
      dispatch(
        CommonActions.deleteApiCaller(
          `${Urls.Manage_Account_Data}/${itemUniqueId[deleteIndex]}`,
          () => {
  
            console.log(itemUniqueId, "dgcfk")
            dispatch(
              adminDashboardActions.getManageAccountList()
            );
        
          }
        )
      );
      setCallDelete(false)
  
    }
  
    return (
    <>
      {form && <FormModal itmValue={itmValue} isOpen={form} setModal={setForm} FormComponent={formComponent} modalHeading={modalHeading}/>}
      {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} />}
      {actions && <ActionsModal setAlerts={setAlerts} actions={actions} setCallDelete={setCallDelete} setActions={setActions} />}
  
      <div className='flex'>
        <div>
          <FilteringTable 
          AddFormNavigate={AddFormNavigate}
          AddFormButtonName={AddFormButtonName} 
            tableColumns={marketsTableColumns}
             tableData={accountTableData} 
             />
        </div>
      </div>
    </>
  
      
    )
}

export default Accounts