import React, { useState } from 'react'
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import CommonActions from '../../../store/actions/common-actions';
import { useDispatch } from 'react-redux';
import { Urls } from '../../../utils/url';
import adminDashboardActions from '../../../store/actions/AdminDashboard-actions';
import { ALERTS } from '../../../store/reducers/component-reducer';

const ActionsModal = ({setAlerts, actions, setActions,setCallDelete }) => {

    const [smallModal, setSmallModal] = useState(false);

    // console.log(deleteItem,"deleteItem")
    const dispatch = useDispatch()
    return (
        <>
            <Modal
                className="fade bd-example-modal-sm"
                size="sm"
                show={actions}
            >
                {/* <Modal.Header>
                    <Modal.Title>Modal title</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => setActions(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header> */}
                <Modal.Body style={{color:"black",fontWeight:"initial",fontSize:"15px",
                    backgroundColor:'#FEF9FF'
                }}><h5 style={{paddingLeft:'5px',color:'black'}}>Do you Want to Delete this?</h5></Modal.Body>
              <div style={{width:"100%",textAlign:"center",backgroundColor:'#FEF9FF'}}>
                <Button
                style={{width:"100px", margin:"5px"}}
                        variant="primary"
                        onClick={() => setActions(false)}
                    >
                        Back
                    </Button>
                    <Button
                        style={{width:"100px", margin:"5px"}}
                        onClick={()=>{

                                 
                             setTimeout(()=>{
                                setAlerts(true)
                                
                             },1000)
                             setTimeout(()=>{
                                setCallDelete(true)
                             },500)
                             setActions(false)
                             
                              
                        }}
                              variant="danger">Delete</Button>
                              </div>
                {/* <Modal.Footer>
                  
            </Modal.Footer> */}
        </Modal >
    </>
  )
}

export default ActionsModal

