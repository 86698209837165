import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import ExcelUploadForm from '../../pages/DATAPLUS/Admin Dashboard/Project Database/ExcelUploadForm'

const ExcelUploadModal = ({FormComponent,excelModal,setExcelModal}) => {

   
   console.log(FormComponent,"_____form")
  return (
    <>
         <Modal
                           className="fade bd-example-modal-sm"
                           size="sm"
                           show={excelModal}
                        >
                           <Modal.Header style={{backgroundColor:"#470468", color:'white'}}>
                             <div style={{display:'flex'}}> <Modal.Title  style={{ color:'white'}}> Upload File</Modal.Title>
                              <Button
                              style={{ color:'white'}}
                                 variant=""
                                 className="close"
                                 onClick={() =>
                                    
                                    
                                    setExcelModal(false)

                                 }
                              >
                                 <span>&times;</span>
                              </Button></div>
                           </Modal.Header>
                           <Modal.Body style={{backgroundColor:"#FEF9FF"}}>
                            <FormComponent setExcelModal={setExcelModal}/>
                           </Modal.Body>
                           {/* <Modal.Footer>
                              <Button
                                 variant="danger light"
                                 onClick={() => setExcelModal(false)}
                              >
                                 Close
                              </Button>
                              <Button variant="primary">Save changes</Button>
                           </Modal.Footer> */}
                        </Modal>
    </>
  )
}

export default ExcelUploadModal