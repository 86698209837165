import React, { useEffect, useState } from 'react'
import AlertsModal from '../../../../components/DATAPLUS_Components/AlertsModal';
import { useForm } from 'react-hook-form';
import DATAPLUS_CommonForm from '../../../../components/DATAPLUS_Components/DATAPLUS_CommonForm';
import { useDispatch } from 'react-redux';
import adminDashboardActions from '../../../../../store/actions/AdminDashboard-actions';


const EditTableDataPD = ({type=[],modalHeading="",item,modal,setModal}) => {
 
    const dispatch = useDispatch()
    const[alerts,setAlerts] = useState(false)

console.log(type,"itemmmm")

    const
    { register,
      handleSubmit,
      setError,
      watch,
      getValues,
      setValue,
      formState:{errors}
      } = useForm();
   

    
      
      

      const Form =[{
        label:`${modalHeading}`,
        name:`${modalHeading}`,
        type: type?.inputType === 'select' ? type?.inputType : 'textArea',
        option: type?.inputType !== 'select' ? '' : type?.option,
        required:true,
        disabled:false,

    }]


    const onSubmit = (data) => {
        
      
            console.log(data,"______datasumit")

            dispatch(adminDashboardActions.postProjectDatabaseList(item.uniqueid, data, () => {
                dispatch(adminDashboardActions.getProjectDatabaseList())
              }))
        
            
              setTimeout(()=>{
                setAlerts(true)
               },1000)

               setTimeout(()=>{
                if(!alerts)
                    {
                        setModal(false)
                    }
            },3000)
                
      };
      useEffect(() => {
        console.log(item,item?.uniqueid,"________itemmss")
        if (item?.uniqueid) {
          console.log(Form, item, "xdrcg");
          console.log("called_called");
          Form.forEach((key) => {
            console.log(`sdxghbj`, key.name, item[key.name]);
            if (item[key.name] && typeof item[key.name] === 'object' && "$oid" in item[key.name]) {
              setValue(key.name, item[key.name]["$oid"]);
            } else {
              setValue(key.name, item[key.name]);
            }
          });
        }
      }, [item?.uniqueid]);
      
      
  return (
    <>
     {alerts && <AlertsModal alerts={alerts} setAlerts={setAlerts} setModal={setModal}/>}
        <form onSubmit={handleSubmit(onSubmit)}>
    <DATAPLUS_CommonForm Form={Form} errors={errors} register={register}/>
     <button type='submit' className='btn btn-primary'>
       Submit
     </button>
   </form>
    </>
  )

}

export default EditTableDataPD