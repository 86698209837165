import React, { useState } from 'react'
import FilteringTable from '../../../components/table/FilteringTable/FilteringTable';
import { ColumnFilter } from '../../../components/table/FilteringTable/ColumnFilter';
import { Button } from 'react-bootstrap';
import FormModal from '../../../components/DATAPLUS_Components/FormModal';
import CheckInRequestForm from './CheckInRequestForm';

const CheckInRequest = () => {

  const[form,setForm]  = useState(false)
  const[actions,setActions]=useState(false)
  const[callDelete,setCallDelete] = useState(false)
  const [itmValue, setItmValue] = useState({})
  const[alerts,showAlerts] = useState(false)
  

  // 
  const modalButton = "Add Request";
  let FormComponent = (CheckInRequestForm)
  const AddFormButton =(<Button style={{margin:"10px"}}
  variant="primary"
  className="close"
  onClick={() => setForm(true)}
>
  Request
</Button>)
  const tableHeading ="Check In Request Dashbaord"
  const showFilter =false
  
  const tableColumns = [
    {
      Header: 'S No.',
      // Footer: 'S No',
      accessor: 'sNo',
      Filter: ColumnFilter,
    },
    {
      Header: 'SITE ID',
      // Footer: 'account',
      accessor: "accountName",
      Filter: ColumnFilter,
    },
    {
      Header: 'Smaller Cell Suffix',
      // Footer: 'S No',
      accessor: 'marketName',
      Filter: ColumnFilter,
    },
    {
      Header: 'Development or Operations Work',
      // Footer: 'account',
      accessor: "mobilenumber",
      Filter: ColumnFilter,
    },
    {
      Header: 'Check Project',
      // Footer: 'S No',
      accessor: 'name',
      Filter: ColumnFilter,
    },
    {
      Header: 'Project',
      // Footer: 'S No',
      accessor: '',
      Filter: ColumnFilter,
    },
    {
      Header: 'Equipment Vendor',
      // Footer: 'account',
      accessor: "crewCompanyName",
      Filter: ColumnFilter,
    },
    {
      Header: 'Vendor',
      // Footer: 'S No',
      accessor: 'email',
      Filter: ColumnFilter,
    },
    {
      Header: 'SA/NSA',
      // Footer: 'account',
      accessor: "mobileAppAccess",
      Filter: ColumnFilter,
    },
    {
      Header: 'Nest(hours)',
      // Footer: 'S No',
      accessor: 'password',
      Filter: ColumnFilter,
    },
    {
      Header: 'SOW',
      // Footer: 'account',
      accessor: "userrole",
      Filter: ColumnFilter,
    },
    {
      Header: 'SOW others',
      // Footer: 'S No',
      accessor: 'crewType',
      Filter: ColumnFilter,
    },
    {
      Header: 'TT/CR',
      // Footer: 'S No',
      accessor: '',
      Filter: ColumnFilter,
    },
    {
      Header: 'Updated information from GC after rejection',
      // Footer: 'S No',
      accessor: '',
      Filter: ColumnFilter,
    },
    {
      Header: 'ACTION',
      // Footer: 'account',
      accessor: "actions",
      Filter: "",
    },


  ]



  const GROUPED_COLUMNS = [
    {
      Header: 'Id',
      Footer: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Name',
      Footer: 'Name',
      columns: [
        {
          Header: 'First Name',
          Footer: 'First Name',
          accessor: 'first_name'
        },
        {
          Header: 'Last Name',
          Footer: 'Last Name',
          accessor: 'last_name'
        },
      ]
    },
    {
      Header: 'Info',
      Footer: 'Info',
      columns: [
        {
          Header: 'Date of  Birth',
          Footer: 'Date of  Birth',
          accessor: 'date_of_birth'
        },
        {
          Header: 'Country',
          Footer: 'Country',
          accessor: 'country',
        },
        {
          Header: 'Phone',
          Footer: 'Phone',
          accessor: 'phone'
        },
      ]
    },
  ]

  // if(callDelete)
  //   {
  //   dispatch(
  //     CommonActions.deleteApiCaller(
  //       `${Urls.Delete_GC_Item}/${itemUniqueId}`,
  //       () => {

  //         console.log(itemUniqueId, "dgcfk")
  //         dispatch(
  //           adminDashboardActions.getGCList()
  //         );
  //         dispatch(ALERTS({ show: false }));
  //       }
  //     )
  //   );
  //   setCallDelete(false)
  
  // }
  
  return (
    <>
      


    {/* {actions && <ActionsModal  actions={actions} setCallDelete={setCallDelete} setActions={setActions} />} */}
     
      {/* {modal && <EditModal modalHeading={modalHeading} setModal={setModal} isOpen={modal} FormComponent={FormComponent} itmValue={itmValue} />} */}

      
      {form && <FormModal modalHeading={modalButton} isOpen={form} setModal={setForm} FormComponent={FormComponent} itmValue={itmValue}/>}

      {/* <MPAG_Modal modalButton={modalButton} FormComponent={FormComponent} /> */}
      {/* <MPAG_Modal tableColumns={tableColumns} tableData={tableData}  /> */}
  {/* <FilterModal FormComponent={Filter} tableColumns={tableColumns} tableData={tableData} modalButton={filterButton}/> */}
  {/* <h4 className="card-title" style={{marginLeft:"10px"}}>{tableHeading}</h4> */}
      <div>
        <FilteringTable AddFormButton={AddFormButton} showFilter={showFilter} tableColumns={tableColumns} tableData={['a','b']} tableHeading={tableHeading} />
      </div>
    </>
  )


}

export default CheckInRequest