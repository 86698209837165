import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination, useSortBy } from 'react-table';
import { Row, Card, Button } from "react-bootstrap";
import { GlobalFilter } from './GlobalFilter';
import FilterModal from '../../DATAPLUS_Components/FilterModal';
import { useNavigate } from 'react-router-dom';
import './filtering.css';

export const ExpandableTablePD = ({
    expandKey,
    tableColumns2,
    uploadExcel = false,
    showMarket = "",
    filterHeading = "",
    AddFormButton = (<></>),
    tableColumns,
    tableData,
    expandedRowContent,
    onRowClick,
    setForm = "false",
    tableHeading,
    showFilter = true,
    showButton,
    AddFormButtonName = "",
    AddFormNavigate = "",
    setExcelModal = "false"
}) => {
    const [expandedRows, setExpandedRows] = useState(new Set());

    const EXPANDED_COLUMNS = tableColumns2.map((itm) =>  {return{accessor:itm.accessor,header:itm.Header}});
    console.log(EXPANDED_COLUMNS, "______dayta");

    const handleRowClick = (siteId) => {
        setExpandedRows(prev => {
            const newExpandedRows = new Set(prev);
            if (newExpandedRows.has(siteId)) {
                newExpandedRows.delete(siteId);
            } else {
                newExpandedRows.add(siteId);
            }
            return newExpandedRows;
        });
    };

    const columns = useMemo(() => tableColumns, []);
    const data = useMemo(() => Array.isArray(tableData) ? tableData : [], [tableData]);
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance;

    const { globalFilter, pageIndex } = state;
    const navigate = useNavigate();
    const modalButton = "Filter";

    const specialKeys = ["_","PSAP ", "e911 ", "TVW ", "EDP ", "IX ", "CIQ ", "RFDS ", "IWOS "];
    

    const formatExpandedContent = (rowData) => {
      
        const regularColumns = EXPANDED_COLUMNS.filter(col => !specialKeys.some(key => col.header.includes(key)));
        const regularRows = [];
        for (let i = 0; i < regularColumns.length; i += 4) {
            const chunk = regularColumns.slice(i, i + 4);
            regularRows.push(
                <tr style={{width:'200%'}} key={`row-${i}`} className="expanded-row">
                    {chunk.map((columnKey, j) => (
                        <td
                        
                            key={`cell-${i}-${j}`}
                            style={{  borderWidth: '1px', borderStyle: 'solid', borderColor: '#470468', boxShadow: '0 0 10px #470468' }}
                            className="expanded-cell"
                        >
                             
                            <strong className="expanded-header">{columnKey.header}:</strong> {rowData[columnKey.accessor] || "N/A"}
                        </td>
                    ))}
                    {chunk.length < 4 && [...Array(4 - chunk.length)].map((_, j) => <td key={`cell-${i}-${j + chunk.length}`} className="expanded-cell-empty"></td>)}
                </tr>
            );
        }
        const specialRows = specialKeys.map((key, index) => {
            const filteredColumns = EXPANDED_COLUMNS.filter(col => col.header.includes(key));
            if (filteredColumns.length > 0) {
                return (
       
        <div style={{display:"grid",margin:"10px", position:'relative', border: '1px solid #ccc', borderColor: '#470468', boxShadow: '0 0 10px #470468' , borderRadius: '5px', padding: '10px', margin: '10px 0',width:'100%',marginTop:"40px" }}>
            <div style={{ position:'absolute',top:'-10px',left:"10px",backgroundColor:"#470468",paddingInline:"5px", paddingBlock:'2px', fontWeight: 'bold', marginBottom: '10px', color: 'white',borderRadius:'5px' }}>{key=== "_" ? "SITE INFO":key}</div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {filteredColumns.map((col, colIndex) => (
                    <div key={colIndex} style={{ flex: '1 1 45%', marginBottom: '10px', marginTop:'10px' }}>
                        <strong>{col.header}:</strong> 
                        <div style={{width:'fit-content', height:'fit-content', }}>{rowData[col.accessor] ? <div style={{padding:'10px',borderColor: '#470468', boxShadow: '0 0 10px #470468',border: '1px solid #ccc', borderRadius: '5px',color:'black', paddingLeft:"10px",margin: '10px 0',alignItems:'center', }}>{rowData[col.accessor]}</div>:"1234" }</div>
                    </div>
                    // div -<div style={{ color:'black', paddingLeft:"10px",margin: '10px 0',alignItems:'center',  }}>{rowData[col.accessor]}</div>:<div >{""}</div>
                    // css -borderColor: '#470468', boxShadow: '0 0 10px #470468',height:'23px' , border: '1px solid #ccc', borderRadius: '5px'
                ))}
            </div>
        </div>
                );
            }
            return null;
        }).filter(Boolean);

       
       

        return [...regularRows,...specialRows];
    };

    const [columnsPerRow, setColumnsPerRow] = useState(1);

    const updateColumnsPerRow = () => {
        const width = window.innerWidth;
        if (width < 576) {
            setColumnsPerRow(1); // small size
        } else if (width >= 576 && width < 768) {
            setColumnsPerRow(1); // small size
        } else if (width >= 768 && width < 992) {
            setColumnsPerRow(2); // medium size
        } else if (width >= 992) {
            setColumnsPerRow(3); // large size
        }
    };

    useEffect(() => {
        updateColumnsPerRow();
        window.addEventListener('resize', updateColumnsPerRow);
        return () => window.removeEventListener('resize', updateColumnsPerRow);
    }, []);

    const Filter = (
        <Card style={{ color: "black" }} className=''>
            <Card.Body className=''>
                {headerGroups.map(headerGroup => (
                    <div
                        style={{
                            justifyContent: 'center',
                            margin: "10px",
                            color: "black",
                            display: "flex",
                            flexWrap: "wrap"
                        }}
                        className="grid-form"
                        {...headerGroup.getHeaderGroupProps()}
                    >
                        {headerGroup.headers.map((column, index) => (
                           
                            <div
                                key={index}
                                style={{
                                    flex: `1 1 ${100 / columnsPerRow}%`,
                                    maxWidth: `${100 / columnsPerRow}%`,
                                    boxSizing: "border-box",
                                    padding: "5px"
                                }}
                                
                                {...column.getHeaderProps()}
                            >
                                {(column.Header !== "Actions" && column.Filter !== "") && column.render("Header")}
                                {column?.canFilter ? column.render("Filter") : null}
                            </div>
                        ))}
                    </div>
                ))}
            </Card.Body>
        </Card>
    );

    return (
        <>
            <div className="card">
                <div className="card-header" style={{ display: "flex", justifyContent: "space-between", height: "80px" }}>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                    </div>
                    <div className='add-form-button'>
                        {showFilter && <FilterModal
                            FilteringComponent={Filter} modalButton={modalButton} />}

                        {console.log(AddFormNavigate, "AddFormNavigate")}

                        {uploadExcel ? <Button style={{ marginLeft: "10px", backgroundColor: "#470468", borderColor: "#470468" }}
                            variant="primary"
                            className="close"
                            onClick={() => setExcelModal(true)}
                        >
                            Upload File
                        </Button> : ""}

                        {AddFormButtonName !== "" && (<Button style={{ marginLeft: "10px", backgroundColor: "#470468", borderColor: "#470468" }}
                            variant="primary"
                            className="close"
                            onClick={() => navigate(AddFormNavigate)}
                        >
                            {AddFormButtonName}
                        </Button>)}
                        {AddFormButtonName === "" && (AddFormButton)}
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    console.log(row, "_____Rowwww");
                                    prepareRow(row);
                                    const siteId = row.original[`${expandKey}`];
                                    const isExpanded = expandedRows.has(siteId);
                                    return (
                                        <React.Fragment key={row.id}>
                                            <tr  {...row.getRowProps()} onClick={() => handleRowClick(siteId)}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                ))}
                                            </tr>
                                            {isExpanded && (
                                                <tr >
                                                    <td  colSpan={headerGroups[0].headers.length}>
                                                        <table className="expanded-table">
                                                            <tbody >
                                                           
                                                                {formatExpandedContent(row.original)}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                        <div>
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <span>
                                | Go to page:{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(pageNumber);
                                    }}
                                    style={{ width: '80px' }}
                                />
                            </span>
                        </div>
                       <div className="text-center">
                            <div className="filter-pagination mt-3" style={{ backgroundColor: "#fff" }}>
                                <button
                                    style={{ color: 'black' }}
                                    className="previous-button"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {'<<'}
                                </button>
                                <button
                                    style={{ color: 'black' }}
                                    className="previous-button"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    Previous
                                </button>
                                <button
                                    style={{ color: 'black' }}
                                    className="next-button"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    Next
                                </button>
                                <button
                                    style={{ color: 'black' }}
                                    className="next-button"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {'>>'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExpandableTablePD;


 //             <div key={index} className="special-section">
        //                <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', margin: '10px 0' }}>
        //     <div style={{ fontWeight: 'bold', marginBottom: '10px', color: '#007bff' }}>{key}</div>
        //     <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        //         {filteredColumns.map((col, colIndex) => (
        //             <div key={colIndex} style={{ flex: '1 1 45%', marginBottom: '10px' }}>
        //                 <strong>{col.header}:</strong> {rowData[col.accessor] ?<div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', margin: '10px 0' }}>{rowData[col.accessor]}</div>:<div>NA</div>}
        //             </div>
        //         ))}
        //     </div>
        // </div>
        //             </div>