import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import componentReducer from '../../../store/reducers/component-reducer'

const AlertsModal = ({setAlerts,alerts,navigateLink="",setModal="",msg=""}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  let msgData  =  useSelector((state)=>state?.componentReducer?.alerts)
  console.log(msgData,"____wertyui")



  console.log(msgData.text,"_____msgData")
  console.log(navigateLink,"navigateLink")
  return (
    <>
        <Modal className="fade" 
        show={alerts}
        >
                           {/* <Modal.Header> */}
                              {/* <Modal.Title>Modal title</Modal.Title> */}
                              {/* <Button
                                 onClick={() => setModalCentered(false)}
                                 variant=""
                                 className="close"
                              >
                                 <span>&times;</span>
                              </Button> */}
                           {/* </Modal.Header> */}
                           <Modal.Body style={{textAlign:"center", backgroundColor:'#FEF9FF'}}>
                          { msgData.show ? <i style={{color:"green",fontWeight:"bold",fontSize:"50px"}} class="bi bi-check2-circle"></i>:
                          <i style={{color:"red",fontWeight:"bold",fontSize:"50px"}}class="bi bi-x-octagon"></i>}
                              <p style={{color:"black",fontWeight:"initial",fontSize:"15px"}}>
                                {msgData?.text ? msgData?.text : "Something went Wrong" }
                              </p>
                           </Modal.Body>
                           <div style={{width:"100%",textAlign:"right",backgroundColor:'#FEF9FF'}}>
                           <Button
                           style={{width:"100px", margin:"10px"}}
                           onClick={
                                ()=>{return setAlerts(false) , (msgData.show&&setModal==="")&&navigate(navigateLink)}} 
                              variant={msgData.show ? "primary":"danger"}>{msgData.show ? "OK":"Close"}</Button>
                           </div>
                        </Modal>

    </>
  )
}

export default AlertsModal